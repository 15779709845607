<template>
  <div class="statistics-wrapper">
    <search-form v-model="searchParams" :fields="searchFields" :okLoading="loading" :fieldsVal="fieldsVal" @on-search="toSearch" @on-reset="toReset" ref="searchForm" :selectData="searchSelectData" @on-change="searchChange"></search-form>
    <div class="statistics-content-wrapper">
      <div class="statistics-tab table-top">
        <div class="tab-left">
          <ul>
            <li v-for="(tab, tInd) in staTab"
                :key="tInd"
                :class="{'current': tab.key === staTKey}"
                @click="toTab(tab, tInd)"
                v-has-permi="tab.permission">
              {{tab.name}}
            </li>
          </ul>
          <template v-if="staTKey === 'list'">
<!--            <Button :loading="exportLoading" :disabled="exportLoading" @click="toOpr('export')" v-has-permi="['PRIV_STATISTICS_CONSUME_EXPORT']"><Icon type="md-download" />导出当前页</Button>
            <Button :loading="exportLoading" :disabled="exportLoading" @click="toOpr('exportAll')" v-has-permi="['PRIV_STATISTICS_CONSUME_EXPORT']"><Icon type="md-download" />导出全部</Button>-->
          </template>
        </div>
      </div>
      <div class="statistics-box" ref="table" :style="{height: tableHeight + 'px'}">
        <Loading :show="loading" :styl="{background: 'rgba(255,255,255,.3)'}"></Loading>
        <div class="charts-box" v-show="staTKey === 'chart'" id="charts-item" ref="charts" ></div>
        <div class="statistics-grid" v-if="staTKey === 'list'">
          <Table stripe :columns="columns" :data="list" :loading="loading" :height="tableHeight - 70" @on-sort-change="sortChange" @on-selection-change="tableSelectChange"></Table>
          <Page
            :total="total"
            :current="params.pageIndex"
            :page-size="params.pageSize"
            show-elevator
            show-sizer
            show-total
            @on-change="pageChange"
            @on-page-size-change="pageSizeChange"
            class="table-page1"></Page>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import * as echarts from 'echarts'
import SearchForm from '@/components/SearchForm'
import { mapState } from 'vuex'
import { getPersonConsume, personConsumeExport } from '@/libs/api'
import { exportExcel, getStorage } from '@/libs/tools'
import Loading from '@/components/Loading'
import { getAllSiteList } from '@/libs/requestCommon'
export default {
  name: "period-statis",
  components: {
    SearchForm,
    Loading
  },
  data () {
    return {
      loading: false,
      exportLoading: false,
      user: getStorage('user') ? JSON.parse(getStorage('user')) : {},
      fieldsVal: {
        yearMonth: dayjs(new Date()).format('YYYY-MM'),
        siteIds: []
      },
      searchFields: [
        {
          type: 'month',
          label: '时间',
          name: 'yearMonth',
          clearable: false,
          editable: false
        },
        {
          type: 'input',
          label: '手机号',
          name: 'userPhone'
        },
        {
          type: 'select',
          label: '站点名称',
          name: 'siteIds',
          useValue: 'value',
          cls: 'half',
          filterable: true,
          multiple: true
        }
      ],
      searchParams: {},
      searchSelectData: {},
      staTab: [
        {
          name: '个人消费统计图',
          key: 'chart',
          permission: ['PRIV_STATISTICS_CONSUME_CHART']
        },
        {
          name: '个人消费列表',
          key: 'list',
          permission: ['PRIV_STATISTICS_CONSUME_LIST']
        }
      ],
      staTKey: 'chart',
      params: {
        pageSize: 20,
        pageIndex: 1,
        sortColumn: 'totalElectricQuantity',
        sortOrder: 0
      },
      total: 0,
      columns: [
        /*{
          type: 'selection',
          width: 60,
          align: 'center'
        },*/
        {
          key: 'index',
          title: '序号',
          width: 70,
          align: 'center',
          render: (h, params) => {
            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'userPhone',
          title: '手机号',
          align: 'center',
          minWidth: 150
        },
        {
          key: 'siteName',
          title: '所属站点',
          align: 'center',
          width: 200
        },
        {
          key: 'consumptionAmount',
          title: '消费金额(元)',
          align: 'center',
          width: 150,
          sortable: 'custom',
          render: (h, params) => {
            let num = (params.row.consumptionAmount / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'paymentAmount',
          title: '实际支付金额(元)',
          align: 'center',
          width: 180,
          sortable: 'custom',
          render: (h, params) => {
            let num = (params.row.paymentAmount / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'totalElectricQuantity',
          title: '总电量(度)',
          align: 'center',
          width: 120,
          sortable: 'custom',
          render: (h, params) => {
            let num = (params.row.totalElectricQuantity / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'tipCharge',
          title: '尖电量(度)',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            let num = (params.row.tipCharge / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'peakCharge',
          title: '峰电量(度)',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            let num = (params.row.peakCharge / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'averageCharge',
          title: '平电量(度)',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            let num = (params.row.averageCharge / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'valleyCharge',
          title: '谷电量(度)',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            let num = (params.row.valleyCharge / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        }
      ],
      list: [],
      chartsData: [],
      chartsObj: null,
      chartsOpt: {
        color: ['#fac858', '#6fd2ef'],
        grid: {
          left: '3%',
          right: '3%',
          bottom: '10px',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: []
        },
        yAxis: [
          {
            type: 'value'
          }
        ],
        legend: {
          top: '20px',
          data: ['消费金额(元)', '电量(度)']
        },
        series: [
          {
            data: [],
            name: '消费金额(元)',
            type: 'bar',
            smooth: true,
            label: {
              show: false,
              position: 'top'
            }
          },
          {
            data: [],
            name: '电量(度)',
            type: 'line',
            smooth: true,
            label: {
              show: false
            }
          }
        ]
      },
      selectedList: []
    }
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  mounted () {
    this.$nextTick(() => {
      let powers = getStorage('user') ? JSON.parse(getStorage('user')).userPower : []
      let powerTab = this.staTab.filter((ele) => {
        return powers.includes(ele.permission[0])
      })
      this.staTKey = powerTab.length ? powerTab[0].key : ''
      this.getSiteData()
      this.searchParams = Object.assign({}, this.fieldsVal)
      setTimeout(() => {
        this.getData()
      }, 200)
    })
  },
  methods: {
    getData () {
      setTimeout(() => {
        if (this.staTKey === 'chart') {
          this.getCharts()
        } else if (this.staTKey === 'list'){
          this.getList()
        }
      }, 0)
    },
    toSearch (obj) {
      this.searchParams = obj
      this.params.pageIndex = 1
      this.getData()
    },
    toReset (obj) {
      this.searchParams = obj
      this.fieldsVal.yearMonth = dayjs(new Date()).format('YYYY-MM')
      this.searchParams.yearMonth = dayjs(new Date()).format('YYYY-MM')
      this.getData()
    },
    toTab (tab) {
      this.staTKey = tab.key
      this.params.pageIndex = 1
      this.getData()
    },
    pageChange (pageIndex) {
      this.params.pageIndex = pageIndex
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    getCharts () {
      this.chartsObj = echarts.init(document.getElementById('charts-item'))
      let width = this.$refs.table.clientWidth
      let height = this.$refs.table.clientHeight
      this.chartsObj.resize({
        width: width + 'px',
        height: height + 'px'
      })
      this.setChartsData()
    },
    setChartsData () {
      let xData = []
      let amountData = []
      let chargeData = []
      let params = {
        pageSize: 30,
        sortColumn: 'consumptionAmount',
        sortOrder: 0
      }
      this.getList(params,(data) => {
        this.chartsData = data
        if (data.length) {
          data.forEach((item) => {
            xData.push(item.userPhone)
            amountData.push((item.consumptionAmount / 10000).toFixed(2))
            chargeData.push((item.totalElectricQuantity / 10000).toFixed(2))
          })
        }
        this.chartsOpt.xAxis.data = xData
        this.chartsOpt.series[0].data = amountData
        this.chartsOpt.series[1].data = chargeData
        this.chartsObj.setOption(this.chartsOpt)
      })
    },
    async getList (params, callback) {
      this.loading = true
      await getPersonConsume(Object.assign({}, this.searchParams, this.params, params)).then((res) => {
        if (res.data.code === 0) {
          this.loading = false
          this.list = res.data.data.dataSource
          this.total = res.data.data.totalCount
          // this.list = res.data.data.PersonalMonthlyRanking.data
          // this.total = res.data.data.PersonalMonthlyRanking.total
          callback && callback(this.list)
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    sortChange (params) {
      let sortInd = {
        asc: 1, // 升序
        desc: 0 // 降序
      }
      this.params.sortColumn = params.key
      this.params.sortOrder = sortInd[params.order]
      this.getList()
    },
    tableSelectChange (selection) { // table选中项发生变化时就会触发
      this.selectedList = selection
    },
    toOpr (type) {
      if (['export', 'exportAll'].includes(type)) {
        if (!this.list.length) {
          return this.$Message.error('暂无数据!')
        }
        this.$Modal.confirm({
          title: '导出',
          content: '确认导出?',
          onOk: () => {
            let data = Object.assign({}, this.searchParams, this.params)
            if (type === 'exportAll') {
              data.pageSize = this.total
            }
            personConsumeExport(data).then((res) => {
              if (res.status === 200) {
                 exportExcel('个人消费统计报表-[' + dayjs(new Date(this.searchParams.yearMonth)).format('YYYY-MM')+ ']月度汇总', res.data)
              } else {
                this.$Message.error(res.data)
              }
            })
          }
        })
      }
    },
    async getSiteData () {
      getAllSiteList((arr) => {
        this.searchSelectData.siteIds = arr
        this.fieldsVal.siteIds = this.user.siteIds ? (this.user.siteIds.indexOf(',') > -1 ? this.user.siteIds.split(',') : [this.user.siteIds]) : []
      })
    },
    searchChange (val) {
      this.searchParams = val
    }
  },
  watch: {
    searchParams: {
      handler (val) {
        if (Array.isArray(val.siteIds)) {
          if (val.siteIds.length) {
            val.siteIds = val.siteIds.join(',')
          } else {
            val.siteIds = this.user.siteIds
          }
        } else {
          if (!val.siteIds) {
            val.siteIds = this.user.siteIds
          }
        }
        val.yearMonth && (val.yearMonth = dayjs(new Date(val.yearMonth)).format('YYYY-MM'))
      }
    }
  }
};
</script>

<style scoped lang="less">
  @import "statistics";
</style>
