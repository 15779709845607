<template>
  <div class="no-data" :style="styles">
    <i class="iconfont icon-wushuju"></i>
    <p>{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    title: {
      type: String,
      default: '暂无数据！'
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="less">
.no-data{
  width: 100%^;
  display: flex;
  flex-wrap: wrap;
  padding: @base * 4 0;
  justify-content: center;
  i{
    font-size: 72px;
  }
  p{
    width: 100%;
    text-align: center;
    padding-top: @base;
  }
}
</style>