<template>
  <div class="help-wrapper">
    <search-form :fields="searchFields" @on-search="toSearch" @on-reset="toReset" :okLoading="loading"></search-form>
    <div class="help-content" :style="{height: tableHeight + 'px'}">
      <div class="table-top">
        <Button href="javascript:;" @click="toOpr('add')" v-has-permi="['PRIV_MEMBERS_CREATE']"><Icon type="md-add"/> 新增</Button>
      </div>
      <Table stripe :columns="columns" :data="list" :loading="loading" ref="table" :height="tableHeight - 62"></Table>
    </div>
    <Modal :title="oprType === 'add' ? '新增' : '编辑'"
           v-model="showModal"
           width="700"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           :mask-closable="false">
      <div slot="footer">
        <Button @click="modalCancel">取消</Button>
        <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :model="modalFormData" ref="modalForm" :label-width="120" :rules="modalRuleValid">
        <div class="form-content">
          <FormItem label="等级编号: " prop="memberLevelNumber" class="half">
            <Input type="number" v-model="modalFormData.memberLevelNumber"/>
          </FormItem>
          <FormItem label="等级名称: " prop="memberLevelName" class="half">
            <Input v-model="modalFormData.memberLevelName"/>
          </FormItem>
          <FormItem label="折扣类型" class="half" prop="discountType">
            <Select v-model="modalFormData.discountType">
               <Option v-for="(opt, oInd) in discountTypeOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
            </Select>
          </FormItem>
          <template v-if="modalFormData.discountType === 1">
            <FormItem label="会员折扣: " prop="memberLevelDiscount" class="half">
              <Input type="number" v-model="modalFormData.memberLevelDiscount">
                <span slot="append">折</span>
              </Input>
            </FormItem>
          </template>
          <template v-if="modalFormData.discountType === 2">
            <FormItem label="尖会员价: " prop="tipMemberPrice" class="half">
              <Input type="number" v-model="modalFormData.tipMemberPrice">
                <span slot="append">元</span>
              </Input>
            </FormItem>
            <FormItem label="峰会员价: " prop="peakMemberPrice" class="half">
              <Input type="number" v-model="modalFormData.peakMemberPrice">
                <span slot="append">元</span>
              </Input>
            </FormItem>
            <FormItem label="平会员价: " prop="averageMemberPrice" class="half">
              <Input type="number" v-model="modalFormData.averageMemberPrice">
                <span slot="append">元</span>
              </Input>
            </FormItem>
            <FormItem label="谷会员价: " prop="valleyMemberPrice" class="half">
              <Input type="number" v-model="modalFormData.valleyMemberPrice">
                <span slot="append">元</span>
              </Input>
            </FormItem>
          </template>
          <template v-if="modalFormData.discountType === 3">
            <FormItem label="尖服务费单价: " prop="tipServiceCharge" class="half">
              <Input type="number" v-model="modalFormData.tipServiceCharge">
                <span slot="append">元</span>
              </Input>
            </FormItem>
            <FormItem label="峰服务费单价: " prop="peakServiceCharge" class="half">
              <Input type="number" v-model="modalFormData.peakServiceCharge">
                <span slot="append">元</span>
              </Input>
            </FormItem>
            <FormItem label="平服务费单价: " prop="averageServiceCharge" class="half">
              <Input type="number" v-model="modalFormData.averageServiceCharge">
                <span slot="append">元</span>
              </Input>
            </FormItem>
            <FormItem label="谷服务费单价: " prop="valleyServiceCharge" class="half">
              <Input type="number" v-model="modalFormData.valleyServiceCharge">
                <span slot="append">元</span>
              </Input>
            </FormItem>
          </template>
          <FormItem label="一口价" prop="fixedBuyPrice" class="half">
            <Input type="number" v-model="modalFormData.fixedBuyPrice">
              <span slot="append">元</span>
            </Input>
          </FormItem>
          <FormItem label="备注: " prop="memberLevelRemarke" class="long">
            <Input v-model="modalFormData.memberLevelRemarke" type="textarea" />
          </FormItem>
        </div>
      </Form>
    </Modal>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import { mapState } from 'vuex'
import { getMemberList, memberAdd, memberUpdate } from '@/libs/api'
import { userTypeOpt, userSexOpt, discountTypeOpt } from '@/libs/dict'
import { getSelectLabel } from '@/libs/tools'
let formData = {
  memberLevelNumber: '',
  memberLevelName: '',
  memberLevelDiscount: '',
  memberLevelRemarke: '',
  discountType: '',
  tipMemberPrice: '',
  peakMemberPrice: '',
  averageMemberPrice: '',
  valleyMemberPrice: '',
  tipServiceCharge: '',
  peakServiceCharge: '',
  averageServiceCharge: '',
  valleyServiceCharge: '',
  fixedBuyPrice: ''
}
export default {
  name: "member-list",
  components: {
    SearchForm
  },
  data () {
    let priceValid = { validator: (rule, value, callback) => {
        let reg = /^(([1-9]\d*)|0)(\.\d{1,5})?$/
        if (!reg.test(value)) {
        callback('请输入小数点最多5位的数字')
      } else {
      callback()
    }
  }, trigger: 'change'}
    return {
      userTypeOpt,
      userSexOpt,
      discountTypeOpt,
      searchFields: [
        {
          label: '等级名称',
          name: 'memberLevelName',
          type: 'input'
        }
      ],
      searchParams: {},
      loading: false,
      columns: [
        {
          type: 'index',
          title: '序号',
          width: 70,
          align: 'center'
        },
        {
          key: 'memberLevelNumber',
          title: '等级编号',
          width: 100,
          align: 'center'
        },
        {
          key: 'memberLevelName',
          title: '会员名称',
          align: 'center',
          width: 150
        },
        {
          key: 'discountType',
          title: '折扣类型',
          align: 'center',
          width: 180,
          render: (h, params) => {
            let str = params.row.discountType ? getSelectLabel(discountTypeOpt, params.row.discountType) : '--'
            return (<span>{str}</span>)
          }
        },
        {
          key: 'memberLevelDiscount',
          title: '折扣',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let discount = '--'
            if (params.row.discountType === 1) {
              discount = params.row.memberLevelDiscount / 100 + '折'
            }
            return (<span>{discount}</span>)
          }
        },
        {
          key: 'tipElectricityPrice',
          title: '尖时段一口价(元)',
          align: 'center',
          width: 180,
          render: (h, params) => {
            let str  = '--'
            if (params.row.discountType === 2) {
              return (<span>{(params.row.tipMemberPrice / 100000).toFixed(5)}</span>)
            } else {
              return (<span>{str}</span>)
            }
          }
        },
        {
          key: 'peakElectricityPrice',
          title: '峰时段一口价(元)',
          align: 'center',
          width: 180,
          render: (h, params) => {
            let str  = '--'
            if (params.row.discountType === 2) {
              return (<span>{(params.row.peakMemberPrice / 100000).toFixed(5)}</span>)
            } else {
              return (<span>{str}</span>)
            }
          }
        },
        {
          key: 'averageElectricityPrice',
          title: '平时段一口价(元)',
          align: 'center',
          width: 180,
          render: (h, params) => {
            let str  = '--'
            if (params.row.discountType === 2) {
              return (<span>{(params.row.averageMemberPrice / 100000).toFixed(5)}</span>)
            } else {
              return (<span>{str}</span>)
            }
          }
        },
        {
          key: 'valleyElectricityPrice',
          title: '谷时段一口价(元)',
          align: 'center',
          width: 180,
          render: (h, params) => {
            let str  = '--'
            if (params.row.discountType === 2) {
              return (<span>{(params.row.valleyMemberPrice / 100000).toFixed(5)}</span>)
            } else {
              return (<span>{str}</span>)
            }
          }
        },
        {
          key: 'memberLevelRemarke',
          title: '备注',
          align: 'center',
          minWidth: 300,
          render: (h, params) => {
            let str = params.row.memberLevelRemarke ?  params.row.memberLevelRemarke : '--'
            return (<span>{str}</span>)
          }
        },
        {
          key: 'opr',
          title: '操作',
          align: 'center',
          fixed: 'right',
          minWidth: 120,
          render: (h, params) => {
            return (
              <span className="table-opr-btn">
                <a href="javascript:;" className="mod" onClick={this.toOpr.bind(this, 'mod', params.row)} v-has-permi={['PRIV_MEMBERS_UPDATE']}><i
                  class="iconfont icon-bianji"></i> 编辑</a>
              </span>
            )
          }
        }
      ],
      list: [],
      oprType: '',
      modData: {},
      showModal: false,
      modalFormData: Object.assign({}, formData),
      modalRuleValid: {
        memberLevelNumber: [
          { required: true, message: '请输入等级编号!'}
        ],
        memberLevelName: [
          { required: true, message: '请输入等级名称!'},
        ],
        discountType:[
          { required: true, message: '请选择折扣类型!'},
        ],
        memberLevelDiscount: [
          { required: true, message: '请输入会员折扣!'}
        ],
        tipMemberPrice: [
          { required: true, message: '请输入价格!'},
          priceValid
        ],
        peakMemberPrice: [
          { required: true, message: '请输入价格!'},
          priceValid
        ],
        averageMemberPrice: [
          { required: true, message: '请输入价格!'},
          priceValid
        ],
        valleyMemberPrice: [
          { required: true, message: '请输入价格!'},
          priceValid
        ],
        tipServiceCharge: [
          { required: true, message: '请输入价格!'},
          priceValid
        ],
        peakServiceCharge: [
          { required: true, message: '请输入价格!'},
          priceValid
        ],
        averageServiceCharge: [
          { required: true, message: '请输入价格!'},
          priceValid
        ],
        valleyServiceCharge: [
          { required: true, message: '请输入价格!'},
          priceValid
        ],
        fixedBuyPrice: [
          priceValid
        ]
      },
      showMemberOpt: false,
      btnLoading: false
    }
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  mounted () {
    this.$nextTick(() => {
      this.getList()
    })
  },
  methods: {
    toSearch (obj) {
      this.searchParams = obj
      this.getList()
    },
    toReset (obj) {
      this.searchParams = obj
      this.getList()
    },
    async getList () {
      this.loading = true
      await getMemberList(Object.assign({}, this.searchParams)).then((res) => {
        if (res.data.code === 0) {
          this.loading = false
          this.list = res.data.data
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    toOpr (type, row) {
      this.oprType = type
      if (row) {
        this.modData = row
      }
      if (['add', 'mod'].includes(type)) {
        this.showModal = true
      }
    },
    modalCancel () {
      this.showModal = false
    },
    modalOk () {
      this.$refs.modalForm.validate(async (valid) => {
        if (valid) {
          let url = {
            add: memberAdd,
            mod: memberUpdate
          }
          let formData = {}
          this.$refs.modalForm.fields.forEach((field) => {
            let name = field.prop
            if ([ 'tipMemberPrice', 'peakMemberPrice', 'averageMemberPrice', 'valleyMemberPrice', 'tipServiceCharge', 'peakServiceCharge', 'averageServiceCharge', 'valleyServiceCharge', 'fixedBuyPrice'].includes(name)) { // 价格处理
              formData[name] = (this.modalFormData[name] * 100000).toFixed(0)
            } else {
              formData[name] = this.modalFormData[name]
            }
          })
          if (formData.discountType === 1) { // 总服务费折扣
            formData.memberLevelDiscount = (this.modalFormData.memberLevelDiscount * 100).toFixed(0)
          }
          if (this.oprType === 'mod') formData.memberLevelId = this.modData.memberLevelId
          this.btnLoading = true
          await url[this.oprType](formData).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.$Message.success('操作成功')
              this.showModal = false
              this.getList()
            } else {
              this.$Message.error(res.data.message)
            }
          }).catch(() => {
            this.btnLoading = false
          })
        }
      })
    },
    initForm () {
      if (this.oprType === 'mod') {
        Object.keys(this.modalFormData).forEach(key => {
          if (key === 'memberLevelDiscount') {
            this.modalFormData.memberLevelDiscount = (this.modData.memberLevelDiscount / 100).toFixed(2)
          } else if (['tipMemberPrice', 'peakMemberPrice', 'averageMemberPrice', 'valleyMemberPrice', 'tipServiceCharge', 'peakServiceCharge', 'averageServiceCharge', 'valleyServiceCharge', 'fixedBuyPrice'].includes(key)){
            this.modalFormData[key] = (this.modData[key] / 100000).toFixed(5)
          } else {
            this.modalFormData[key] = this.modData[key]
          }
        })
      } else {
        this.modalFormData = Object.assign({}, formData)
      }
    }
  },
  watch: {
    showModal (val) {
      if (!val) {
        this.modData = {}
        this.oprType = ''
        this.$refs.modalForm.resetFields()
      } else {
        this.initForm()
      }
    }
  }
};
</script>

<style scoped lang="less">
.help-wrapper{
  .title{
    padding: @base;
    font-size: 16px;
    background: #f1f1f1;
  }
  .help-content{
    margin-top: @base;
    box-sizing: border-box;
    overflow-y: auto;
  }
  /deep/.ivu-table-cell{
    p{
     padding-bottom: @base / 2;
      &:last-child{
        padding-bottom: 0;
      }
    }
  }
}
</style>
