<template>
  <div class="main-box">
    <div class="main">
      <div class="top-wrapper" ref="top">
        <p class="logo">满电行管理监控平台</p>
        <MenuList refName="menu" :height="height" :data="menuData" :activeName="menuActiveName"></MenuList>
      </div>
      <div class="center" :style="{width: width + 'px'}">
        <div class="center-top">
          <div class="header-left">
            <!--          <p class="right-name">{{ancientRoute.meta.text}}</p>-->
            <!--          <MenuList refName="childMenu" mode="horizontal" :data="menuChildData" :activeName="menuActiveName"></MenuList>-->
            <breadcrumb-new v-if="!$route.meta.noBreadcrumb"></breadcrumb-new>
          </div>
          <div class="header-wrapper">
            <Poptip trigger="hover">
              <a href="javascript:;" class="username">{{ user.userName }}<Icon type="md-arrow-dropdown" /></a>
              <ul slot="content" class="header-user">
                <li @click="toMod">修改密码</li>
              </ul>
            </Poptip>
            <span class="icon" @click="toMessage"><Icon type="md-mail" /></span>
            <span class="icon" @click="toLogout"><Icon type="md-exit" /></span>
          </div>
        </div>
        <div class="content" :style="{height: height + 'px'}">
          <div class="router-content" :style="routerStyle">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
    <div class="main-bottom">
      <p>Copyright © 2011-2023 <a :href="globalConfig.companyUrl" target="_blank">{{ globalConfig.companyUrl }}</a> 版权所有:
        {{ globalConfig.company }} 公网备案
        <a href="https://beian.miit.gov.cn/" target="_blank">{{ globalConfig.icpNum }}</a>
      </p>
    </div>
    <Modal v-model="showPwd" title="修改密码" width="400" :mask-closable="false" :before-close="pwdBeforeClose">
      <Form :model="form" class="form-password" :rules="pwdFormRule" ref="pwdForm">
        <FormItem label="原密码: " prop="userAdminPwd" required>
          <Input type="password" style="width: 0;opacity: 0;" />
          <Input type="password" v-model="form.userAdminPwd" placeholder="请输入原密码"/>
        </FormItem>
        <FormItem label="新密码: " prop="userAdminPwdNew" required>
          <Input type="password" v-model="form.userAdminPwdNew" placeholder="请输入新密码" />
        </FormItem>
        <FormItem label="确认密码: " prop="repeatPwd" required>
          <Input type="password" v-model="form.repeatPwd" placeholder="请输入确认密码" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button class="ivu-btn-text" @click="pwdCancel">取消</Button>
        <Button class="ivu-btn-primary" @click="pwdOk">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import MenuList from '@/components/MenuList/MenuList'
import dayjs from 'dayjs'
import { removeStorageAll, getStorage, setStorage } from '@/libs/tools'
import { mapState } from 'vuex'
import { getTableHeight } from '@/libs/util'
import { mainRoutes } from '@/router'
import BreadcrumbNew from '@/components/BreadcrumbNew/index-old.vue'
import { updatePwd, AMapWebServiceKey, getBaseReqUrlNoPage } from "@/libs/api"
import globalConfig from '@/config'
import axios from 'axios'
import {getBaseReqUrl} from "../libs/api";

export default {
  name: 'Main',
  components: {
    MenuList,
    BreadcrumbNew
  },
  data () {
    return {
      globalConfig,
      user: getStorage('user') ? JSON.parse(getStorage('user')) : {},
      height: 0,
      timer: null,
      menuData: [],
      menuChildData: [],
      ancientRoute: this.$route.matched[0],
      width: 0,
      showPwd: false,
      form: {
        userAdminPwd: '',
        userAdminPwdNew: '',
        repeatPwd: ''
      },
      pwdFormRule: {
        userAdminPwd: [{required: true, message: '请输入原密码!'}],
        userAdminPwdNew: [{required: true, message: '请输入新密码!'}],
        repeatPwd: [
          {required: true, message: '请输入确认密码!'},
          { validator: (rule, value, callback) => {
              if (value !== this.form.userAdminPwdNew) {
                callback(new Error('两次密码输入不一致!'))
              } else {
                callback()
              }
            }}
        ]
      },
      routerStyle: {}
    }
  },
  computed: {
    ...mapState(['currentTime', 'menuActiveName'])
  },
  mounted () {
    this.$nextTick(() => {
      this.routerStyle = ['/home'].includes(this.$route.path) ? {
        backgroundColor: '#f1f1f1',
        padding: 0
      } : {}
      this.$store.dispatch('setMenuName',this.$route.name)
      this.menuData = this.getMenuData(mainRoutes)
      this.menuChildData = this.getChildMenuData()
      this.menuData.forEach((item) => {
        if ([5, 6, 7, 8].includes(this.user.userType)) {
          if (item.path === '/statistics') {
            item.meta.auth = true
            if (item.children.length) {
              item.children.forEach((child) => {
                if (child.path === '/person-consume') {
                  child.meta.auth = false
                } else {
                  child.meta.auth = true
                }
              })
            }
          } else {
            item.meta.auth = false
          }
        } else {
          item.meta.auth = true
          if (item.children.length) {
            item.children.forEach((child) => {
              child.meta.auth = true
            })
          }
        }
      })
      this.setRightHeight()
      this.$nextTick(() => {
        this.setTableHeight()
      })
      window.onresize = () => {
        this.setRightHeight()
        this.setTableHeight()
        let chartPage = this.findCharts(this, [])
        if (chartPage.length) {
          setTimeout(() => {
            Object.keys(chartPage[0]).forEach((key) => {
              if (key.toUpperCase().indexOf('CHARTSOBJ') > -1) {
                chartPage[0][key].resize()
              }
            })
          }, 100)
        }
      }
      this.timer = setInterval(() => {
        this.$store.dispatch('setTime', dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'))
      }, 1000)

      this.getDistrictData()
      this.getBaseReqUrl()
    })
  },
  methods: {
    toLogout () {
      this.$Modal.confirm({
        title: '退出',
        content: '确认退出系统？',
        onOk: () => {
          // this.$store.dispatch('removeUser')
          removeStorageAll()
          this.$router.push('/appshow')
        }
      })
    },
    setRightHeight () {
      let bottomHeight = document.querySelector('.main-bottom').offsetHeight
      this.height = document.documentElement.clientHeight - 65 - bottomHeight
      this.width = document.querySelector('.main').offsetWidth - 200
    },
    setTableHeight () {
      let child = this.$children
      if (child.length) {
        child.forEach((item) => {
          if (item.$refs.table || (item.$children[0] && item.$children[0].$refs.table)) { // 表格高度
            getTableHeight()
          }
        })
      }
    },
    getMenuData (list) {
      let res = []
      list.forEach(item => {
        if (!item.meta || (item.meta && !item.meta.hideInMenu)) {
          if (item.name.indexOf(this.authMenu) > -1) {
            Object.keys(this.user).length && (item.meta.auth = this.user.EName.indexOf(this.manager) > -1)
          }
          res.push(this.getObj(item))
        } else { // 当前菜单hideInMenu为true时
          if (this.hasChild(item)) {
            item.children.forEach(child => {
              if (child.name.indexOf(this.authMenu) > -1) {
                Object.keys(this.user).length && (child.meta.auth = this.user.EName.indexOf(this.manager) > -1)
              }
              res.push(this.getObj(child))
            })
          }
        }
      })
      return res
    },
    hasChild (item) { // 是否有子级
      return item.children && item.children.length
    },
    getObj (obj) { // 递归获取当前菜单及子级的值
      let res = {
        icon: obj.meta && obj.meta.icon ? obj.meta.icon : '',
        name: obj.name,
        path: obj.path,
        meta: obj.meta || {}
      }
      if (this.hasChild(obj)) {
        res.children = this.getMenuData(obj.children)
      }
      return res
    },
    getChildMenuData(list) { // 获取子级菜单数据
      let arr = []
      list = list || this.menuData
      let parentName = this.ancientRoute.name
      list.forEach((item) => {
        if (item.name === parentName) {
          arr = item.children
          return false
        }
      })
      return arr
    },
    toMod () {
      this.showPwd = true
    },
    toMessage () {

    },
    findCharts (ele, arr) { // 查询页面是否有charts
      if (ele.$refs.charts) {
        arr.push(ele)
        return false
      }
      if (ele.$children.length) {
        ele.$children.forEach((item) => {
          this.findCharts(item,arr)
        })
      }
      return arr
    },
    getDistrictData () { // 行政区域
      let regionData = JSON.parse(getStorage('regionData'))
      if (!(regionData && regionData.length)) {
        axios({
          url: 'https://restapi.amap.com/v3/config/district',
          params: {
            key: AMapWebServiceKey,
            subdistrict: 3
          }
        }).then((res) => {
          let data = res.data
          if (data.status === '1') {
            let provinceArr = data.districts[0].districts
            provinceArr.forEach((pro) => {
              pro.label = pro.name
              pro.value = pro.adcode
              pro.children = pro.districts
              if (pro.districts.length) {
                pro.districts.forEach((city) => {
                  city.label = city.name
                  city.value = city.adcode
                  city.children = city.districts
                  if (city.districts.length) {
                    city.districts.forEach((county) => {
                      county.label = county.name
                      county.value = county.adcode
                      county.children = county.districts
                    })
                  }
                })
              }
            })
            setStorage('regionData', JSON.stringify(provinceArr))
          }
        })
      }
    },
    pwdBeforeClose () {
      return false
    },
    pwdOk () {
      this.$refs.pwdForm.validate().then(async (valid) => {
        if (valid) {
          await updatePwd({
            userAdminPwd: this.form.userAdminPwd,
            userAdminPwdNew: this.form.userAdminPwdNew,
            userWxOpenId: this.user.userWxOpenId
          }).then((res) => {
            if (res.data.code === 0) {
              this.$Message.success('操作成功！')
              this.showPwd = false
              this.$store.dispatch('removeUser')
              removeStorageAll()
              this.$router.push('/login')
            }
          })
        }
      })
    },
    pwdCancel () {
      this.showPwd = false
    },
    getBaseReqUrl () {
      getBaseReqUrlNoPage({}).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data
          setStorage('urls', JSON.stringify(data))
        } else {
          this.$Message.error(res.data.message)
        }
      })
    }
    },
    watch: {
      '$route': {
        handler (to) {
          this.ancientRoute = to.matched[0]
          this.$store.dispatch('setMenuName', to.name)
          this.menuChildData = this.getChildMenuData()
          this.$nextTick(() => {
            this.setTableHeight()
            this.routerStyle = ['/home'].includes(to.path) ? {
              backgroundColor: '#f1f1f1',
              padding: 0
            } : {}
          })
        }
      },
      showPwd (val) {
        if (!val) {
          this.form = {
            userAdminPwd: '',
            userAdminPwdNew: '',
            repeatPwd: ''
          }
          this.$refs.pwdForm.resetFields()
        }
      }
    }
  }
</script>
<style lang="less">
.top{
  display: flex;
  flex-wrap: wrap;
}
.center-top{
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  box-shadow: 0 0 3px rgba(66, 169, 243, .1);
  padding: 13px @base * 2 0 @base * 2;
  height: 60px;
  box-sizing: border-box;
  justify-content: space-between;
  .header-left{
    display: flex;
    align-items: center;
  }
  .right-name{
    font-size: 20px;
    margin-right: @base * 2;
  }
  .header-wrapper{
    display: flex;
    .icon{
      font-size: 22px;
      padding: 0 @base * 2;
      color: @baseColor;
      cursor: pointer;
      position: relative;
      &:before{
        content: '';
        width: 1px;
        background: #dfdfdf;
        height: 60%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .ivu-poptip{
      margin-right: @base * 2;
    }
    .header-user{
      padding:0 @base;
      li{
        text-align: center;
        padding: @base * 2 0;
        cursor: pointer;
        border-bottom: 1px solid #dfdfdf;
        &:last-child{
          border-bottom: 0;
        }
      }
    }
  }
}
.ivu-form.form-password{
  padding: 0 @base * 4;
  .ivu-form-item{
    width: 100%;
  }
}
.main-bottom{
  width: 100%;
  padding-top: @base;
  text-align: center;
}
</style>

