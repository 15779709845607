<template>
  <div class="help-wrapper">
    <search-form :fields="searchFields" @on-search="toSearch" @on-reset="toReset" :okLoading="loading"></search-form>
    <div class="help-content" :style="{height: tableHeight + 'px'}">
      <div class="table-top">
        <Button @click="toOpr('add')" v-has-permi="['PRIV_POWERS_CREATE']"><Icon type="md-add"/> 新增</Button>
      </div>
      <Table stripe
             :columns="columns"
             :data="list"
             :loading="loading"
             ref="table"
             :height="tableHeight - 22"
             :span-method="handleSpan"></Table>
      <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
    </div>
    <Modal :title="oprType === 'add' ? '新增' : '编辑'"
           v-model="showModal"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           :mask-closable="false">
      <div slot="footer">
        <Button @click="modalCancel">取消</Button>
        <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :model="modalFormData" ref="modalForm" :label-width="120" :rules="modalRuleValid" v-if="showModal">
        <FormItem label="系统页面: " class="long" prop="page">
          <Tree-select :modelValue="modalFormData.page"
                       :filterable="true"
                       :multiple="false"
                       :clearable="true"
                       @on-change="treeChange"
                       :data="menuList"></Tree-select>
        </FormItem>
        <FormItem label="权限名称" class="long" prop="name">
          <Input v-model="modalFormData.name" placeholder="请输入权限名称，命令规范（PRIV_[页面]_[动作]）"/>
        </FormItem>
        <FormItem label="权限描述" prop="description" class="long">
          <Select v-model="modalFormData.description">
            <Option v-for="(opt, oInd) in descList" :key="oInd" :value="opt.label" :label="opt.label"></Option>
          </Select>
        </FormItem>
        <FormItem label="权限备注" prop="remark" class="long">
          <Input type="textarea" v-model="modalFormData.remark" />
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import TreeSelect from '@/components/tree-select'
import { getPowersQuery, setPowersAdd, setPowersMod, setPowersDel, getMenusQuery, getDictQueryByGroup } from '@/libs/api'
import { mapState } from 'vuex'
let formData = {
  name: '',
  page: '',
  description: '',
  remark: ''
}
export default {
  name: "role-manage",
  components: {
    SearchForm,
    TreeSelect
  },
  data () {
    return {
      searchFields: [
        {
          label: '权限名称/系统页面',
          name: 'name',
          type: 'input',
          cls: 'three'
        }
      ],
      searchParams: {},
      loading: false,
      columns: [
        {
          key: 'index',
          title: '序号',
          width: 80,
          align: 'center',
          render: (h, params) => {
            let ind = params.index  + 1 + this.params.pageSize * (this.params.pageIndex - 1)
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'page',
          title: '系统页面',
          align: 'center',
          minWidth: 120
        },
        {
          key: 'name',
          title: '权限标识',
          align: 'center',
          width: 200,
          tooltip: true
        },
        {
          key: 'description',
          title: '权限描述',
          align: 'center',
          width: 100
        },
        {
          key: 'remark',
          title: '备注',
          align: 'center',
          width: 140
        },
        {
          key: 'createTime',
          title: '创建时间',
          align: 'center',
          width: 200
        },
        {
          key: 'opr',
          title: '操作',
          align: 'center',
          minWidth: 200,
          render: (h, params) => {
            return (
                <span class="table-opr-btn">
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'mod', params.row)} v-has-permi={['PRIV_POWERS_UPDATE']}>
                  <i class="iconfont icon-bianji"></i> 编辑</a>
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'del', params.row)} v-has-permi={['PRIV_POWERS_DELETE']}>
                  <i class="iconfont icon-shanchu"></i> 删除</a>
              </span>
            )
          }
        }
      ],
      list: [],
      total: 0,
      params: {
        pageIndex: 1,
        pageSize: 20
      },
      oprType: 'add',
      btnLoading: false,
      showModal: false,
      modalFormData: Object.assign({}, formData),
      modalRuleValid: {
        page: [{required: true, message: '请选择系统页面!'}],
        name: [{required: true, message: '请输入权限名称!'}],
        description: [{required: true, message: '请选择权限描述!'}],
        sortIndex: [{required: true, message: '请输入排序!'}]
      },
      modData: {},
      menuList: [],
      descList: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getList()
      this.getMenuList()
      this.getDictData()
    })
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  methods: {
    toSearch (obj) {
      this.params.pageIndex = 1
      this.searchParams = obj
      this.getList()
    },
    toReset (obj) {
      this.searchParams = obj
      this.getList()
    },
    pageChange (pageIndex) {
      this.params.pageIndex = pageIndex
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    async getList () {
      this.loading = true
      await getPowersQuery(Object.assign({}, this.searchParams, this.params)).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          this.total = res.data.data.totalCount
          this.list = res.data.data.dataSource
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    toOpr (type, row) {
      this.oprType = type
      if (row) {
        this.modData = row
      }
      if (['add', 'mod'].includes(type)) {
        this.showModal = true
        if (type === 'mod') {
          this.initForm()
        }
      } else if (type === 'del') {
        this.$Modal.confirm({
          title: '删除',
          content: '是否要删除该角色?',
          loading: true,
          onOk: async () => {
            await setPowersDel({
              id: row.id
            }).then((res) => {
              if (res.data.code === 0) {
                this.$Message.success('操作成功')
                this.getList()
                this.$Modal.remove()
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      }
    },
    modalOk () {
      this.$refs.modalForm.validate(async (valid) => {
        if (valid) {
          let url = {
            add: setPowersAdd,
            mod: setPowersMod
          }
          let formData = Object.assign({}, this.modalFormData)
          if (this.oprType === 'mod') {
            formData.id = this.modData.id
          }
          this.btnLoading = true
          url[this.oprType](formData).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.$Message.success('操作成功')
              this.getList()
              this.showModal = false
            } else {
              this.$Message.error(res.data.message)
            }
          })
        }
      })
    },
    modalCancel () {
      this.showModal = false
    },
    initForm () {
      Object.keys(this.modalFormData).forEach((key) => {
        this.modalFormData[key] = this.modData[key]
      })
    },
    async getMenuList () {
      await getMenusQuery({}).then((res) => {
        if (res.data.code === 0) {
          let treeData = res.data.data
          if (treeData.length) {
            treeData.forEach((item) => {
              this.setDataVal(item)
            })
          }
        this.menuList = treeData
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    setDataVal (item) {
      item.title = item.name
      item.id = item.name
      if (item.children && item.children.length) {
        item.children.forEach((child) => {
          item.title = item.name
          item.id = item.name
          this.setDataVal(child)
        })
      } else {
        item.children = []
      }
    },
    treeChange (val) {
      this.modalFormData.page = val
    },
    async getDictData () {
      await getDictQueryByGroup({
        option: 'SYSTEM_POWER_DESCRIPTION'
      }).then((res) => {
        if (res.data.code === 0) {
          this.descList = res.data.data
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    handleSpan ({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 1) {
        // console.log(row)
      }
    }
  },
  watch: {
    showModal (val) {
      if (!val) {
        this.$refs.modalForm.resetFields()
        this.modalFormData = Object.assign({}, formData)
        this.btnLoading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/.three{
    display: flex;
    align-items: center;
    .ivu-form-item-label{
      width: 150px !important;
    }
    .ivu-form-item-content{
      margin-left: 0 !important;
    }
  }
</style>
