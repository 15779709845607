<template>
    <div class="help-wrapper" :style="{height: tableHeight + 'px'}">
        <search-form :fields="searchFields" :okLoading="loading" @on-search="toSearch" @on-reset="toReset" :selectData="searchSelectData"></search-form>
        <div class="help-content">
            <div class="table-top">
                <Button @click="toOpr('add')" v-has-permi="['PRIV_TIMEFEE_CREATE']"><Icon type="md-add"/> 新增</Button>
            </div>
            <Table stripe :columns="columns" :data="list" :loading="loading" ref="table" :height="tableHeight - 62"></Table>
        </div>
        <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
        <Modal :title="modalType === 'add' ? '新增' : '修改'"
           v-model="showModal"
           width="1465"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           :mask-closable="false">
            <div slot="footer">
                <Button @click="modalCancel">取消</Button>
                <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
            </div>
            <Form :model="modalFormData" ref="modalForm" :label-width="120" :rules="modalRuleValid" v-if="showModal">
                <div class="form-content group time-price" ref="timeRef">
                    <div class="time-interval">
                        <FormItem label="时段策略名称: " prop="name" class="half">
                            <Input v-model="modalFormData.name"/>
                        </FormItem>
                        <FormItem :label-width="90">
                            <Icon type="md-add-circle" class="icon-add" @click="toAddTime"/> 添加时间区间
                        </FormItem>
                    </div>
                    <div class="fee-time" v-for="(price, pInd) in modalFormData.timeSlices" :key="pInd">
                        <Icon type="md-remove-circle" class="icon-time" @click="toDelTime(pInd)"/>
                        <FormItem :prop="'timeSlices.' + pInd +'.startTime'" :rules="{required: true,message: '请选择开始时间'}">
                            <Select v-model="modalFormData.timeSlices[pInd].startTime" @on-change="timeChange(pInd, 'startTime')" placeholder="请选择开始时间">
                                <Option v-for="item in timeList" :value="item.value" :key="item.value" :disabled="getTimeSts(item.label, pInd, 'start')" v-show="!getTimeSts(item.label, pInd, 'start')">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem :prop="'timeSlices.' + pInd +'.endTime'" :rules="{required: true,message: '请选择结束时间'}">
                            <Select v-model="modalFormData.timeSlices[pInd].endTime" @on-change="timeChange(pInd, 'endTime')" placeholder="请选择结束时间">
                                <Option v-for="item in timeList" :value="item.value" :key="item.value" :disabled="getTimeSts(item.label, pInd, 'end')" v-show="!getTimeSts(item.label, pInd, 'end')">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem :prop="'timeSlices.' + pInd +'.type'" :rules="{required: true,message: '请选择时段类别'}">
                            <Select v-model="modalFormData.timeSlices[pInd].type" placeholder="请选择时段类别">
                                <Option v-for="item in timeInterval" :value="item.value" :key="item.value">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                    </div>
                </div>
                <div class="set-site">
                    <Transfer
                        :data="siteList"
                        :target-keys="selectList"
                        :list-style="listStyle"
                        :titles="['未选择站点','已选择站点']"
                        :operations="['取消','修改']"
                        filter-placeholder="请输入搜索站点"
                        filterable
                        @on-change="handleChange">
                    </Transfer>
                </div>
            </Form>
        </Modal>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import SearchForm from '@/components/SearchForm'
    import {TimeSliceGetPage,TimeSliceCreate,TimeSliceGetDetail,TimeSliceSiteInPlan,TimeSliceEdit,TimeSliceDelete,querySiteAll,TimeSlicePushById} from '@/libs/api'
    import { mapState } from 'vuex'
    export default {
        name: 'time-fee',
        components: {
            SearchForm
        },
        data(){
            return {
                loading: false,
                searchForm: {},
                total: 0,
                params: {
                    pageIndex: 1,
                    pageSize: 20
                },
                list: [],
                showModal: false,
                btnLoading: false,
                searchFields: [
                    {
                        label: '时段名称',
                        name: 'name',
                        type: 'input'
                    },
                    {
                        label: '下发状态',
                        name: 'isPush',
                        type: 'select'
                    }
                ],
                searchSelectData: {
                    isPush: [
                        {value: 1, label: '已下发'},
                        {value: 0, label: '未下发'}
                    ]
                },
                modalFormData: {
                    name: "",
                    timeSlices: [
                        {
                            startTime: null,
                            endTime: null,
                            type: null
                        }
                    ]
                },
                modalRuleValid: {
                    name: [{required: true, message: '请输入时段策略名称'}]
                },
                columns: [
                    {
                        key: 'index',
                        title: '序号',
                        width: 70,
                        align: 'center',
                        render: (h, params) => {
                            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
                            return (<span>{ind}</span>)
                        }
                    },
                    {
                        key: 'name',
                        title: '时段名称',
                        align: 'center'
                    },
                    {
                        key: 'sites',
                        title: '所属站点',
                        align: 'center',
                        render: (h, params) => {
                            let res = params.row.sites.join(',')
                            return (<Tooltip max-width="210" transfer content={res}><div class='site-title'>{res}</div></Tooltip>)
                        }
                    },
                    {
                        key: 'fee1TimeSlice',
                        title: '尖时段',
                        align: 'center',
                        render: (h, params) => {
                            let res = params.row.fee1TimeSlice.join(',')
                            return (<Tooltip max-width="240" transfer content={res}><div class='site-title'>{res}</div></Tooltip>)
                        }
                    },
                    {
                        key: 'fee2TimeSlice',
                        title: '峰时段',
                        align: 'center',
                        render: (h, params) => {
                            let res = params.row.fee2TimeSlice.join(',')
                            return (<Tooltip max-width="240" transfer content={res}><div class='site-title'>{res}</div></Tooltip>)
                        }
                    },
                    {
                        key: 'fee3TimeSlice',
                        title: '平时段',
                        align: 'center',
                        render: (h, params) => {
                            let res = params.row.fee3TimeSlice.join(',')
                            return (<Tooltip max-width="240" transfer content={res}><div class='site-title'>{res}</div></Tooltip>)
                        }
                    },
                    {
                        key: 'fee4TimeSlice',
                        title: '谷时段',
                        align: 'center',
                        render: (h, params) => {
                            let res = params.row.fee4TimeSlice.join(',')
                            return (<Tooltip max-width="240" transfer content={res}><div class='site-title'>{res}</div></Tooltip>)
                        }
                    },
                    {
                        key: 'isPush',
                        title: '下发状态',
                        align: 'center',
                        render: (h, params) => {
                            let res = params.row.isPush
                            return (<span>{ res ? '已下发' : '未下发'}</span>)
                        }
                    },
                    {
                        title: '操作',
                        align: 'center',
                        fixed: 'right',
                        width: 260,
                        render: (h, params) => {
                            return (
                                <span class="table-opr-btn">
                                    <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'edit', params.row)} v-has-permi={['PRIV_TIMEFEE_UPDATE']}>
                                        <i class="iconfont icon-bianji"></i> 编辑
                                    </a>
                                    <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'distribute', params.row)} v-has-permi={['PRIV_TIMEFEE_ISSUE']}>
                                        <i class="iconfont icon-xiafapeizhi2"></i> 下发
                                    </a>
                                    <a href="javascript:;" class="del" onClick={this.delRate.bind(this, params.row)} v-has-permi={['PRIV_TIMEFEE_DELETE']}>
                                        <i class="iconfont icon-shanchu"></i> 删除
                                    </a>
                                </span>
                            )
                        }
                    }
                ],
                modalType: '',
                timeList: [],
                timeInterval: [
                    {
                        value: 1,
                        label: '尖'
                    },
                    {
                        value: 2,
                        label: '峰'
                    },
                    {
                        value: 3,
                        label: '平'
                    },
                    {
                        value: 4,
                        label: '谷'
                    }
                ],
                // 穿梭框
                siteList: [], //未绑定站点
                selectList: [], //已绑定站点
                listStyle: { //自定义样式
                    width: '287px',
                    height: '122px'
                }
            }
        },
        computed: {
            ...mapState(['tableHeight'])
        },
        mounted () {
            this.$nextTick(() => {
                this.TimeSliceGetPage()
                for (let i = 0; i <= 24; i++) {
                    let it = i < 10 ? '0' + i : i
                    if (i === 24) {
                        this.timeList.push({
                            label: it + ':00',
                            value: it + ':00'
                        })
                    } else {
                        this.timeList.push({
                            label: it + ':00',
                            value: it + ':00'
                        },
                        {
                            label: it + ':30',
                            value: it + ':30'
                        })
                    }
                }
            })
        },
        methods: {
            toSearch (obj) {
                this.searchForm = obj
                this.params.pageIndex = 1
                this.TimeSliceGetPage()
            },
            toReset (obj) {
                this.searchForm = obj
                this.TimeSliceGetPage()
            },
            toOpr (type, row) {
                this.modalType = type
                switch (type){
                    case 'add':
                        this.getSite()
                        this.showModal = true
                        break
                    case 'edit':
                        this.detailRate(row)
                        this.showModal = true
                        break
                    case 'distribute':
                        this.distribute(row)
                        break
                }
            },
            pageChange (pageNum) {
                this.params.pageIndex = pageNum
                this.TimeSliceGetPage()
            },
            pageSizeChange (pageSize) {
                this.params.pageSize = pageSize
                this.TimeSliceGetPage()
            },
            modalOk () {
                this.$refs.modalForm.validate(async (valid) => {
                    if (valid) {
                        if(this.modalFormData.timeSlices[this.modalFormData.timeSlices.length-1].endTime !== '24:00'){
                            this.$Message.error('时段不足24小时')
                        }else{
                            if(this.modalType === 'add'){ //新增
                                let res = await TimeSliceCreate(Object.assign({},this.modalFormData,{sites: this.selectList}))
                                if(res.data.code === 0){
                                    this.showModal = false
                                    this.$Message.success('新增成功')
                                    this.resetFields()
                                    this.TimeSliceGetPage()
                                }else{
                                    this.$Message.error(res.data.message)
                                }
                            }else{ //修改
                                let res = await TimeSliceEdit(Object.assign({},this.modalFormData,{sites: this.selectList}))
                                if(res.data.code === 0){
                                    this.showModal = false
                                    this.$Message.success('修改成功')
                                    this.resetFields()
                                    this.TimeSliceGetPage()
                                }else{
                                    this.$Message.error(res.data.message)
                                }
                            }
                        }
                    }
                })
            },
            modalCancel(){ //取消
                this.showModal = false
                this.resetFields()
            },
            resetFields(){ //重置
                this.$refs.modalForm.resetFields()
            },
            async getSite(){ //获取所有站点
                let res = await querySiteAll()
                if(res.data.code === 0){
                    res.data.data.forEach((item)=>{
                        this.siteList.push({
                            key: item.siteId,
                            label: item.siteName
                        })
                    })
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            toAddTime () { //添加时间段
                this.modalFormData.timeSlices.push({
                    startTime: null,
                    endTime: null,
                    type: null
                })
                setTimeout(()=>{
                    this.listStyle.height = this.$refs.timeRef.offsetHeight + 'px'
                },100)
            },
            toDelTime (index) {
                if(this.modalFormData.timeSlices.length > 1){
                    this.modalFormData.timeSlices.splice(index, 1)
                    this.listStyle.height = this.$refs.timeRef.offsetHeight - 53 + 'px'
                }
            },
            async TimeSliceGetPage(){ //获取列表
                let params = Object.assign({}, this.params, this.searchForm)
                let res = await TimeSliceGetPage(params)
                if(res.data.code === 0){
                    this.total = res.data.data.totalCount
                    this.list = res.data.data.dataSource
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            async GetSiteInPlan(id){ //获取绑定站点
                let res = await TimeSliceSiteInPlan({id})
                if(res.data.code === 0){
                    res.data.data.forEach((item)=>{
                        this.siteList.push({
                            key: item.siteId,
                            label: item.siteName
                        })
                        if(item.inPlan){
                            this.selectList.push(item.siteId)
                        }
                    })
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            async detailRate(row){ //详情
                this.GetSiteInPlan(row.id) //获取绑定站点
                let res = await TimeSliceGetDetail({id:row.id})
                if(res.data.code === 0){
                    this.modalFormData = res.data.data
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            delRate(row){ //删除时段
                this.$Modal.confirm({
                    title: '警告',
                    width: 300,
                    content: '确认删除该时段吗!',
                    loading: true,
                    onOk: async () => {
                        let res = await TimeSliceDelete({id: row.id})
                        if(res.data.code === 0){
                            this.$Modal.remove()
                            this.$Message.success('操作成功!')
                            this.TimeSliceGetPage()
                        }else{
                            this.$Modal.remove()
                            this.$Message.error(res.data.message)
                        }
                    }
                })
            },
            handleChange (newTargetKeys) { //设置站点
                this.selectList = [...new Set(newTargetKeys)]
            },
            distribute(row){ //下发
                if(row.isPush){
                    this.$Message.info('该费率已下发!')
                }else{
                    this.$Modal.confirm({
                        title: '提示',
                        width: 300,
                        content: '确认下发该费率吗!',
                        loading: true,
                        onOk: async () => {
                            let res = await TimeSlicePushById({id:row.id})
                            if(res.data.code === 0){
                                this.$Modal.remove()
                                this.$Message.success('操作成功')
                                this.ServiceFeeGetPage()
                            }else{
                                this.$Message.error(res.data.message)
                            }
                        }
                    })
                }
            },
            timeChange (index, name) {
                this.$refs.modalForm.validateField('timeSlices.' + index + '.' + name)
                let len = this.getTimeLength()
                if (len >= 48) {
                    this.modalFormData.timeSlices[index + 1] && this.modalFormData.timeSlices.splice(-1)
                }
            },
            getTimeLength(val) { // 获取时段内总长度
                val = val || this.modalFormData.timeSlices
                let len = 0
                val.forEach((item) => {
                    let nowDay = dayjs(new Date).format('YYYY-MM-DD')
                    let time1 = item.startTime ? dayjs(nowDay + ' ' + item.startTime) : ''
                    let time2 = item.endTime ? dayjs(nowDay + ' ' + item.endTime) : ''
                    if (time1 && time2) {
                        let diff = time2.diff(time1, 'minute')
                        len += diff / 30
                    }
                })
                return len
            },
            getTimeSts(item, index, type) { // 价格设置中时间选项的是否可选
                let flag = false
                let currentForm = this.modalFormData.timeSlices
                let nowDay = dayjs(new Date).format('YYYY-MM-DD')
                let currentTime = dayjs(nowDay + ' ' + item).unix()
                if (type === 'start') {
                    if(index === 0 && item !== '00:00'){
                        flag = true
                    }else{
                        let curEndTime = currentForm[index].endTime
                        let currentEt = curEndTime ? dayjs(nowDay + ' ' + curEndTime).unix() : ''
                        let prevEndTime = index > 0  ? currentForm[index - 1].endTime : ''
                        let prevEt = prevEndTime ? dayjs(nowDay + ' ' + prevEndTime).unix() : ''
                        if (prevEt && (currentTime !== prevEt) || (currentEt && currentTime >= currentEt)) { // 不等于上一个结束时间/大于等于当前结束时间
                            flag = true
                        }
                    }
                } else if (type === 'end') { // 结束时间
                    let curStartTime = currentForm[index].startTime
                    let currentSt = curStartTime ? dayjs(nowDay + ' ' + curStartTime).unix() : ''
                    let nextStartTime = currentForm[index + 1] ? currentForm[index + 1].startTime : ''
                    let nextSt = nextStartTime ? dayjs(nowDay + ' ' + nextStartTime).unix() : ''
                    if (currentSt && currentTime <= currentSt || (nextSt && currentTime > nextSt)) { // 小于等于当前开始时间/大于下一个开始时间
                        flag = true
                    }
                }
                return flag
            }
        },
        watch: {
            showModal(val){
                if(val){
                    this.listStyle.height = '230px'
                    setTimeout(()=>{
                        this.listStyle.height = this.$refs.timeRef.offsetHeight + 'px'
                    },150)
                }
                this.modalFormData = {//清空表单
                    name: "",
                    timeSlices: [
                        {
                            startTime: null,
                            endTime: null,
                            type: null
                        }
                    ]
                }
                this.siteList = [] //清空获取站点
                this.selectList = [] //清空绑定站点
            }
        }
    }
</script>

<style lang="less" scoped>
@import 'rate-manage';
    .set-site{
        padding-left: 10px;
        margin: 10px 0;
    }
    .group{
        width: 755px;
    }
</style>