<template>
  <router-view />
</template>

<script>

export default {
  name: "main-right"
};
</script>

<style scoped>

</style>
