import { render, staticRenderFns } from "./serve-fee.vue?vue&type=template&id=baf5f460&scoped=true&"
import script from "./serve-fee.vue?vue&type=script&lang=js&"
export * from "./serve-fee.vue?vue&type=script&lang=js&"
import style0 from "./serve-fee.vue?vue&type=style&index=0&id=baf5f460&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baf5f460",
  null
  
)

export default component.exports