<template>
  <div class="login-wrapper" :style="{height: height + 'px'}">
    <div class="login-box">
      <div class="logo">
        <img src="">
        <p class="text">满电行管理监控平台</p>
      </div>
      <div class="login-content">
        <div class="login-form">
          <Form ref="loginForm" :model="formData" :rules="formRules">
            <FormItem prop="userName" label="用户名:">
              <Input type="password" class="hide-input"/>
              <Input v-model="formData.userName" autocomplete="off">
                <Icon slot="prepend" type="md-person"></Icon>
              </Input>
            </FormItem>
            <FormItem prop="userAdminPwd" label="密码:">
              <Input v-model="formData.userAdminPwd" type="password" autocomplete="off">
                <Icon slot="prepend" type="ios-lock"></Icon>
              </Input>
            </FormItem>
          </Form>
          <Button type="info" class="login-btn" @click="toLogin" :disabled="loading" :loading="loading">登录</Button>
        </div>
      </div>
      <div class="footer">
        <p>Copyright © 2011-2023 <a :href="globalConfig.companyUrl" target="_blank">{{ globalConfig.companyUrl }}</a> 版权所有:
          {{ globalConfig.company }} 公网备案
          <a href="https://beian.miit.gov.cn/" target="_blank">{{ globalConfig.icpNum }}</a>
        </p>
        <p class="download">APP下载：
          <a href="http://www.mdxxny.com/ApKFile/mdx_yyb.apk">安卓下载</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { setStorage } from '@/libs/tools'
import { login, getEncryptDecrypt, getSign } from '@/libs/api'
import dayjs from 'dayjs'
import globalConfig from '@/config'
export default {
  name: "login",
  data () {
    return {
      globalConfig,
      height: 0,
      formData: {
        userName: '',
        userAdminPwd: ''
      },
      formRules: {
        userName: [{required: true, message: '请输入用户名'}],
        userAdminPwd: [{required: true, message: '请输入密码'}]
      },
      loading: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      // console.log(window.innerWidth, window.innerHeight)
      this.height =  window.innerHeight
    })
  },
  methods: {
    toLogin () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
            login(this.formData).then((res) => {
              this.loading = false
              if (res.data.code === 0) {
                let data = res.data.data
                data.UserInfo.companyId && (data.UserInfo.companyId = data.UserInfo.companyId.toString())
                data.UserInfo.siteIds && (data.UserInfo.siteId = data.UserInfo.siteIds.toString())
                data.UserInfo.userPower = [
                  "PRIV_MENUS_CREATE",
                  "PRIV_STATISTICS_CONSUME_LIST",
                  "PRIV_CHARGE_CREATE",
                  "PRIV_OPTIONS_UPDATE",
                  "PRIV_FEE_CLOSE",
                  "PRIV_STATION_OPEN",
                  "PRIV_COMPANY_VIEW",
                  "PRIV_FEE_OPEN",
                  "PRIV_ROLES_UPDATE",
                  "PRIV_DISCOUNT_CREATE",
                  "PRIV_INVOICE_UPDATE",
                  "PRIV_COMPANY_WITHDRAW",
                  "PRIV_ROLEPOWERS_VIEW",
                  "PRIV_USERS_CREATE",
                  "PRIV_MENUS_VIEW",
                  "PRIV_ORDER_ABNORMAL_VIEW",
                  "PRIV_USERS_VIEW",
                  "PRIV_DISCOUNT_VIEW",
                  "PRIV_CHARGE_OPEN",
                  "PRIV_STATISTICS_CONSUME_EXPORT",
                  "PRIV_USERS_REFUND",
                  "PRIV_STATISTICS_ORDER_EXPORT",
                  "PRIV_THREE_COMPANY_OPEN",
                  "PRIV_STATISTICS_ORDER_SUMMARY",
                  "PRIV_ORDER_HISTORY_VIEW",
                  "PRIV_INVOICE_DELETE",
                  "PRIV_OPTIONS_CREATE",
                  "PRIV_MEMBERS_UPDATE",
                  "PRIV_ORDER_REALTIME_VIEW",
                  "PRIV_OPTIONS_VIEW",
                  "PRIV_USERS_RESETPASSWD",
                  "PRIV_ROLEUSERS_VIEW",
                  "PRIV_CHARGE_SEND",
                  "PRIV_CHARGE_VIEW",
                  "PRIV_FEE_CREATE",
                  "PRIV_FEE_IMPORT",
                  "PRIV_USERS_DELETE",
                  "PRIV_DISCOUNT_UPDATE",
                  "PRIV_STATISTICS_CONSUME_CHART",
                  "PRIV_USERS_UPDATE",
                  "PRIV_MEMBERS_VIEW",
                  "PRIV_STATION_CREATE",
                  "PRIV_INVOICE_SEND",
                  "PRIV_THREE_COMPANY_RECHARGE",
                  "PRIV_APP_UPDATE",
                  "PRIV_MEMBERS_CREATE",
                  "PRIV_THREE_COMPANY_RECONCILE",
                  "PRIV_ROLEPOWERS_UPDATE",
                  "PRIV_STATION_VIEW",
                  "PRIV_MENUS_UPDATE",
                  "PRIV_POWERS_DELETE",
                  "PRIV_THREE_COMPANY_SETAMOUNT",
                  "PRIV_CHARGE_UPDATE",
                  "PRIV_THREE_COMPANY_RECHARGE_RECORD",
                  "PRIV_DISCOUNT_OPEN",
                  "PRIV_USERS_ORDER",
                  "PRIV_DISCOUNT_CLOSE",
                  "PRIV_USERS_CHECK",
                  "PRIV_APP_VIEW",
                  "PRIV_STATISTICS_ORDER_DETAIL",
                  "PRIV_POWERS_UPDATE",
                  "PRIV_ROLES_DELETE",
                  "PRIV_STATISTICS_ORDER_VIEW",
                  "PRIV_OPTIONS_DELETE",
                  "PRIV_MENUS_DELETE",
                  "PRIV_CHARGE_CLOSE",
                  "PRIV_FEE_UPDATE",
                  "PRIV_ROLEUSERS_DELETE",
                  "PRIV_STATION_UPDATE",
                  "PRIV_APP_CREATE",
                  "PRIV_CHARGE_CHECK",
                  "PRIV_STATISTICS_ORDER_CHART",
                  "PRIV_STATION_SEND",
                  "PRIV_HELP_VIEW",
                  "PRIV_INVOICE_VIEW",
                  "PRIV_COMPANY_CREATE",
                  "PRIV_ROLES_CREATE",
                  "PRIV_CHARGE_RESTART",
                  "PRIV_COMPANY_UPDATE",
                  "PRIV_STATION_CLOSE",
                  "PRIV_ROLES_VIEW",
                  "PRIV_THREE_COMPANY_CLOSE",
                  "PRIV_RECHARGE_VIEW",
                  "PRIV_STATISTICS_CONSUME_VIEW",
                  "PRIV_THREE_COMPANY_VIEW",
                  "PRIV_USERS_RECHARGE",
                  "PRIV_POWERS_VIEW",
                  "PRIV_POWERS_CREATE",
                  "PRIV_ROLEUSERS_CREATE",
                  "PRIV_FEE_VIEW"
                ]
                setStorage('user', JSON.stringify(data.UserInfo))
                setStorage('token', data.jwtToken)
                this.$router.push('/home')
              } else{
                this.$Message.error(res.data.message)
              }
            }).catch((err) => {
              this.loading = false
            })

        }
      })
    },
    resetForm () {
      this.$refs.loginForm.resetFields()
      this.formData = {
        userName: '',
        userAdminPwd: ''
      }
    },
    toDownload () {
      let src = 'http://www.hiwaypower.com/ApKFile/mdx_yyb.apk'
      window.onload(src)
    }
  }
};
</script>

<style lang="less" scoped>
  @import "login";
</style>
