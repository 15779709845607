import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main-old'
// import MainRight from '@/views/MainRight'
import viewUI from 'view-design'
import { getStorage, removeStorageAll } from '@/libs/tools'
import store from '@/store'
import globalConfig from '@/config'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export const defaultRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login-old.vue')
    // component: () => import('../views/login/login-second.vue')
    // component: () => import('../views/login/login-new.vue')
  },
  {
    path: '/big-screen',
    name: 'big-screen',
    component: () => import('../views/big-screen/big-screen.vue')
  },
  {
    path: '/appshow',
    name: 'appshow',
    component: () => import('../views/appshow/appshow.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/privacy/privacy.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/service/service.vue')
  }
]
export const mainRoutes = [
  {
    path: '/',
    name: 'home',
    component: Main,
    redirect: '/appshow',
    meta: {
      text: '首 页',
      size: 14,
      icon: 'icon-dapingzhanshi',
      auth: true
    },
    children: [
      {
        path: '/home',
        name: 'home-page',
        component: () => import('../views/home/home.vue'),
        meta: {
          auth: true,
          hideInMenu: true,
          noBreadcrumb: true
        }
      }
    ]
  },
  /*{
    path: '/order',
    name: 'order',
    component: Main,
    redirect: '/realTime-order/operator',
    meta: {
      text: '订单管理',
      size: 18, // 图片大小
      icon: 'icon-dingdandingdanmingxishouzhimingxi',
      auth: true
    },
    children: [
      {
        path: '/operator-order',
        name: 'operator-order',
        component: MainRight,
        redirect: '/realTime-order/operator',
        meta: {
          text: '个人订单',
          auth: true
        },
        children: [
          {
            path: '/realTime-order/operator',
            name: 'realTime-order/operator',
            component: () => import('../views/order-manage/realTime-order.vue'),
            meta: {
              text: '实时订单',
              auth: true
            }
          },
          {
            path: '/history-order/operator',
            name: 'history-order/operator',
            component: () => import('../views/order-manage/history-order.vue'),
            meta: {
              text: '历史订单',
              auth: true
            }
          },
          {
            path: '/abnormal-order/operator',
            name: 'abnormal-order/operator',
            component: () => import('../views/order-manage/abnormal-order.vue'),
            meta: {
              text: '异常订单',
              auth: true
            }
          }
        ]
      },
      {
        path: '/organ-order',
        name: 'organ-order',
        component: MainRight,
        redirect: '/realTime-order/organ',
        meta: {
          text: '机构订单',
          auth: true
        },
        children: [
          {
            path: '/realTime-order/organ',
            name: 'realTime-order/organ',
            component: () => import('../views/order-manage/realTime-order.vue'),
            meta: {
              text: '实时订单',
              auth: true
            }
          },
          {
            path: '/history-order/organ',
            name: 'history-order/organ',
            component: () => import('../views/order-manage/history-order.vue'),
            meta: {
              text: '历史订单',
              auth: true
            }
          },
          {
            path: '/abnormal-order/organ',
            name: 'abnormal-order/organ',
            component: () => import('../views/order-manage/abnormal-order.vue'),
            meta: {
              text: '异常订单',
              auth: true
            }
          }
        ]
      },
    ]
  },*/
  {
    path: '/order',
    name: 'order',
    component: Main,
    redirect: '/realTime-order/operator',
    meta: {
      text: '订单管理',
      size: 18, // 图片大小
      icon: 'icon-dingdandingdanmingxishouzhimingxi',
      auth: true
    },
    children: [
      {
        path: '/realTime-order/operator',
        name: 'realTime-order/operator',
        component: () => import('../views/order/real-time.vue'),
        meta: {
          text: '实时订单',
          auth: true
        }
      },
      {
        path: '/history-order/operator',
        name: 'history-order/operator',
        component: () => import('../views/order/history-order.vue'),
        meta: {
          text: '历史订单',
          auth: true
        }
      },
      {
        path: '/abnormal-order/operator',
        name: 'abnormal-order/operator',
        component: () => import('../views/order/abnormal-order.vue'),
        meta: {
          text: '异常订单',
          auth: true
        }
      }
    ]
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: Main,
    // redirect: '/statistics/order-statistics',
    meta: {
      text: '统计中心',
      size: 18,
      icon: 'icon-tongji',
      auth: true
    },
    children: [
     /* {
        path: '/order-statistics',
        name: 'order-statistics',
        component: () => import('../views/statistics/order-statistics.vue'),
        meta: {
          text: '订单统计',
          auth: true
        }
      },*/
      {
        path: '/statistics/order-statistics',
        name: '/order-statistics',
        component: () => import('../views/statistics/order-statistics.vue'),
        meta: {
          text: '订单汇总统计',
          auth: true
        }
      },
      {
        path: '/statistics/person-consume',
        name: 'person-consume',
        component: () => import('../views/statistics/person-consume.vue'),
        meta: {
          text: '消费统计',
          auth: true
        }
      },
      {
        path:'/statistics/ele-statistics',
        name:'ele-statistics',
        component: () => import('../views/statistics/ele-statistics.vue'),
        meta:{
          text: '电量统计',
          auth: true
        }
      },
      {
        path: '/statistics/report-statistics',
        name:'report-statistics',
        component: () => import('../views/statistics/report-statistics.vue'),
        meta:{
          text: '报表中心',
          auth: true
        }
      }
    ]
  },
  {
    path: '/state-list',
    name: 'state-list',
    component: Main,
    redirect: '/platform-state',
    meta: {
      text: '电站电桩',
      size: 16,
      icon: 'icon-chongdianzhuang',
      auth: true
    },
    children: [
      {
        path: '/platform-state',
        name: 'platform-state',
        component: () => import('../views/state-manage/station.vue'),
        meta: {
          text: '电站管理',
          auth: true,
          icon: 'icon-caidan',
          size: 14
        }
      },
      {
        path: '/state-manage/terminal',
        name: 'platform-terminal',
        component: () => import('../views/state-manage/terminal.vue'),
        meta: {
          text: '电桩管理',
          auth: true
        }
      },
      {
        path: '/price-set',
        name: 'price-set',
        component: () => import('../views/state-manage/state-set/price-set.vue'),
        meta: {
          text: '费率设置',
          auth: true
        }
      }
    ]
  },
  {
    path: '/help',
    name: 'help',
    component: Main,
    redirect: '/help-list',
    meta: {
      text: '帮助中心',
      size: 16,
      icon: 'icon-shiyongbangzhu',
      auth: true
    },
    children: [
      {
        path: '/help-list',
        name: 'help-list',
        component: () => import('../views/system/help-list.vue'),
        meta: {
          text: '帮助文档',
          auth: true
        }
      }
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: Main,
    redirect: '/user-list',
    meta: {
      text: '用户管理',
      size: 16,
      icon: 'icon-shiyongbangzhu',
      auth: true
    },
    children: [
      {
        path: '/user-list',
        name: 'user-list',
        component: () => import('../views/users/user-list.vue'),
        meta: {
          text: '用户管理',
          auth: true
        }
      },
      {
        path: '/member-list',
        name: 'member-list',
        component: () => import('../views/users/members.vue'),
        meta: {
          text: '会员管理',
          auth: true
        }
      },
      {
        path: '/recharge-list',
        name: 'recharge-list',
        component: () => import('../views/users/recharge.vue'),
        meta: {
          text: '流水明细',
          auth: true
        }
      }
    ]
  },
  {
    path: '/company',
    name: 'company',
    component: Main,
    redirect: '/company-list',
    meta: {
      text: '运营商管理',
      size: 16,
      icon: 'icon-shiyongbangzhu',
      auth: true
    },
    children: [
      {
        path: '/company-list',
        name: 'company-list',
        component: () => import('../views/company-manage/company-list.vue'),
        meta: {
          text: '运营商管理',
          auth: true
        }
      },
      {
        path: '/three-abut-company',
        name: 'three-abut-company',
        component: () => import('../views/company-manage/three-company.vue'),
        meta: {
          text: '第三方对接公司',
          auth: true
        }
      },
      {
        path: '/discount-manage',
        name: 'discount-manage',
        component: () => import('../views/company-manage/discount.vue'),
        meta: {
          text: '优惠券管理',
          auth: true
        }
      },
      {
        path: '/invoice-manage',
        name: 'invoice-manage',
        component: () => import('../views/company-manage/invoice.vue'),
        meta: {
          text: '开票管理',
          auth: true
        }
      }
    ]
  },
  {
    path: '/system',
    name: 'system',
    component: Main,
    redirect: '/app-version',
    meta: {
      text: '系统管理',
      size: 16,
      icon: 'icon-xitongpeizhi',
      auth: true
    },
    children: [
      {
        path: '/app-version',
        name: 'app-version',
        component: () => import('../views/system/app-manage.vue'),
        meta: {
          text: 'app管理',
          auth: true
        }
      }
    ]
  },
  /*{
    path: '/test',
    name: 'test',
    component: Main,
    redirect: '/test-page',
    meta: {
      text: '测试',
      size: 16,
      icon: 'icon-shiyongbangzhu',
      auth: true
    },
    children: [
      {
        path: '/test-page',
        name: 'test-page',
        component: () => import('../views/test/test.vue'),
        meta: {
          text: '测试页',
          auth: true
        }
      }
    ]
  }*/
]
const routes = defaultRoutes.concat(mainRoutes)
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  viewUI.LoadingBar.start()
  if (globalConfig.startPage.includes(to.path)) {
    next()
  } else {
    const user = getStorage('user') ? JSON.parse(getStorage('user')) : {}
    const token = getStorage('token')
    if (!user || !Object.keys(user).length) {
      if (to.path !== '/login') {
        removeStorageAll()
        store.dispatch('removeUser')
        next('/login')
      } else {
        next()
      }
    } else {
      if ([5, 6, 7, 8].includes(user.userType)) {
        if (!['/statistics/person-consume', '/statistics/order-statistics'].includes(to.path)) {
          next('/statistics/order-statistics')
        } else {
          next()
        }
      } else {
        if (to.path === '/login') {
          if (token) {
            next('/home')
          }
        }
        next()
      }
    }
  }
})

router.afterEach(() => {
  viewUI.LoadingBar.finish()
})
export default router
