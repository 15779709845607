let dict = {
  appShowOpt: [ // APP显示
    {
      label: 'APP可见',
      value: 1
    },
    {
      label: 'APP不可见',
      value: 2
    }
  ],
  runAttrOpt: [ // 运营属性
    {
      label: '自营',
      value: 1
    },
    {
      label: '加盟',
      value: 2
    },
    {
      label: '虚拟',
      value: 3
    }
  ],
  runStatusOpt: [ // 运营状态
    {
      label: '未知',
      value: 0
    },
    {
      label: '建设中',
      value: 1
    },
    {
      label: '关闭下线',
      value: 5
    },
    {
      label: '维护中',
      value: 6
    },
    {
      label: '正常使用',
      value: 50
    }
  ],
  stateLabelOpt: [ // 电站标签/站点服务设施
    {
      label: '免费WIFI',
      value: '1'
    },
    {
      label: '空调休息室',
      value: '2'
    },
    {
      label: '按摩室',
      value: '3'
    },
    {
      label: '便利店',
      value: '4'
    },
    {
      label: '洗车',
      value: '5'
    },
    {
      label: '饮用水',
      value: '6'
    },
    {
      label: '厕所',
      value: '7'
    },
    {
      label: '快餐',
      value: '8'
    },
    {
      label: '自动售货机',
      value: '9'
    }
  ],
  isValidOpt: [
    {
      label: '是',
      value: 2
    },
    {
      label: '否',
      value: 1
    }
  ],
  isValidBoolean: [
    {
      label: '是',
      value: true
    },
    {
      label: '否',
      value: false
    }
  ],
  siteTypeOpt: [ // 电站类型
    {
      label: '公共',
      value: 1
    },
    {
      label: '个人',
      value: 50
    },
    {
      label: '公交(专用)',
      value: 100
    },
    {
      label: '环卫(专用)',
      value: 101
    },
    {
      label: '物流(专用)',
      value: 102
    },
    {
      label: '出租车(专用)',
      value: 103
    },
    {
      label: '其他',
      value: 255
    }
  ],
  constructSiteOpt: [ // 建设场所
    {
      label: '居民区',
      value: 1
    },
    {
      label: '公共机构',
      value: 2
    },
    {
      label: '企事业单位',
      value: 3
    },
    {
      label: '写字楼',
      value: 4
    },
    {
      label: '工业园区',
      value: 5
    },
    {
      label: '交通枢纽',
      value: 6
    },
    {
      label: '大型文体设施',
      value: 7
    },
    {
      label: '城市绿地',
      value: 8
    },
    {
      label: '大型建筑配建停车场',
      value: 9
    },
    {
      label: '路边停车位',
      value: 10
    },
    {
      label: '城际高速服务区',
      value: 11
    },
    {
      label: '其他',
      value: 255
    }
  ],
  radioCommonOpt: [ // 默认单选按钮数据
    {
      label: '无',
      value: 1
    },
    {
      label: '有',
      value: 2
    }
  ],
  specialParkingOpt: [ // 特殊车位
    {
      label: '客车车位',
      value: '1'
    },
    {
      label: '货车车位',
      value: '2'
    }
  ],
  parkingChargeTypeOpt: [ // 停车收费类型
    {
      label: '停车收费',
      value: '1'
    },
    {
      label: '停车免费',
      value: '2'
    },
    {
      label: '限时免费',
      value: '3'
    },
    {
      label: '充电限免',
      value: '4'
    }
  ],
  surroundingOpt: [ // 周边基础设施
    {
      label: '公厕',
      value: 1
    },
    {
      label: '近学校',
      value: 2
    },
    {
      label: '近地铁站',
      value: 3
    },
    {
      label: '近机场',
      value: 4
    },
    {
      label: '近商场',
      value: 5
    },
    {
      label: '近高速',
      value: 6
    },
    {
      label: '近火车站',
      value: 7
    },
    {
      label: '近汽车站',
      value: 8
    },
    {
      label: '近4S店',
      value: 9
    }
  ],
  electricStateOpt: [ // 电枪状态
    {
      label: '离线',
      value: '00'
    },
    {
      label: '故障',
      value: '01'
    },
    {
      label: '空闲',
      value: '02'
    },
    {
      label: '充电中',
      value: '03'
    },
    {
      label: '已插枪未充电',
      value: '04'
    },
    {
      label: '充电完成未拔枪',
      value: '05'
    },
    {
      label: '预约',
      value: '07'
    },
    {
      label: '充电启动中',
      value: '08'
    },
    {
      label: '充电完成',
      value: '09'
    },
    {
      label: '定时充电',
      value: '10'
    }
  ],
  chartStateOpt: [ // 电桩工作状态
    {
      label: '启用',
      value: 1
    },
    {
      label: '停用',
      value: 2
    }
  ],
  electricLinkStatusOpt: [ // 电枪是否已经归位
    {
      label: '否',
      value: '00'
    },
    {
      label: '是',
      value: '01'
    },
    {
      label: '未知',
      value: '02'
    }
  ],
  chargeTypeOpt: [ // 充电桩类型
    {
      label: '直流',
      value: '00'
    },
    {
      label: '交流',
      value: '01'
    }
  ],
  rateCategoryOpt: [
    {
      label: '尖',
      value: '00'
    },
    {
      label: '峰',
      value: '01'
    },
    {
      label: '平',
      value: '02'
    },
    {
      label: '谷',
      value: '03'
    }
  ],
  openStateOpt: [ // 电桩开放状态
    {
      label: '未启用',
      value: 1
    },
    {
      label: '启用',
      value: 2
    },
    {
      label: '停用',
      value: 3
    }
  ],
  chargePowerOpt: [ // 充电桩功率
    {
      label: '7KW',
      value: '7',
      filter: '01'
    },
    {
      label: '15KW',
      value: '15',
      filter: '01'
    },
    {
      label: '30KW',
      value: '30',
      filter: '01'
    },
    {
      label: '45KW',
      value: '45',
      filter: '00'
    },
    {
      label: '60KW',
      value: '60',
      filter: '00'
    },
    {
      label: '90KW',
      value: '90',
      filter: '00'
    },
    {
      label: '120KW',
      value: '120',
      filter: '00'
    },
    {
      label: '180KW',
      value: '180',
      filter: '00'
    },
    {
      label: '240KW',
      value: '240',
      filter: '00'
    },
    {
      label: '360KW',
      value: '360',
      filter: '00'
    }
  ],
  auxiliaryPowerOpt: [ // 充电桩辅助电源
    {
      label: '0V',
      value: '0'
    },
    {
      label: '12/24V',
      value: '12/24'
    }
  ],
  workingVoltageOpt: [ // 充电桩工作电压
    {
      label: '220V',
      value: '220'
    },
    {
      label: '750V',
      value: '750'
    },
    {
      label: '1000V',
      value: '1000'
    }
  ],
  chargeStopOpt: [ // 充电停止原因
    { // 云快充
      value: '40',
      label: '结束充电，APP远程停止'
    },
    {
      value: '41',
      label: '结束充电，SOC达到100%'
    },
    {
      value: '42',
      label: '结束充电，充电电量满足设定条件'
    },
    {
      value: '43',
      label: '结束充电，充电金额满足设定条件'
    },
    {
      value: '44',
      label: '结束充电，充电时间满足设定条件'
    },
    {
      value: '45',
      label: '结束充电，手动停止充电'
    },
    {
      value: '46',
      label: '其他方式（预留）'
    },
    {
      value: '47',
      label: '其他方式（预留）'
    },
    {
      value: '48',
      label: '其他方式（预留）'
    },
    {
      value: '49',
      label: '其他方式（预留）'
    },
    {
      value: '4A',
      label: '充电启动失败，充电桩控制系统故障(需要重启或自动恢复)'
    },
    {
      value: '4B',
      label: '充电启动失败，控制导引断开'
    },
    {
      value: '4C',
      label: '充电启动失败，断路器跳位'
    },
    {
      value: '4D',
      label: '充电启动失败，电表通信中断'
    },
    {
      value: '4E',
      label: '充电启动失败，余额不足'
    },
    {
      value: '4F',
      label: '充电启动失败，充电模块故障'
    },
    {
      value: '50',
      label: '充电启动失败，急停开入'
    },
    {
      value: '51',
      label: '充电启动失败，防雷器异常'
    },
    {
      value: '52',
      label: '充电启动失败，BMS未就绪'
    },
    {
      value: '53',
      label: '充电启动失败，温度异常'
    },
    {
      value: '54',
      label: '充电启动失败，电池反接故障'
    },
    {
      value: '55',
      label: '充电启动失败，电子锁异常'
    },
    {
      value: '56',
      label: '充电启动失败，合闸失败'
    },
    {
      value: '57',
      label: '充电启动失败，绝缘异常'
    },
    {
      value: '58',
      label: '预留'
    },

    {
      value: '59',
      label: '充电启动失败，接收BMS握手报文BHM超时'
    },
    {
      value: '5A',
      label: '充电启动失败，接收BMS和车辆的辨识报文超时BRM'
    },
    {
      value: '5B',
      label: '充电启动失败，接收电池充电参数报文超时BCP'
    },
    {
      value: '5C',
      label: '充电启动失败，接收BMS完成充电准备报文超时BRO AA'
    },
    {
      value: '5D',
      label: '充电启动失败，接收电池充电总状态报文超时BCS'
    },
    {
      value: '5E',
      label: '充电启动失败，接收电池充电要求报文超时BCL'
    },

    {
      value: '5F',
      label: '充电启动失败，接收电池状态信息报文超时BSM'
    },
    {
      value: '60',
      label: '充电启动失败，GB2015电池在BHM阶段有电压不允许充电'
    },
    {
      value: '61',
      label: '充电启动失败，GB2015辨识阶段在BRO_AA时候电池实际电压与BCP报文电池电压差距大于5%'
    },
    {
      value: '62',
      label: '充电启动失败，B2015充电机在预充电阶段从BRO_AA变成BRO_00状态'
    },
    {
      value: '63',
      label: '充电启动失败，接收主机配置报文超时'
    },
    {
      value: '64',
      label: '充电启动失败，充电机未准备就绪,我们没有回CRO AA，对应老国标'
    },
    {
      value: '65',
      label: '其他原因'
    },
    {
      value: '66',
      label: '其他原因'
    },
    {
      value: '67',
      label: '其他原因'
    },
    {
      value: '68',
      label: '其他原因'
    },
    {
      value: '69',
      label: '其他原因'
    },
    {
      value: '6A',
      label: '充电异常中止，系统闭锁'
    },
    {
      value: '6B',
      label: '充电异常中止，导引断开'
    },
    {
      value: '6C',
      label: '充电异常中止，断路器跳位'
    },
    {
      value: '6D',
      label: '充电异常中止，电表通信中断'
    },
    {
      value: '6E',
      label: '充电异常中止，余额不足'
    },
    {
      value: '6F',
      label: '充电异常中止，交流保护动作'
    },
    {
      value: '70',
      label: '充电异常中止，直流保护动作'
    },
    {
      value: '71',
      label: '充电异常中止，充电模块故障'
    },
    {
      value: '72',
      label: '充电异常中止，急停开入'
    },
    {
      value: '73',
      label: '充电异常中止，防雷器异常'
    },
    {
      value: '74',
      label: '充电异常中止，温度异常'
    },
    {
      value: '75',
      label: '充电异常中止，输出异常'
    },
    {
      value: '76',
      label: '充电异常中止，充电无流'
    },
    {
      value: '77',
      label: '充电异常中止，电子锁异常'
    },
    {
      value: '78',
      label: '预留'
    },
    {
      value: '79',
      label: '充电异常中止，总充电电压异常'
    },
    {
      value: '7A',
      label: '充电异常中止，总充电电流异常'
    },
    {
      value: '7B',
      label: '充电异常中止，单体充电电压异常'
    },
    {
      value: '7C',
      label: '充电异常中止，电池组过温'
    },
    {
      value: '7D',
      label: '充电异常中止，最高单体充电电压异常'
    },
    {
      value: '7E',
      label: '充电异常中止，最高电池组过温'
    },
    {
      value: '7F',
      label: '充电异常中止，BMV单体充电电压异常'
    },
    {
      value: '80',
      label: '充电异常中止，BMT电池组过温'
    },
    {
      value: '81',
      label: '充电异常中止，电池状态异常停止充电'
    },
    {
      value: '82',
      label: '充电异常中止，车辆发报文禁止充电'
    },
    {
      value: '83',
      label: '充电异常中止，充电桩断电'
    },
    {
      value: '84',
      label: '充电异常中止，接收电池充电总状态报文超时'
    },
    {
      value: '85',
      label: '充电异常中止，接收电池充电总状态报文超时'
    },
    {
      value: '86',
      label: '充电异常中止，接收电池状态信息报文超时'
    },
    {
      value: '87',
      label: '充电异常中止，接收BMS中止充电报文超时'
    },
    {
      value: '88',
      label: '充电异常中止，接收BMS充电统计报文超时'
    },
    {
      value: '89',
      label: '充电异常中止，接收对侧CCS报文超时'
    },
    {
      value: '8A',
      label: '其他原因'
    },
    {
      value: '8B',
      label: '其他原因'
    },
    {
      value: '8C',
      label: '其他原因'
    },
    {
      value: '8D',
      label: '其他原因'
    },
    {
      value: '8E',
      label: '其他原因'
    },
    {
      value: '8F',
      label: '其他原因'
    },
    {
      value: '90',
      label: '未知原因停止'
    },
    {
      value: '0000',
      label: '未知'
    },
    {
      value: '0100',
      label: '充满'
    },
    {
      value: '0200',
      label: '主动停止（按键）'
    },
    {
      value: '0300',
      label: '主动停止（远程）'
    },
    {
      value: '0500',
      label: '紧急停机'
    },
    {
      value: '0500',
      label: '故障停止'
    },
    {
      value: '0600',
      label: '其他故障'
    },
    {
      value: '1000',
      label: '计费控制单元正常停止'
    },
    {
      value: '1100',
      label: '计费控制单元自身故障终止'
    },
    {
      value: '1200',
      label: '计费控制单元判断充电控制器故障停止'
    },
    {
      value: '1300',
      label: '启动完成状态帧确认超时故障终止'
    },
    {
      value: '1400',
      label: '充电中控制导引故障'
    },
    {
      value: '1500',
      label: '充电控制器与计费控制单元通讯超时'
    },
    {
      value: '1600',
      label: '充电桩充电中暂停超时'
    },
    {
      value: '1700',
      label: '急停按钮动作故障'
    },
    {
      value: '1800',
      label: '门禁故障'
    },
    {
      value: '1900',
      label: '避雷器故障'
    },
    {
      value: '2000',
      label: '烟感故障'
    },
    {
      value: '2100',
      label: '计费控制单元自身故障终止'
    },
    {
      value: '2200',
      label: '交流输入接触器据动/误动故障'
    },
    {
      value: '2300',
      label: '交流输入接触器粘连故障'
    },
    {
      value: '2400',
      label: '交流输入故障(过压，欠压，缺相， 过流)'
    },
    {
      value: '2500',
      label: '充电桩过温故障'
    },
    {
      value: '2600',
      label: '充电接口过温故障'
    },
    {
      value: '2700',
      label: '充电接口电子锁故障'
    },
    {
      value: '2800',
      label: '直流输出接触器据动/误动故障'
    },
    {
      value: '2900',
      label: '直流输出接触器粘连故障'
    },
    {
      value: '3000',
      label: '直流输出熔断器故障'
    },
    {
      value: '3100',
      label: '并联接触器据动/误动故障'
    },
    {
      value: '3200',
      label: '并联接触器粘连故障'
    },
    {
      value: '3300',
      label: '泄放回路故障'
    },
    {
      value: '3400',
      label: '辅助电源故障'
    },
    {
      value: '3500',
      label: '充电机模块故障'
    },
    {
      value: '3600',
      label: '直流输出电压过压故障'
    },
    {
      value: '3700',
      label: '直流输出电压欠压故障'
    },
    {
      value: '3800',
      label: '直流输出电流过流故障'
    },
    {
      value: '3900',
      label: '直流输出短路故障'
    },
    {
      value: '4000',
      label: '电池充电需求报文(BCL)超时'
    },
    {
      value: '4100',
      label: '电池充电总状态报文(BCS)超时'
    },
    {
      value: '4200',
      label: '动力蓄电池状态信息(BSM)超时'
    },
    {
      value: '4300',
      label: 'BSM报文中单体动力蓄电池电压过压'
    },
    {
      value: '4400',
      label: 'BSM报文中单体动力蓄电池电压过低'
    },
    {
      value: '4500',
      label: 'BSM报文中 SOC 过高'
    },
    {
      value: '4600',
      label: 'BSM报文中 SOC 过低'
    },
    {
      value: '4700',
      label: 'BSM报文中充电过电流'
    },
    {
      value: '4800',
      label: 'BSM报文中动力蓄电池温度过高'
    },
    {
      value: '4900',
      label: 'BSM报文中动力蓄电池绝缘状态异常'
    },
    {
      value: '5000',
      label: 'BSM报文中连接器连接状态异常'
    },
    {
      value: '5100',
      label: 'BMS正常终止充电'
    },
    {
      value: '5200',
      label: 'BMS异常终止充电'
    },
    {
      value: '5300',
      label: '充电桩判断BMS其他异常终止'
    },
    {
      value: '5400',
      label: '充电桩其他故障'
    },
    {
      value: '5500',
      label: '充电桩直接跳到了待机阶段'
    },
    {
      value: '5600',
      label: '充电桩直接跳到了故障阶段'
    },
    {
      value: '0001',
      label: '充电中控制导引故障'
    },
    {
      value: '0101',
      label: '充电控制器与计费控制单元通讯超时'
    },
    {
      value: '0201',
      label: '急停按钮动作故障'
    },
    {
      value: '0301',
      label: '门禁故障'
    },
    {
      value: '0401',
      label: '避雷器故障'
    },
    {
      value: '0501',
      label: '烟感故障'
    },
    {
      value: '0601',
      label: '交流输入断路器故障'
    },
    {
      value: '0701',
      label: '交流输入接触器据动/误动故障'
    },
    {
      value: '0801',
      label: '交流输入接触器粘连故障'
    },
    {
      value: '0901',
      label: '交流输入故障(过压，欠压，缺相，过流)'
    },
    {
      value: '1001',
      label: '充电桩过温故障'
    },
    {
      value: '1101',
      label: '充电接口过温故障'
    },
    {
      value: '1201',
      label: '充电接口电子锁故障'
    },
    {
      value: '1301',
      label: '绝缘监测故障'
    },
    {
      value: '1401',
      label: '电池极性反接故障'
    },
    {
      value: '1501',
      label: '直流输出接触器据动/误动故障'
    },
    {
      value: '1601',
      label: '直流输出接触器粘连故障'
    },
    {
      value: '1701',
      label: '直流输出熔断器故障'
    },
    {
      value: '1801',
      label: '并联接触器据动/误动故障'
    },
    {
      value: '1901',
      label: '并联接触器粘连故障'
    },
    {
      value: '2001',
      label: '泄放回路故障'
    },
    {
      value: '2101',
      label: '辅助电源故障'
    },
    {
      value: '2201',
      label: '充电机模块故障'
    },
    {
      value: '2301',
      label: '直流输出电压过压故障'
    },
    {
      value: '2401',
      label: '直流输出电压欠压故障'
    },
    {
      value: '2501',
      label: '直流输出电流过流故障'
    },
    {
      value: '2601',
      label: '直流输出短路故障'
    },
    {
      value: '2701',
      label: '电池最高允许充电电压小于充电机最小输出电压'
    },
    {
      value: '2801',
      label: '绝缘监测前直流输出接触器外侧电压≥10V'
    },
    {
      value: '2901',
      label: '启动充电前直流输出接触器外侧电压与通信报文电池电压相差>±5%'
    },
    {
      value: '3001',
      label: '启动充电前直流输出接触器外侧电压小于充电机最小输出电压'
    },
    {
      value: '3101',
      label: '启动充电前直流输出接触器外侧电压大于充电机最大输出电压'
    },
    {
      value: '3201',
      label: '电池端电压大于电池最高允许充电电压'
    },
    {
      value: '3301',
      label: 'BRM报文接收超时'
    },
    {
      value: '3401',
      label: 'BRM报文数据项异常(报文数据长度错误，BMS通信协议版本号错误，其他数据错误)'
    },
    {
      value: '3501',
      label: 'BCP报文接收超时'
    },
    {
      value: '3601',
      label: 'BCP报文数据项异常(报文数据长度错误，电池当前电压越限，其他数据项错误)'
    },
    {
      value: '3701',
      label: 'BRO报文(0x00)接收超时'
    },
    {
      value: '3801',
      label: 'BRO报文(0xAA)接收超时'
    },
    {
      value: '3901',
      label: '充电机其他故障(启动过程收到了BST、BCL超时，风机故障、电表通讯故障)'
    },
    {
      value: '4001',
      label: '直流电表通讯故障'
    },
    {
      value: '4101',
      label: '启动中-TCU要求停止'
    },
    {
      value: '4201',
      label: 'VIN鉴权失败'
    },
    {
      value: '4301',
      label: '启动完成帧超时报文接收超时'
    },
    {
      value: '0002',
      label: '数据合法性校验失败(“充电启动帧”中“负荷控制开关”数据项合法性校验失败)'
    },
    {
      value: '0102',
      label: '充电控制器与计费控制单元通讯超时(“计费控制单元心跳帧”接收超时)'
    },
    {
      value: '0202',
      label: '充电控制器与计费控制单元“版本校验”未完成'
    },
    {
      value: '0302',
      label: '充电控制器与计费控制单元“下发充电参数”未完成'
    },
    {
      value: '0402',
      label: '充电桩暂停服务'
    },
    {
      value: '0502',
      label: '充电桩处于“工作”状态'
    },
    {
      value: '0602',
      label: '充电桩处于“暂停”状态'
    },
    {
      value: '0702',
      label: '充电桩与车辆处于未连接状态'
    },
    {
      value: '0802',
      label: '急停按钮动作故障'
    },
    {
      value: '0902',
      label: '门禁故障'
    },
    {
      value: '1002',
      label: '避雷器故障'
    },
    {
      value: '1102',
      label: '烟感故障'
    },
    {
      value: '1202',
      label: '充电桩过温故障(一体式充电桩内部过温故障保护，分体式充电桩充电机柜内部过温故障保护或充电桩内部过温故障保护)'
    },
    {
      value: '1302',
      label: '充电接口过温故障'
    },
    {
      value: '1402',
      label: '充电接口电子锁故障'
    },
    {
      value: '1502',
      label: '绝缘监测故障(仅直流桩使用)'
    },
    {
      value: '1602',
      label: '电池极性反接故障(仅直流桩使用)'
    },
    {
      value: '1702',
      label: 'BMS通信超时故障(仅直流桩使用)'
    },
    {
      value: '1802',
      label: '输入高压断路器故障',
    },
    {
      value: '1902',
      label: '输入高压接触器据动/误动故障'
    },
    {
      value: '2002',
      label: '输入高压接触器粘连故障'
    },
    {
      value: '2102',
      label: '输出高压接触器据动/误动故障'
    },
    {
      value: '2202',
      label: '输出高压接触器粘连故障',
    },
    {
      value: '2302',
      label: '输出高压熔断器故障'
    },
    {
      value: '2402',
      label: '并联接触器据动/误动故障(仅直流桩使用)'
    },
    {
      value: '2502',
      label: '并联接触器粘连故障(仅直流桩使用)'
    },
    {
      value: '2602',
      label: '泄放回路故障'
    },
    {
      value: '2702',
      label: '辅助电源故障(仅直流桩使用)'
    },
    {
      value: '2802',
      label: '充电机模块故障',
    },
    {
      value: '2902',
      label: '输入电源故障(电压过压故障、电压欠压、交流输入缺相、输入电流过流等)'
    },
    {
      value: '3002',
      label: '输出电压过压故障'
    },
    {
      value: '3102',
      label: '输出电压欠压故障'
    },
    {
      value: '3202',
      label: '输出电流过流故障',
    },
    {
      value: '3302',
      label: '输出短路故障'
    },
    {
      value: '3402',
      label: '无空余充电机模块可用(仅限具备功率分配功能的直流充电桩使用)'
    },
    {
      value: '3502',
      label: '充电桩其它故障'
    },
    {
      value: '3602',
      label: '启动应答帧超时'
    },
    {
      value: '0003',
      label: '账户余额不足',
    },
    {
      value: '0103',
      label: '达到设定金额'
    },
    {
      value: '0203',
      label: '达到设定时长'
    },
    {
      value: '0303',
      label: '达到设定电量'
    },
    {
      value: '0403',
      label: '达到设定SOC'
    },
    {
      value: '0503',
      label: 'VIN码读取超时',
    },
    {
      value: '0603',
      label: '车辆不支持VIN码读取'
    },
    {
      value: '0703',
      label: 'VIN码鉴权超时'
    },
    {
      value: '0803',
      label: 'VIN码鉴权失败'
    },
    {
      value: '0903',
      label: '正在充电'
    },
    {
      value: '1003',
      label: '桩禁用',
    },
    {
      value: '1103',
      label: '桩离线，与平台通讯断'
    },
    {
      value: '1203',
      label: '系统掉电'
    },
    {
      value: '1303',
      label: 'TCU故障'
    },
    {
      value: '1403',
      label: '刷卡停机'
    },
    {
      value: '1503',
      label: 'App模式密码停机',
    },
    {
      value: '1603',
      label: 'IC卡模式密码停机'
    },
    {
      value: '1703',
      label: 'VIN启动，本地停机'
    },
    {
      value: '1803',
      label: '账号密码停机'
    },
    {
      value: '1903',
      label: 'APP远程停止'
    },
    {
      value: '2003',
      label: '启动超时停止',
    },
    {
      value: '2103',
      label: '电表读数异常'
    },
    {
      value: '2203',
      label: '无费率停止充电'
    },
    {
      value: '2303',
      label: '电流过小'
    },
    {
      value: '2403',
      label: '达到服务器预设SOC停止'
    },
    {
      value: '2503',
      label: '本地管理员停止'
    },
    {
      value: '2603',
      label: '电表通讯故障'
    },
    {
      value: '2703',
      label: '电表通讯故障'
    },
    {
      value: '2803',
      label: '刷卡器通讯故障'
    },
    {
      value: '2903',
      label: '电表-读数激增异常'
    },
    {
      value: '3003',
      label: '电表-窃电异常'
    }
  ],
  userTypeOpt: [ // 用户类型
    {
      value: 1,
      label: '普通用户'
    },
    {
      value: 2,
      label: '禁止退款用户'
    },
    {
      value: 3,
      label: '管理员'
    },
    {
      value: 5,
      label: '对账账户'
    },
    {
      value: 6,
      label: '企业账户'
    },
    {
      value: -1,
      label: '大屏用户'
    }
  ],
  userSexOpt: [ // 用户性别
    {
      value: 0,
      label: '男'
    },
    {
      value: 1,
      label: '女'
    }
  ],
  discountTypeOpt: [ // 折扣类型
    {
      value: 1,
      label: '总服务费折扣'
    },
    {
      value: 2,
      label: '服务费分时段一口价(总价)'
    },
    {
      value: 3,
      label: '服务费一口价'
    }
  ],
  startFailOpt: [ // 充电启动失败原因
    {
      value: '00',
      label: '无'
    },
    {
      value: '01',
      label: '设备编号不存在'
    },
    {
      value: '02',
      label: '枪已在充电'
    },
    {
      value: '03',
      label: '设备故障'
    },
    {
      value: '04',
      label: '设备离线'
    },
    {
      value: '05',
      label: '未插枪'
    }
  ],
  rateTypeOpt: [ // 费率类型
    {
      value: 0,
      label: '云快充'
    },
    {
      value: 1,
      label: '万马扩展'
    },
    {
      value: 2,
      label: '万马基础'
    }
  ],
  chargeBrandOpt: [ // 充电桩品牌类型
    {
      value: 0,
      label: '云快充'
    },
    {
      value: 1,
      label: '万马'
    }
  ],
  siteFeeTypOpt: [ // 站点收费类型
    {
      value: 0,
      label: '会员制收费'
    },
    {
      value: 1,
      label: '普通收费'
    },
    {
      value: 2,
      label: '一口价收费'
    }
  ],
  companyTypeOpt: [ // 运营商类型
    {
      value: 1,
      label: '运营商'
    },
    {
      value: 2,
      label: '合作公司'
    },
    {
      value: 3,
      label: '合作运营公司'
    }
  ],
  isAllowOverchargeOpt: [ // 是否允许多充
    {
      value: 0,
      label: '不允许'
    },
    {
      value: 1,
      label: '允许'
    }
  ],
  enabledOpt: [ //是否显示手机
    {
      value: 1,
      label: '显示'
    },
    {
      value: 0,
      label: '隐藏'
    }
  ],
  activityConditionOpt: [ // 优惠券使用条件
    {
      value: 1,
      label: '抵扣服务费'
    },
    {
      value: 2,
      label: '抵扣全部'
    }
  ],
  activityTypeOpt: [ // 活动类型
    {
      value: 1,
      label: '优惠券'
    },
    {
      value: 2,
      label: '通知公告'
    },
    {
      value: 3,
      label: '活动'
    }
  ],
  activityIsValidOpt: [ // 活动是否有效
    {
      value: 1,
      label: '有效'
    },
    {
      value: 2,
      label: '无效'
    }
  ],
  supplyTypeOpt: [ // 供电类型
    {
      value: 1,
      label: '直供电'
    },
    {
      value: 2,
      label:'转供电'
    }
  ],
  paymentOpt: [ //  支付方式
    {
      value: '刷卡',
      label: '刷卡'
    },
    {
      value: '线上',
      label: '线上'
    },
    {
      value: '现金',
      label: '现金'
    },
    {
      value: '电子钱包',
      label: '电子钱包'
    }
  ],
  chargeReplaceTypeOpt: [ // 充换电类型
    {
      value: 1,
      label: '充电'
    },
    {
      value: 2,
      label: '换电'
    },
    {
      value: 3,
      label: '充换电一体'
    }
  ],
  equipmentTypeOpt: [ // 监管平台充电桩类型
    {
      value: 1,
      label: '直流设备'
    },
    {
      value: 2,
      label: '交流设备'
    },
    {
      value: 3,
      label: '交直流一体设备'
    },
    {
      value: 4,
      label: '无线设备'
    },
    {
      value: 5,
      label: '其它'
    }
  ],
  equipmentStsOpt: [ // 设备状态
    {
      value: 1,
      label: '在线'
    },
    {
      value: 2,
      label: '离线'
    }
  ],
  netLinkTypeOpt: [ //网络链接类型
    {
      value: '0x00',
      label: 'SIM卡'
    },
    {
      value: '0x02',
      label: 'WAN'
    },
    {
      value: '0x03',
      label: '其它'
    }
  ],
  chargeOperatorOpt: [ // 运营商
    {
      value: '0x00',
      label: '移动'
    },
    {
      value: '0x02',
      label: '电信'
    },
    {
      value: '0x03',
      label: '联通'
    },
    {
      value: '0x04',
      label: '其它'
    }
  ],
  ElectricStateOpt: [//电枪状态
    {
      value: 0,
      label: '离线'
    },
    {
      value: 1,
      label: '故障'
    },
    {
      value: 2,
      label: '空闲中'
    },
    {
      value: 3,
      label: '充电进行中'
    },
    {
      value: 4,
      label: '充电结束'
    },
    {
      value: 5,
      label: '启动失败'
    },
    {
      value: 6,
      label: '预约状态'
    },
    {
      value: 7,
      label: '系统故障(不能给汽车充电)'
    }
  ],
  ElectricLinkStatusOpt: [//电枪链接状态
    {
      value: 1,
      label: '在线'
    },
    {
      value: 2,
      label: '离线'
    }
  ],
  CarLinkStatusOpt: [//车链接状态
    {
      value: 1,
      label: '连接'
    },
    {
      value: 2,
      label: '未连接'
    }
  ],
  ConnectorTypeOpt: [//充电枪类型
    {
      value: 1,
      label: '家用插座'
    },
    {
      value: 2,
      label: '交流接口插座'
    },
    {
      value: 3,
      label: '交流接口插头'
    },
    {
      value: 4,
      label: '直流接口枪头'
    },
    {
      value: 5,
      label: '无线充电座'
    },
    {
      value: 6,
      label: '其他'
    },
  ],
  NationalStandardOpt:[//国家标准
    {
      value: 1,
      label: '2011'
    },
    {
      value: 2,
      label: '2015'
    },
  ],
  threeCompanyState: [ //第三方公司状态
    {
      value: 1,
      label: '正常'
    },
    {
      value: 2,
      label: '停用'
    },
    {
      value: 3,
      label: '已删除'
    }
  ],
  orderStateOpt: [ // 订单状态
    {
      value: 1,
      label: '准备充电'
    },
    {
      value: 2,
      label: '充电中'
    },
    {
      value: 3,
      label: '充电结束'
    },
    {
      value: 4,
      label: '开启充电超时'
    },
    {
      value: 5,
      label: '订单超时'
    },
    {
      value: 6,
      label: '开启充电失败'
    },
    {
      value: 7,
      label: '非法账单'
    },
    {
      value: 8,
      label: '手动结束异常订单'
    },
    {
      value: 9,
      label: '测试订单'
    },
    {
      value: 99,
      label: '快电测试订单'
    }
  ],
  orderStartModeOpt: [ // 订单启动方式
    {
      value: 0,
      label: '微信'
    },
    {
      value: 1,
      label: '支付宝'
    },
    {
      value: 2,
      label: 'APP'
    },
    {
      value: 3,
      label: '刷卡'
    },
    {
      value: 4,
      label: '快电'
    },
    {
      value: 5,
      label: '星络充电'
    },
    {
      value: 6,
      label: '新电途'
    },
    {
      value: 9,
      label: '其他'
    }
  ],
  rechargeTypeOpt: [ // 充值类型
    {
      value: 0,
      label: '用户充值'
    },
    {
      value: 1,
      label: '企业充值'
    }
  ],
  lockingGateOpt: [ // 是否对接道闸
    {
      value: 0,
      label: '否'
    },
    {
      value: 1,
      label: '罗浮广场充电站'
    },
    {
      value: 2,
      label: '东二环核工业充电站'
    },
    {
      value: 3,
      label: '府青路（踏水桥西街）充电站'
    }
  ],
  showAppOpt: [
    {
      value: '0',
      label: '微信'
    },
    {
      value: '1',
      label: '支付宝'
    },
    {
      value: '2',
      label: 'APP'
    },
    {
      value: '3',
      label: '刷卡'
    }
  ],
  companyStsOpt: [
    {
      value: 1,
      label: '正常'
    },
    {
      value: 2,
      label: '停用'
    },
    {
      value: 3,
      label: '已删除'
    }
  ],
  menuTypeOpt: [ // 菜单类型
    {
      value: 0,
      label: '目录'
    },
    {
      value: 1,
      label: '菜单'
    }
  ],
  rechargeAmountTypeOpt: [ // 充值余额类型
    {
      value: 0,
      label: '其他账户'
    },
    {
      value: 1,
      label: '预充值'
    }
  ],
  mdxServerTypeOpt: [ // 满电行服务收费类型
    {
      value: 1,
      label: '灵活模式(多少钱/度,超过多少度-多少钱/度)'
    },
    {
      value: 2,
      label: '固定模式收费-多少钱/年/枪'
    },
    {
      value: 3,
      label: '固定收费-多少钱/度'
    }
  ]
}

export const appShowOpt = dict.appShowOpt // APP是否显示
export const runAttrOpt = dict.runAttrOpt // 运营属性
export const runStatusOpt = dict.runStatusOpt // 运营状态
export const stateLabelOpt = dict.stateLabelOpt // 电站标签/站点服务设施
export const isValidOpt = dict.isValidOpt
export const isValidBoolean = dict.isValidBoolean
export const siteTypeOpt = dict.siteTypeOpt // 电站类型
export const constructSiteOpt = dict.constructSiteOpt // 建设场所
export const radioCommonOpt = dict.radioCommonOpt // 默认单选按钮数据
export const specialParkingOpt = dict.specialParkingOpt // 特殊车位
export const parkingChargeTypeOpt = dict.parkingChargeTypeOpt // 停车收费类型
export const surroundingOpt = dict.surroundingOpt // 周边基础设施
export const electricStateOpt = dict.electricStateOpt // 电枪状态
export const chartStateOpt = dict.chartStateOpt // 电桩状态
export const electricLinkStatusOpt = dict.electricLinkStatusOpt // 电枪是否已经归位
export const chargeTypeOpt = dict.chargeTypeOpt // 充电桩类型
export const rateCategoryOpt = dict.rateCategoryOpt // 费率价格类别
export const rateTypeOpt = dict.rateTypeOpt // 费率类型
export const openStateOpt = dict.openStateOpt // 电桩开放状态
export const chargePowerOpt = dict.chargePowerOpt // 电桩功率
export const auxiliaryPowerOpt = dict.auxiliaryPowerOpt // 充电桩辅助电源
export const workingVoltageOpt = dict.workingVoltageOpt // 充电桩工作电压
export const chargeStopOpt = dict.chargeStopOpt // 充电停止原因
export const startFailOpt = dict.startFailOpt // 充电启动失败原因
export const userTypeOpt = dict.userTypeOpt // 用户类型
export const userSexOpt = dict.userSexOpt // 用户性别
export const discountTypeOpt = dict.discountTypeOpt // 折扣类型
export const chargeBrandOpt = dict.chargeBrandOpt // 充电桩品牌类型
export const siteFeeTypOpt = dict.siteFeeTypOpt // 站点收费类型
export const companyTypeOpt = dict.companyTypeOpt // 运营商类型
export const isAllowOverchargeOpt = dict.isAllowOverchargeOpt // 是否允许多充
export const enabledOpt = dict.enabledOpt //是否显示手机
export const activityConditionOpt = dict.activityConditionOpt // 优惠券使用条件
export const activityTypeOpt = dict.activityTypeOpt // 活动类型
export const activityIsValidOpt = dict.activityIsValidOpt // 活动是否有效
export const supplyTypeOpt = dict.supplyTypeOpt // 供电类型
export const paymentOpt = dict.paymentOpt // 刷卡方式
export const chargeReplaceTypeOpt = dict.chargeReplaceTypeOpt // 充换电类型
export const equipmentTypeOpt = dict.equipmentTypeOpt // 监管平台充电桩类型
export const equipmentStsOpt = dict.equipmentStsOpt // 设备状态
export const netLinkTypeOpt = dict.netLinkTypeOpt // 网络链接类型
export const chargeOperatorOpt = dict.chargeOperatorOpt // 运营商
export const ElectricStateOpt = dict.ElectricStateOpt // 电枪状态
export const ElectricLinkStatusOpt = dict.ElectricLinkStatusOpt // 电枪状态
export const CarLinkStatusOpt = dict.CarLinkStatusOpt // 车链接状态
export const ConnectorTypeOpt = dict.ConnectorTypeOpt // 充电枪类型
export const NationalStandardOpt = dict.NationalStandardOpt // 国家标准
export const threeCompanyState = dict.threeCompanyState // 第三方公司状态
export const orderStateOpt = dict.orderStateOpt // 订单状态
export const orderStartModeOpt = dict.orderStartModeOpt // 订单启动方式
export const rechargeTypeOpt = dict.rechargeTypeOpt // 充值类型
export const lockingGateOpt = dict.lockingGateOpt // 是否对接道闸
export const showAppOpt = dict.showAppOpt // 展示到应用
export const companyStsOpt = dict.companyStsOpt // 运营商状态
export const menuTypeOpt = dict.menuTypeOpt // 菜单类型
export const rechargeAmountTypeOpt = dict.rechargeAmountTypeOpt // 充值余额类型
export const mdxServerTypeOpt = dict.mdxServerTypeOpt // 满电行服务收费类型
