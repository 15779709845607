<template>
  <div class="timing-recharge">
    定时充电
  </div>
</template>

<script>
export default {
  name: "timing-recharge"
};
</script>

<style scoped>

</style>
