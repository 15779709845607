<template>
  <div class="help-wrapper">
    <search-form :fields="searchFields" @on-search="toSearch" @on-reset="toReset" :okLoading="loading"></search-form>
    <div class="help-content" :style="{height: tableHeight + 'px'}">
      <div class="table-top">
        <Button @click="toOpr('add')" v-has-permi="['PRIV_OPTIONS_CREATE']"><Icon type="md-add"/> 新增</Button>
      </div>
      <Table stripe :columns="columns" :data="list" :loading="loading" ref="table" :height="tableHeight - 22"></Table>
      <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
    </div>
    <Modal :title="oprType === 'add' ? '新增' : '编辑'"
           v-model="showModal"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           :mask-closable="false"
           width="750">
      <div slot="footer">
        <Button @click="modalCancel">取消</Button>
        <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :model="modalFormData" ref="modalForm" :label-width="120" :rules="modalRuleValid" v-if="showModal">
        <FormItem label="字典名称: " class="half" prop="option">
          <Input v-model="modalFormData.option" />
        </FormItem>
        <FormItem label="标签" class="half" prop="label">
          <Input v-model="modalFormData.label"/>
        </FormItem>
        <FormItem label="值" prop="value" class="half">
          <Input v-model="modalFormData.value" />
        </FormItem>
        <FormItem label="排序" prop="sortIndex" class="half">
          <Input type="number" v-model="modalFormData.sortIndex" />
        </FormItem>
        <FormItem label="字典备注" prop="remark" class="long">
          <Input type="textarea" v-model="modalFormData.remark" />
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import { getDictQuery, setDictAdd, setDictMod, setDictDel } from '@/libs/api'
import { mapState } from 'vuex'
let formData = {
  option: '',
  label: '',
  value: '',
  sortIndex: 0,
  remark: ''
}
export default {
  name: "role-manage",
  components: {
    SearchForm
  },
  data () {
    return {
      searchFields: [
        {
          label: '字典名称',
          name: 'option',
          type: 'input',
          cls: 'three'
        }
      ],
      searchParams: {},
      loading: false,
      columns: [
        {
          key: 'index',
          title: '序号',
          width: 80,
          align: 'center',
          render: (h, params) => {
            let ind = params.index  + 1 + this.params.pageSize * (this.params.pageIndex - 1)
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'option',
          title: '字典名称',
          align: 'center',
          width: 200,
          tooltip: true
        },
        {
          key: 'label',
          title: '标签',
          align: 'center',
          minWidth: 120
        },
        {
          key: 'value',
          title: '字典值',
          align: 'center',
          width: 100
        },
        {
          key: 'sortIndex',
          title: '排序',
          align: 'center',
          width: 140
        },
        {
          key: 'remark',
          title: '备注',
          align: 'center',
          width: 200
        },
        {
          key: 'opr',
          title: '操作',
          align: 'center',
          fixed: 'right',
          minWidth: 200,
          render: (h, params) => {
            return (
                <span class="table-opr-btn">
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'mod', params.row)} v-has-permi={['PRIV_OPTIONS_UPDATE']}>
                  <i class="iconfont icon-bianji"></i> 编辑</a>
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'del', params.row)} v-has-permi={['PRIV_OPTIONS_DELETE']}>
                  <i class="iconfont icon-shanchu"></i> 删除</a>
              </span>
            )
          }
        }
      ],
      list: [],
      total: 0,
      params: {
        pageIndex: 1,
        pageSize: 20
      },
      oprType: 'add',
      btnLoading: false,
      showModal: false,
      modalFormData: Object.assign({}, formData),
      modalRuleValid: {
        option: [{required: true, message: '请输入字典名称!'}],
        label: [{required: true, message: '请输入标签!'}],
        value: [{required: true, message: '请输入字典值!'}],
        sortIndex: [{required: true, message: '请输入排序!'}]
      },
      modData: {}
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getList()
    })
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  methods: {
    toSearch (obj) {
      this.params.pageIndex = 1
      this.searchParams = obj
      this.getList()
    },
    toReset (obj) {
      this.searchParams = obj
      this.getList()
    },
    pageChange (pageIndex) {
      this.params.pageIndex = pageIndex
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    async getList () {
      this.loading = true
      await getDictQuery(Object.assign({}, this.searchParams, this.params)).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          this.total = res.data.data.totalCount
          this.list = res.data.data.dataSource
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    toOpr (type, row) {
      this.oprType = type
      if (row) {
        this.modData = row
      }
      if (['add', 'mod'].includes(type)) {
        this.showModal = true
        if (type === 'mod') {
          this.initForm()
        }
      } else if (type === 'del') {
        this.$Modal.confirm({
          title: '删除',
          content: '是否要删除该角色?',
          loading: true,
          onOk: async () => {
            await setDictDel({
              id: row.id
            }).then((res) => {
              if (res.data.code === 0) {
                this.$Message.success('操作成功')
                this.getList()
                this.$Modal.remove()
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      }
    },
    modalOk () {
      this.$refs.modalForm.validate(async (valid) => {
        if (valid) {
          let url = {
            add: setDictAdd,
            mod: setDictMod
          }
          let formData = Object.assign({}, this.modalFormData)
          if (this.oprType === 'mod') {
            formData.id = this.modData.id
          }
          this.btnLoading = true
          url[this.oprType](formData).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.$Message.success('操作成功')
              this.getList()
              this.showModal = false
            } else {
              this.$Message.error(res.data.message)
            }
          })
        }
      })
    },
    modalCancel () {
      this.showModal = false
    },
    initForm () {
      Object.keys(this.modalFormData).forEach((key) => {
        this.modalFormData[key] = this.modData[key]
      })
    },
    setDataVal (item) {
      item.title = item.name
      if (item.children && item.children.length) {
        item.children.forEach((child) => {
          item.title = item.name
          this.setDataVal(child)
        })
      } else {
        item.children = []
      }
    },
    treeChange (val) {
      this.modalFormData.page = val
    }
  },
  watch: {
    showModal (val) {
      if (!val) {
        this.$refs.modalForm.resetFields()
        this.modalFormData = Object.assign({}, formData)
        this.btnLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
