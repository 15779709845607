<template>
  <div class="menu-list" :class="'menu-list-' + mode">
    <i-Menu :ref="refName"
            :mode="mode"
            :active-name="activeName"
            :style="{height: height - 72 + 'px'}"
            :open-names="openNames"
            width="200px">
      <template v-for="(menu, mInd) in menuData">
        <template v-if="menu.children && menu.children.length && showChild">
          <Submenu :key="menu + mInd" :name="menu.name" v-if="menu.meta.auth">
            <template slot="title">
              <i class="iconfont" :class="menu.meta.icon" :style="{fontSize: (menu.meta.size || 28) + 'px'}"></i>
              <span class="item-text">{{ menu.meta.text }}</span>
            </template>
            <template v-for="(child, cInd) in menu.children">
              <template v-if="child.children && child.children.length && showChild">
               <Submenu :key="child + cInd" :name="child.name" v-if="child.meta.auth">
                 <template slot="title">
                   <i class="iconfont" :class="child.meta.icon ? child.meta.icon : 'icon-caidan1'" :style="{fontSize: (child.meta.size || 14) + 'px'}"></i>
                   <span class="item-text">{{child.meta.text}}</span>
                 </template>
                 <template v-for="(third, tInd) in child.children">
                   <MenuItem :name="third.name" :key="third + tInd" :to="third.path" v-if="third.meta.auth">
                     <i class="iconfont" :class="third.meta.icon ? third.meta.icon : 'icon-caidan'" :style="{fontSize: (third.meta.size || 14) + 'px'}"></i>
                     <span class="item-text">{{ third.meta.text }}</span>
                   </MenuItem>
                 </template>
               </Submenu>
              </template>
              <template v-else>
                <MenuItem :name="child.name" :key="child + cInd" :to="child.path" v-if="child.meta.auth">
                  <i class="iconfont" :class="child.meta.icon ? child.meta.icon : 'icon-caidan'" :style="{fontSize: (child.meta.size || 14) + 'px'}"></i>
                  <span class="item-text">{{ child.meta.text }}</span>
                </MenuItem>
              </template>
            </template>
          </Submenu>
        </template>
        <template v-else>
          <MenuItem :name="menu.name" :key="menu + mInd" :to="menu.path" v-if="menu.meta.auth">
            <i class="iconfont" :class="menu.meta.icon" :style="{fontSize: (menu.meta.size || 28) + 'px'}"></i>
            <span class="item-text">{{ menu.meta.text }}</span>
          </MenuItem>
        </template>
      </template>
    </i-Menu>
  </div>
</template>

<script>
import config from '@/config'
import { getStorage } from '@/libs/tools'

export default {
  name: "MenuList",
  props: {
    mode: {
      type: String,
      default: 'vertical'
    },
    height: {
      type: Number,
      default: 0
    },
    data: {
      type: Array,
      default () {
        return []
      }
    },
    showChild: { // 是否展示子级
      type: Boolean,
      default: true
    },
    activeName: {
      type: String,
      default: ''
    },
    refName: {
      type: String,
      default: 'menu'
    }
  },
  data () {
    return {
      user: getStorage('user') ? JSON.parse(getStorage('user')) : {},
      authMenu: config.menu.auth,
      manager: config.menu.manager,
      menuData: [],
      openNames: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.menuData = this.data
      this.$nextTick(() => {
        this.$refs[this.refName].updateActiveName()
        // this.$refs[this.refName].updateOpened()
      })
    })
  },
  methods: {
  },
  watch: {
    data (val) {
      this.menuData = val
    },
    '$route': {
      handler () {
        this.$nextTick(() => {
          this.$refs[this.refName].updateActiveName()
          // this.$refs[this.refName].updateOpened()
        })
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="less">
  @import "MenuList";
</style>
