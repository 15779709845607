<template>
  <div class="help-wrapper">
    <search-form :fields="searchFields" @on-search="toSearch" @on-reset="toReset" :okLoading="loading" :selectData="searchSelectData"></search-form>
    <div class="help-content" :style="{height: tableHeight + 'px'}">
      <div class="table-top">
<!--        <Button @click="toOpr('add')"><Icon type="md-add"/> 新增</Button>-->
      </div>
      <Table stripe :columns="columns" :data="list" :loading="loading" ref="table" :height="tableHeight - 62"></Table>
    </div>
    <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
    <Modal :title="oprType === 'add' ? '新增' : '编辑'"
      v-model="showModal"
      width="700"
      class="modal-detail modal-form"
      class-name="vertical-center-modal"
      :mask-closable="false">
      <div slot="footer">
        <Button @click="modalCancel">取消</Button>
        <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :model="modalFormData" ref="modalForm" :label-width="120" :rules="modalRuleValid">
        <div class="form-content">
          <FormItem label="公司名称: " prop="foreignCompanyName" class="half">
            <Input v-model="modalFormData.foreignCompanyName" />
          </FormItem>
          <FormItem label="公司Key: " prop="operatorID" class="half">
            <Input v-model="modalFormData.operatorID" />
          </FormItem>
          <FormItem label="公司密匙: " prop="operatorSecret" class="half">
            <Input v-model="modalFormData.operatorSecret" />
          </FormItem>
          <FormItem label="公司创建时间: " prop="foreignCompanyTime " class="half">
            <DatePicker v-model="modalFormData.foreignCompanyTime"></DatePicker>
          </FormItem>
          <FormItem label="公司电话: " prop="foreignCompanyPhone " class="half">
            <Input v-model="modalFormData.foreignCompanyPhone" />
          </FormItem>
          <FormItem label="公司税号: " prop="foreignCompanyDuty" class="half">
            <Input v-model="modalFormData.foreignCompanyDuty"/>
          </FormItem>
          <FormItem label="开户银行: " prop="foreignOpeningBank" class="half">
            <Input v-model="modalFormData.foreignOpeningBank"/>
          </FormItem>
          <FormItem label="银行账户: " prop="foreignBankAccount" class="half">
            <Input v-model="modalFormData.foreignBankAccount"/>
          </FormItem>
          <FormItem label="公司状态: " prop="foreignCompanyState" class="half">
            <Select v-model="modalFormData.foreignCompanyState">
              <Option v-for="(opt, oInd) in threeCompanyState" :key="oInd" :value="opt.value">{{opt.label}}</Option>
            </Select>
          </FormItem>
          <FormItem label="数据加密密钥: " prop="dataSecret" class="half">
            <Input v-model="modalFormData.dataSecret" />
          </FormItem>
          <FormItem label="初始化向量: " prop="dataSecretIV" class="half">
            <Input  v-model="modalFormData.dataSecretIV" />
          </FormItem>
          <FormItem label="签名密钥: " prop="sigSecret" class="half">
            <Input v-model="modalFormData.sigSecret" />
          </FormItem>
          <FormItem label="互联互通url地址: " prop="baseUrl" class="long" required>
            <Input  v-model="modalFormData.baseUrl" />
          </FormItem>
          <FormItem label="公司备注: " prop="foreignCompanyRemark" class="long">
            <Input type="textarea" v-model="modalFormData.foreignCompanyRemark" />
          </FormItem>
        </div>
      </Form>
    </Modal>
    <Modal width="1100" title="对账" :footer-hide="true" v-model="showSettleModal" class="settle-account-modal">
       <div class="account-settle-content">
        <div class="settle-tab-box">
          <ul class="settle-tab">
            <li v-for="(sTab, sInd) in settleTab" :key="sInd" :class="{current: settleUlInd === sInd}">{{sTab.label}}</li>
          </ul>
          <div class="settle-tab-btn">
            <Button @click="toModalOpr('all')">新增对账</Button>
<!--            <Button @click="showWithdraw = true">对账记录</Button>-->
          </div>
        </div>
        <search-form :fields="settleSearchFields" @on-search="toSettleSearch" @on-reset="toSettleReset" :okLoading="settleLoading" :fieldsVal="settleFieldsVal"></search-form>
        <Table stripe
          :columns="settleCol"
          :data="settleList"
          :loading="settleLoading"
          :height="tableHeight - 100"></Table>
      </div>
    </Modal>
    <Modal title="新增对账" v-model="settleApplyShow" width="500" class-name="vertical-center-modal modal-form" :mask-closable="false">
      <div slot="footer">
        <Button @click="settleApplyCancel">取消</Button>
        <Button type="primary" @click="settleApplyOk" :loading="isWithdrawApply" :disabled="isWithdrawApply">确定</Button>
      </div>
      <Form :label-width="100" :model="settleTime" :rules="settleTimeRules" ref="settleTimeForm">
        <div class="form-content">
          <FormItem label="开始时间" class="long" prop="startTime">
            <DatePicker type="datetime" v-model="settleTime.startTime"></DatePicker>
          </FormItem>
          <FormItem label="结束时间: " class="long" prop="endTime">
            <DatePicker type="datetime" v-model="settleTime.endTime"></DatePicker>
          </FormItem>
        </div>
      </Form>
    </Modal>
    <Modal title="对账记录" v-model="showWithdraw" width="1100" class-name="vertical-center-modal" :footer-hide="true">
      <search-form :fields="withdrawFields" :selectData="withdrawSelectData" @on-search="toWithdrawSearch" @on-reset="toWithdrawReset" :okLoading="loading"></search-form>
      <Table stripe
        :columns="withdrawCol"
        :data="withdrawList"
        :loading="withdrawLoading"
        :height="tableHeight - 150"></Table>
      <Page
        :total="withdrawTotal"
        :current="withdrawPageParams.pageIndex"
        :page-size="withdrawPageParams.pageSize"
        show-elevator
        show-sizer
        show-total
        @on-change="withdrawPageChange"
        @on-page-size-change="withdrawPageSizeChange"
        class="table-page1"></Page>
    </Modal>
    <Modal title="充值"
      class="modal-detail modal-form"
      class-name="vertical-center-modal"
      v-model="showRecharge"
      width="600"
      :mask-closable="false">
      <div slot="footer">
        <Button @click="rechargeCancel">取消</Button>
        <Button type="primary" @click="rechargeOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :label-width="120" :model="rechargeForm" :rules="rechargeRule" ref="rechargeFormRef" v-if="showRecharge">
        <FormItem class="long">
          确定要为{{modData.companyName}}的企业用户充值?
        </FormItem>
        <FormItem label="金额: " class="long" prop="money">
          <Input type="number" v-model="rechargeForm.money" >
            <span slot="append">元</span>
          </Input>
        </FormItem>
        <FormItem label="密码: " class="long" prop="password">
          <Input type="password" v-model="rechargeForm.password" />
        </FormItem>
        <FormItem label="备注: " class="long" prop="rechargeRemarke">
          <Input type="textarea" v-model="rechargeForm.rechargeRemarke" />
        </FormItem>
      </Form>
    </Modal>
    <Modal title="充值记录" v-model="rechargeRecordShow" width="800">
      <Table stripe :columns="recordColumns" :data="recordList" :loading="recordLoading" ref="table" height="300"></Table>
      <Page :total="recordTotal" :current="recordParams.pageIndex" :page-size="recordParams.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="recordPageChange" @on-page-size-change="recordPageSizeChange"></Page>
    </Modal>
    <Modal title="设置允许充电最低金额" v-model="showSetAmount" width="400" :mask-closable="false">
      <div slot="footer">
        <Button @click="setAmountCancel">取消</Button>
        <Button type="primary" @click="setAmountOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Input v-model="minimumUsageAmount" type="number">
        <span slot="append">元</span>
      </Input>
    </Modal>
    <Modal title="设置折扣服务率" v-model="showDrainageDiscount" width="300" :mask-closable="false">
      <div slot="footer">
        <Button @click="drainageDiscountCancel">取消</Button>
        <Button type="primary" @click="drainageDiscountOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Input v-model="drainageDiscount" type="number">
        <span slot="append">%</span>
      </Input>
      <span style="color: #ff1313;" v-if="modData.foreignId == 6">服务费=服务费-服务费*折扣率(输入的值)</span>
      <span style="color: #ff1313;" v-if="modData.foreignId == 4">服务费=服务费*折扣率(输入的值)</span>
    </Modal>
     <Modal title="第三方平台结算通道费" v-model="showPassageFee" width="300" :mask-closable="false">
      <div slot="footer">
        <Button @click="passageFeeCancel">取消</Button>
        <Button type="primary" @click="passageFeeOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Input v-model="passageFeeDiscount" type="number" number>
        <span slot="append">‰</span>
      </Input>
    </Modal>
    <Modal title="运营商站点" v-model="operatorShow" width="1260">
      <Table stripe :columns="operatorColumns" :data="operatorList" :loading="operatorLoading" ref="table" height="300"></Table>
    </Modal>
    <Modal title="绑定站点" v-model="siteShow" width="686">
      <div slot="footer">
        <Button @click="siteCancel">取消</Button>
        <Button type="primary" @click="siteOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Transfer
        :data="siteList"
        :target-keys="selectList"
        :list-style="listStyle"
        :titles="['未选择站点','已选择站点']"
        :operations="['取消','绑定']"
        filter-placeholder="请输入搜索站点"
        filterable
        @on-change="handleChange">
    </Transfer>
    </Modal>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import { mapState } from 'vuex'
import { companyAdd, getThreeCompanyQuery, companyMod, withdrawQuery, withdrawApplyOk, insertRecharge, userRechargeQuery, getKDCheckOrder, getKDGenerateOrder, setThreeAvailableAmount, setThreeCompanyState, GetForeignById, MiniAmount, setDiscount, setRechargeRecord, rechargeRecord, setSlottingAllowance, GetHlhtSelectSiteManagement, UpdateHlhtSelectSiteManagement } from '@/libs/api'
import { threeCompanyState } from '@/libs/dict'
import dayjs from 'dayjs'
import { getStorage, getSelectLabel } from '@/libs/tools'
import { getAllSiteList } from '@/libs/requestCommon'
let formData = {
  foreignCompanyName: "",
  operatorID: "",
  operatorSecret: "",
  foreignCompanyTime: "",
  foreignCompanyPhone: "",
  foreignCompanyAdress: "",
  foreignCompanyDuty: "",
  foreignOpeningBank: "",
  foreignBankAccount: "",
  foreignCompanyState: 1,
  foreignCompanyRemark: "",
  dataSecret: null,
  dataSecretIV: null,
  sigSecret: '',
  baseUrl: ''
}
export default {
  name: "three-about-company",
  components: {
    SearchForm
  },
  data () {
    return {
      threeCompanyState,
      user: getStorage('user') ? JSON.parse(getStorage('user')) : {},
      searchFields: [
        {
          label: '运营商名称',
          name: 'foreignCompanyName',
          type: 'input'
        },
      ],
      searchParams: {},
      searchSelectData: {},
      params: {
        pageSize: 10,
        pageIndex: 1
      },
      loading: false,
      columns: [
        {
          type: 'index',
          title: '序号',
          align: 'center',
          width: 70,
          render: (h, params) => {
            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'foreignCompanyName',
          title: '公司名称',
          align: 'center',
          width: 250
        },
        {
          key: 'foreignCompanyPhone',
          title: '公司电话',
          align: 'center',
          width: 150
        },
        {
          key: 'foreignCompanyAdress',
          title: '公司地址',
          align: 'center',
          width: 180
        },
        // {
        //   key: 'foreignCompanyBalance',
        //   title: '公司余额(元)',
        //   align: 'center',
        //   width: 150,
        //   render: (h, params) => {
        //     let num = (params.row.foreignCompanyBalance / 100).toFixed(2)
        //     return (<span>{num}</span>)
        //   }
        // },
        {
          key: 'foreignCompanyDuty',
          title: '公司税号',
          align: 'center',
          width: 180
        },
        {
          key: 'foreignOpeningBank',
          title: '开户银行',
          align: 'center',
          width: 150
        },
        {
          key: 'foreignBankAccount',
          title: '银行账号',
          align: 'center',
          width: 180
        },
        {
          key: 'foreignCompanyState',
          title: '公司状态',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let str = getSelectLabel(threeCompanyState, params.row.foreignCompanyState)
            return (<span>{str}</span>)
          }
        },
        // {
        //   key: 'availableBalance',
        //   title: '允许充电最低余额(元)',
        //   align: 'center',
        //   width: 180,
        //   render: (h, parmas) => {
        //     let num = Number(parmas.row.availableBalance / 100).toFixed(2)
        //     return (<span>{num}</span>)
        //   }
        // },
        // {
        //   key: 'settlementDiscount',
        //   title: '结算折扣(服务费)%',
        //   align: 'center',
        //   width: 160
        // },
        {
          key: 'foreignCompanyRemark',
          title: '公司备注',
          minWidth: 200
        },
        {
          key: 'opr',
          title: '操作',
          align: 'center',
          fixed: 'right',
          minWidth: 220,
          render: (h, params) => {
            let obj = {
              2: ['启用', 'icon-qiyong', 'open'],
              1: ['停用', 'icon-tingyong_line', 'close'],
            }
            // <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'reconcile', params.row)} v-has-permi={['PRIV_THREE_COMPANY_RECONCILE']}><i
            //         class="iconfont icon-kaihuhedui"></i> 对账</a>
            return (
                <span class="table-opr-btn">
                  <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'operator', params.row)} v-has-permi={['PRIV_COMPANY_VIEW']}>
                  <i class="iconfont icon-chakanjiexi"></i> 运营商详情</a>
                  {[1, 2].includes(params.row.foreignCompanyState) ?
                      <a href="javascript:;" class={obj[params.row.foreignCompanyState][2]}
                        onClick={this.toOpr.bind(this, obj[params.row.foreignCompanyState][2], params.row)} v-has-permi={params.row.foreignCompanyState === 2 ? ['PRIV_THREE_COMPANY_OPEN'] : ['PRIV_THREE_COMPANY_CLOSE']}>
                        <i class={'iconfont ' + obj[params.row.foreignCompanyState][1]}></i> {obj[params.row.foreignCompanyState][0]}
                      </a> : ''}
               </span>
            )
          }
        }
      ],
      list: [],
      total: 0,
      oprType: '',
      modData: {},
      showModal: false,
      modalFormData: Object.assign({}, formData),
      modalRuleValid: {
        foreignCompanyName: [
          { required: true, message: '请输入公司名称!' },
        ],
        operatorID: [
          { required: true, message: '请输入公司Key' },
          { maxLength: 9, message: '最大长度为9！' }
        ],
        foreignCompanyDuty: [
          { required: true, message: '请输入公司税号!' },
        ],
        foreignOpeningBank: [
          { required: true, message: '请输入开户银行!' },
        ],
        foreignBankAccount: [
          { required: true, message: '请输入银行账户!' },
        ],
        baseUrl: [
          { required: true, message: '请选择互联互通url地址！'},
        ]
      },
      showSettleModal: false,
      settleTab: [
        {
          label: '待对账列表',
          key: 'withdraw'
        }
      ],
      settleUlInd: 0,
      settleKey: 'withdraw',
      settleLoading: false,
      settleSearchFields: [
        {
          label: '开始时间',
          name: 'startTime',
          type: 'datetime'
        },
        {
          label: '结束时间',
          name: 'endTime',
          type: 'datetime'
        }
      ],
      settleSearchForm: {},
      settleFieldsVal: {
        startTime: dayjs(new Date()).format('YYYY-MM-DD') + ' 00:00:00',
        endTime: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
      },
      settlePageParams: {
        companyId: ''
      },
      settleTime: {
        startTime: '',
        endTime: ''
      },
      settleTimeRules: {
        startTime: [
          { required: true, message: '请选择开始时间!' }
        ],
        endTime: [
          { required: true, message: '请选择结束时间!'}
        ]
      },
      withdrawalTotalAmount: '', // 可提现金额
      withdrawedAmount: '', // 已提现金额
      settleCol: [
        {
          type: 'index',
          title: '序号',
          width: 80,
          align: 'center'
        },
        {
          key: 'userWxOpenId',
          title: '充电订单号',
          align: 'center',
          width: 250
        },
        {
          key: 'orderStartTime',
          title: '订单开始时间',
          align: 'center',
          width: 180
        },
        {
          key: 'orderEndTime',
          title: '订单结束时间',
          align: 'center',
          width: 180
        },
        {
          key: 'totalElectricQuantity',
          title: '电量(度)',
          width: 150,
          align: 'center',
          render: (h, params) => {
            let str = (params.row.totalElectricQuantity / 10000).toFixed(2)
            return (<span>{str}</span>)
          }
        },
        {
          key: 'kuaiDianPay',
          title: '快电支付(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.kuaiDianPay / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'totalAmount',
          title: '推送价格(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.totalAmount / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'electricityTotal',
          title: '电费总价(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.electricityTotal / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'totalServiceChargePrice',
          title: '服务费总价(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.totalServiceChargePrice / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'serviceFeeDiscountPrice',
          title: '服务费折扣后价格(元)',
          align: 'center',
          minWidth: 150,
          render: (h, params) => {
            let num = (params.row.serviceFeeDiscountPrice / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        }
      ],
      settleList: [],
      settleApplyShow: false,
      modalModData: {},
      showWithdraw: false,
      withdrawCol: [
        {
          key: 'index',
          title: '序号',
          align: 'center',
          width: 80,
          render: (h, params) => {
            let ind = params.index + 1 + (this.withdrawPageParams.pageIndex - 1) * this.withdrawPageParams.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'endTime',
          title: '订单结束时间',
          align: 'center',
          width: 180
        },
        {
          key: 'withdrawalNumber',
          title: '流水号',
          align: 'center',
          width: 200
        },
        {
          key: 'userName',
          title: '操作人',
          align: 'center',
          width: 120
        },
        {
          key: 'withdrawalTotalAmount',
          title: '提现金额(元)',
          align: 'center',
          width: 180,
          render: (h, params) => {
            let num = (params.row.withdrawalTotalAmount / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'wxServiceCharge',
          title: '微信手续费(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.wxServiceCharge / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'mdxServiceCharge',
          title: '满电行手续费(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.mdxServiceCharge / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'kuaiDianPay',
          title: '快电支付(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.kuaiDianPay / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'amountReceived',
          title: '到账金额(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.amountReceived / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'totalElectricQuantity',
          title: '电量(度)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.totalElectricQuantity / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: '',
          title: '提现状态',
          align: 'center',
          width: 150,
          render: (h, params) => {
            if (params.row.withdrawalStatus === 2) {// 已提现
              return (<span style="color: green;">已提现</span>)
            } else {
              return (<span style="color: red;">待执行</span>)
            }
          }
        },
        {
          key: 'withdrawalName',
          title: '提现运营商',
          align: 'center',
          width: 150
        },
        {
          key: 'withdrawalBank',
          title: '提现银行',
          align: 'center',
          width: 180,
        },
        {
          key: 'withdrawalAccount',
          title: '提现账户',
          align: 'center',
          width: 180
        },
        {
          key: 'withdrawalTime',
          title: '操作时间',
          align: 'center',
          width: 180
        },
        {
          key: 'withdrawalRemarks',
          title: '提现备注',
          minWidth: 200,
          align: 'center'
        },
        {
          key: 'opr',
          title: '操作',
          width: 200,
          fixed: 'right',
          render: (h, params) => {
            if (params.row.withdrawalStatus !== 2) {
              return (
                  <span class="table-opr-btn">
                    <a href="javascript:;" class="mod" onClick={this.toModalOpr.bind(this, 'withdrawOk', params.row)}>
                      <i class="iconfont icon-bianji"></i> 执行</a>
                    <a href="javascript:;" class="detail" onClick={this.toModalOpr.bind(this, 'detail', params.row)}>
                      <i class="iconfont icon-chakanjiexi"></i> 订单明细</a>
                  </span>
              )
            } else { // 已提现
              return (
                  <span class="table-opr-btn">
                     <a href="javascript:;" class="detail" onClick={this.toModalOpr.bind(this, 'detail', params.row)}>
                          <i class="iconfont icon-chakanjiexi"></i> 订单明细</a>
                  </span>
              )
            }
          }
        }
      ],
      withdrawList: [],
      withdrawLoading: false,
      withdrawTotal: 0,
      withdrawPageParams: {
        pageSize: 10,
        pageIndex: 1,
        withdrawalStatus: 0,
        companyId: ''
      },
      isWithdrawApply: false,
      withdrawType: '',
      withdrawFields: [
        {
          label: '提现状态',
          name: 'withdrawalStatus',
          type: 'select'
        }
      ],
      withdrawSelectData: {
        withdrawalStatus: [
          {
            label: '待执行',
            value: 1
          },
          {
            label: '已提现',
            value: 2
          }
        ]
      },
      btnLoading: false,
      showRecharge: false,
      rechargeForm: {
        money: '',
        password: '',
        rechargeType: 1,
        rechargeRemarke: ''
      },
      rechargeRule: {
        money: [{required: true, message: '请输入金额!'}],
        password: [{required: true, message: '请输入密码!'}],
        rechargeRemarke: [{required: true, message: '请输入备注!'}]
      },
      rechargeRecordShow: false,
      recordColumns: [
        {
          key: 'index',
          title: '序号',
          width: 70,
          align: 'center',
          render: (h, params) => {
            let ind = params.index + 1 + (this.recordParams.pageIndex - 1) * this.recordParams.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'state',
          title: '类型',
          width: 120,
          align: 'center',
          render: (h, params) => {
            let payment = {
              'wxpay': '微信',
              'zfbpay': '支付宝',
              'apppay': '微信APP',
              'zfbapppay': '支付宝APP',
              'SDCZ': '手动充值',
              'XTZS': '系统赠送',
              'YCZ': '预充值'
            }
            return (<span>{payment[params.row.paymentMethod] ? payment[params.row.paymentMethod] : '--'}</span>)
          }
        },
        {
          key: 'userPhone',
          title: '手机号',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let str = params.row.userPhone ? params.row.userPhone : '--'
            return (<span>{str}</span>)
          }
        },
        {
          key: 'userOpenId',
          title: '用户OpenId',
          align: 'center',
          width: 270
        },
        {
          key: 'rechargeMoney',
          title: '金额(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.rechargeMoney /100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'rechargeTime',
          title: '时间',
          align: 'center',
          width: 200
        },
        {
          key: 'rechargeRemarke',
          title: '备注',
          align: 'center',
          minWidth: 200
        }
      ],
      recordList: [],
      recordLoading: false,
      recordTotal: 0,
      recordParams: {
        pageSize: 20,
        pageIndex: 1,
        ledgerCompany: null,
        userWxOpenId: '',
        rechargeState: 'SUCCESS'
      },
      
      //运营商站点
      operatorShow: false,
      operatorLoading: false,
      //设置最低金额
      showSetAmount: false,
      minimumUsageAmount: null,
      operatorColumns: [
        {
          type: 'index',
          title: '序号',
          align: 'center',
          width: 70,
          render: (h, params) => {
            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'companyName',
          title: '运营商',
          align: 'center',
          width: 150
        },
        {
          key: 'preRechargeAmount',
          title: '第三方剩余余额(元)',
          align: 'center',
          width: 160,
          render: (h, params) => {
            let num = (params.row.preRechargeAmount / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'minimumUsageAmount',
          title: '允许充电最低余额(元)',
          align: 'center',
          width: 180,
          render: (h, parmas) => {
            let num = Number(parmas.row.minimumUsageAmount / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'drainageDiscount',
          title: '结算折扣(服务费)%',
          align: 'center',
          width: 180
        },
        {
          key: 'slottingAllowance',
          title: '结算通道费(服务费‰)',
          align: 'center',
          width: 180,
          render: (h, params) => {
            let num = Number(params.row.slottingAllowance / 10)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'opr',
          title: '操作',
          align: 'center',
          fixed: 'right',
          minWidth: 300,
          render: (h, params) => {
            return (
                <span class="table-opr-btn">
                  <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'recharge', params.row)} v-has-permi={['PRIV_THREE_COMPANY_RECHARGE']}>
                  <i class="iconfont icon-bianji"></i> 充值</a>
                  <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'bingSite', params.row)} v-has-permi={['PRIV_THREE_COMPANY_RECHARGE']}>
                  <i class="iconfont icon-bianji"></i> 绑定站点</a>
                  <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'discount', params.row)} v-has-permi={['PRIV_THREE_COMPANY_DISCOUNT']}>
                  <i class="iconfont icon-bianji"></i> 设置折扣</a>
                  <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'passageFee', params.row)} v-has-permi={['PRIV_THREE_COMPANY_PASSAGEFEE']}>
                  <i class="iconfont icon-bianji"></i> 设置通道费</a>
                  <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'rechargeRecord', params.row)} v-has-permi={['PRIV_THREE_COMPANY_RECHARGE_RECORD']}>
                  <i class="iconfont icon-chakanjiexi"></i> 充值记录</a>
                  <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'setAmount', params.row)} v-has-permi={['PRIV_THREE_COMPANY_SETAMOUNT']}>
                  <i class="iconfont icon-xiafapeizhi2"></i> 设置允许充电最低余额</a>
               </span>
            )
          }
        }
      ],
      operatorList: [],
      // 折扣率
      showDrainageDiscount: false,
      drainageDiscount: null,
      //第三方通道费
      showPassageFee: false,
      passageFeeDiscount: null,
      //绑定站点
      siteShow: false,
      siteList: [],
      selectList: [],
      listStyle: { //自定义样式
        width: '280px',
        height: '400px'
      }
    }
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  mounted () {
    this.$nextTick(() => {
      this.getList()
    })
  },
  methods: {
    pageChange (pageNum) {
      this.params.pageIndex = pageNum
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    toSearch (obj) {
      this.searchParams = obj
      this.params.pageIndex = 1
      this.getList()
    },
    toReset (obj) {
      this.searchParams = obj
      this.getList()
    },
    async getList () {
      this.loading = true
      let sParams = {}
      Object.keys(this.searchParams).forEach((key) => {
        this.searchParams[key] && (sParams[key] = this.searchParams[key])
      })
      await getThreeCompanyQuery(Object.assign({}, sParams, this.params)).then((res) => {
        if (res.data.code === 0) {
          this.loading = false
          this.list = res.data.data.dataSource
          this.total = res.data.data.totalCount
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    toOpr (type, row) {
      this.oprType = type
      if (row) {
        this.modData = row
      }
      if (['add', 'mod'].includes(type)) {
        this.showModal = true
      } else if (type === 'reconcile') { // 对账
        this.showSettleModal = true
      } else if (type === 'recharge') { // 充值
        this.showRecharge = true
      } else if(type === 'passageFee'){
        this.showPassageFee = true
      }else if (type === 'rechargeRecord') { // 充值记录
        this.rechargeRecordShow = true
        this.recordParams.ledgerCompany = row.companyId
        this.recordGetList(row.companyId)
      } else if (type === 'setAmount') { // 设置最低余额
        this.showSetAmount = true
      }else if(type === 'operator'){
        this.operatorShow = true
        this.operatorGet(row.foreignId)
      }else if(type === 'discount'){
        this.showDrainageDiscount = true
      }else if(type === 'bingSite'){
        this.siteShow = true
        this.getSite()
      }else if (['open', 'close'].includes(type)) { // 启用/禁用
        let obj = {
          1: [2, '停用'],
          2: [1, '启用']
        }
        this.$Modal.confirm({
          title: obj[row.foreignCompanyState][1],
          content: `确定${obj[row.foreignCompanyState][1]}该公司?`,
          loading: true,
          onOk: async () => {
            await setThreeCompanyState({
              foreignId: row.foreignId,
              state: obj[row.foreignCompanyState][0]
            }).then((res) => {
              if (res.data.code === 0) {
                this.$Message.success('操作成功!')
                this.$Modal.remove()
                this.getList()
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      }
    },
    toModalOpr (type, row) {
      this.modalModData = row
      if (['all'].includes(type)) { // 提现申请-分站点/一键提现 // settleApply
        this.settleApplyShow = true
        this.withdrawType = type
      } else if (type === 'settleApply') {
        this.$Message.info('正在开发中...')
      } else if (type === 'withdrawOk') { // 提现执行
        this.$Modal.confirm({
          title: '提示',
          content: '确认执行此条提现申请?',
          onOk: async () => {
            await withdrawApplyOk({
              withdrawalStatus: 2,
              withdrawalId: row.withdrawalId
            }).then((res) => {
              if (res.data.code === 0) {
                this.$Message.success('操作成功')
                this.getWithdrawList()
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      } else if (type === 'detail') { // 订单明细
        this.$router.push({
          path: '/order-statistics',
          query: {
            withdrawalNumber: row.withdrawalNumber,
            staTInd: 2
          }
        })
      }
    },
    modalCancel () {
      this.showModal = false
    },
    modalOk () {
      this.$refs.modalForm.validate(async (valid) => {
        if (valid) {
          let url = {
            add: companyAdd,
            mod: companyMod
          }
          let formData = Object.assign({}, this.modalFormData)
          formData.companyTime = dayjs(new Date(this.modalFormData.companyTime)).format('YYYY-MM-DD')
          formData.mdxRate = (formData.mdxRate - 0) * 100
          formData.wechatRate = (formData.wechatRate - 0) * 100
          if (this.oprType === 'mod') formData.companyID = this.modData.companyID
          this.btnLoading = true
          await url[this.oprType](formData).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.$Message.success('操作成功')
              this.showModal = false
              this.getList()
            } else {
              this.$Message.error(res.data.message)
            }
          })
        }
      })
    },
    initForm () {
      if (this.oprType === 'mod') {
        Object.keys(this.modalFormData).forEach(key => {
          if (['wechatRate', 'mdxRate'].includes(key)) {
            this.modalFormData[key] = (this.modData[key] / 100).toFixed(2)
          } else {
            this.modalFormData[key] = this.modData[key]
          }
        })
      } else {
        this.modalFormData = Object.assign({}, formData)
      }
    },
    toSettleSearch (obj) {
      this.settleSearchForm = obj
      this.getSettleModalList()
    },
    toSettleReset (obj) {
      this.settleSearchForm = obj
      this.getSettleModalList()
    },
    getSettleModalList () {
      this.settleLoading = true
      this.getWithdrawWaitingList()
    },
    async getWithdrawWaitingList () {
      let params = Object.assign({}, this.settleFieldsVal, this.settleSearchForm, {
        // companyId: this.modData.foreignId
      })
      await getKDCheckOrder(params).then((res) => {
        if (res.data.code === 0) {
          this.settleLoading = false
          this.settleList = res.data.data.orderInformation
        } else {
          this.settleLoading = false
          this.settleList = []
          this.$Message.error(res.data.message)
        }
      })
    },
    settleApplyCancel (){
      this.settleApplyShow = false
    },
     settleApplyOk () {
      this.$refs.settleTimeForm.validate(async (valid) => {
        if (valid) {
          let data = {
            startTime: dayjs(new Date(this.settleTime.startTime)).format('YYYY-MM-DD HH:mm:ss'),
            endTime: dayjs(new Date(this.settleTime.endTime)).format('YYYY-MM-DD HH:mm:ss'),
          }
          await getKDGenerateOrder(data).then((res) => { // 生成对账单申请
            if (res.data.code === 0) {
              this.$Message.success('操作成功')
              this.getSettleModalList()
              this.settleApplyShow = false
            } else {
              this.$Message.error(res.data.message)
            }
          })
        }
      })
    },
    async getSiteData () {
      getAllSiteList((arr) => {
        this.settleSelectData.siteIds = arr
        this.settleFieldsVal.siteIds = this.user.siteIds ? (this.user.siteIds.indexOf(',') > -1 ? this.user.siteIds.split(',') : [this.user.siteIds]) : []
      })
    },
    async getWithdrawList () { // 待提现列表-分站点
      await withdrawQuery(this.withdrawPageParams).then((res) => {
        if (res.data.code === 0) {
          this.withdrawList = res.data.data.OperatorWithdrawal.data
          this.withdrawTotal = res.data.data.OperatorWithdrawal.total
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    withdrawPageChange (pageNum) {
      this.withdrawPageParams.pageIndex = pageNum
      this.getWithdrawList()
    },
    withdrawPageSizeChange (pageSize) {
      this.withdrawPageSizeChange.pageSize = pageSize
      this.getWithdrawList()
    },
    withdrawChange (type) {
      if (type === 'start') {
        this.settleTime.startTime = dayjs(new Date(this.settleTime.startTime)).format('YYYY-MM-DD')
      }
      if (type === 'end') {
        if (this.settleTime.endTime) {
          this.settleTime.endTime = dayjs(new Date(this.settleTime.endTime)).format('YYYY-MM-DD')
        }
      }
    },
    toWithdrawSearch (obj) {
      this.withdrawPageParams.withdrawalStatus = obj.withdrawalStatus ? obj.withdrawalStatus : 0
      this.getWithdrawList()
    },
    toWithdrawReset () {
      this.withdrawPageParams.withdrawalStatus = 0
      this.getWithdrawList()
    },
    rechargeCancel () {
      this.showRecharge = false
      this.btnLoading = false
    },
    rechargeOk () { //充值
      this.$refs.rechargeFormRef.validate(async (valid) => {
        if (valid) {
          this.btnLoading = true
          await setRechargeRecord({
            userOpenId: this.modData.operatorID,
            money: Number((this.rechargeForm.money * 100).toFixed(2)),
            operator: this.user.userWxOpenId,
            password: this.rechargeForm.password,
            rechargeRemarke: this.rechargeForm.rechargeRemarke,
            rechargeType: this.rechargeForm.rechargeType,
            companyId: this.modData.companyId,
            foreignId: this.modData.foreignId
          }).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.showRecharge = false
              this.$Message.success('操作成功')
            //   this.getList()
              this.operatorGet(this.modData.foreignId)
            } else {
              this.$Message.error(res.data.message)
            }
          })
        }
      })
    },
    recordPageChange (pageNum) {
      this.recordParams.pageIndex = pageNum
      this.recordGetList()
    },
    recordPageSizeChange (pageSize) {
      this.recordParams.pageSize = pageSize
      this.recordGetList()
    },
    async recordGetList () { //充值记录
      this.recordLoading = true;
      this.recordParams.userWxOpenId = this.modData.operatorID
      let res =  await userRechargeQuery(this.recordParams)
      if (res.data.code === 0) {
        this.recordLoading = false
        this.recordList = res.data.data.dataSource
        this.recordTotal = res.data.data.totalCount
      }else{
        this.recordLoading = false
        this.$Message.error(res.data.message)
      }
    },
    setAmountCancel () {
      this.showSetAmount = false
      this.minimumUsageAmount = null
    },
    async setAmountOk () {
      if (!this.minimumUsageAmount) {
        return this.$Message.error('请输入金额')
      }
      this.btnLoading = true
      let res = await MiniAmount({
        minimumUsageAmount: this.minimumUsageAmount*100,
        drainageId: this.modData.drainageId
      })
      if (res.data.code === 0) {
        this.btnLoading = false
        this.$Message.success('操作成功!')
        this.operatorGet(this.modData.foreignId)
        this.showSetAmount = false
        this.minimumUsageAmount = null
      } else {
        this.btnLoading = false
        this.$Message.error(res.data.message)
      }
      // 旧
      // setThreeAvailableAmount({
      //   minimumUsageAmount: this.minimumUsageAmount,
      //   drainageId: this.modData.drainageId
      // }).then((res) => {
      //   if (res.data.code === 0) {
      //     this.$Message.success('操作成功!')
      //     this.getList()
      //     this.showSetAmount = false
      //     this.minimumUsageAmount = null
      //   } else {
      //     this.$Message.error(res.data.message)
      //   }
      // })
    },
    // 第三方合作公司运营
    async operatorGet(foreignId){
      this.operatorLoading = true
      let res = await GetForeignById({foreignId})
      if(res.data.code === 0){
        this.operatorLoading = false
        this.operatorList = res.data.data.DrainageSettlement
      }else{
        this.operatorLoading = false
        this.$Message.error(res.data.message)
      }
    },
    // 折扣率
    drainageDiscountCancel () {
      this.showDrainageDiscount = false
      this.drainageDiscount = null
    },
    async drainageDiscountOk(){
      if (!this.drainageDiscount) {
        return this.$Message.error('请输入折扣比率')
      }
      this.btnLoading = true
      let res = await setDiscount({
        drainageDiscount: this.drainageDiscount,
        drainageId: this.modData.drainageId
      })
      if (res.data.code === 0) {
        this.btnLoading = false
        this.$Message.success('操作成功!')
        this.operatorGet(this.modData.foreignId)
        this.showDrainageDiscount = false
        this.drainageDiscount = null
      } else {
        this.btnLoading = false
        this.$Message.error(res.data.message)
      }
    },
    //通道费
    passageFeeCancel(){
      this.showPassageFee = false
      this.passageFeeDiscount = null
    },
    async passageFeeOk(){
      if (!this.passageFeeDiscount) {
        return this.$Message.error('请输入结算通道比率')
      }else if(this.passageFeeDiscount<1||this.passageFeeDiscount>100){
        return this.$Message.error('请输入1到100之间的数')
      }
      this.btnLoading = true
      let res = await setSlottingAllowance({
        slottingAllowance: this.passageFeeDiscount*10,
        drainageId: this.modData.drainageId
      })
      if (res.data.code === 0) {
        this.btnLoading = false
        this.$Message.success('操作成功!')
        this.operatorGet(this.modData.foreignId)
        this.showPassageFee = false
        this.passageFeeDiscount = null
      } else {
        this.btnLoading = false
        this.$Message.error(res.data.message)
      }
    },
    //绑定站点
    async getSite(){ //获取所有站点
      let res = await GetHlhtSelectSiteManagement({companyId: this.modData.companyId, foreignId: this.modData.foreignId, isSelect: 0})
      let notres = await GetHlhtSelectSiteManagement({companyId: this.modData.companyId, foreignId: this.modData.foreignId, isSelect: 1})
      if(res.data.code === 0 && notres.data.code === 0){
        res.data.data.forEach((item)=>{
          this.siteList.push({
            key: item.siteId,
            label: item.siteName
          })
        })
        notres.data.data.forEach((item)=>{
          this.siteList.push({
            key: item.siteId,
            label: item.siteName
          })
          this.selectList.push(item.siteId);
        })
      }else{
        this.$Message.error(res.data.message)
      }
    },
    handleChange (newTargetKeys) { //设置站点
      this.selectList = [...new Set(newTargetKeys)]
    },
    siteCancel(){ //绑定站点取消
      this.siteShow = false
    },
    async siteOk(){ //绑定站点确定
      this.btnLoading = true
      let res = await UpdateHlhtSelectSiteManagement({companyId: this.modData.companyId, foreignId: this.modData.foreignId, siteId: this.selectList})
      if(res.data.code === 0 ){
        this.$Message.success('操作成功!')
        this.btnLoading = false
        this.siteShow = false
      }else{
        this.$Message.error(res.data.message)
      }
    }
  },
  watch: {
    showModal (val) {
      if (!val) {
        this.modData = {}
        this.oprType = ''
        this.$refs.modalForm.resetFields()
      } else {
        this.initForm()
      }
    },
    showSettleModal (val) {
      if (!val) {
        this.modalModData = {}
        this.withdrawType = ''
      } else {
        this.getSettleModalList()
      }
    },
    settleApplyShow (val) {
      if (!val) {
        this.isWithdrawApply = false
        this.settleTime.startTime = ''
        this.settleTime.endTime = ''
        this.$refs.settleTimeForm.resetFields()
      }
    },
    showWithdraw (val) {
      if (!val) {
        this.withdrawList = []
        this.withdrawPageParams = {
          pageSize: 10,
          pageIndex: 1,
          companyId: this.modData.companyID
        }
        this.withdrawTotal = 0
        this.getWithdrawAll()
      } else {
        this.withdrawPageParams.companyId = this.modData.companyID
        this.getWithdrawList()
      }
    },
    siteShow(){
      this.siteList = []
      this.selectList = []
      this.btnLoading = false
    }
  }
};
</script>

<style scoped lang="less">
@import "./company-manage";
.help-wrapper{
  .title{
    padding: @base;
    font-size: 16px;
    background: #f1f1f1;
  }
  .help-content{
    margin-top: @base;
    box-sizing: border-box;
    overflow-y: auto;
  }
}
</style>
