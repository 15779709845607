<template>
  <div class="big-screen-wrapper" :style="{minHeight: height + 'px'}">
    <div class="screen-top">
     <p class="title">满电行充电统计平台</p>
      <p class="time">{{nowDate}}</p>
    </div>
    <div class="screen-box-top">
      <div class="screen-box-wrapper screen-left">
        <div class="screen-item">
          <div class="screen-title">
            <span class="title-left">充电桩实时状态分布</span>
          </div>
          <div class="charts-box" id="charge-status"></div>
        </div>
        <div class="screen-item">
          <div class="screen-title">
            <span class="title-left">24小时充电次数统计</span>
          </div>
          <div class="charts-box" id="charge-time" style="height: 322px;"></div>
        </div>
      </div>
      <div class="screen-box-wrapper screen-right">
        <div class="screen-item">
          <div class="screen-title">
            <span class="title-left">收入统计</span>
          </div>
          <ul class="income-list">
            <li v-for="(income, iInd) in incomeArr" :key="iInd">
              <label>{{income.label}}</label>
              <ICountUp :delay="countUpOpt.delay" :endVal="income.value" :options="countUpOpt.options"></ICountUp>
            </li>
          </ul>
        </div>
        <div class="screen-item">
          <div class="screen-title">
            <span class="title-left">订单统计</span>
          </div>
          <ul class="filter-tab">
            <li v-for="(filter, fInd) in filterTab" :key="fInd" :class="{current: orderFilInd === fInd}" @click="toFilterTab('order', fInd)">{{filter.label}}</li>
          </ul>
          <ul class="income-list">
            <li v-for="(order, oInd) in orderStaArr" :key="oInd">
              <label>{{order.label}}</label>
              <ICountUp :delay="countUpOpt.delay" :endVal="orderFilInd === 0 ? order.value : order.value + '0'" :options="countUpOpt.options"></ICountUp>
            </li>
          </ul>
        </div>
        <div class="screen-item">
          <div class="screen-title">
            <span class="title-left">充电站区县分布</span>
          </div>
          <div class="charts-box" id="site-count" style="height: 250px;"></div>
        </div>
      </div>
      <div class="screen-center" :style="{height: centerHeight + 'px'}">
        <el-amap class="map" :vid="'amap-vue'" :amap-manager="amapManager"></el-amap>
      </div>
    </div>
    <div class="screen-bottom">
      <div class="screen-item" style="width: 28%;">
          <div class="screen-title">
            <span class="title-left">充电站充电时长排名</span>
            <ul class="title-tab">
              <li v-for="(rank, rInd) in timeTabList" :key="rInd" :class="{'current': rInd === rankTabInd}">{{rank.label}}</li>
            </ul>
          </div>
          <ul class="rank-list">
            <li v-for="(item, index) in rankList" :key="index">
              <div class="rank-title">
                <span class="name" :title="item.siteName">{{index + 1}} {{item.siteName}}</span>
                <span class="text">
                <ICountUp :delay="100" :endVal="item.value" :options="countUpOpt.options"></ICountUp>
              </span>
              </div>
              <Progress :percent="item.value"
                        :stroke-width="10"
                        :stroke-color="[colorToRgb('#6DECEC', 0.4), colorToRgb('#6DECEC', 1)]"
                        hide-info>
              </Progress>
            </li>
          </ul>
        </div>
      <div class="screen-item screen-item-site">
        <div class="screen-title">
          <span class="title-left">本月充电统计</span>
          <Select v-model="chargeSiteId" placeholder="全部场站" size="small"></Select>
        </div>
        <div class="charts-box" id="week-charge-statistics" style="height: 400px;"></div>
      </div>
      <div class="screen-item" style="width: 20%;">
        <div class="screen-title">
          <span class="title-left">告警日志</span>
        </div>
        <div class="alarm-list">
          <li v-for="(aItem, aInd) in alarmData" :key="aInd">
              <span class="square"></span>
              <p class="info">
                <span class="time">{{aItem.time}}</span>
                <span class="title">{{aItem.title}}</span>
                <span class="content">{{aItem.content}}</span>
              </p>
          </li>
        </div>
      </div>
    </div>
    <Loading :show="loading" :styl="{background: 'rgba(0,0,0,.8)'}" class="loading"></Loading>
  </div>
</template>

<script>
import ICountUp from 'vue-countup-v2'
import Loading from '@/components/Loading'
import dayjs from 'dayjs'
import { colorToRgb } from '@/libs/tools'
import * as echarts from 'echarts'
import VueAMap from 'vue-amap'
let amapManager = new VueAMap.AMapManager()
export default {
  name: "big-screen",
  components: {
    ICountUp,
    Loading
  },
  data () {
    return {
      loading: false,
      countUpOpt: {
        delay: 1000,
        options: {
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
          prefix: '',
          suffix: ''
        }
      },
      height: 0,
      dateTimer: null,
      nowDate: '',
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      timeTabList: [
        {
          label: '本周',
          value: 'week'
        },
        {
          label: '本月',
          value: 'month'
        }
      ],
      rankTabInd: 0,
      rankTotal: 0,
      rankList: [
        {
          siteName: '杨柳公园3号停车场',
          value: 90
        },
        {
          siteName: '华川物流',
          value: 80
        },
        {
          siteName: '邦泰.理想城',
          value: 70
        },
        {
          siteName: '杨柳公园3号停车场',
          value: 60
        },
        {
          siteName: '华川物流',
          value: 50
        },
        {
          siteName: '邦泰.理想城',
          value: 40
        },
        {
          siteName: '杨柳公园3号停车场',
          value: 30
        },
        {
          siteName: '华川物流',
          value: 20
        },
        {
          siteName: '邦泰.理想城',
          value: 10
        },
        {
          siteName: '邦泰.理想城',
          value: 5
        }
      ],
      chartObj: {
        chargeStsObj: null,
        chargeTimeObj: null,
        siteCountObj: null,
        siteChargeObj: null
      },
      pieCfg: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: '60%',
          top: 'center',
          padding: 10,
          textStyle: {
            color: '#fff'
          },
          data: []
        },
        series: [{
          type: 'pie',
          // roseType: 'radius',
          radius: ['25%', '60%'],
          center: ['30%', '50%'],
          data: [],
          hoverAnimation: false,
          label: {
            show: false
          }
        }]
      },
      lineCfg: {
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0,
          top: '15%',
          left: '3%',
          right: '3%',
          bottom: '5%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          orient: 'vertical',
          left: 'center',
          padding: [10, 0, 0, 0],
          data: [],
          //图例文字样式
          textStyle: {
            color: 'rgba(36, 173, 254, 1)',
            fontSize: '12px'
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              show: false,
            },
            interval: 1,
            axisLabel: {
              color: 'rgba(36, 173, 254, 1)',
              fontSize:"12px"
            },
            axisLine: {
              show: false
            },
            data: [],
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            min: 0,
            max: 100,
            interval: 20,
            axisLabel: {
              textStyle: {
                //坐标轴颜色
                color: 'rgba(36, 173, 254, 1)',
                fontSize:"12px"
              }
            },
            //坐标轴线样式
            splitLine: {
              show: true,
              lineStyle: {
                type: 'solid', //solid实线;dashed虚线
                color: 'rgba(36, 173, 254, 0.2)'
              }
            }
          }
        ],
        series: [
          {
            name: "充电次数",
            data: [],
            type: 'line',
            smooth: true, //true曲线; false折线
            itemStyle: {
              normal: {
                color: '#ffc600', //改变折线点的颜色
                lineStyle: {
                  color: '#ffc600', //改变折线颜色
                  type: 'solid'
                }
              }
            },
            areaStyle: {
              //折线图颜色半透明
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(255,198,0, 0.5)' // 0% 处的颜色
                }, {
                  offset: 1, color: 'rgba(255,198,0, 0.1)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            }
          }
        ]
      },
      lineChartsOpt: {
        color: ['#fac858', '#6fd2ef'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '10px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#fff',
            fontSize:"12px"
          },
        },
        yAxis: [
          {
            type: 'value',
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: 'rgba(255,255,255,.2)'
              }
            },
            axisLabel: {
              textStyle: {
                //坐标轴颜色
                color: '#fff',
                fontSize:"12px"
              }
            }
          },
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                //坐标轴颜色
                color: '#fff',
                fontSize:"12px"
              }
            }
          }
        ],
        legend: {
          top: '20px',
          textStyle: {
            color: '#fff',
            fontSize: '12px'
          }
        },
        series: [{
          data: [],
          name: '订单(笔)',
          type: 'bar',
          smooth: true,
          showSymbol: false,
          symbol: 'roundRect',
          areaStyle: {
            opacity: 0.8
          },
          lineStyle: {
            width: 0
          }
        },
          {
            data: [],
            name: '电量(度)',
            type: 'line',
            smooth: true,
            showSymbol: false,
            symbol: 'roundRect',
            yAxisIndex: 1
          }]
      },
      centerHeight: 0,
      incomeArr: [
        {
          label: '今日总收入(元)',
          value: 3465.32,
          key: 'day'
        },
        {
          label: '本月总收入(元)',
          value: 82915.72,
          key: 'month'
        }
      ],
      filterTab: [
        {
          label: '今日',
          key: 'day'
        },
        {
          label: '本月',
          key: 'month'
        }
      ],
      orderFilInd: 0,
      orderStaArr: [
        {
          label: '充电订单笔数(笔)',
          key: 'chargeCount',
          value: 20
        },
        {
          label: '充电量(度)',
          key: 'chargeElectricNumber',
          value: 600
        },
        {
          label: '充电时长(时)',
          key: 'chargeTime',
          value: 20
        }
      ],
      amapManager,
      mapEvents: {
        init (o) {
          console.log(o)
          o.setMapStyle('amap://styles/blue')
        }
      },
      chargeSiteId: '',
      alarmData: [
        {
          time: '2022-07-18 13:00:00',
          title: '杨柳公园',
          content: '杨柳公园充电站11号桩故障'
        },
        {
          time: '2022-07-18 13:00:00',
          title: '杨柳公园',
          content: '杨柳公园充电站11号桩故障'
        },
        {
          time: '2022-07-18 13:00:00',
          title: '杨柳公园',
          content: '杨柳公园充电站11号桩故障'
        },
        {
          time: '2022-07-18 13:00:00',
          title: '杨柳公园',
          content: '杨柳公园充电站11号桩故障'
        },
        {
          time: '2022-07-18 13:00:00',
          title: '杨柳公园',
          content: '杨柳公园充电站11号桩故障'
        }
      ]
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.height = document.documentElement.clientHeight
      this.loading = false
      this.nowDate = dayjs(new Date()).format(this.dateFormat)
      setInterval(() => {
        this.nowDate = dayjs(new Date()).format(this.dateFormat)
      }, 1000)
      this.centerHeight = document.getElementsByClassName('screen-left')[0].clientHeight
      this.init()
    })
  },
  beforeDestroy () {
    this.clearTimer()
  },
  methods: {
    colorToRgb,
    clearTimer () {
      clearInterval(this.dateTimer)
      this.dateTimer = null
    },
    init () {
      this.getChargeStatus()
      this.getChargeTime()
      this.getSiteCount()
      this.getSiteCharts()
      setTimeout(() => {
        this.initMap()
      }, 200)
    },
    getChargeStatus () {
      this.chartObj.chargeStsObj = echarts.init(document.getElementById('charge-status'))
      this.setChargeStatus()
    },
    setChargeStatus () { // 充电桩实时状态
      let data = [
        {
          name: '空闲',
          value: 50
        },
        {
          name: '充电中',
          value: 20
        },
        {
          name: '故障',
          value: 1
        },
        {
          name: '离线',
          value: 3
        }
      ]
      let option=  Object.assign({}, this.pieCfg)
      let legend = []
      data.forEach((item) => {
        legend.push(item.name)
      })
      option.color = ['#62c88d', '#e4365d', '#ea8b00', '#9a9b9d']
      option.legend.data = legend
      option.series[0].data = data
      this.chartObj.chargeStsObj.setOption(option)
    },
    getChargeTime () {
      this.chartObj.chargeTimeObj = echarts.init(document.getElementById('charge-time'))
      this.setChargeTime()
    },
    setChargeTime () {
      let count = 24
      let xData = []
      let yData = []
      for (let i = 0; i < count; i ++) {
        xData.push(i + '点')
        yData.push((Math.random() * 100).toFixed(0))
      }
      let option = Object.assign({}, this.lineCfg)
      option.legend.data = ['充电次数']
      option.series[0].data = yData
      option.xAxis[0].data = xData
      this.chartObj.chargeTimeObj.setOption(option)
    },
    getSiteCount () {
      this.chartObj.siteCountObj = echarts.init(document.getElementById('site-count'))
      this.setSiteCount()
    },
    setSiteCount () {
      let data = [
        {
          name: '达川区',
          value: 10
        },
        {
          name: '通川区',
          value: 5
        },
        {
          name: '宣汉县',
          value: 1
        },
        {
          name: '开江县',
          value: 1
        },
        {
          name: '大竹县',
          value: 1
        },
        {
          name: '渠县',
          value: 1
        },
        {
          name: '万源市',
          value: 1
        }
      ]
      let option=  Object.assign({}, this.pieCfg)
      let legend = []
      data.forEach((item) => {
        legend.push(item.name)
      })
      option.legend.data = legend
      option.series[0].roseType = 'radius'
      option.series[0].radius = ['20%', '55%']
      option.series[0].data = data
      this.chartObj.siteCountObj.setOption(option)
    },
    getRankList () {
      this.rankTotal = 0
      if (this.rankList.length) {
        this.rankList.forEach((item) => {
          this.rankTotal += item.value
        })
      }
    },
    getRankPercent () {},
    toFilterTab (type, index) {
      switch (type) {
        case 'order': {
          this.orderFilInd = index
          break
        }
        default:
      }
    },
    initMap () {
      let map = this.amapManager._map
      map.setMapStyle('amap://styles/darkblue')
      this.loading = false
    },
    getSiteCharts () {
      this.chartObj.siteChargeObj = echarts.init(document.getElementById('week-charge-statistics'))
      this.setSiteChartData()
    },
    setSiteChartData () {
      let len = 30
      let xData = []
      for (let i = 0; i < len; i++) {
        let it = i + 1 > 9 ? i + 1 : '0' + (i + 1)
        xData.push('2022-06-' + it)
      }
      let sData = []
      let eData = []
      for (let i = 0; i < len; i++) {
        sData.push((Math.random() * 100).toFixed(0))
      }
      for (let i = 0; i < len; i++) {
        eData.push((Math.random() * 400).toFixed(0))
      }
      this.lineChartsOpt.xAxis.data = xData
      this.lineChartsOpt.series[0].data = sData
      this.lineChartsOpt.series[1].data = eData
      this.chartObj.siteChargeObj.setOption(this.lineChartsOpt)
    },
  }
};
</script>

<style scoped lang="less">
  @import "big-screen";
</style>
