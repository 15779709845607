<template>
  <div class="spin-box" v-if="show" :style="styles">
    <div v-if="text" class="text"><span>{{ text }}</span><Spin></Spin><Spin></Spin><Spin></Spin></div>
    <Spin size="small"></Spin>
    <Spin size="small"></Spin>
    <Spin></Spin>
    <Spin></Spin>
    <Spin size="large"></Spin>
    <Spin size="large"></Spin>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    height: {
      type: [String, Number],
      default: '100%'
    },
    styl: {
      type: Object,
      default () {
        return {}
      }
    },
    text: {
      type: String,
      default: '加载中'
    }
  },
  data () {
    return {
      styles: {}
    }
  },
  mounted () {
    this.$nextTick(() => {
      let h = typeof this.height === 'string' ? this.height : this.height + 'px'
      this.styles = Object.assign({}, {
        height: h
      }, this.styl)
    })
  }
}
</script>

<style scoped lang="less">
.spin-box{
  width: 100%;
  position: absolute;
  left: 0;
  top:0;
  background: rgba(0,0,0,.9);
  z-index: 10;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  .text{
    width: 100%;
    text-align: center;
    color: @baseColor;
    margin-bottom: @base;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      margin-right: @base / 2;
    }
    /deep/.ivu-spin-dot{
      width: 4px;
      height: 4px;
    }
  }
}
</style>
