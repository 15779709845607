import axios from './api.request'
// 地图key
export const mapKey = '4OXBZ-MJ4WF-22TJG-JNXV7-P7QRK-6CBK7'
// 地图中心点
export const center = {
    lng: 107.50182,
    lat: 31.21465,
    zoom: 12
}
// 达州市地理坐标为北纬30°75′-32°07′，东经106°94′-108°06′。
// 文件上传
export const uploadImage = '/api/SiteManagement/UploadImage'

// 登录
export const login = (data) => {
    return axios.request({
        noToken: true,
        url: '/mdxapi/ForeignCharging/query_token',
        data,
        method: 'post'
    })
}
export const getEncryptDecrypt = (data) => { // 加解密
    return axios.request({
        noToken: true,
        url: '/mdxapi/InsideHelp/AesEncryptDecrypt',
        data
    })
}
export const getSign = (data) => {
    return axios.request({
        noToken: true,
        url: '/mdxapi/InsideHelp/GetSign',
        data
    })
}
/*启停充电--start*/
export const testApi = (data) => {
    return axios.request({
        url: '/mdxapi/ForeignCharging/query_equip_business_policy',
        data
    })
}
/*启停充电--end*/
export const startCharge = (data) => { // 开始充电
    return axios.request({
        url: '/mdxapi/ForeignCharging/query_start_charge',
        data
    })
}
export const stopCharge = (data) => { // 停止充电
    return axios.request({
        url: '/mdxapi/ForeignCharging/query_stop_charge',
        data
    })
}
/*电站--start*/
export const querySite = (data) => { // 根据条件信询电站信息
    return axios.request({
        url: '/mdxapi/ForeignCharging/query_stations_info',
        data
    })
}
export const querySiteStas = (data) => { //此查询用于定期获取每个充电站，在某个周期内的统计信息
    return axios.request({
        url: '/mdxapi/ForeignCharging/query_station_stats',
        data
    })
}
export const querySiteAll = (params) => { // 查询站点(不分页)
    return axios.request({
        noToken: true,
        url: '/api/SiteManagement/GetAllSiteManagement',
        params,
        method: 'get'
    })
}
export const querySiteById = (params) => { // 根据id查询信息
    return axios.request({
        noToken: true,
        url: '/api/SiteManagement/GetById',
        params,
        method: 'get'
    })
}
export const siteAdd = (data) => { // 新增
    return axios.request({
        noToken: true,
        url: '/api/SiteManagement/Insert',
        data
    })
}
export const siteUpdate = (data) => { // 修改
    return axios.request({
        noToken: true,
        url: '/api/SiteManagement/Update',
        data
    })
}
export const siteUpdateState = (params) => { // 根据Id修改状态
    return axios.request({
        noToken: true,
        url: '/api/SiteManagement/UpdateState',
        params,
        method: 'get'
    })
}

export const chargePointAdd = (data) => { // 充电桩新增
    return axios.request({
        noToken: true,
        url: '/api/ChargePointManagement/Insert',
        data
    })
}
export const queryChargePoint = (data) => { // 充电桩查询
    return axios.request({
        noToken: true,
        url: 'api/ChargePointManagement/GetChargePointManagementSearch',
        data
    })
}
export const chargePointUpdate = (data) => { // 充电桩修改
    return axios.request({
        noToken: true,
        url: '/api/ChargePointManagement/Update',
        data
    })
}
export const chargeRateSetting = (data) => { // 充电桩下发费率命令
    return axios.request({
        noToken: true,
        url: '/api/Socket/SocketBillingSettings',
        data
    })
}
export const chargeRateSettingWM = (data) => { // 充电桩下发费率命令-万马
    return axios.request({
        noToken: true,
        url: '/api/SocketWM/SocketBillingSettingsWM',
        data
    })
}
export const chargePointUpdateState = (params) => { // 根据Id修改状态
    return axios.request({
        noToken: true,
        url: '/api/ChargePointManagement/UpdateState',
        params,
        method: 'get'
    })
}
export const chargePointUpdateRate = (params) => { // 根据Id修改所属费率组
    return axios.request({
        noToken: true,
        url: '/api/ChargePointManagement/UpdateRateId',
        params,
        method: 'get'
    })
}
export const getHeartbeatFront = (params) => { // 获取桩信息的心跳和时间
    return axios.request({
        noToken: true,
        url: '/api/Socket/SocketCheckHeartbeatFront',
        params,
        method: 'get'
    })
}
export const addElectricGun = (data) => {//新增电枪
    return axios.request({
        noToken: true,
        url: '/api/ElectricManagement/Insert',
        data
    })
}
export const updateElectricGun = (data) => {//修改电枪
    return axios.request({
        noToken: true,
        url: '/api/ElectricManagement/Update',
        data
    })
}
export const getElectricSearch = (data) => { // 获取电枪列表
    return axios.request({
        noToken: true,
        url: '/api/ElectricManagement/GetElectricManagementSearch',
        data
    })
}
export const getEleByChargeId = (params) => { // 根据电桩Id获取电枪信息
    return axios.request({
        noToken: true,
        url: '/api/ElectricManagement/GetByChargeId',
        params,
        method: 'get'
    })
}
export const getRealData = (params) => { // 实时监测电枪数据
    return axios.request({
        noToken: true,
        url: '/api/Socket/SocketGetChargingRealTimeStatus',
        params,
        method: 'get'
    })
}
export const getRemoteReboot = (params) => { // 远程重启
    return axios.request({
        noToken: true,
        url: '/api/Socket/SocketRemoteReboot',
        params,
        method: 'get'
    })
}
export const getCheckTime = (params) => { // 核对时间(充电桩与服务器核对时间一天一次)
    return axios.request({
        noToken: true,
        url: '/api/Socket/SocketCheckTime',
        params,
        method:'get'
    })
}
export const setQRCode = (params) => { // 电枪下发二维码
    return axios.request({
        noToken: true,
        url: '/api/Socket/IssueQRCode',
        params,
        method: 'get'
    })
}
/*电站--end*/
/*费率组管理--start*/
export const chargeRatesQuery = (data) => {
    return axios.request({
        noToken: true,
        url: '/api/ChargeRates/GetChargeRatesSearch',
        data
    })
}
export const chargeRatesAdd = (data) => { // 新增
    return axios.request({
        noToken: true,
        url: '/api/ChargeRates/Insert',
        data
    })
}
export const chargeRatesUpdate = (data) => { // 修改
    return axios.request({
        noToken: true,
        url: '/api/ChargeRates/Update',
        data
    })
}
export const updateRateIdBySiteIs = (params) => { // 下发费率
    return axios.request({
        noToken: true,
        timeout: 60000,
        url: '/api/ChargePointManagement/UpdateRateIdBySiteIds',
        params,
        method: 'get'
    })
}
export const chargeRatesUpdateState = (params) => { // 根据id修改状态
    return axios.request({
        noToken: true,
        url: '/api/ChargeRates/UpdateState',
        params,
        method: 'get'
    })
}
/*费率组管理--end*/
/*订单管理--start*/
export const getOrderList = (data) => { // 获取订单列表
    return axios.request({
        noToken: true,
        url: '/api/OrderInformation/GetOrderInformationSearch',
        data
    })
}
export const getRealOrderInfo = () => { // 获取实时订单数据
    return axios.request({
        noToken: true,
        url: '/api/Socket/SocketGetSocketRealTimeDataFront',
        method: 'get'
    })
}
/*订单管理--end*/
/*用户管理--start*/
export const getUserList = (data) => { // 获取用户列表
    return axios.request({
        noToken: true,
        url: '/api/UserInfo/GetUserInfoSearch',
        data
    })
}
export const userListUpdate = (data) => { // 根据用户id修改用户音系
    return axios.request({
        noToken: true,
        url: '/api/UserInfo/UpdateUserInfo',
        data
    })
}
export const updatePwd = (data) => { // 修改密码
    return axios.request({
        noToken: true,
        url: '/api/UserInfo/UpdateUserPwd',
        data
    })
}
export const userReconcileAdd = (data) => { // 新增对账用户
    return axios.request({
        noToken: true,
        url: '/api/UserInfo/InserUserReconciliation',
        data
    })
}
export const userDel = (params) => { // 删除用户
    return axios.request({
        noToken: true,
        url: '/api/UserInfo/DeleteUser',
        params,
        method: 'get'
    })
}
export const insertRecharge = (data) => {
    return axios.request({
        noToken: true,
        url: 'api/UserRechargeRecord/InsertUserRechargeRecord',
        data
    })
}
export const getMemberList = (params) => { // 获取会员等级
    return axios.request({
        noToken: true,
        url: '/api/MemberLevelManagement/GetAll',
        params,
        method: 'get'
    })
}
export const memberAdd = (data) => { // 会员等级新增
    return axios.request({
        noToken: true,
        url: '/api/MemberLevelManagement/Insert',
        data
    })
}
export const memberUpdate = (data) => { // 会员等级修改
    return axios.request({
        noToken: true,
        url: '/api/MemberLevelManagement/Update',
        data
    })
}
export const userRechargeQuery = (data) => { // 流水记录查询
    return axios.request({
        noToken: true,
        url: '/api/UserRechargeRecord/GetUserRechargeRecordSearch',
        data
    })
}
export const userRechargeAdd = (data) => { // 充值记录新增
    return axios.request({
        noToken: true,
        url: '/api/UserRechargeRecord/InsertUserRechargeRecord',
        data
    })
}
export const userRechargeUpdate = (data) => { // 充值记录修改
    return axios.request({
        noToken: true,
        url: '/api/UserRechargeRecord/Update',
        data
    })
}
export const checkCarInfo = (params) => { // 审核用户车辆信息
    return axios.request({
        noToken: true,
        url: '/api/UserInfo/UpdateAuditStatus',
        params,
        method: 'get'
    })
}
/*用户管理--end*/
/*统计--start*/
// 个人消费统计
export const getPersonConsume = (data) => { // 个人消费统计
    return axios.request({
        noToken: true,
        url: '/api/OrderInformation/GetPersonalMonthlyRanking',
        data
    })
}
export const personConsumeExport = (data) => { // 个人消费统计导出
    return axios.request({
        noToken: true,
        url: '/api/OrderInformation/ExportExcelSummary',
        data,
        responseType: 'blob'
    })
}
// 订单统计
export const getOrderCharts = (data) => { // 订单汇总图表
    return axios.request({
        noToken: true,
        url: '/api/OrderInformation/GetOrderInformationSummaryChart',
        data
    })
}
export const getOrderSummary = (data) => { // 订单汇总列表
    return axios.request({
        noToken: true,
        url: '/api/OrderInformation/GetOrderInformationSummary',
        data
    })
}
export const orderSummaryExport = (data) => { // 订单汇总导出
    return axios.request({
        noToken: true,
        url: '/api/OrderInformation/ExportExcelSummary',
        data,
        responseType: 'blob'
    })
}
export const orderDetailList = (data) => { // 订单明细列表
    return axios.request({
        noToken: true,
        url: '/api/OrderInformation/GetOrderInformationNewSearch',
        data
    })
}
export const orderDetailExport = (data) => { // 订单明细导出
    return axios.request({
        noToken: true,
        url: '/api/OrderInformation/ExportExcelDetailed',
        data,
        responseType: 'blob'
    })
}
/*统计--end*/
/*运营商管理--start*/
export const companyAdd = (data) => { // 运营商新增
    return axios.request({
        noToken: true,
        url: '/api/Company/Insert',
        data
    })
}
export const companyQuery = (params) => { // 运营商查询
    return axios.request({
        noToken: true,
        url: '/api/Company/GetAllCompany',
        params,
        method: 'get'
    })
}
export const companyMod = (data) => { // 运营商修改
    return axios.request({
        noToken: true,
        url: '/api/Company/Update',
        data
    })
}
export const withdrawWaitingQuery = (params) => { // 待提现记录查询(分站点)
    return axios.request({
        noToken: true,
        url: '/api/OperatorSettlement/GenerateSettlementList',
        params,
        method: 'get'
    })
}
export const withdrawWaitingQuerySummary = (params) => { // 待提现记录查询(汇总)
    return axios.request({
        noToken: true,
        url: '/api/OperatorWithdrawal/GetWithdrawalSummary',
        params,
        method: 'get'
    })
}
export const withdrawAdd = (data) => { // 提现申请
    return axios.request({
        noToken: true,
        url: '/api/OperatorWithdrawal/InsertOperatorSettlement',
        data
    })
}
export const withdrawQuery = (data) => { // 运营商提现记录查询
    return axios.request({
        noToken: true,
        url: '/api/OperatorWithdrawal/GetAllOperatorWithdrawal',
        data
    })
}
export const withdrawApplyOk = (params) => { // 修改提现记录状态
    return axios.request({
        noToken: true,
        url: '/api/OperatorWithdrawal/UpdateStatus',
        params,
        method: 'get'
    })
}
export const getSumOperatorWithdrawal = (params) => { // 已提现总金额
    return axios.request({
        noToken: true,
        url: '/api/OperatorWithdrawal/GetSumOperatorWithdrawal',
        params,
        method: 'get'
    })
}
/*运营商管理--end*/
/*活动管理--start*/
export const getActivityQuery = (data) => { // 活动查询
    return axios.request({
        noToken: true,
        url: '/api/ActivityList/GetActivityListSearch',
        data
    })
}
export const setActivityAdd = (data) => { //活动新增
    return axios.request({
        noToken: true,
        url: '/api/ActivityList/Insert',
        data
    })
}
export const setActivityUpdate = (data) => { //活动修改
    return axios.request({
        noToken: true,
        url: '/api/ActivityList/Update',
        data
    })
}
export const setActivityState = (params) => {
    return axios.request({
        noToken: true,
        url: '/api/ActivityList/UpdateState',
        params,
        method: 'get'
    })
}
/*活动管理--end*/
