<template>
  <div class="order-wrapper">
    <search-form :fields="searchFields" :okLoading="loading" :minSize="2" @on-search="toSearch" @on-reset="toReset"></search-form>
    <div class="order-list" ref="table" :style="{height: tableHeight + 'px'}">
      <template v-if="!loading">
        <template v-if="list.length">
          <div class="order-item" v-for="(item, index) in list" :key="index">
            <div class="item-top">
              <p class="left">充电单号: {{item.transactionNumber}}
                <span class="status" :style="{backgroundColor: statusText(item.status).color}">{{ statusText(item.status).text }}</span>
              </p>
              <p class="right">开始时间: {{item.orderCreateTime}}</p>
            </div>
            <p class="name">{{item.userPhone}}</p>
            <div class="order-ul-box">
              <ul class="order-ul">
                <li v-for="(attr, aInd) in listAttr" :key="aInd">
                  <label>{{attr.name}}:</label>
                  <span :title="item.chargeNumber + item.gunNumber"
                        v-if="attr.key === 'chargeNumber'">{{item.chargeNumber + item.gunNumber}}</span>
                  <span :title="item[attr.key]" v-else>{{item[attr.key] ? item[attr.key] + (attr.unit ? attr.unit : '') : '--'}}</span>
                </li>
              </ul>
              <div class="list-btn-box" style="margin-top: -15px;">
                <a class="detail" href="javascript:;" @click="toDetail(item)">
                  <i class="iconfont icon-chakanjiexi"></i>
                  <span>详情</span></a>
              </div>
            </div>
           </div>
          </template>
          <no-data v-else></no-data>
        </template>
        <loading :show="loading" :styl="{backgroundColor: 'rgba(255,255,255,.3)'}"></loading>
    </div>
    <Page :total="total" :current="params.pageNo" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
    <Modal v-model="showDetail" title="详情" :fullscreen="true" class-name="modal-detail">
      <div class="modal-box">
        <div class="title">
          <span>充电单号: {{detail.rechargeNo}}
            <label class="status" v-if="detail.status" :style="{backgroundColor: statusText(detail.status).color}">
              {{ statusText(detail.status).text }}
            </label>
          </span>
        </div>
        <ul class="detail-ul">
          <li><label>用户账号: </label><span>{{detail.userName}}</span></li>
          <li><label>用户昵称: </label><span>{{detail.nickName}}</span></li>
          <li v-for="(attr, aInd) in listAttr" :key="aInd">
            <label>{{attr.name}}: </label>
            <span :title="detail[attr.key]">{{detail[attr.key] ? detail[attr.key] : '——'}}</span>
          </li>
        </ul>
      </div>
      <div class="modal-box modal-box-center">
        <ul class="use-ele-detail">
          <li v-for="(attr, aInd) in useEleAttr" :key="aInd">
            <i class="iconfont" :class="attr.icon" :style="{color: attr.color}"></i>
            <p>
              <label>{{attr.name}}: </label>
              <span>{{detail[attr.key]}}{{attr.unit}}</span>
            </p>
          </li>
        </ul>
        <p class="recharge-time">{{detail.startTime}}充电开始</p>
      </div>
      <div class="modal-box">
        <div class="title">
          <span>实时状态监测</span>
          <ul class="title-tab">
            <li v-for="(tTab, tTInd) in titleTab" :key="tTInd" :class="{'current': tTInd === titleTInd}" @click="toTitleTab(tTInd)">{{tTab.name}}</li>
          </ul>
        </div>
        <div class="titleTab-box">
          <div class="charts-box" v-if="titleTInd === 0">
            charts
          </div>
          <template v-if="titleTInd === 1">
            <ul class="grid-tab">
              <li v-for="(gTab, gInd) in gridTab" :key="gInd" :class="{'current': gInd === gridTInd}" @click="toGridTab(gInd)">{{gTab.name}}</li>
            </ul>
            <div class="grid-table">
              <Table :columns="monitorCol[gridTInd]" :data="monitorData" stripe></Table>
              <Page :total="modalTotal" :current="modalParams.pageNo" :page-size="modalParams.pageSize" show-elevator show-sizer show-total @on-change="pageModalChange" @on-page-size-change="pageSizeModalChange"></Page>
            </div>
          </template>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import { mapState } from 'vuex'
import NoData from '@/components/NoData'
import Loading from '@/components/Loading'
import dayjs from 'dayjs'
import { getOrderList, getRealOrderInfo } from '@/libs/api'
import { getSelectLabel } from '@/libs/tools'
import { chargeStopOpt } from '@/libs/dict'

export default {
  name: "realTime-order",
  components: {
    SearchForm,
    NoData,
    Loading
  },
  data () {
    return {
      chargeStopOpt,
      loading: false,
      routeType: '',
      searchFields: [
        {
          label: '时间选择',
          type: 'multiple',
          cls:'multiple half',
          child: [
            {
              name: 'startTime',
              type: 'datetime'
            },
            {
              name: 'endTime',
              type: 'datetime'
            }
          ]
        },
        {
          label: '充电单号',
          name: 'orderNumber',
          type: 'input'
        },
        {
          label: '充电方式',
          name: 'rechargeType',
          type: 'select'
        },
        {
          label: '用户账号',
          name: 'userNo',
          type: 'input'
        },
        {
          label: '用户昵称',
          name: 'userName',
          type: 'input'
        },
        {
          label: '充电卡号',
          name: 'rechargeCard',
          type: 'input'
        },
        {
          label: '归属机构',
          name: 'belongOrgan',
          type: 'input'
        },
        {
          label: '充电车辆',
          name: 'carNo',
          type: 'input'
        },
        {
          label: '车辆型号',
          name: 'carModel',
          type: 'select'
        },
        {
          label: '电站名称',
          name: 'stateName',
          type: 'input'
        },
        {
          label: '终端名称',
          name: 'chargeNumber',
          type: 'input'
        },
        {
          label: '运营商',
          name: 'operatorName',
          type: 'input'
        },
        {
          label: '运营商属性',
          name: 'terminalType',
          type: 'select'
        },
        {
          label: '订单类型',
          name: 'orderType',
          type: 'select'
        },
        {
          label: '充电模式',
          name: 'rechargePattern',
          type: 'select'
        },
        {
          label: '交易属性',
          name: 'dealAttr',
          type: 'select'
        },
      ],
      listAttr: [
        {
          key: 'physicalCardNumber',
          name: '充电卡号'
        },
        {
          key: 'cumulativeChargingTime',
          name: '已充时间',
          unit: '分钟'
        },
        {
          key: 'chargedAmount',
          name: '已充费用',
          unit: '元'
        },
        {
          key: 'siteName',
          name: '电站名称'
        },
        {
          key: 'dealAttr',
          name: '交易属性'
        },
        {
          key: 'belongOrgan',
          name: '归属机构'
        },
        {
          key: 'chargingDegree',
          name: '已充电量',
          unit: '度'
        },
        {
          key: 'outputCurrent',
          name: '充电电流',
          unit: 'A'
        },
        {
          key: 'outputVoltage',
          name: '充电电压',
          unit: 'V'
        },
        {
          key: 'chargeNumber',
          name: '终端编号'
        },
        {
          key: 'orderType',
          name: '订单类型'
        },
        {
          key: 'deliveryTime',
          name: '上送时间'
        },
        {
          key: 'carNo',
          name: '充电车辆'
        },
        {
          key: 'uploadSOC',
          name: '当前SOC',
          unit: '%'
        },
        {
          key: 'operatorName',
          name: '运营商'
        },
        {
          key: 'rechargePattern',
          name: '充电模式'
        }
      ],
      list: [],
      params: {
        pageSize: 10,
        pageNo: 1,
        orderState: 2
      },
      total: 100,
      showDetail: false,
      detail: {},
      useEleAttr: [
        {
          icon: 'icon-icon-',
          name: '电量',
          color: '#DD5227',
          key: 'eleCount',
          unit: 'kw.h'
        },
        {
          icon: 'icon-yujing_',
          name: 'SOC',
          color: '#4d8cfd',
          key: 'soc',
          unit: '%'
        },
        {
          icon: 'icon-shalou',
          name: '时长',
          color: '#E0572A',
          key: 'useTime',
          unit: '分钟'
        }
      ],
      titleTab: [
        {
          name: '图表'
        },
        {
          name: '列表'
        }
      ],
      titleTInd: 0,
      gridTab: [
        {
          name: '上传实时监测数据'
        },
        {
          name: 'BMS需求和充电机输出'
        },
        {
          name: '充电过程BMS信息'
        }
      ],
      gridTInd: 0,
      monitorCol: {
        0: [
          {
            key: 'startTime',
            title: '上传时间'
          },
          {
            key: 'voltage',
            title: '电压(V)'
          },
          {
            key: 'ampere',
            title: '电流(A)'
          },
          {
            key: 'soc',
            title: 'SOC(%)'
          },
          {
            key: 'eleCount',
            title: '电量(度)'
          },
          {
            key: 'lossEleCount',
            title: '计损电量(度)'
          },
          {
            key: 'remainTime',
            title: '剩余时间'
          },
          {
            key: 'useTime',
            title: '已充时间'
          },
          {
            key: 'rechargeStatus',
            title: '充电状态'
          },
          {
            key: 'gunTemperature',
            title: '枪线温度(℃)'
          },
          {
            key: 'gunConnectSts',
            title: '枪连接状态'
          }
        ],
        1: [
          {
            key: 'maxTemperature',
            title: '电池组最高温度(℃)'
          },
          {
            key: 'maxTempNo',
            title: '最高温度监测点编号'
          },
          {
            key: 'minTemperature',
            title: '电池组最低温度(℃)'
          },
          {
            key: 'minTempNo',
            title: '最低温度监测点编号'
          }
        ],
        2: [
          {
            key: 'maxVoltage',
            title: '最高单体电压'
          },
          {
            key: 'maxVolNo',
            title: '最高单体电压监测点编号'
          },
          {
            key: 'demandVoltage',
            title: '需求电压(V)'
          },
          {
            key: 'demandAmpere',
            title: '需求电流(A)'
          }
        ]
      },
      monitorData: [],
      modalParams: {
        pageSize: 10,
        pageNo: 1
      },
      modalTotal: 0,
      listTimer: null
    }
  },
  beforeDestroy () {
    this.clearTimer()
  },
  mounted () {
    this.$nextTick(() => {
      this.routeType = this.$route.path.split('/')[this.$route.path.split('/').length - 1]
      this.getList()
    })
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  methods: {
    getSelectLabel,
    statusText (val) {
      let obj = {
        1: {
          text: '充电中',
          color: '#4d8cfd'
        }
      }
      return obj[val] ? obj[val] : {
        text: '',
        color: ''
      }
    },
    toSearch (obj) {
      this.searchForm = obj
      this.params.pageNo = 1
      this.getList()
    },
    toReset (obj) {
      this.searchForm = obj
      this.getList()
    },
    pageChange (page) {
      this.params.pageNo = page
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    pageModalChange (page) {
      this.modalParams.pageNo = page
    },
    pageSizeModalChange (pageSize) {
      this.modalParams.pageSize = pageSize
    },
    toDetail (item) {
      this.showDetail = true
      this.detail = item
    },
    toTitleTab (index) {
      this.titleTInd = index
      this.gridTInd = 0
      this.modalParams = {
        pageNo: 1,
        pageSize: 10
      }
      this.modalTotal = 0
    },
    toGridTab (index) {
      this.gridTInd = index
      this.modalParams = {
        pageNo: 1,
        pageSize: 10
      }
      this.modalTotal = 0
    },
    async getList () {
      // if (this.routeType === 'operator') { // 个人
        this.loading = true
        await getOrderList(Object.assign({}, this.searchForm, this.params)).then((res) => {
          if (res.data.code === 0) {
            this.loading = false
            this.list = res.data.data.OrderInformation.data
            this.total = res.data.data.OrderInformation.total
            if (this.list.length) {
              this.getRealOrderDetail()
              this.listTimer = setInterval(() => {
                this.getRealOrderDetail()
              }, 5000)
            }
          } else {
            this.$Message.error(res.data.message)
          }
        })
      // }
    },
    async getRealOrderDetail () {
      await getRealOrderInfo().then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data
          this.list.forEach((item) => {
            let number = item.chargeNumber + item.gunNumber
            let info = data[number]
            if (info) {
              info.chargingDegree = (info.chargingDegree / 10000).toFixed(2)  // 已充电量
              info.chargedAmount = (info.chargedAmount / 10000).toFixed(2) // // 充电费用
              info.outputCurrent = (info.outputCurrent / 10).toFixed(1) // 充电电流
              info.outputVoltage = (info.outputVoltage / 10).toFixed(1) // 充电电压
              Object.keys(info).forEach((key) => {
                this.$set(item, key, info[key])
              })
            }
          })
        }
      })
    },
    getTimeDiff (start, end) {
      let day = 0
      let hour = 0
      let minute = 0
      let st = dayjs(start)
      let et = dayjs(end)
      let dayDiff = et.diff(st, 'day', true)
      if (dayDiff > 0) {
        day = Math.floor(dayDiff)
      }
      if (String(dayDiff).indexOf('.') > -1) {
        let dDigit =  Number('0.' + String(dayDiff).split('.')[1])
        let hNum = dDigit * 24
        if (hNum > 0) {
          hour = Math.floor(hNum)
        }
        if (String(hNum).indexOf('.') > -1) {
          let hDigit = Number('0.' + String(hNum).split('.')[1])
          let mNum = hDigit * 60
          if (mNum > 0) {
            minute = Math.floor(mNum)
          }
        }
      }
      let dStr = day > 0 ? day + '天' : ''
      let hStr = hour > 0 ? hour + '小时' : ''
      let mStr = minute > 0 ?  minute + '分钟' : ''
      return dStr + hStr + mStr
    },
    clearTimer () {
      if (this.listTimer) {
        clearInterval(this.listTimer)
        this.listTimer = null
      }
    }
  },
  watch: {
    showDetail (val) {
      if(!val) {
        this.detail = {}
        this.titleTInd = 0
        this.gridTInd = 0
        this.modalParams = {
          pageNo: 1,
          pageSize: 10
        }
        this.modalTotal = 0
      }
    },
    '$route': {
      handler (val) {
        this.routeType = val.path.split('/')[val.path.split('/').length - 1]
        this.getList()
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="less">
  @import "order-manage";
</style>
