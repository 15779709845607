<template>
  <div class="login-wrapper" :style="{height: height + 'px'}">
    <div class="login-box">
      <div class="logo">
        <img src="">
        <p class="text">满电行管理监控平台</p>
      </div>
      <div class="login-content">
        <div class="login-form">
          <Form ref="loginForm" :model="formData" :rules="formRules">
            <FormItem prop="OperatorID" label="用户名:">
              <Input type="password" class="hide-input"/>
              <Input v-model="formData.OperatorID" autocomplete="off">
                <Icon slot="prepend" type="md-person"></Icon>
              </Input>
            </FormItem>
            <FormItem prop="OperatorSecret" label="密码:">
              <Input v-model="formData.OperatorSecret" type="password" autocomplete="off">
                <Icon slot="prepend" type="ios-lock"></Icon>
              </Input>
            </FormItem>
          </Form>
          <Button type="info" class="login-btn" @click="toLogin" :disabled="loading" :loading="loading">登录</Button>
        </div>
      </div>
      <div class="footer">
        <p>Copyright © 2011-2022 <a href="http://www.cdhiway.com" target="_blank">www.cdhiway.com</a> 版权所有: 成都常道科技有限责任公司 公网备案
          <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备20001351号-3</a>
        </p>
        <p class="download">APP下载：
          <a href="http://www.mdxxny.com/ApKFile/mdx_yyb.apk">安卓下载</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { setStorage } from '@/libs/tools'
import { login} from '@/libs/apiSecond'
import { getEncryptRequest, getDecryptRequest } from '@/libs/requestCommon'
export default {
  name: "login",
  data () {
    return {
      height: 0,
      formData: {
        OperatorID: '',
        OperatorSecret: ''
      },
      formRules: {
        OperatorID: [{required: true, message: '请输入用户名'}],
        OperatorSecret: [{required: true, message: '请输入密码'}]
      },
      loading: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.height =  window.innerHeight
    })
  },
  methods: {
    toLogin () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
         this.loading = true
          getEncryptRequest({ // 加密
            data: this.formData,
            OperatorID: this.formData.OperatorID,
            success: (data) => {
              login(data).then((res) => { // 登录
                this.loading = false
                if (res.data.Ret === 0) {
                  let t = res.data.Data
                  getDecryptRequest({ // 解密token
                    str: t,
                    success: (dData) => {
                      let token = dData.AccessToken
                      setStorage('user', JSON.stringify({
                        OperatorID: this.formData.OperatorID
                      }))
                      setStorage('token', token)
                      let redirect = this.$route.query.redirect
                      let path = redirect ? redirect :  '/'
                      this.$router.push({
                        path: path
                      })
                    },
                    error: (dErr) => {
                      this.loading = false
                      this.$Message.error(dErr.Msg)
                    }
                  })
                } else {
                  this.loading = false
                  this.$Message.error(res.data.Msg)
                }
              })
            },
            error: (err) => {
              this.loading = false
              this.$Message.error(err.Msg)
            }
          })
        }
      })
    },
    resetForm () {
      this.$refs.loginForm.resetFields()
      this.formData = {
        OperatorID: '',
        OperatorSecret: ''
      }

    },
    toDownload () {
      let src = 'http://www.hiwaypower.com/ApKFile/mdx_yyb.apk'
      window.onload(src)
    }
  }
};
</script>

<style lang="less" scoped>
  @import "login";
</style>
