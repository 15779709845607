<template>
  <div class="statistics-wrapper">
    <search-form :fields="searchFields" :selectData="searchSelectData" @on-search="toSearch" @on-reset="toReset" @on-date-change="dateChange" @on-select-change="toChange" :okLoading="loading"></search-form>
    <div class="export">
      <Button :loading="exportLoading" :disabled="exportLoading" @click="toOpr" class="export-button" v-has-permi="['PRIV_RECHARGE_EXPORT']"><Icon type="md-download"/>导出全部</Button>
    </div>
    <div class="statistics-content-wrapper" :style="{height: tableHeight + 'px'}">
      <Table stripe :columns="columns" :data="list" :loading="loading" ref="table" :height="tableHeight"></Table>
      <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
    </div>
    <Loading :show="exportLoading" :styl="{background: 'rgba(255,255,255,.65)'}" text="导出中，请等待..."></Loading>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import { mapState } from 'vuex'
import { userRechargeQuery,companyQueryGet,flowDetails } from '@/libs/api'
import Loading from '@/components/Loading'
import { exportExcel } from '@/libs/tools'
export default {
  name: "recharge-list",
  components: {
    SearchForm,
    Loading
  },
  data () {
    return {
      exportLoading: false,
      searchFields: [
        {
          label: '开始日期',
          name: 'startTime',
          type: 'date'
        },
        {
          label: '结束日期',
          name: 'endTime',
          type: 'date'
        },
        {
          label: '名字',
          name: 'userName',
          type: 'input'
        },
        {
          label: '手机',
          name: 'userPhone',
          type: 'input'
        },
        {
          label: 'openID',
          name: 'userWxOpenId',
          type: 'input'
        },
        {
          label: '企业公司',
          name: 'companyId',
          type: 'select'
        },
        {
          label: '交易状态',
          name: 'rechargeState',
          type: 'select'
        },
        {
          label: '付款方式',
          name: 'paymentMethod',
          type: 'select',
          disabled: true
        }
      ],
      searchSelectData: {
        rechargeState: [
          {
            label: '支付成功',
            value: 'SUCCESS'
          },
          {
            label: '退款完成',
            value: 'SUCCESSREFUND'
          },
        ],
        paymentMethod: [
          {
            label: '微信',
            value: 'wxpay'
          },
          {
            label: '支付宝',
            value: 'zfbpay'
          },
          {
            label: '微信APP',
            value: 'apppay'
          },
          {
            label: '支付宝app',
            value: 'zfbapppay'
          },
          {
            label: '手动充值[系统回馈]',
            value: 'SDCZ'
          },
          {
            label: '系统赠送',
            value: 'XTZS'
          },
          {
            label: '预充值',
            value: 'YCZ'
          }
        ],
        companyId: []
      },
      searchParams: {
        companyId: '',
        paymentMethod: '',
        rechargeStat: '',
        startTime: '',
        endTime: '',
        userName: '',
        userPhone: '',
        userWxOpenId: ''
      },
      params: {
        pageSize: 20,
        pageIndex: 1
      },
      total: 0,
      loading: false,
      columns: [
        {
          key: 'index',
          title: '序号',
          width: 70,
          align: 'center',
          render: (h, params) => {
            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'rechargeMoney',
          title: '金额(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.rechargeMoney /100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'rechargeTime',
          title: '时间',
          align: 'center',
          width: 200
        },
        {
          key: 'rechargeRemarke',
          title: '备注',
          align: 'center',
          minWidth: 200
        },
        {
          key: 'state',
          title: '类型',
          width: 120,
          align: 'center',
          render: (h, params) => {
            let payment = {
              'SUCCESS': '支付成功',
              'REFUND': '转入退款',
              'NOTPAY': '未支付',
              'CLOSED': '已关闭',
              'REVOKED': '已撤销',
              'USERPAYING': '支付中',
              'PAYERROR': '支付失败',
              'APPLYREFUND': '退款中',
              'SUCCESSREFUND': '退款完成',
              'REFUNDCLOSED': '退款关闭',
              'REFUNDABNORMAL': '退款异常',
              'Consumption': '消费成功',
            }
            return (<span>{payment[params.row.rechargeState] ? payment[params.row.rechargeState] : '--'}</span>)
          }
        },
        {
          key: 'payType',
          title: '支付方式',
          width: 120,
          align: 'center',
          render: (h, params) => {
            let payment = {
              'wxpay': '微信',
              'zfbpay': '支付宝',
              'apppay': '微信APP',
              'zfbapppay': '支付宝APP',
              'SDCZ': '手动充值',
              'XTZS': '系统赠送',
              'YCZ': '预充值'
            }
            return (<span>{payment[params.row.paymentMethod] ? payment[params.row.paymentMethod] : '--'}</span>)
          }
        },
        {
          key: 'userName',
          title: '用户名',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let str = params.row.userName ? params.row.userName : '--'
            return (<span>{str}</span>)
          }
        },
        {
          key: 'companyId',
          title: '企业名',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let str = params.row.companyName ? params.row.companyName : '--'
            return (<span>{str}</span>)
          }
        },
        {
          key: 'userPhone',
          title: '手机号',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let str = params.row.userPhone ? params.row.userPhone : '--'
            return (<span>{str}</span>)
          }
        },
        {
          key: 'userOpenId',
          title: '用户OpenId',
          align: 'center',
          width: 270
        }
      ],
      list: [],
    }
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  mounted () {
    this.$nextTick(() => {
      this.getList()
      this.getCompany()
    })
  },
  methods: {
    toSearch (obj) {
      this.searchParams = obj
      this.params.pageIndex = 1
      this.getList()
    },
    toReset (obj) {
      this.searchParams = obj
      this.getList()
    },
    toChange(value){
      if(value == 'SUCCESSREFUND'){
        this.searchFields[7].disabled = true
      }else if(!value){
        this.searchFields[7].disabled = true
      }else{
        this.searchFields[7].disabled = false
      }
    },
    pageChange (pageNum) {
      this.params.pageIndex = pageNum
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    async getList () {
      this.loading = true
      await userRechargeQuery(Object.assign({}, this.searchParams, this.params)).then((res) => {
        if (res.data.code === 0) {
          this.loading = false
          this.list = res.data.data.dataSource
          this.total = res.data.data.totalCount
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    async getCompany(){
      let res = await companyQueryGet()
      if (res.data.code === 0) {
        let list = []
        res.data.data.forEach(item => {
          list.push({
            label: item.companyName,
            value: item.companyID
          })
        });
        this.searchSelectData.companyId = list
      }
    },
    dateChange(obj,name){
      if(name == 'startTime'){
        this.searchParams.startTime = obj
      }
    },
    toOpr(){
      if(!this.searchParams.startTime){
        this.$Message.warning('请先选择开始时间！')
      }else{
        this.$Modal.confirm({
          title: '导出',
          content: '确认导出?',
          onOk: async () => {
            this.exportLoading = true
            let data = Object.assign({},this.searchParams,this.params,{
              orderBy: 'startTime',
              sort: 'descending'
            })
            let res = await flowDetails(data)
            if (res.status === 200) {
              this.exportLoading = false
              exportExcel('用户流水明细', res.data)
            }else{
              this.$Message.error(res.data)
            }
          }
        })
      }
    }
  },
  watch: {
    
  }
};
</script>

<style scoped lang="less">
  @import "../statistics/statistics";
</style>
