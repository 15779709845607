<template>
  <div class="platform-wrapper">
    <search-form :fields="searchFields" :okLoading="loading" @on-search="toSearch" @on-reset="toReset" :fieldsVal="searchFieldsVal" :selectData="searchSelectData" @on-change="searchChange"></search-form>
    <div class="table-top">
      <Button href="javascript:;" @click="toChargeOpr('add')" v-has-permi="['PRIV_CHARGE_CREATE']"><Icon type="md-add"/> 新增</Button>
      <Button href="javascript:;" @click="toChargeOpr('batchSend')" v-has-permi="['PRIV_CHARGE_SEND']"><i class="iconfont icon-xiafapeizhi2"></i> 批量下发</Button>
<!--      <Button href="javascript:;" @click="toChargeOpr('batchCheckTime')" v-has-permi="['PRIV_CHARGE_CHECK']"><i class="iconfont icon-kaihuhedui"></i> 批量核对时间</Button>-->
    </div>
    <div class="platform-list" ref="table" :style="{height: tableHeight + 'px'}">
      <Table ref="tList" :columns="terminalCol" :data="list" :height="tableHeight" @on-selection-change="tableSelectChange" :loading="loading"></Table>
    </div>
    <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
    <Modal v-model="showOprModal" :title="oprType === 'add' ? '新增' : '编辑'"
      width="950"
      :mask-closable="false"
      class-name="modal-detail modal-form">
      <div slot="footer">
        <Button @click="showOprModal=false">取消</Button>
        <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :model="modalTerminalForm" ref="modalTerminalForm" :label-width="120" :rules="modalTerminalRuleValid" v-if="showOprModal">
        <FormItem label="所属电站" prop="siteCode" class="three">
          <Select v-model="modalTerminalForm.siteCode">
            <Option v-for="(opt, oInd) in siteList" :key="oInd" :value="opt.siteCode">{{opt.siteName}}</Option>
          </Select>
        </FormItem>
        <FormItem label="充电桩编号" prop="chargeNumber" class="three">
          <Input v-model="modalTerminalForm.chargeNumber" :disabled="this.oprType === 'mod'"/>
        </FormItem>
        <FormItem label="充电桩名称" prop="chargeName" class="three">
          <Input v-model="modalTerminalForm.chargeName" />
        </FormItem>
        <FormItem label="充电桩类型" prop="chargeType" class="three">
          <Select v-model="modalTerminalForm.chargeType">
            <Option v-for="(opt, oInd) in chargeTypeOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
          </Select>
        </FormItem>
        <FormItem label="监管平台充电桩类型" prop="equipmentType" class="three">
          <Select v-model="modalTerminalForm.equipmentType">
            <Option v-for="(opt, oInd) in equipmentTypeOpt" :key="oInd" :value="opt.value" :label="opt.label"></Option>
          </Select>
        </FormItem>
        <FormItem label="充电桩Ip端口" class="three" prop="chargeIpPort">
          <Input v-model="modalTerminalForm.chargeIpPort" />
        </FormItem>
        <FormItem label="充电桩协议类型" class="three" prop="chargeAgreementType">
          <Input v-model="modalTerminalForm.chargeAgreementType" />
        </FormItem>
        <FormItem label="今日充电量" class="three" prop="chargeElectricDay">
          <Input v-model="modalTerminalForm.chargeElectricDay" />
        </FormItem>
        <FormItem label="今日充电次数" class="three" prop="chargeCountDay">
          <Input type="number" v-model="modalTerminalForm.chargeCountDay" />
        </FormItem>
        <FormItem label="总充电电量" class="three" prop="chargeElectricAll">
          <Input v-model="modalTerminalForm.chargeElectricAll" />
        </FormItem>
        <FormItem label="车位号" class="three" prop="parkingNumber">
          <Input v-model="modalTerminalForm.parkingNumber" />
        </FormItem>
        <FormItem label="累计费用" class="three" prop="accumulatedExpenses">
          <Input v-model="modalTerminalForm.accumulatedExpenses">
            <span slot="append">元</span>
          </Input>
        </FormItem>
        <FormItem label="品牌类型" prop="chargeBrand" class="three">
          <Select v-model="modalTerminalForm.chargeBrand">
            <Option v-for="(opt, oInd) in chargeBrandOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
          </Select>
        </FormItem>
        <FormItem label="充电桩功率" prop="chargePower" class="three">
          <Select v-model="modalTerminalForm.chargePower">
            <template v-for="(opt, oInd) in chargePowerOpt" >
              <Option v-if="opt.filter === modalTerminalForm.chargeType" :key="oInd" :value="opt.value">{{opt.label}}</Option>
            </template>
          </Select>
        </FormItem>
        <FormItem label="辅助电源" prop="auxiliaryPower" class="three">
          <Select v-model="modalTerminalForm.auxiliaryPower">
              <Option v-for="(opt, oInd) in auxiliaryPowerOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
          </Select>
        </FormItem>
        <FormItem label="工作电压" prop="workingVoltage" class="three">
          <Select v-model="modalTerminalForm.workingVoltage">
            <Option v-for="(opt, oInd) in workingVoltageOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
          </Select>
        </FormItem>
        <FormItem label="开放状态" prop="openState" class="three">
          <Select v-model="modalTerminalForm.openState" :disabled="this.oprType === 'mod'">
            <Option v-for="(opt, oInd) in openStateOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
          </Select>
        </FormItem>
        <FormItem label="设备状态" prop="equipmentStatus" class="three">
          <Select v-model="modalTerminalForm.equipmentStatus">
            <Option v-for="(opt, oInd) in equipmentStsOpt" :key="oInd" :value="opt.value" :label="opt.label"></Option>
          </Select>
        </FormItem>
        <FormItem label="最后在线时间" prop="chargeLastTime" class="three">
          <DatePicker
            type="datetime"
            style="width: 200px"
            v-model="modalTerminalForm.chargeLastTime"
            @on-change="(fmtVal, val) => dateChange(fmtVal, val, 'chargeLastTime')"></DatePicker>
        </FormItem>
        <FormItem label="充电协议版本" prop="communicationProtocolShow" class="three">
          <Input v-model="modalTerminalForm.communicationProtocolShow" />
        </FormItem>
        <FormItem label="程序版本" prop="programVersionShow" class="three">
          <Input v-model="modalTerminalForm.programVersionShow" />
        </FormItem>
        <FormItem label="充电桩制造商" prop="chargeManufacturer" class="three">
          <Input v-model="modalTerminalForm.chargeManufacturer" />
        </FormItem>
        <FormItem label="充电桩枪口数量" prop="chargeElectricCount" class="three">
          <Input type="number" v-model="modalTerminalForm.chargeElectricCount" />
        </FormItem>
        <FormItem label="地锁编号" prop="floorLockNumber" class="three">
          <Input v-model="modalTerminalForm.floorLockNumber" />
        </FormItem>
        <FormItem label="设备生产商" prop="manufacturerID" class="three">
          <Input v-model="modalTerminalForm.manufacturerID" />
        </FormItem>
        <FormItem label="设备生产商全称" prop="manufacturerName" class="three">
          <Input v-model="modalTerminalForm.manufacturerName" />
        </FormItem>
        <FormItem label="设备型号" prop="equipmentModel" class="three">
          <Input v-model="modalTerminalForm.equipmentModel" />
        </FormItem>
        <FormItem label="设备生产日期" prop="productionDate" class="three">
          <DatePicker
            type="date"
            style="width: 180px"
            v-model="modalTerminalForm.productionDate"
            @on-change="(fmtVal, val) => dateChange(fmtVal, val, 'productionDate')"></DatePicker>
        </FormItem>
        <FormItem label="最大输出电流" prop="chargeMaxCurrent" class="three">
          <Input v-model="modalTerminalForm.chargeMaxCurrent" />
        </FormItem>
        <FormItem label="网络链接类型" prop="networkLinkType" class="three">
          <Select v-model="modalTerminalForm.networkLinkType">
            <Option v-for="(opt, oInd) in netLinkTypeOpt" :key="oInd" :value="opt.value" :label="opt.label"></Option>
          </Select>
        </FormItem>
        <FormItem label="Sim卡" prop="simCard" class="three">
          <Input v-model="modalTerminalForm.simCard" />
        </FormItem>
        <FormItem label="运营商" prop="chargeOperator" class="three">
          <Select v-model="modalTerminalForm.chargeOperator">
            <Option v-for="(opt, oInd) in chargeOperatorOpt" :key="oInd" :value="opt.value" :label="opt.label"></Option>
          </Select>
        </FormItem>
        <FormItem label="充电桩经度" prop="equipmentLng" class="three">
          <Input v-model="modalTerminalForm.equipmentLng" />
        </FormItem>
        <FormItem label="充电桩纬度" prop="equipmentLat" class="three">
          <Input v-model="modalTerminalForm.equipmentLat" />
        </FormItem>
      </Form>
    </Modal>
    <Modal title="下发" v-model="showSendModal" width="500" :mask-closable="false">
      <div slot="footer">
        <Button @click="showSendModal=false">取消</Button>
        <Button type="primary" @click="sendModalOk" :loading="sendLoading">确定</Button>
      </div>
      <Form inline :label-width="120" :model="sendParams" :rules="sendFormValid" ref="sendForm">
        <FormItem label="充电桩编号" style="width: 100%;">
          <span style="word-break: break-all;">{{sendParams.chargeNumber}}</span>
        </FormItem>
        <FormItem label="所属费率" style="width: 100%;" prop="rateId">
          <Select v-model="sendParams.rateId" :filterable="true">
            <Option v-for="(rate, rInd) in rateList" :key="rInd" :value="rate.rateId" :label="rate.rateName">
              <div class="select-rate-box">
                <p class="name">{{rate.rateName}}</p>
                <p class="content">
                <span v-for="(price, pInd) in rate.frontEndRateDetails.split(',').length / 2" :key="pInd">
                  {{(getSelectLabel(rateCategoryOpt, '0' + pInd))}}:
                  价格 {{(rate.frontEndRateDetails.split(',')[pInd * 2] / 100000).toFixed(4)}}元;服务费 {{(rate.frontEndRateDetails.split(',')[pInd * 2 + 1] / 100000).toFixed(4)}}元
                </span>
                </p>
              </div>
            </Option>
          </Select>
        </FormItem>
      </Form>
    </Modal>
    <Modal :title="oprType === 'electricadd' ? '新增电枪' : '修改电枪'" v-model="showEleModal" width="950" :mask-closable="false" class-name="modal-detail modal-form">
      <div slot="footer">
        <Button @click="showEleModal=false">取消</Button>
        <Button type="primary" @click="electricOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :model="ElectricFormData" ref="ElectricFormData" :label-width="120" :rules="ElectricRuleValid" v-if="showEleModal">
        <FormItem label="电枪编号" prop="electricCode" class="three">
          <Input v-model="ElectricFormData.electricCode" disabled/>
        </FormItem>
        <FormItem label="电桩Id" prop="chargeId" class="three">
          <Input v-model="ElectricFormData.chargeId" disabled/>
        </FormItem>
        <!-- <FormItem label="电枪状态" prop="electricState" class="three">
          <Select v-model="ElectricFormData.electricState" disabled>
            <Option v-for="(opt, oInd) in ElectricStateOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
          </Select>
        </FormItem>
        <FormItem label="电枪链接状态" prop="electricLinkStatus" class="three">
          <Select v-model="ElectricFormData.electricLinkStatus" disabled>
            <Option v-for="(opt, oInd) in ElectricLinkStatusOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
          </Select>
        </FormItem>
        <FormItem label="车链接状态" prop="carLinkStatus" class="three">
          <Select v-model="ElectricFormData.carLinkStatus" disabled>
            <Option v-for="(opt, oInd) in CarLinkStatusOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
          </Select>
        </FormItem> -->
        <FormItem label="站点编号" prop="siteCode" class="three">
          <Input v-model="ElectricFormData.siteCode" disabled/>
        </FormItem>
        <FormItem label="充电枪类型" prop="connectorType" class="three">
          <Select v-model="ElectricFormData.connectorType">
            <Option v-for="(opt, oInd) in ConnectorTypeOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
          </Select>
        </FormItem>
        <FormItem label="额定电压上限" prop="voltageUpperLimits" class="three">
          <Input v-model="ElectricFormData.voltageUpperLimits" type="number">
            <span slot="append">V</span>
          </Input>
        </FormItem>
        <FormItem label="额定电压下限" prop="voltageLowerLimits" class="three">
          <Input v-model="ElectricFormData.voltageLowerLimits" type="number">
            <span slot="append">V</span>
          </Input>
        </FormItem>
        <FormItem label="额定电流" prop="electricCurrent" class="three">
          <Input v-model="ElectricFormData.electricCurrent" type="number">
            <span slot="append">A</span>
          </Input>
        </FormItem>
        <FormItem label="额定功率" prop="power" class="three">
          <Input v-model="ElectricFormData.power" type="number">
            <span slot="append">kW</span>
          </Input>
        </FormItem>
        <FormItem label="国家标准" prop="nationalStandard" class="three">
          <Select v-model="ElectricFormData.nationalStandard">
            <Option v-for="(opt, oInd) in NationalStandardOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
          </Select>
        </FormItem>
        <FormItem label="充电枪名称" prop="connectorName" class="three">
          <Input v-model="ElectricFormData.connectorName"/>
        </FormItem>
        <FormItem label="停⻋场⻋位编号" prop="parkNo" class="three">
          <Input v-model="ElectricFormData.parkNo"/>
        </FormItem>
        <!-- <FormItem label="充电桩编号" prop="chargeNumber" class="three">
          <Input v-model="ElectricFormData.chargeNumber"/>
        </FormItem>
        <FormItem label="桩名称" prop="chargeName" class="three">
          <Input v-model="ElectricFormData.chargeName"/>
        </FormItem>
        <FormItem label="电桩功率" prop="chargePower" class="three">
          <Input v-model="ElectricFormData.chargePower">
            <span slot="append">kW</span></Input>
        </FormItem>
        <FormItem label="辅助电源" prop="auxiliaryPower" class="three">
          <Select v-model="ElectricFormData.auxiliaryPower">
              <Option v-for="(opt, oInd) in auxiliaryPowerOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
          </Select>
        </FormItem>
        <FormItem label="工作电压" prop="workingVoltage" class="three">
          <Input v-model="ElectricFormData.workingVoltage">
            <span slot="append">kW</span></Input>
        </FormItem>
        <FormItem label="桩类型" prop="chargeType" class="three">
          <Input v-model="ElectricFormData.chargeType"/>
        </FormItem>
        <FormItem label="充电桩品牌" prop="chargeBrand" class="three">
          <Input v-model="ElectricFormData.chargeBrand"/>
        </FormItem>
        <FormItem label="站费率" prop="frontEndRateDetails" class="three">
          <Input v-model="ElectricFormData.frontEndRateDetails"/>
        </FormItem>
        <FormItem label="费率组" prop="frontEndPeriodRateNo" class="three">
          <Input v-model="ElectricFormData.frontEndPeriodRateNo"/>
        </FormItem> -->
      </Form>
    </Modal>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import TableExpand from '@/components/TableExpand'
import { mapState } from 'vuex'
import { chargeTypeOpt, rateCategoryOpt, openStateOpt, chargePowerOpt, auxiliaryPowerOpt, workingVoltageOpt, chargeBrandOpt, equipmentTypeOpt, equipmentStsOpt, netLinkTypeOpt, chargeOperatorOpt, ElectricStateOpt, ElectricLinkStatusOpt, CarLinkStatusOpt, ConnectorTypeOpt, NationalStandardOpt } from '@/libs/dict'
import { querySite, chargeRatesQuery, chargePointAdd, chargePointUpdate, queryChargePoint, chargeRateSetting, chargeRateSettingWM, chargePointUpdateState, chargePointUpdateRate, getRemoteReboot, getCheckTime, getHeartbeatFront, querySiteAll, addElectricGun, updateElectricGun } from '@/libs/api'
import { getSelectLabel, getStorage } from '@/libs/tools'
import config from '@/config'
import axios from '@/libs/api.request'
let formData = {
  siteCode: '',
  chargeNumber: '',
  chargeName: '',
  chargeType: '00',
  chargeIpPort: '',
  chargeAgreementType: null,
  chargeElectricDay: '',
  chargeCountDay: null,
  chargeElectricAll: '',
  parkingNumber: '',
  accumulatedExpenses: '',
  openState: null,
  equipmentStatus: null,
  chargeLastTime: '',
  chargeBrand: 0,
  chargeManufacturer: '',
  chargeIp: '',
  communicationProtocol: '',
  communicationProtocolShow: '',
  programVersion: '',
  programVersionShow: '',
  chargeElectricCount: 2,
  chargeMaxVoltage: '',
  chargeMaxCurrent: '',
  rateId: '',
  networkLinkType: '',
  simCard: '',
  chargeOperator: '',
  chargePower: null,
  auxiliaryPower: '12/24',
  workingVoltage: '750',
  floorLockNumber: '',
  equipmentType: 1,
  manufacturerID: '',
  manufacturerName: '',
  equipmentModel: '',
  productionDate: '',
  equipmentLng: '',
  equipmentLat: '',
}
let EleformData = { //新增电枪
  electricCode: '',
  chargeId: 0,
  electricState: 0,
  electricLinkStatus: 1,
  carLinkStatus: 1,
  siteCode: '',
  connectorType: null,
  voltageUpperLimits: 0,
  voltageLowerLimits: 0,
  electricCurrent: 0,
  power:0,
  nationalStandard: null,
  connectorName: '',
  parkNo: '',
  // chargeNumber: '',
  // chargeName: '',
  // chargePower: '',
  // auxiliaryPower: null,
  // workingVoltage: null,
  // chargeType: '',
  // chargeBrand: 0,
  // frontEndRateDetails: '',
  // frontEndPeriodRateNo: ''
}
/* eslint-disable */
export default {
  name: "platform-terminal",
  components: {
    SearchForm,
    TableExpand
  },
  data () {
    return {
      chargeTypeOpt,
      rateCategoryOpt,
      openStateOpt,
      chargePowerOpt,
      auxiliaryPowerOpt,
      workingVoltageOpt,
      chargeBrandOpt,
      equipmentTypeOpt,
      equipmentStsOpt,
      netLinkTypeOpt,
      chargeOperatorOpt,
      ElectricStateOpt,
      ElectricLinkStatusOpt,
      CarLinkStatusOpt,
      ConnectorTypeOpt,
      NationalStandardOpt,
      loading: false,
      searchFields: [
        {
          label: '归属电站',
          name: 'siteCode',
          type: 'select',
          useValue: 'value',
          cls: 'half',
          filterable: true,
          multiple: true
        },
        {
          label: '电桩名称',
          name: 'chargeName',
          type: 'input'
        },
        {
          label: '电桩编码',
          name: 'chargeNumber',
          type: 'input'
        }
      ],
      searchForm: { },
      searchFieldsVal: {
        siteCode: this.$route.query.siteCode ? this.$route.query.siteCode : ''
      },
      searchSelectData: {
        siteCode: []
      },
      oprBtnArr: [
        {
          name: '编辑',
          icon: 'icon-bianji',
          type: 'mod'
        },
        {
          name: '启用',
          icon: 'icon-qiyong',
          type: 'open'
        },
        {
          name: '查看电枪',
          icon: 'icon-chongdianzhuang1',
          type: 'view'
        },
        {
          name: '详情',
          icon: 'icon-chakanjiexi',
          type: 'detail'
        }
      ],
      oprType: 'add',
      list: [],
      params: {
        pageSize: 10,
        pageIndex: 1
      },
      total: 0,
      showOprModal: false,
      showEleModal :false,
      modData: {},
      terminalCol: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          key: 'index',
          title: '序号',
          align: 'center',
          width: 80,
          render: (h, params) => {
            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'chargeName',
          title: '电桩名称',
          align: 'center',
          width: 180,
        },
        {
          key: 'chargeNumber',
          title: '电桩编号',
          align: 'center',
          width: 180
        },
        {
          key: 'siteName',
          title: '归属电站',
          align: 'center',
          minWidth: 120
        },
        {
          key: 'openState',
          title: '开放状态',
          align: 'center',
          width: 150,
          render: (h, params) => {
            return (<span class={(params.row.openState === 2 ? 'open' : 'close') + ' rate-state'}>{getSelectLabel(openStateOpt, params.row.openState)}</span>)
          }
        },
        /*{
          key: 'workStatus',
          title: '工作状态',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            let sts = {
              0: '离线',
              1: '在线'
            }
            return (
              <span style={{color: params.row.workStatus === 0 ? 'red' : 'green'}}>{sts[params.row.workStatus] ? sts[params.row.workStatus] : ''}</span>
            )
          }
        },*/
        {
          key: 'chargeBrand',
          title: '品牌类型',
          align: 'center',
          width: 100,
          render: (h, params) => {
            let str = getSelectLabel(chargeBrandOpt, params.row.chargeBrand)
            return (<span>{str}</span>)
          }
        },
        {
          key: 'rateName',
          title: '所属费率',
          align: 'center',
          width: 120
        },
        {
          key: 'chargeElectricCount',
          title: '充电桩枪口数量',
          align: 'center',
          width: 140,
          render: (h, params) => {
            return (<span>{Number(params.row.chargeElectricCount)}</span>)
          }
        },
        {
          type: 'expand',
          width: 20,
          render: (h, params) => {
            return h(TableExpand, {
              props: {
                row: params.row
              },
              on:{
                Update:(row)=>{
                  this.toChargeOpr('ElectricaddUpdate',row)
                }
              }
            })
          }
        },
        {
          key: 'sendState',
          title: '下发状态',
          align: 'center',
          width: 200,
          render: (h, params) => {
            let str = this.sendStateObj[params.row.chargeNumber]
            let num = str ? str.slice(0,1) : ''
            return (<span style={{color: num === '0' ? 'red' : 'green'}}>{str ? str.split(':')[1] : '--'}</span>)
          }
        },
        {
          key: 'chargeType',
          title: '充电桩类型',
          align: 'center',
          width: 150,
          render: (h, params) => {
            return (
              <span>{getSelectLabel(chargeTypeOpt, params.row.chargeType)}</span>
            )
          }
        },
        {
          key: 'communicationProtocolShow',
          title: '充电协议版本',
          align: 'center',
          width: 150
        },
        {
          key: 'chargeManufacturer',
          title: '充电桩制造商',
          align: 'center',
          width: 150
        },
        {
          key: 'opr',
          title: '操作',
          width: 250,
          render: (h, params) => {
            let obj = {
              1: ['启用', 'icon-qiyong', 'open'],
              2: ['停用', 'icon-tingyong_line', 'close'],
              3: ['启用', 'icon-qiyong', 'open']
            }
            return (
              <span class="table-opr-btn">
                <a href="javascript:;" class="mod" onClick={this.toChargeOpr.bind(this, 'mod', params.row)} v-has-permi={['PRIV_CHARGE_UPDATE']}>
                  <i class="iconfont icon-bianji"></i> 编辑
                </a>
                <a href="javascript:;" class={obj[params.row.openState][2]} onClick={this.toChargeOpr.bind(this, obj[params.row.openState][2], params.row)} v-has-permi={params.row.openState === 2 ? ['PRIV_CHARGE_CLOSE'] : ['PRIV_CHARGE_OPEN']}>
                  <i class={ 'iconfont ' + obj[params.row.openState][1]}></i> {obj[params.row.openState][0]}
                </a>
                <a href="javascript:;" class="open" onClick={this.toChargeOpr.bind(this, 'send', params.row)} v-has-permi={['PRIV_CHARGE_SEND']}>
                  <i class="iconfont icon-xiafapeizhi2"></i> 下发
                </a>
                {params.row.chargeBrand === 0 ?
                  <span>
                    <a href="javascript:;" class="open" onClick={this.toChargeOpr.bind(this, 'restart', params.row)} v-has-permi={['PRIV_CHARGE_RESTART']}>
                    <i class="iconfont icon-chongqi"></i> 远程重启
                  </a>
                  <a href="javascript:;" class="open"
                     onClick={this.toChargeOpr.bind(this, 'checkTime', params.row)} v-has-permi={['PRIV_CHARGE_CHECK']}>
                    <i class="iconfont icon-kaihuhedui"></i> 核对时间
                  </a>
                  <a href="javascript:;" class="open"
                     onClick={this.toChargeOpr.bind(this, 'electricadd', params.row)} v-has-permi={['PRIV_CHARGE_CREATE']}>
                    <i class="iconfont icon-kaihuhedui"></i> 新增电枪
                  </a>
                  </span>
                  : ''
                }
              </span>
            )
          }
        }
      ],
      modalTerminalForm: Object.assign({}, formData),
      ElectricFormData: Object.assign({}, EleformData),
      modalTerminalRuleValid: {
        siteCode: [{required: true, message: '请选择所属电站'}],
        chargeNumber: [{required: true, message: '请输入充电桩编号'}],
        chargeName: [{required: true, message: '请输入充电桩名称'}],
        chargeType: [{required: true, message: '请选择充电桩类型'}],
        chargePower: [{required: true, message: '请选择充电功率'}],
        auxiliaryPower: [{required: true, message: '请选择辅助电源'}],
        workingVoltage: [{required: true, message: '请选择工作电压'}],
        openState: [{required: true, message: '请选择开放类型'}],
        chargeBrand: [{required: true, message: '请选择品牌类型'}]
      },
      ElectricRuleValid: {
        electricCode: [{required: true, message: '请输入电枪编号'}],
        electricLinkStatus: [{required: true, message: '请选择电枪链接状态'}],
        electricState: [{required: true, message: '请选择电枪状态'}],
        carLinkStatus: [{required: true, message: '请输入车链接状态'}],
        siteCode: [{required: true, message: '请输入站点编号'}],
        chargeNumber: [{required: true, message: '请输入充电桩编号'}],
        chargeName: [{required: true, message: '请输入桩名称'}],
      },
      rateList: [],
      siteList: [],
      showSendModal: false,
      sendParams: {
        rateId: '', // 费率id
        chargeNumber: '', // 充电桩编号
        chargeId: '', // 充电桩id
        chargeBrand: ''
      },
      sendFormValid: {
        rateId: [{required: true, message: '请选择所属费率'}]
      },
      sendStateObj: {},
      sendLoading: false,
      chargeTimer: null,
      selectArr: [], // 选中数据
      btnLoading: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.chargeTimer = setInterval(() => {
        this.getChargeWorkStatus()
      }, 10000)
      this.searchFields.forEach((item) => {
        this.$set(this.searchForm, item.name, this.searchFieldsVal[item.name] ? this.searchFieldsVal[item.name] : '')
      })
      this.getSiteData()
      setTimeout(() => {
        this.getList()
      }, 0)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.chargeTimer) // 清除定时器
        this.chargeTimer = null
      })
    })
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  methods: {
    getSelectLabel,
    statusText (val) {
      let obj = {
        1: {
          text: '充电中',
          color: '#4d8cfd'
        }
      }
      return obj[val]
    },
    async getList () {
      this.list = []
      this.loading = true
      let params = Object.assign({}, this.searchForm, this.params)
      await queryChargePoint(params).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          this.list = res.data.data.dataSource
          this.total = res.data.data.totalCount
         this.getChargeWorkStatus()
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    pageChange (page) {
      this.params.pageIndex = page
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    pageModalChange (page) {
      this.modalParams.pageIndex = page
    },
    pageSizeModalChange (pageSize) {
      this.modalParams.pageSize = pageSize
    },
    toSearch (obj) {
      this.searchForm = obj
      this.params.pageIndex = 1
      setTimeout(() => {
        this.getList()
      }, 0)
    },
    toReset (obj) {
      this.searchForm = obj
      setTimeout(() => {
        this.getList()
      }, 0)
    },
    toChargeOpr (type, row) { // 操作
      this.oprType = type
      if (row) this.modData = row
      if (type === 'send') { // 下发
        this.showSendModal = true
        this.sendParams.chargeNumber = row.chargeNumber
        this.sendParams.chargeId = row.chargeId
      } else if (type === 'batchSend') { // 批量下发
        if (!this.sendParams.chargeId) {
          return this.$Message.error('请至少选择一条数据')
        }
        if (this.sendParams.chargeBrand.indexOf('0') > -1 && this.sendParams.chargeBrand.indexOf('1') > -1) {
          return this.$Message.error('请选择同一品牌的电桩!')
        }
        this.showSendModal = true
      } else if (['mod', 'add'].includes(type)) { //新增/修改
        this.showOprModal = true
        if (row) this.modData = row
      } else if (['open', 'close'].includes(type)) { //启用/禁用
        let name = '启用'
        let value = 2
        if (row.openState === 2) {
          name = '停用'
          value = 3
        }
        this.$Modal.confirm({
          title: name,
          content: `是否${name}该充电桩?`,
          onOk: async () => {
            await chargePointUpdateState({
              chargeId: row.chargeId,
              openState: value
            }).then((res) => {
              if (res.data.code === 0) {
                this.$Message.success('操作成功')
                this.getList()
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      } else if (['restart', 'checkTime'].includes(type)) { // 远程重启/核对时间
        let urls = getStorage('urls') ? JSON.parse( getStorage('urls')) : []
        let url = (process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro) + '/api/Socket'
        if (urls.length) {
          urls.forEach((it) => {
            if (it.powerStation === this.modData.siteCode && it.socketChargeBrand === this.modData.chargeBrand) {
              url = it.siteBaseUrl
              return false
            }
          })
        }
        let obj = {
          restart: {
            url: url + '/SocketRemoteReboot',
            title: '远程重启',
            params: {
              chargeNumber: row.chargeNumber,
              executionMode: '1'
            }
          },
          checkTime: {
            url: url + '/SocketCheckTime',
            title: '核对时间',
            params: {
              chargeNumber: row.chargeNumber
            }
          }
        }
        this.$Modal.confirm({
          title: obj[type].title,
          content: `是否要执行${obj[type].title}命令?`,
          onOk: () => {
            axios.request({
              url: obj[type].url,
              params: obj[type].params,
              timeout: 60000,
              method: 'get'
            }).then((res) => {
              if (res.data.code === 0) {
                this.$Message.success('操作成功')
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      } else if (type === 'batchCheckTime') { // 批量核对时间
        if (!this.sendParams.chargeId) {
          return this.$Message.error('请至少选择一条数据')
        }
        this.$Modal.confirm({
          title: '核对时间',
          content: '是否要执行核对时间命令?',
          onOk: async () => {
            await getCheckTime({
              chargeNumber: this.sendParams.chargeNumber
            }).then((res) => {
              if (res.data.code === 0) {
                this.$refs.tList.selectAll(false)
                let data = res.data.data
                let strArr = []
                if (Object.keys(data).length) {
                  Object.keys(data).forEach((charge) => {
                    let val = data[charge].slice(1)
                    strArr.push(charge + val)
                  })
                  this.$Message.success({
                    content: `<p style="width: 400px;word-break: break-all;text-align: center;line-height: 1.5;">${strArr.join(';')}</p>`,
                    duration: 6
                  })
                }
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      }else if(type === 'electricadd'){//新增电枪
        this.showEleModal = true
        this.ElectricFormData.chargeId = row.chargeId
      }else if(type === 'ElectricaddUpdate'){//修改电枪
        this.ElectricFormData = row
        this.showEleModal = true
      }
    },
    resetModalForm () { // 重置新增表单
      this.$refs.modalTerminalForm.resetFields()
      this.modalTerminalForm = Object.assign({}, formData)
    },
    resetEleModalForm () { // 重置电枪新增表单
      this.$refs.ElectricFormData.resetFields()
      this.ElectricFormData = Object.assign({}, EleformData)
    },
    sendModalOk () { // 下发费率
      this.$refs.sendForm.validate((valid) => {
        if (valid) {
          this.sendLoading = true
          if (this.oprType === 'send') { // 单个下发
            let urls = getStorage('urls') ? JSON.parse( getStorage('urls')) : []
            let extraUrl = this.modData.chargeBrand === 0 ? '/api/Socket' : '/api/SocketWM'
            let url = (process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro) + extraUrl
            if (urls.length) {
              urls.forEach((it) => {
                if (it.powerStation === this.modData.siteCode && it.socketChargeBrand === this.modData.chargeBrand) {
                  url = it.siteBaseUrl
                  return false
                }
              })
            }
           /* this.chargeUpdateRate({
              chargeId: this.sendParams.chargeId,
              chargeNumber: this.sendParams.chargeNumber
            }, this.modData.chargeBrand)*/
            this.substationChargeUpdateRate({
              chargeId: this.sendParams.chargeId,
              chargeNumber: this.sendParams.chargeNumber
            }, this.modData.chargeBrand, url)
          } else if (this.oprType === 'batchSend'){ // 批量下发
            /*let ykcObj = {
              chargeId: [],
              chargeNumber: []
            }
            let wmObj = {
              chargeId: [],
              chargeNumber: []
            }
            this.selectArr.forEach((item) => {
              if (item.chargeBrand === 0) {
                ykcObj.chargeId.push(item.chargeId)
                ykcObj.chargeNumber.push(item.chargeNumber)
              } else if (item.chargeBrand === 1){
                wmObj.chargeId.push(item.chargeId)
                wmObj.chargeNumber.push(item.chargeNumber)
              }
            })
            if (ykcObj.chargeId.length) { // 云快充
              this.chargeUpdateRate({
                chargeId: ykcObj.chargeId.join(','),
                chargeNumber: ykcObj.chargeNumber.join(',')
              }, 0)
            }
            if (wmObj.chargeId.length) { // 万马
              this.chargeUpdateRate({
                chargeId: wmObj.chargeId.join(','),
                chargeNumber: wmObj.chargeNumber.join(',')
              }, 1)
            }*/
            this.substationChargeUpdateRateBatch()
          }
        }
      })
    },
    async chargeUpdateRate (params, type) { // 请求费率接口
      await chargePointUpdateRate({
        chargeIds: params.chargeId,
        rateId: this.sendParams.rateId
      }).then((res1) => {
        if (res1.data.code === 0) {
          let obj = {
            0: chargeRateSetting, // 云快充
            1: chargeRateSettingWM, // 万马
          }
          obj[type]({
            chargeNumber: params.chargeNumber,
            rateId: this.sendParams.rateId
          }).then((res) => {
            if (res.data.code === 0) {
              this.$refs.tList.selectAll(false)
              this.sendStateObj = res.data.data
              let strArr = []
              Object.keys(this.sendStateObj).forEach((key) => {
                let str = key + ':' + this.sendStateObj[key].split(':')[1]
                strArr.push(`<p style="word-break: break-all;text-align: center;line-height: 1.5;">${str}</p>`)
              })
              this.$Message.info({
                content: `<div style="width: 400px;">${strArr.join('')}</div>`,
                duration: 6
              })
              // todo 下发费率时修改列表费率
              this.showSendModal = false
            } else {
              this.$Message.error(res.data.message)
            }
          })
        } else {
          this.$Message.error(res1.data.message)
        }
      })
    },
    async substationChargeUpdateRate (params, type, url) { // 分站请求费率接口
      await chargePointUpdateRate({
        chargeIds: params.chargeId,
        rateId: this.sendParams.rateId
      }).then((res1) => {
        if (res1.data.code === 0) {
          let urlStr = {
            0: '/SocketBillingSettings',
            1: '/SocketBillingSettingsWM'
          }
          axios.request({
            url: url + urlStr[type],
            data: {
              chargeNumber: params.chargeNumber,
              rateId: this.sendParams.rateId
            },
            noToast: true,
            timeout: 60000
          }).then((res) => {
            this.sendLoading = false
            if (res.data.code === 0) {
              this.$refs.tList.selectAll(false)
              this.sendStateObj = res.data.data
              let strArr = []
              Object.keys(this.sendStateObj).forEach((key) => {
                let str = key + ':' + this.sendStateObj[key].split(':')[1]
                strArr.push(`<p style="word-break: break-all;text-align: center;line-height: 1.5;">${str}</p>`)
              })
              this.$Message.info({
                content: `<div style="width: 400px;">${strArr.join('')}</div>`,
                duration: 3
              })
              // todo 下发费率时修改列表费率
              this.showSendModal = false
            } else {
              this.$Message.error(res.data.message)
            }
          })
        } else {
          this.$Message.error(res1.data.message)
        }
      })
    },
    substationChargeUpdateRateBatch () { // 分站批量下发
      let urls = getStorage('urls') ? JSON.parse( getStorage('urls')) : []
      let urlArr = []
      let urlObjArr = []
      if (urls.length) {
        this.selectArr.forEach((se) => {
          urls.forEach((url) => {
            if (url.powerStation === se.siteCode) {
              url.chargeBrand = se.chargeBrand
              if (!urlArr.length) {
                urlArr.push(url.siteBaseUrl)
                this.$set(url, 'chargeId', [se.chargeId])
                this.$set(url, 'chargeNumber', [se.chargeNumber])
                this.$set(url, 'chargeBrand', [se.chargeBrand])
                urlObjArr.push(url)
              } else {
                let ind = urlArr.indexOf(url.siteBaseUrl)
                if (ind > -1) {
                  let current = urlObjArr[ind].chargeId
                  if (current.indexOf(se.chargeId) === -1) {
                    current.push(se.chargeId)
                    urlObjArr[ind].chargeNumber.push(se.chargeNumber)
                  }
                } else {
                  urlArr.push(url.siteBaseUrl)
                  this.$set(url, 'chargeId', [se.chargeId])
                  this.$set(url, 'chargeNumber', [se.chargeNumber])
                  urlObjArr.push(url)
                }
              }
            }
          })
        })
      }
      if (urlObjArr.length) {
        urlObjArr.forEach((charge) => {
          let params = Object.assign({}, charge)
          params.chargeId = charge.chargeId.join(',')
          params.chargeNumber = charge.chargeNumber.join(',')
          let extraUrl = params.chargeBrand === 0 ? '/api/Socket' : '/api/SocketWM'
          let defaultUrl = (process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro) + extraUrl
          this.substationChargeUpdateRate(params, charge.chargeBrand, charge.siteBaseUrl ? charge.siteBaseUrl : defaultUrl)
        })
      } else {
        let ykcObj = {
          chargeId: [],
          chargeNumber: []
        }
        let wmObj = {
          chargeId: [],
          chargeNumber: []
        }
        this.selectArr.forEach((item) => {
          if (item.chargeBrand === 0) {
            ykcObj.chargeId.push(item.chargeId)
            ykcObj.chargeNumber.push(item.chargeNumber)
          } else if (item.chargeBrand === 1){
            wmObj.chargeId.push(item.chargeId)
            wmObj.chargeNumber.push(item.chargeNumber)
          }
        })
        if (ykcObj.chargeId.length) { // 云快充
          this.chargeUpdateRate({
            chargeId: ykcObj.chargeId.join(','),
            chargeNumber: ykcObj.chargeNumber.join(',')
          }, 0)
        }
        if (wmObj.chargeId.length) { // 万马
          this.chargeUpdateRate({
            chargeId: wmObj.chargeId.join(','),
            chargeNumber: wmObj.chargeNumber.join(',')
          }, 1)
        }
      }
    },
    modalOk () {
      let ref = this.$refs.modalTerminalForm
      ref.validate(async (valid) => {
        if (valid) {
          let formData = Object.assign({}, this.modalTerminalForm)
          if (this.oprType === 'mod') {
            formData.chargeId = this.modData.chargeId
          }
          let urlObj = {
            add: chargePointAdd,
            mod: chargePointUpdate
          }
          this.btnLoading = true
          await urlObj[this.oprType](formData).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.oprSuccess()
            } else {
              this.$Message.error(res.data.message)
            }
          }).catch(() => {
            this.btnLoading = false
          })
        }
      })
    },
    async electricOk(){
      let ref = this.$refs.ElectricFormData
      ref.validate(async (valid) => {
        if (valid) {
          let formData = Object.assign({}, this.ElectricFormData)
          let urlObj = {
            electricadd: addElectricGun,
            ElectricaddUpdate: updateElectricGun
          }
          let res = await urlObj[this.oprType](formData)
          if (res.data.code === 0) {
            this.btnLoading = false
            this.EleSuccess()
          } else {
            this.btnLoading = false
            this.$Message.error(res.data.message)
          }
        }
      })
    },
    oprSuccess () {
      this.$Message.success('操作成功')
      this.getList()
      this.showOprModal = false
    },
    EleSuccess(){
      this.$Message.success('操作成功')
      this.getList()
      this.showEleModal = false
    },
    initForm () {
      if (this.oprType === 'mod') {
        Object.keys(this.modalTerminalForm).forEach((key) => {
          this.modalTerminalForm[key] = this.modData[key]
        })
      }
    },
    async getRateList () { // 获取费率组列表
      await chargeRatesQuery({
        rateIsValid: 2
      }).then((res) => {
        if (res.data.code === 0) {
          this.rateList = res.data.data
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    async getSiteList () { // 获取电站列表
      await querySite({
        pageSize: 10000,
        pageIndex: 1
      }).then((res) => {
        if (res.data.code === 0) {
          // this.siteList = res.data.data.SiteManagement.data
          let arr = []
          let data = res.data.data.dataSource
          if (data.length) {
            data.forEach((item) => {
              // if (item.siteState === 1) { // 经营中
                arr.push(item)
              // }
            })
          }
          this.siteList = arr
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    tableSelectChange (selection) { // table选中项发生变化时就会触发
      let idArr = []
      let numberArr = []
      let brandArr = []
      if (selection.length) {
        selection.forEach((item) => {
          idArr.push(item.chargeId)
          numberArr.push(item.chargeNumber)
          brandArr.push(item.chargeBrand)
        })
      }
      this.sendParams.chargeId = idArr.join(',')
      this.sendParams.chargeNumber = numberArr.join(',')
      this.sendParams.chargeBrand = brandArr.join(',')
      this.selectArr = selection
    },
    async getChargeWorkStatus () { // 获取电桩工作状态
      await getHeartbeatFront({}).then((res) => {
        if (res.data.code === 0) {
          let onlineNumberArr = []
          Object.keys(res.data.data).forEach((key) => {
            let k = key
            onlineNumberArr.push(k)
          })
          if (this.list.length) {
            this.list.forEach((item) => {
              let number = item.chargeBrand === 1 ? item.chargeNumber : item.chargeNumber + '01'
              this.$set(item, 'workStatus', onlineNumberArr.indexOf(number) > -1 ? 1 : 0)
            })
          }
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    async getSiteData () {
      await querySiteAll({}).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data
          let arr = []
          if (data.length) {
            data.forEach((item) => {
              if (item.siteState !== 1) {
                arr.push({
                  label: item.siteName,
                  value: item.siteCode
                })
              }
            })
          }
          this.searchSelectData.siteCode = arr
        }
      })
    },
    searchChange (val) {
      this.searchForm = val
    },
    dateChange (fmtVal, val, key) {
      this.modalTerminalForm[key] = fmtVal
    }
  },
  watch: {
    showOprModal (val) {
      if (!val) {
        this.oprType = 'add'
        this.modData = {}
        this.resetModalForm()
      } else {
        this.initForm()
        this.getSiteList()
      }
    },
    showEleModal(val){
      if (!val) {
        this.resetEleModalForm()
      } else {
        this.getSiteList()
      }
    },
    showSendModal (val) {
      if (!val) {
        this.$refs.sendForm.resetFields()
        this.sendLoading = false
        this.oprType = 'add'
        this.modData = {}
        this.selectArr = []
        this.sendParams = {
          rateId: '',
          chargeNumber: '',
          chargeId: '',
          chargeBrand: ''
        }
      } else {
        this.getRateList()
      }
    },
    searchForm (val) {
      val.siteCode && (val.siteCode = val.siteCode.join(','))
    }
  }
};
</script>

<style scoped lang="less">
@import "state-manage";
.select-rate-box{
  display: flex;
  flex-wrap: wrap;
  padding: @base;
  border-bottom: 1px solid #f1f1f1;
  align-items: center;
  .name{
    padding-right: @base * 2;
    min-width: 200px;
    max-width: 300px;
    word-break: break-all;
    white-space: normal;
    margin-bottom: @base / 2;
  }
  .content{
    span{
      display: block;
      padding-bottom: @base / 2;
      font-size: 12px;
    }
  }
}
</style>
