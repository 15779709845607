import { render, staticRenderFns } from "./login-second.vue?vue&type=template&id=73360124&scoped=true&"
import script from "./login-second.vue?vue&type=script&lang=js&"
export * from "./login-second.vue?vue&type=script&lang=js&"
import style0 from "./login-second.vue?vue&type=style&index=0&id=73360124&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73360124",
  null
  
)

export default component.exports