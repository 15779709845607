<template>
  <div>
    <Form ref="formSearch" :model="formSearch" :label-width="110" class="search-form" :class="cls">
      <FormItem v-for="(field, fInd) in collapse ? fields.slice(0, minSize) : fields"
                :key="fInd"
                :label="field.label + ':'"
                :style="field.styl"
                :prop="field.name"
                :class="field.cls"
                v-show="field.show === '1' || !field.show">
        <Input v-if="field.type === 'input'"
               v-model="formSearch[field.name]"
               :placeholder="field.placeholder ? field.placeholder : '请输入' + field.label"/>
        <template v-if="field.type === 'multiple'">
          <template v-for="(child, cInd) in field.child">
            <Select v-if="child.type === 'select'"
                    :key="cInd + child.type"
                    :clearable="field.clearable || true"
                    v-model="formSearch[child.name]"
                    @on-change="selectChange($event, child)">
              <Option v-for="(opt, oInd) in getCurrentSData(child)" :value="child.useValue === 'value' || !child.useValue ? opt.value : opt.label" :key="oInd">{{opt.label}}</Option>
            </Select>
            <span v-if="cInd !== 0" :key="cInd + 'split'"> - </span>
            <DatePicker v-if="['datetime', 'date', 'month', 'year'].includes(child.type)"
                        :key="cInd + 'content'"
                        :type="child.type"
                        :format="child.format"
                        :editable="!field.editable ? field.editable : true"
                        :clearable="!field.clearable ? field.clearable : true"
                        :name="child.name"
                        v-model="formSearch[child.name]"
                        :placeholder="child.placeholder" @on-change="timeChange($event, child.name)">
            </DatePicker>
            <label class="unit" :class="child.cls" :key="cInd" v-if="child.type === 'text'">{{child.value}}</label>
          </template>
        </template>
        <Select v-if="field.type === 'select'"
                v-model="formSearch[field.name]"
                :multiple="field.multiple"
                :clearable="field.clearable || true"
                :filterable="field.filterable"
                :disabled="field.disabled || false"
                :max-tag-count="field.tagCount"
                @on-change="selectChange($event, field)">
          <Option v-for="(opt, oInd) in getCurrentSData(field)" :value="field.useValue === 'value'  || !field.value? opt.value : opt.label" :key="oInd">{{opt.label}}</Option>
        </Select>
        <DatePicker v-if="['datetime', 'date', 'month', 'year'].includes(field.type)"
                    :type="field.type"
                    :name="field.name"
                    :options="field.options"
                    :editable="!field.editable ? field.editable : true"
                    :clearable="!field.clearable ? field.clearable : true"
                    :format="field.format"
                    v-model="formSearch[field.name]"
                    :placeholder="field.placeholder ? field.placeholder : '请选择' + field.label"
                    @on-change="timeChange($event, field.name)">
        </DatePicker>
        <Cascader v-if="field.type === 'cascader'"
                  v-model="formSearch[field.name]"
                  :name="field.name"
                  :data="getCurrentSData(field)"
                  :filterable="field.filterable"></Cascader>
      </FormItem>
      <FormItem :label-width="30" class="form-item-btn" v-if="showSearch || showReset">
        <Button type="primary" @click="toSearch" :loading="okLoading" v-if="showSearch">
          <Icon type="ios-search" v-if="!okLoading"/> 查询
        </Button>
        <Button @click="toReset" v-if="showReset">
          <Icon type="md-refresh" /> 重置
        </Button>
        <div class="search-collapse-box" @click="toCollapse" v-if="fields.length > minSize">
          <template v-if="collapse">
            展开
            <Icon type="ios-arrow-down" />
          </template>
          <template v-else>
            收起
            <Icon type="ios-arrow-up" />
          </template>
        </div>
      </FormItem>
    </Form>
  </div>
</template>

<script>
/**
 * ---fields字段说明
 * name key值
 * nameSecond 第二个key值，用于取下拉框数据
 * label 名称
 * type 表单类型
 * placeholder 提示语
 * styl  行内样式
 * child: [] 嵌套子表单
 * format 类型为datetime时的时间格式
 * options 类型为select、radioGroup、checkboxGroup时的选项 例： { label: '待审核', value: '1'}
 * * multiple 类型为select时，是否为多选
 * filterable 类型为select时，是否支持搜索
 * tagCount 类型为select时，选时最多显示多少个 tag
 * show 是否显示 1 显示; 0 不显示； 默认显示
 */
import { getTableHeight } from '@/libs/util'
export default {
  name: "index",
  props: {
    cls: {
      type: String,
      default: ''
    },
    minSize: { // 展示表单的最小长度
      type: Number,
      default: 5
    },
    fields: {
      type: Array,
      default () {
        return []
      }
    },
    fieldsVal: { //默认值
      type: Object,
      default () {
        return {}
      }
    },
    selectData: { // 下拉框数据集合
      type: Object,
      default () {
        return {}
      }
    },
    okLoading: { // 设置按钮为加载中状态
      type: Boolean,
      default: false
    },
    showSearch: { // 是否显示查询按钮
      type: Boolean,
      default: true
    },
    showReset: { // 是否显示重置按钮
      type: Boolean,
      default: true
    }
  },
  data () {
    let sForm = this.getFormField()
    return {
      formSearch: sForm,
      collapse: true,
      oprType: 'search'
    }
  },
  methods: {
    getFormField () {
      let sForm = {}
      this.fields.forEach((field) => { // 查询表单赋默认属性
        if (field.name) {
          if (field.multiple) { // 多选
            sForm[field.name] = this.fieldsVal[field.name] ? this.fieldsVal[field.name] : []
          } else {
            sForm[field.name] = this.fieldsVal[field.name] ? this.fieldsVal[field.name] : ''
          }
        } else {
          if (field.child && field.child.length) {
            field.child.forEach((ch) => {
              if (ch.multiple) {
                sForm[ch.name] = this.fieldsVal[ch.name] ? this.fieldsVal[ch.name] : []
              } else {
                sForm[ch.name] = this.fieldsVal[ch.name] ? this.fieldsVal[ch.name] : ''
              }
            })
          }
        }
      })
      return sForm
    },
    toSearch () {
      this.$emit('on-search', Object.assign({}, this.formSearch))
    },
    toReset () {
      this.$refs.formSearch.resetFields()
      Object.keys(this.formSearch).forEach((key) => {
        this.formSearch[key] = this.fieldsVal[key] ? this.fieldsVal[key] : ''
      })
      this.$emit('on-reset', Object.assign({}, this.formSearch))
    },
    timeChange (val, name) {
      this.formSearch[name] = val
      this.$emit('on-date-change', val, name)
    },
    selectChange (value, field) {
      if (field.multiple) {
        if (value.length > field.tagCount) {
          this.$Message.warning(`最多只能选择${field.tagCount}条`)
          this.formSearch[field.name].pop()
        }
      }
      this.$emit('on-select-change', value, field)
    },
    getCurrentSData (field) {
      let opt = this.selectData[field.nameSecond ? field.nameSecond : field.name] ? this.selectData[field.nameSecond ? field.nameSecond : field.name] : field.options
      return opt
    },
    toCollapse () {
      this.collapse = !this.collapse
      setTimeout(() => {
        getTableHeight()
      }, 100)
    }
  },
  watch: {
    fieldsVal: {
      handler() {
        this.formSearch = this.getFormField()
      },
      deep: true
    },
    formSearch: {
      handler (val) {
        this.$emit('on-change', Object.assign({}, val))
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="less">
  @import "index";
</style>
