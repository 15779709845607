<template>
  <div class="order-wrapper">
    <div class="opr-btn-box">
      <a href="javascript:;" class="export"><Icon type="md-download" />导出</a>
    </div>
    <search-form :fields="searchFields" :okLoading="loading" @on-search="toSearch" @on-reset="toReset"></search-form>
    <div class="order-list" ref="table" :style="{height: tableHeight + 'px'}">
      <template v-if="!loading">
        <template v-if="list.length">
          <div class="order-item" v-for="(item, index) in list" :key="index">
            <div class="item-top">
              <p class="left">充电单号: {{item.orderNumber}}
                <span class="status" :style="{backgroundColor: '#4d8cfd'}">{{getSelectLabel(startFailOpt.concat(chargeStopOpt), item.startFailState)}}</span>
              </p>
            </div>
            <p class="name">{{item.userPhone}}</p>
            <div class="order-ul-box">
              <ul class="order-ul">
                <li v-for="(attr, aInd) in listAttr" :key="aInd">
                  <label>{{attr.name}}:</label>
                  <span :title="item[attr.key]">
                <template v-if="item[attr.key]">
                   {{item[attr.key] ? item[attr.key] + (attr.unit ? attr.unit : '') : '--'}}
                </template>
                <template v-else>——</template>
              </span>
                </li>
              </ul>
              <div class="list-btn-box" style="margin-top: -15px;">
<!--                <a class="detail" href="javascript:;" @click="toDetail(item)">
                  <i class="iconfont icon-chakanjiexi"></i>
                  <span>详情</span>
                </a>-->
              </div>
            </div>
          </div>
        </template>
        <no-data v-else></no-data>
      </template>
      <loading :show="loading" :styl="{backgroundColor: 'rgba(255,255,255,.3)'}"></loading>
    </div>
    <Page :total="total" :current="params.pageNo" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
    <Modal v-model="showDetail" title="详情" :fullscreen="true" class-name="modal-detail">
      <div class="modal-box">
        <div class="title">
          <span>充电单号: {{detail.rechargeNo}}
            <label class="status" v-if="detail.status" :style="{backgroundColor: statusText(detail.status).color}">
              {{ statusText(detail.status).text }}
            </label>
            <label class="status" v-if="detail.orderStatus" :style="{backgroundColor: statusText(detail.orderStatus, 'order').color}">
              {{ statusText(detail.orderStatus, 'order').text }}
            </label>
          </span>
        </div>
        <div class="modal-base-info">
          <ul class="info-ul">
            <li v-for="(attr, aInd) in modalAttr.slice(0,8)" :key="aInd">
              <label>{{attr.name}}: </label>
              <span :title="detail[attr.key]">{{detail[attr.key] ? detail[attr.key] : '——'}}</span>
            </li>
          </ul>
          <div class="base-info-center">
            <p class="first">订单结束原因: APP远程停止</p>
            <div class="price-content">
              <p>
                <span>订单总金额: ￥{{detail.orderAmount}}</span>
                <span>订单总优惠: ￥0.00</span>
              </p>
              <p>
                <span class="real-num">￥{{detail.receivedAmount}}</span>
                <span>实收金额</span>
              </p>
            </div>
            <ul class="time-info">
              <li>
                <samp></samp>
                <label>准备充电</label>
                <span>2022-03-22 12:51</span>
              </li>
              <li>
                <samp></samp>
                <label>开始充电</label>
                <span>2022-03-22 12:51</span>
              </li>
              <li>
                <samp></samp>
                <label>结束充电</label>
                <span>2022-03-22 14:02</span>
              </li>
              <li>
                <samp></samp>
                <label>订单支付</label>
                <span>2022-03-22 12:51</span>
              </li>
            </ul>
          </div>
          <ul class="info-ul">
            <li v-for="(attr, aInd) in modalAttr.slice(8)" :key="aInd">
              <label>{{attr.name}}: </label>
              <span :title="detail[attr.key]">{{detail[attr.key] ? detail[attr.key] : '——'}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-box">
        <div class="title">
          <span>充电明细</span>
        </div>
        <Table :columns="rDetailCol"
               stripe
               :data="rDetailData"
               show-summary
               :summary-method="detailSummary"></Table>
      </div>
      <div class="modal-box">
        <div class="title"><span>充电费用信息</span></div>
        <ul class="price-info">
          <li>
            <p>
              <label>电费</label>
              <span>所有折扣前的电站标价计算出的电费费用=电度数*电站电费标价</span>
            </p>
            <p class="pNum">13.35元</p>
          </li>
          <li>
            <p>
              <label>服务费</label>
              <span>所有折扣前的电站标价计算出的服务费费用=电度数*电站服务费标价</span>
            </p>
            <p class="pNum">4.91元</p>
          </li>
          <li class="total">
            <p>
              <label>订单总金额</label>
              <span>所有折扣前的电站标价计算出的费用=电费+服务费</span>
            </p>
            <p class="pNum-special">18.26元</p>
          </li>
        </ul>
      </div>
      <div class="modal-box">
        <div class="title"><span>运营商实收信息</span></div>
        <ul class="price-info">
          <li>
            <p>
              <label>电站活动抵扣</label>
              <span>当前订单享受的运营商发起电站折扣活动抵扣金额</span>
            </p>
            <p class="pNum">
              <label>电费：0.00元</label>
              <label>服务费：0.00元</label>
            </p>
          </li>
          <li>
            <p>
              <label>优惠券抵扣</label>
              <span>用户充电结算时使用的运营商优惠券抵扣金额</span>
            </p>
            <p class="pNum">
              <label>电费：0.00元</label>
              <label>服务费：0.00元</label>
            </p>
          </li>
          <li class="total">
            <p>
              <label>运营商实收</label>
              <span>电费: <i>13.35元</i> 服务费: <i>4.91元</i></span>
            </p>
            <p class="pNum-special">18.26元</p>
          </li>
        </ul>
      </div>
      <div class="modal-box">
        <div class="title">
          <span>历史监测数据列表</span>
          <ul class="title-tab">
            <li v-for="(tTab, tTInd) in titleTab" :key="tTInd" :class="{'current': tTInd === titleTInd}" @click="toTitleTab(tTInd)">{{tTab.name}}</li>
          </ul>
        </div>
        <div class="titleTab-box">
          <div class="charts-box" v-if="titleTInd === 0">
            charts
          </div>
          <template v-if="titleTInd === 1">
            <ul class="grid-tab">
              <li v-for="(gTab, gInd) in gridTab" :key="gInd" :class="{'current': gInd === gridTInd}" @click="toGridTab(gInd)">{{gTab.name}}</li>
            </ul>
            <div class="grid-table">
              <Table :columns="monitorCol[gridTInd]" :data="monitorData" stripe></Table>
            </div>
          </template>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import NoData from '@/components/NoData'
import Loading from '@/components/Loading'
import { getOrderList } from '@/libs/api'
import { getSelectLabel } from '@/libs/tools'
import { startFailOpt, chargeStopOpt } from '@/libs/dict'
// let timeFormat = 'YYYY-MM-DD HH:mm:ss'
export default {
  name: "abnormal-order",
  components: {
    SearchForm,
    NoData,
    Loading
  },
  data () {
    return {
      startFailOpt,
      chargeStopOpt,
      loading: false,
      routeType: '',
      searchFields: [
        {
          label: '充电单号',
          name: 'orderNumber',
          type: 'input'
        },
        {
          label: '用户账号',
          name: 'userNo',
          type: 'input'
        },
        {
          label: '充电车辆',
          name: 'carNo',
          type: 'input'
        },
        {
          label: '电站名称',
          name: 'stateName',
          type: 'input'
        },
        {
          label: '终端名称',
          name: 'chargeNumber',
          type: 'input'
        }
      ],
      listAttr: [
        {
          key: 'carNo',
          name: '充电车辆'
        },

        {
          key: 'rechargeNum',
          name: '充电电量',
          unit: '度'
        },
        {
          key: 'rechargeTime',
          name: '充电时长'
        },
        {
          key: 'siteName',
          name: '电站名称'
        },
        {
          key: 'terminalName',
          name: '终端名称'
        },
        {
          key: 'pilePower',
          name: '电桩功率',
          unit: 'kw'
        },
        {
          key: 'orderCreateTime',
          name: '订单时间'
        },
        {
          key: 'consumptionAmount',
          name: '订单金额',
          unit: '元'
        },
        {
          key: 'paymentAmount',
          name: '实收金额',
          unit: '元'
        }
      ],
      list: [],
      params: {
        pageSize: 10,
        pageNo: 1,
        orderState: 6
      },
      total: 100,
      showDetail: false,
      detail: {},
      useEleAttr: [
        {
          icon: 'icon-icon-',
          name: '电量',
          color: '#DD5227',
          key: 'eleCount',
          unit: 'kw.h'
        },
        {
          icon: 'icon-yujing_',
          name: 'SOC',
          color: '#4d8cfd',
          key: 'soc',
          unit: '%'
        },
        {
          icon: 'icon-shalou',
          name: '时长',
          color: '#E0572A',
          key: 'useTime',
          unit: '分钟'
        }
      ],
      titleTab: [
        {
          name: '图表'
        },
        {
          name: '列表'
        }
      ],
      titleTInd: 0,
      gridTab: [
        {
          name: '上传实时监测数据'
        },
        {
          name: 'BMS需求和充电机输出'
        },
        {
          name: '充电过程BMS信息'
        }
      ],
      gridTInd: 0,
      modalAttr: [
        {
          key: 'userName',
          name: '用户账号'
        },
        {
          key: 'nickName',
          name: '用户昵称'
        },
        {
          key: 'rechargeCard',
          name: '充电卡号'
        },
        {
          key: 'belongOrgan',
          name: '归属机构'
        },
        {
          key: 'carNo',
          name: '充电车辆'
        },
        {
          key: 'vin',
          name: '充电VIN码'
        },
        {
          key: 'orderType',
          name: '订单类型'
        },
        {
          key: 'remark',
          name: '车辆备注'
        },
        {
          key: 'terminalName',
          name: '终端名称'
        },
        {
          key: 'pilePower',
          name: '电桩功率',
          unit: 'kw'
        },
        {
          key: 'stateName',
          name: '电站名称'
        },
        {
          key: 'operatorName',
          name: '运营商'
        },
        {
          key: 'terminalType',
          name: '运营商属性'
        },
        {
          key: 'dealAttr',
          name: '交易属性'
        }
      ],
      rDetailCol: [
        {
          title: '充电时段',
          key: 'timeRange',
          width: 300
        },
        {
          title: '电费单价(元/度)',
          key: 'price'
        },
        {
          title: '服务费单价(元/度)',
          key: 'servicePrice'
        },
        {
          title: '充电度数(度)',
          key: 'number'
        },
        {
          title: '费用(元)',
          key: 'account'
        }
      ],
      rDetailData: [
        {
          timeRange: '12:51-14:00',
          price: '0.8094',
          servicePrice: '0.3000',
          number: '16.1900',
          account: '17.95'
        },
        {
          timeRange: '14:00-14:02',
          price: '1.1644',
          servicePrice: '0.3000',
          number: '0.2200',
          account: '0.31'
        }
      ],
      monitorCol: {
        0: [
          {
            key: 'startTime',
            title: '上传时间'
          },
          {
            key: 'voltage',
            title: '电压(V)'
          },
          {
            key: 'ampere',
            title: '电流(A)'
          },
          {
            key: 'soc',
            title: 'SOC(%)'
          },
          {
            key: 'eleCount',
            title: '电量(度)'
          },
          {
            key: 'lossEleCount',
            title: '计损电量(度)'
          },
          {
            key: 'remainTime',
            title: '剩余时间'
          },
          {
            key: 'useTime',
            title: '已充时间'
          },
          {
            key: 'rechargeStatus',
            title: '充电状态'
          },
          {
            key: 'gunTemperature',
            title: '枪线温度(℃)'
          },
          {
            key: 'gunConnectSts',
            title: '枪连接状态'
          }
        ],
        1: [
          {
            key: 'maxTemperature',
            title: '电池组最高温度(℃)'
          },
          {
            key: 'maxTempNo',
            title: '最高温度监测点编号'
          },
          {
            key: 'minTemperature',
            title: '电池组最低温度(℃)'
          },
          {
            key: 'minTempNo',
            title: '最低温度监测点编号'
          }
        ],
        2: [
          {
            key: 'maxVoltage',
            title: '最高单体电压'
          },
          {
            key: 'maxVolNo',
            title: '最高单体电压监测点编号'
          },
          {
            key: 'demandVoltage',
            title: '需求电压(V)'
          },
          {
            key: 'demandAmpere',
            title: '需求电流(A)'
          }
        ]
      },
      monitorData: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.routeType = this.$route.path.split('/')[this.$route.path.split('/').length - 1]
      this.getList()
    })
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  methods: {
    getSelectLabel,
    statusText (val, type) {
      type = type || 'time'
      let obj = {
        time: {
          1: {
            text: '充电中',
            color: '#4d8cfd'
          },
          2: {
            text: '充电结束',
            color: '#999'
          }
        },
        order: {
          1: {
            text: '正常单自动结算',
            color: '#4d8cfd'
          }
        }
      }
      return obj[type][val]
    },
    toSearch (obj) {
      this.searchForm = obj
      this.params.pageNo = 1
      this.getList()
    },
    toReset (obj) {
      this.searchForm = obj
      this.getList()
    },
    pageChange (page) {
      this.params.pageNo = page
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    toDetail (item) {
      this.showDetail = true
      this.detail = item
    },
    toTitleTab (index) {
      this.titleTInd = index
      this.gridTInd = 0
    },
    toGridTab (index) {
      this.gridTInd = index
    },
    detailSummary (params) {
      let sums = {}
      params.columns.forEach((col, cInd) => {
        let key = col.key
        if (cInd === 0) {
          sums[key] = {
            key,
            value: '总计 (总时长: 1小时11分钟)'
          }
        }
        const values = params.data.map(item => item[key])
        if (![1, 2].includes(cInd)) {
          if (!values.every(value => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev - 0 + (curr - 0)
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: v.toFixed(4)
            }
          }
        } else {
          sums[key] = {
            key,
            value: ''
          }
        }

      })
      return sums
    },
    async getList () {
      this.list = []
      // if (this.routeType === 'operator') { // 个人
        this.loading = true
        // getOrderList orderDetailList
        await getOrderList(Object.assign({}, this.searchForm, this.params)).then((res) => {
          if (res.data.code === 0) {
            this.loading = false
            this.list = res.data.data.OrderInformation.data
            this.total = res.data.data.OrderInformation.total
            if (this.list.length) {
              this.list.forEach((item) => {
                item.rechargeTime = this.getTimeDiff(item.orderStartTime, item.orderEndTime)
                item.rechargeNum = (item.totalStopValue - item.totalStartValue).toFixed(4)
                item.terminalName = item.chargeNumber + '-' + item.gunNumber
                item.consumptionAmount = (item.consumptionAmount / 10000).toFixed(2)
                item.paymentAmount = (item.paymentAmount / 10000).toFixed(2)
              })
            }
          } else {
            this.$Message.error(res.data.message)
          }
        })
      // }
    },
    getTimeDiff (start, end) {
      let day = 0
      let hour = 0
      let minute = 0
      let st = dayjs(start)
      let et = dayjs(end)
      let dayDiff = et.diff(st, 'day', true)
      if (dayDiff > 0) {
        day = Math.floor(dayDiff)
      }
      if (String(dayDiff).indexOf('.') > -1) {
        let dDigit =  Number('0.' + String(dayDiff).split('.')[1])
        let hNum = dDigit * 24
        if (hNum > 0) {
          hour = Math.floor(hNum)
        }
        if (String(hNum).indexOf('.') > -1) {
          let hDigit = Number('0.' + String(hNum).split('.')[1])
          let mNum = hDigit * 60
          if (mNum > 0) {
            minute = Math.floor(mNum)
          }
        }
      }
      let dStr = day > 0 ? day + '天' : ''
      let hStr = hour > 0 ? hour + '小时' : ''
      let mStr = minute > 0 ?  minute + '分钟' : ''
      return dStr + hStr + mStr
    }
  },
  watch: {
    showDetail (val) {
      if(!val) {
        this.detail = {}
        this.titleTInd = 0
        this.gridTInd = 0
      }
    },
    '$route': {
      handler (val) {
        this.routeType = val.path.split('/')[val.path.split('/').length - 1]
        this.getList()
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="less">
@import "order-manage";
</style>
