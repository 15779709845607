<template>
    <div class="box">
        <div class="main">
            <div class="statistics">
                圆形图表
            </div>
            <div class="increment">
                数据增量
            </div>
        </div>
        <div class="Chart">
            订单，收入，电量曲线
        </div>
    </div>
</template>

<script>
    export default {
        name: 'report-statistics'
    }
</script>

<style lang="less" scoped>
    .box{
        height: 100%;
        .main{
            height: 40%;
            display: flex;
            .statistics{
                flex: 0 1 40%;
                border: 1px solid #ddd;
            }
            .increment{
                flex: 0 1 60%;
                border: 1px solid #ddd;
            }
        }
        .Chart{
            width: 100%;
            height: 58.5%;
            border: 1px solid #ddd;
        }
    }
    
</style>