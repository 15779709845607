<template>
  <div class="statistics-wrapper">
    <search-form :fields="searchFields[staTKey].concat(searchCommonFields)" :okLoading="loading" :fieldsVal="fieldsVal[staTKey]" :selectData="searchSelectData" @on-search="toSearch" @on-reset="toReset" @on-change="searchChange" :minSize="3"></search-form>
    <div class="statistics-content-wrapper">
      <div class="statistics-tab table-top">
        <div class="tab-left">
          <ul>
            <template v-for="(tab, tInd) in staTab">
              <li :key="tInd" :class="{'current': tab.key === staTKey}" @click="toTab(tab)" v-has-permi="tab.permission">
                {{tab.name}}
              </li>
            </template>
          </ul>
          <Button :loading="exportLoading"
                  :disabled="exportLoading"
                  @click="toOpr('export')"
                  v-if="['detail'].includes(staTKey)"
                  v-has-permi="['PRIV_STATISTICS_ORDER_EXPORT']"><Icon type="md-download" />导出当前页</Button>
          <Button :loading="exportLoading" :disabled="exportLoading" @click="toOpr('exportAll')" v-if="['summary', 'summaryTime', 'detail'].includes(staTKey)" v-has-permi="['PRIV_STATISTICS_ORDER_EXPORT']"><Icon type="md-download" />导出全部</Button>
        </div>
      </div>
      <div class="statistics-box" ref="table" :style="{height: tableHeight + 'px'}">
        <Loading :show="loading" :styl="{background: 'rgba(255,255,255,.3)'}"></Loading>
        <div class="charts-box" v-show="staTKey === 'chart'" id="charts-item" ref="charts"></div>
        <div class="statistics-grid" v-if="['summary', 'summaryTime', 'detail'].includes(staTKey)">
          <Table stripe
                 :columns="tableColumn"
                 :data="list"
                 :loading="loading"
                 :height="tableHeight - 110"
                 @on-sort-change="sortChange"
                 @on-selection-change="tableSelectChange"
                 show-summary :summary-method="handleSummary"></Table>
          <Page
              :total="total"
              :current="params.pageIndex"
              :page-size="params.pageSize"
              show-elevator
              show-sizer
              show-total
              @on-change="pageChange"
              @on-page-size-change="pageSizeChange"
              class="table-page1"></Page>
        </div>
      </div>
    </div>
    <Loading :show="exportLoading" :styl="{background: 'rgba(255,255,255,.65)'}" text="导出中，请等待..."></Loading>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import SearchForm from '@/components/SearchForm'
import { mapState } from 'vuex'
import { orderStateOpt, orderStartModeOpt } from '@/libs/dict'
import { getOrderSummaryChart, getOrderSummaryStatistics, getOrderSummaryStatisticsTime, orderSummaryExportTime, orderSummaryExportNew, orderDetailListNew, orderDetailExportNew,companyQueryGet } from '@/libs/api'
import { exportExcel, getStorage, getSelectLabel } from '@/libs/tools'
import { getAllSiteList, getAllCompanyList } from '@/libs/requestCommon'
import Loading from '@/components/Loading'
import { getTableHeight } from '@/libs/util'

let yearDefaultVal = dayjs(new Date()).format('YYYY-MM')
import * as echarts from 'echarts'
import store from '@/store'
export default {
  name: "order-statistics",
  components: {
    SearchForm,
    Loading
  },
  data () {
    return {
      orderStateOpt,
      orderStartModeOpt,
      loading: false,
      exportLoading: false,
      user: getStorage('user') ? JSON.parse(getStorage('user')) : {},
      fieldsVal: {
        chart: {
          yearMonth: yearDefaultVal,
          siteIds: []
        },
        summary: {
          startDate: dayjs(new Date()).format('YYYY-MM') + '-01 00:00:00',
          endDate: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          siteIds: []
        },
        summaryTime: {
          startDate: dayjs(new Date()).format('YYYY-MM') + '-01 00:00:00',
          endDate: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          siteIds: []
        },
        detail: {
          userPhone: this.$route.query.userPhone ? this.$route.query.userPhone : '',
          withdrawalNumber: this.$route.query.withdrawalNumber ? this.$route.query.withdrawalNumber : '',
          siteIds: []
        }
      },
      searchCommonFields: [
        {
          type: 'select',
          label: '站点名称',
          name: 'siteIds',
          useValue: 'value',
          cls: 'half',
          filterable: true,
          multiple: true
        }
      ],
      searchFields: {
        chart: [
          {
            type: 'month',
            label: '时间',
            name: 'yearMonth',
            clearable: false,
            editable: false
          }
        ],
        summary: [ // 汇总
          {
            type: 'datetime',
            label: '开始时间',
            name: 'startDate',
            editable: false,
            options: {
              disabledDate: (date) => {
                let flag = false
                if (this.searchParams.endDate) {
                  let allowDate = dayjs(new Date(date)).add(1, 'month')
                  flag = dayjs(new Date(this.searchParams.endDate)).isAfter(allowDate)
                }
                return flag
              }
            }
          },
          {
            type: 'datetime',
            label: '结束时间',
            name: 'endDate',
            editable: false,
            options: {
              disabledDate: (date) => {
                let flag = false
                if (this.searchParams.startDate) {
                  let allowDate = dayjs(new Date(date)).subtract(1, 'month')
                  flag = dayjs(new Date(this.searchParams.startDate)).isBefore(allowDate)
                }
                return flag
              }
            }
          }
        ],
        summaryTime: [
          {
            type: 'datetime',
            label: '开始时间',
            name: 'startDate',
            editable: false,
            options: {
              disabledDate: (date) => {
                let flag = false
                if (this.searchParams.endDate) {
                  let allowDate = dayjs(new Date(date)).add(1, 'month')
                  flag = dayjs(new Date(this.searchParams.endDate)).isAfter(allowDate)
                }
                return flag
              }
            }
          },
          {
            type: 'datetime',
            label: '结束时间',
            name: 'endDate',
            editable: false,
            options: {
              disabledDate: (date) => {
                let flag = false
                if (this.searchParams.startDate) {
                  let allowDate = dayjs(new Date(date)).subtract(1, 'month')
                  flag = dayjs(new Date(this.searchParams.startDate)).isBefore(allowDate)
                }
                return flag
              }
            }
          },
          {
            type: 'cascader',
            label: '区域选择',
            name: 'regionData',
            multiple: true,
            cls: 'half',
            filterable: true
          }
        ],
        detail: [ // 明细
          {
            type: 'datetime',
            label: '开始时间',
            name: 'orderStartTime',
            editable: false
          },
          {
            type: 'datetime',
            label: '结束时间',
            name: 'orderEndTime',
            editable: false
          },
          {
            type: 'cascader',
            label: '区域选择',
            name: 'regionData',
            multiple: true,
            cls: 'half',
            filterable: true
          },
          {
            type: 'input',
            label: '手机号',
            name: 'userPhone'
          },
          {
            type: 'input',
            label: '提现流水号',
            name: 'withdrawalNumber'
          },
          {
            type: 'select',
            label: '所属公司',
            name: 'companyId',
            useValue: 'value',
            filterable: true
          },
          {
            type: 'select',
            label: '所属运营商',
            name: 'affiliatedCompanyId',
            useValue: 'value',
            filterable: true
          },
          {
            type: 'select',
            label: '订单状态',
            name: 'orderState',
            useValue: 'value',
            clearable: true
          },
          {
            type: 'select',
            label: '启动方式',
            name: 'startupMode',
            useValue: 'value',
            clearable: true
          },
          {
            type: 'input',
            label: '快电订单号',
            name: 'kuaiDianNumber',
            cls: 'half'
          }
        ]
      },
      searchParams: {
        startDate: '',
        endDate: ''
      },
      searchSelectData: {
        siteIds: [],
        regionData: [],
        companyId: [],
        affiliatedCompanyId: [],
        orderState: orderStateOpt,
        startupMode: orderStartModeOpt,
      },
      staTab: [
        {
          name: '订单月度汇总统计图',
          key: 'chart',
          permission: ['PRIV_STATISTICS_ORDER_CHART']
        },
        {
          name: '订单月度汇总列表',
          key: 'summary',
          permission: ['PRIV_STATISTICS_ORDER_SUMMARY']
        },
        {
          name: '订单汇总列表',
          key: 'summaryTime',
          permission: ['PRIV_STATISTICS_ORDER_DETAIL']
        },
        {
          name: '订单明细列表',
          key: 'detail',
          permission: ['PRIV_STATISTICS_ORDER_DETAIL']
        }
      ],
      staTKey: 'chart',
      params: {
        orderBy: 'orderEndTime',
        sort: 'descending',
        pageSize: 20,
        pageIndex: 1
      },
      total: 0,
      columns: {
        summary: [ // 汇总
          {
            type: 'index',
            title: '序号',
            width: 70,
            align: 'center'
          },
          {
            key: 'siteName',
            title: '所属站点',
            align: 'center',
            width: 200
          },
          {
            key: 'totalElectricQuantity',
            title: '总电量(桩返-度)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.totalElectricQuantity / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'electricityTotal',
            title: '电费总价(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.electricityTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'electricityTotal',
            title: '电费优惠金额(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = ((params.row.electricityTotal - params.row.electricityDiscountTotal) / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'electricityDiscountTotal',
            title: '电费优惠后总价(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.electricityDiscountTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'totalServiceChargePrice',
            title: '服务费总价(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.totalServiceChargePrice / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'preferentialServiceFee',
            title: '服务费优惠金额(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.preferentialServiceFee / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'serviceFeeDiscountPrice',
            title: '服务费折扣后价格(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.serviceFeeDiscountPrice / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'paymentAmount',
            title: '实际支付总价格(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.paymentAmount / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' weChatPayMoney',
            title: '微信小程序支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' alipayPayMoney',
            title: '微信小程序支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.alipayPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' weChatAppPayMoney',
            title: 'App微信支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatAppPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' alipayAppPayMoney',
            title: 'App支付宝支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.alipayAppPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'weChatServiceCharge',
            title: '微信小程序手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'weChatAppServiceCharge',
            title: 'App微信手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatAppServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'alipayAppServiceCharge',
            title: 'App支付宝手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.alipayAppServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'mdxOrderServiceCharge',
            title: '满电行手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.mdxOrderServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'operatorReceived',
            title: '运营商实收(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.operatorReceived / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'mdxNetReceipts',
            title: '满电行账户(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.mdxNetReceipts / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'giftBalanceDeduction',
            title: '活动抵扣金额(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.giftBalanceDeduction / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'ykcDeduction',
            title: '其它账户(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.ykcDeduction / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'userDirectBlance',
            title: '预充值余额(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.userDirectBlance / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'tipCharge',
            title: '尖电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.tipCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'tipServiceChargePrice',
            title: '尖服务费总价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.tipServiceChargePrice / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'tipTotal',
            title: '尖电费总价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.tipTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'peakCharge',
            title: '峰电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.peakCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'peakServiceChargePrice',
            title: '峰服务费总价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.peakServiceChargePrice / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'peakTotal',
            title: '峰电费总价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.peakTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'averageCharge',
            title: '平电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.averageCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'averageServiceChargePrice',
            title: '平服务费总价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.averageServiceChargePrice / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'averageTotal',
            title: '平电费总价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.averageTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'valleyCharge',
            title: '谷电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.valleyCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'valleyServiceChargePrice',
            title: '谷服务费总价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.valleyServiceChargePrice / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'valleyTotal',
            title: '谷电费总价(元)',
            align: 'center',
            minWidth: 120,
            render: (h, params) => {
              let num = (params.row.valleyTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
        ],
        detail: [ // 明细
          {
            key: 'index',
            title: '序号',
            width: 70,
            align: 'center',
            render: (h, params) => {
              let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
              return (<span>{ind}</span>)
            }
          },
          {
            key:'siteName',
            title: '所属站点',
            align: 'center',
            width: 200
          },
          {
            key: 'userPhone',
            title: '手机号',
            align: 'center',
            width: 150
          },
          {
            key: 'companyName',
            title: '所属公司',
            align: 'center',
            width: 200,
            render: (h, params) => {
              let str = params.row.companyName ? params.row.companyName : '--'
              return (<span>{str}</span>)
            }
          },
          {
            key: 'userName',
            title: '用户姓名',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let str = params.row.userName ? params.row.userName : ''
              return (<span>{str}</span>)
            }
          },
          {
            key: 'userWxOpenId',
            title: '用户openId',
            align: 'center',
            width:  180
          },
          {
            key: 'chargeNumber',
            title: '设备编号',
            align: 'center',
            width: 180,
            render: (h, params) => {
              let str = params.row.chargeNumber + params.row.gunNumber
              return (<span>{str}</span>)
            }
          },
          {
            key: 'orderState',
            title: '订单类型',
            width: 150,
            render: (h, params) => {
              let obj = {
                3: '历史订单',
                7: '异常订单'
              }
              return (<span>{obj[params.row.orderState]}</span>)
            }
          },
          {
            key: 'orderState',
            title: '订单状态',
            width: 150,
            render: (h, params) => {
              let str = getSelectLabel(orderStateOpt, params.row.orderState)
              return (<span>{str}</span>)
            }
          },
          {
            key: 'startupMode',
            title: '启动方式',
            width: 150,
            align: 'center',
            render: (h, params) => {
              let str = params.row.startupMode ? getSelectLabel(orderStartModeOpt, params.row.startupMode) : '微信'
              return (<span>{str}</span>)
            }
          },
          {
            key: 'orderStartTime',
            title: '订单开始时间',
            align: 'center',
            width: 170,
            render: (h, params) => {
              let str = params.row.orderStartTime ? dayjs(new Date(params.row.orderStartTime)).format('YYYY-MM-DD HH:mm:ss') : '--'
              return <span>{str}</span>
            }
          },
          {
            key: 'orderEndTime',
            title: '订单结束时间',
            align: 'center',
            width: 170,
            render: (h, params) => {
              let str = params.row.orderEndTime ? dayjs(new Date(params.row.orderEndTime)).format('YYYY-MM-DD HH:mm:ss') : '--'
              return <span>{str}</span>
            }
          },
          {
            key: 'totalElectricQuantity',
            title: '总电量(度-桩返)',
            align: 'center',
            width: 120,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.totalElectricQuantity / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'consumptionAmount',
            title: '总金额(元-桩返)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.consumptionAmount / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'electricityTotal',
            title: '电费总价(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.electricityTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'electricityDiscountTotal',
            title: '电费优惠后总价(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.electricityDiscountTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'totalServiceChargePrice',
            title: '服务费总价(元)',
            width: 150,
            align: 'center',
            render: (h, params) => {
              let str = (params.row.totalServiceChargePrice / 10000).toFixed(2)
              return (<span>{str}</span>)
            }
          },
          {
            key: 'totalAmount',
            title: '订单金额(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.totalAmount / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'serviceFeeDiscount',
            title: '服务费折扣率',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let str = (params.row.serviceFeeDiscount / 100).toFixed(2) + '折'
              return (<span>{str}</span>)
            }
          },
          {
            key: 'preferentialServiceFee',
            title: '服务费优惠金额(元)',
            align: 'center',
            width: 200,
            render: (h, params) => {
              let str = (params.row.preferentialServiceFee / 10000).toFixed(2)
              return (<span>{str}</span>)
            }
          },
          {
            key: 'serviceFeeDiscountPrice',
            title: '服务费折后价格(元)',
            align: 'center',
            width: 200,
            render: (h, params) => {
              let str = (params.row.serviceFeeDiscountPrice / 10000).toFixed(2)
              return (<span>{str}</span>)
            }
          },
          {
            key: 'paymentAmount',
            title: '实付金额(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.paymentAmount / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' weChatPayMoney',
            title: '微信小程序支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' alipayPayMoney',
            title: '微信小程序支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.alipayPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' weChatAppPayMoney',
            title: 'App微信支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatAppPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' alipayAppPayMoney',
            title: 'App支付宝支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.alipayAppPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'weChatServiceCharge',
            title: '微信小程序手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'weChatAppServiceCharge',
            title: 'App微信手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatAppServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'alipayAppServiceCharge',
            title: 'App支付宝手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.alipayAppServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'mdxOrderServiceCharge',
            title: '满电行手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.mdxOrderServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'operatorReceived',
            title: '运营商实收(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.operatorReceived / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'mdxNetReceipts',
            title: '满电行账户(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.mdxNetReceipts / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'giftBalanceDeduction',
            title: '活动抵扣金额(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.giftBalanceDeduction / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'ykcDeduction',
            title: '其他账户(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.ykcDeduction / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'userDirectBlance',
            title: '预充值账户(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.userDirectBlance / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'kuaiDianPay',
            title: '快电支付(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.kuaiDianPay / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'tipElectricityPrice',
            title: '尖电费单价(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.tipElectricityPrice / 100000).toFixed(5)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'tipTotal', // 电价总价
            title: '尖电价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.tipTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'tipElectricity',
            title: '尖服务费单价(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.tipServiceCharge / 100000).toFixed(5)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'tipPreferentialUnitPrice',
            title: '尖优惠服务费单价(元)',
            width: 150,
            align: 'center',
            render: (h, params) => {
              let num = (params.row.tipPreferentialUnitPrice / 100000).toFixed(5)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'tipServiceChargePrice', // 服务费总价
            title: '尖服务价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.tipServiceChargePrice / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'tipPreferentialUnitPriceTotal',
            title: '尖优惠服务费价(元)',
            width: 150,
            align: 'center',
            render: (h, params) => {
              let num = (params.row.tipPreferentialUnitPriceTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'tipCharge',
            title: '尖电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.tipCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'peakElectricityPrice',
            title: '峰电费单价(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.peakElectricityPrice / 100000).toFixed(5)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'peakTotal',
            title: '峰电价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.peakTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'peakServiceCharge',
            title: '峰服务费单价(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.peakServiceCharge / 100000).toFixed(5)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'peakPreferentialUnitPrice',
            title: '峰优惠服务费单价(元)',
            width: 150,
            align: 'center',
            render: (h, params) => {
              let num = (params.row.peakPreferentialUnitPrice / 100000).toFixed(5)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'peakServiceChargePrice',
            title: '峰服务价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.peakServiceChargePrice / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'peakPreferentialUnitPriceTotal',
            title: '峰优惠服务费价(元)',
            width: 150,
            align: 'center',
            render: (h, params) => {
              let num = (params.row.peakPreferentialUnitPriceTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'peakCharge',
            title: '峰电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.peakCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'averageElectricityPrice',
            title: '平电费单价(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.averageElectricityPrice / 100000).toFixed(5)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'averageTotal',
            title: '平电价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.averageTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'averageServiceCharge',
            title: '平服务费单价(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.averageServiceCharge / 100000).toFixed(5)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'averagePreferentialUnitPrice',
            title: '平优惠服务费单价(元)',
            width: 150,
            align: 'center',
            render: (h, params) => {
              let num = (params.row.averagePreferentialUnitPrice / 100000).toFixed(5)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'averageServiceChargePrice',
            title: '平服务价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.averageServiceChargePrice / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'averagePreferentialUnitPriceTotal',
            title: '平优惠服务费价(元)',
            width: 150,
            align: 'center',
            render: (h, params) => {
              let num = (params.row.averagePreferentialUnitPriceTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'averageCharge',
            title: '平电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.averageCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'valleyElectricityPrice',
            title: '谷电费单价(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.valleyElectricityPrice / 100000).toFixed(5)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'valleyTotal',
            title: '谷电价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.valleyTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'valleyServiceCharge',
            title: '谷服务费单价(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.valleyServiceCharge / 100000).toFixed(5)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'valleyPreferentialUnitPrice',
            title: '谷优惠服务费单价(元)',
            width: 150,
            align: 'center',
            render: (h, params) => {
              let num = (params.row.valleyPreferentialUnitPrice / 100000).toFixed(5)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'valleyServiceChargePrice',
            title: '谷服务价(元)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.valleyServiceChargePrice / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'valleyPreferentialUnitPriceTotal',
            title: '谷优惠服务费价(元)',
            width: 150,
            align: 'center',
            render: (h, params) => {
              let num = (params.row.valleyPreferentialUnitPriceTotal / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'valleyCharge',
            title: '谷电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.valleyCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'totalMonthElectricity',
            title: '当前站本月电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.totalMonthElectricity / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'rechargeOrderNumber',
            title: '充值订单号',
            align: 'center',
            width: 120
          },
          {
            key: 'ledgerStatus',
            title: '分账状态',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let obj = {
                0: '空不分账',
                1: '成功',
                2: '失败'
              }
              return (<span>{obj[params.row.ledgerStatus]}</span>)
            }
          }
        ]
      },
      orderCol2:  {
        summary: [ // 汇总
          {
            type: 'index',
            title: '序号',
            width: 70,
            align: 'center'
          },
          {
            key: 'siteName',
            title: '所属站点',
            align: 'center',
            width: 200
          },
          {
            key: 'totalElectricQuantity',
            title: '总电量(桩返-度)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.totalElectricQuantity / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'couponAmount',
            title: '优惠券抵扣金额(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = ((params.row.couponAmount) / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'paymentAmount',
            title: '实际支付总价格(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.paymentAmount / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' weChatPayMoney',
            title: '微信小程序支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' alipayPayMoney',
            title: '微信小程序支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.alipayPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' weChatAppPayMoney',
            title: 'App微信支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatAppPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' alipayAppPayMoney',
            title: 'App支付宝支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.alipayAppPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'weChatServiceCharge',
            title: '微信小程序手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'weChatAppServiceCharge',
            title: 'App微信手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatAppServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'alipayAppServiceCharge',
            title: 'App支付宝手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.alipayAppServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'mdxOrderServiceCharge',
            title: '满电行手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.mdxOrderServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'operatorReceived',
            title: '运营商实收(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.operatorReceived / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'mdxNetReceipts',
            title: '满电行账户(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.mdxNetReceipts / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'giftBalanceDeduction',
            title: '活动抵扣金额(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.giftBalanceDeduction / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'ykcDeduction',
            title: '其它账户(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.ykcDeduction / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'userDirectBlance',
            title: '预充值余额(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.userDirectBlance / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'tipCharge',
            title: '尖电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.tipCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'peakCharge',
            title: '峰电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.peakCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'averageCharge',
            title: '平电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.averageCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'valleyCharge',
            title: '谷电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.valleyCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          }
        ],
        detail: [ // 明细
          {
            key: 'index',
            title: '序号',
            width: 70,
            align: 'center',
            render: (h, params) => {
              let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
              return (<span>{ind}</span>)
            }
          },
          {
            key: 'siteName',
            title: '所属站点',
            align: 'center',
            width: 200
          },
          {
            key: 'chargeNumber',
            title: '设备编号',
            align: 'center',
            width: 200,
            render: (h, params) => {
              return (<span>{params.row.chargeNumber + params.row.gunNumber}</span>)
            }
          },
          {
            key: 'orderStartTime',
            title: '订单开始时间',
            align: 'center',
            width: 170,
            render: (h, params) => {
              let str = params.row.orderStartTime ? dayjs(new Date(params.row.orderStartTime)).format('YYYY-MM-DD HH:mm:ss') : '--'
              return <span>{str}</span>
            }
          },
          {
            key: 'orderEndTime',
            title: '订单结束时间',
            align: 'center',
            width: 170,
            render: (h, params) => {
              let str = params.row.orderEndTime ? dayjs(new Date(params.row.orderEndTime)).format('YYYY-MM-DD HH:mm:ss') : '--'
              return <span>{str}</span>
            }
          },
          {
            key: 'totalElectricQuantity',
            title: '总电量(桩返-度)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.totalElectricQuantity / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'totalAmount',
            title: '订单金额(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.totalAmount / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'couponAmount',
            title: '优惠券抵扣金额(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = ((params.row.couponAmount) / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'paymentAmount',
            title: '实际支付总价格(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.paymentAmount / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' weChatPayMoney',
            title: '微信小程序支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' alipayPayMoney',
            title: '微信小程序支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.alipayPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' weChatAppPayMoney',
            title: 'App微信支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatAppPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: ' alipayAppPayMoney',
            title: 'App支付宝支付金额(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.alipayAppPayMoney / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'weChatServiceCharge',
            title: '微信小程序手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'weChatAppServiceCharge',
            title: 'App微信手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.weChatAppServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'alipayAppServiceCharge',
            title: 'App支付宝手续费(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.alipayAppServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'mdxOrderServiceCharge',
            title: '满电行手续费',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.mdxOrderServiceCharge / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'operatorReceived',
            title: '运营商实收(元)',
            align: 'center',
            width: 150,
            render: (h,params) => {
              let num = (params.row.operatorReceived / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'mdxNetReceipts',
            title: '满电行账户(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.mdxNetReceipts / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'giftBalanceDeduction',
            title: '活动抵扣金额(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.giftBalanceDeduction / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'ykcDeduction',
            title: '其它账户(元)',
            align: 'center',
            width: 150,
            sortable: 'custom',
            render: (h, params) => {
              let num = (params.row.ykcDeduction / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'userDirectBlance',
            title: '预充值账户(元)',
            align: 'center',
            width: 150,
            render: (h, params) => {
              let num = (params.row.userDirectBlance / 100).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'tipCharge',
            title: '尖电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.tipCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'peakCharge',
            title: '峰电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.peakCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'averageCharge',
            title: '平电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.averageCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          },
          {
            key: 'valleyCharge',
            title: '谷电量(度)',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let num = (params.row.valleyCharge / 10000).toFixed(2)
              return (<span>{num}</span>)
            }
          }
        ]
      },
      dateCol: [{
        key: 'orderEndTime',
        title: '所属日期',
        align: 'center',
        width: 150,
        sortable: 'custom',
        fixed: 'left',
        render: (h, params) => {
          let str = dayjs(new Date(params.row.orderEndTime)).format('YYYY-MM-DD')
          const permissions = store.getters && store.getters.permissions
          let flag = permissions.indexOf('PRIV_STATISTICS_ORDER_DETAIL') > -1
          return (flag ? <a href="javascript:;" onClick={this.toDetail.bind(this, params.row)}>{str}</a> : <span>{str}</span>)
        }
      }],
      list: [],
      chartsData: [],
      chartsObj: null,
      chartsOpt: {
        color: ['#fac858', '#6fd2ef', '#ea7ccc'],
        grid: {
          left: '3%',
          right: '3%',
          bottom: '10px',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: []
        },
        yAxis: [
          {
            type: 'value'
          }
        ],
        legend: {
          top: '20px',
          data: ['总电量(度)','消费金额(元)', '满电行实收(元)']
        },
        series: [
          {
            data: [],
            name: '总电量(度)',
            type: 'bar',
            label: {
              show: false
            }
          },
          {
            data: [],
            name: '消费金额(元)',
            smooth: true,
            type: 'line'
          },
          {
            data: [],
            name: '满电行实收(元)',
            type: 'line',
            label: {
              show: false
            }
          }
        ]
      },
      selectedList: []
    }
  },
  computed: {
    ...mapState(['tableHeight']),
    tableColumn () {
      let col = []
      // let tempCol = this.user.userType === 5 ? this.orderCol2 : this.columns
      let tempCol = this.columns
        switch (this.staTKey) {
          case 'summary': col = this.dateCol.concat(tempCol['summary']); break
          case 'summaryTime': col = tempCol['summary']; break
          case 'detail':  col = tempCol['detail']; break
          default: col = []
        }
      return col
    }
  },
  mounted () {
    this.$nextTick(() => {
      let powers = getStorage('user') ? JSON.parse(getStorage('user')).userPower : []
      let powerTab = this.staTab.filter((ele) => {
        return powers.includes(ele.permission[0])
      })
      this.staTKey = this.$route.query.staTKey ? this.$route.query.staTKey : (powerTab.length ? powerTab[0].key : '')
      let regionData = getStorage('regionData') ? JSON.parse(getStorage('regionData')) : []
      regionData.forEach((province) => {
        if (province.children.length) {
          province.children.unshift({
            value: '',
            label: '全部',
            children: []
          })
          province.children.forEach((city) => {
            if (city.children.length) {
              city.children.unshift({
                value: '',
                label: '全部',
                children: []
              })
            }
          })
        }
      })
      this.searchSelectData.regionData = regionData
      this.getSiteData()
      this.getCompanyData()
      if (this.user.userType === 6) {
        this.fieldsVal.companyId = this.user.companyId
      }
      this.searchParams = Object.assign({}, this.fieldsVal[this.staTKey])
      setTimeout(() => {
        this.getData()
      }, 200)
    })
  },
  methods: {
    getData () {
      setTimeout(() => {
        switch (this.staTKey) {
          case 'chart': this.getCharts();break
          case 'summary': this.getList(); break
          case 'summaryTime': this.getListTime(); break
          case 'detail': this.getDetailList(); break
          default:
        }
      }, 0)
    },
    toSearch (obj) {
      this.params.pageIndex = 1
      this.searchParams = obj
      this.getData()
    },
    toReset (obj) {
      this.searchParams = obj
      this.getData()
    },
    getCharts () {
      this.loading = true
      this.chartsObj = echarts.init(document.getElementById('charts-item'))
      let width = this.$refs.table.clientWidth
      let height = this.$refs.table.clientHeight
      this.chartsObj.resize({
        width: width + 'px',
        height: height + 'px'
      })
      this.setChartsData()
    },
    async setChartsData () {
      let xData = []
      let numData = []
      let moneyData = []
      let mdxData = []
      let params = Object.assign({}, this.searchParams, {
        sortColumn: 'orderEndTime',
        sortOrder: 1
      })
      delete params.pageIndex
      delete params.pageSize
      this.chartsOpt.xAxis.data = []
      this.chartsOpt.series[0].data = [] // 总电量
      this.chartsOpt.series[1].data = [] // 消费金额
      await getOrderSummaryChart(params).then((res) => {
        if (res.data.code === 0) {
          this.loading = false
          let data = res.data.data
          this.chartsData = data
          if (data.length) {
            data.forEach((item) => {
              xData.push(dayjs(new Date(item.orderEndTime)).format('YYYY-MM-DD'))
              numData.push((item.totalElectricQuantity) / 10000).toFixed(2)
              moneyData.push((item.consumptionAmount / 10000).toFixed(2))
              mdxData.push((item.mdxNetReceipts / 100).toFixed(2))
            })
          }
          this.chartsOpt.xAxis.data = xData
          this.chartsOpt.series[0].data = numData // 总电量
          this.chartsOpt.series[1].data = moneyData // 消费金额
          this.chartsOpt.series[2].data = mdxData // 满电行实收
          this.chartsObj.setOption(this.chartsOpt)
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    async getList () {
      this.loading = true
      let params = Object.assign({}, this.searchParams, this.params)
      params.startupMode = -1
      await getOrderSummaryStatistics(params).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          this.list = res.data.data.dataSource
          this.total = res.data.data.totalCount
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    async getListTime () {
      this.loading = true
      let params = Object.assign({}, this.searchParams, this.params)
      if (params.orderBy === 'orderEndTime') {
        delete params.orderBy
        delete params.sort
      }
      params.startupMode = -1
      await getOrderSummaryStatisticsTime(params).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          this.list = res.data.data.dataSource
          this.total = res.data.data.totalCount
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    async getDetailList () {
      this.loading = true
      let params = Object.assign({}, this.searchParams, this.params)
      delete params.startDate
      delete params.endDate
      if (!params.startupMode) params.startupMode = -1
      await orderDetailListNew(params).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          this.list = res.data.data.dataSource
          this.total = res.data.data.totalCount
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    sortChange (params) { // 排序
      let sortInd = {
        asc: 'asc', // 升序
        desc: 'descending' // 降序
      }
      this.params.orderBy = params.order !== 'normal' ? params.key : 'orderEndTime'
      this.params.sort = params.order !== 'normal' ? sortInd[params.order] : 'descending'
      this.getData()
    },
    tableSelectChange (selection) { // table选中项发生变化时就会触发
      this.selectedList = selection
    },
    handleSummary (params) { // 处理合计数据
      let sums = {}
      let columns = params.columns
      let data = params.data
      columns.forEach((column, index) => {
        let key = column.key
        if (index === 0) {
          sums[key] = {
            key: key,
            value: '总计'
          }
          return
        }
        const values = data.map(item => Number(item[key]))
          if (!values.every(value => isNaN(value)) && !['userPhone', 'siteName', 'chargeNumber', 'orderStartTime', 'orderEndTime', 'serviceFeeDiscount', 'orderState', 'tipElectricityPrice', 'tipElectricity', 'tipPreferentialUnitPrice', 'peakElectricityPrice', 'peakServiceCharge', 'peakPreferentialUnitPrice', 'averageElectricityPrice', 'averageServiceCharge', 'averagePreferentialUnitPrice', 'valleyElectricityPrice', 'valleyServiceCharge', 'valleyPreferentialUnitPrice', 'userName', 'startupMode'].includes(key)) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            let digit = ['mdxNetReceipts', 'giftBalanceDeduction', 'ykcDeduction', 'kuaiDianPay'].includes(key) ? 100 : 10000
            sums[key] = {
              key,
              value: (v / digit).toFixed(2)
            }
          } else {
            sums[key] = {
              key,
              value: ''
            }
          }
      })
      return sums
    },
    toOpr (type) {
      if (['export', 'exportAll'].includes(type)) {
        this.$Modal.confirm({
          title: '导出',
          content: '确认导出?',
          onOk: () => {
            let data = Object.assign({}, this.searchParams, this.params)
            data.userType = this.user.userType
            if (type === 'exportAll')  {
              delete data.pageSize
              delete data.pageIndex
            }
            if (this.staTKey === 'summary') {
              data.startupMode = -1
              delete data.pageSize
              delete data.pageIndex
            } else if (this.staTKey === 'summaryTime') {
              data.orderState = 3
              if (!data.startupMode) data.startupMode = -1
            } else if (this.staTKey === 'detail') {
              data.queryType = type === 'exportAll' ? 1 : 0
            }
            let url = {
              summary: orderSummaryExportNew,
              summaryTime: orderSummaryExportTime,
              detail: orderDetailExportNew
            }
            let startTime = ''
            let endTime = ''
            let ftStartTime = ''
            let ftEndTime = ''
            startTime = this.searchParams.startDate
            endTime = this.searchParams.endDate
            let dayFmt = 'YYYY-MM-DD'
            ftStartTime = dayjs(new Date(startTime)).format(dayFmt)
            ftEndTime = dayjs(new Date(endTime)).format(dayFmt)

            let title = {
              'summary': '订单月度汇总' + (startTime && endTime ? '-['+ ftStartTime + ' - ' + ftEndTime +']' : '') + '报表',
              'summaryTime': '订单汇总' + (startTime && endTime ? '-['+ ftStartTime + ' - ' + ftEndTime +']' : '') + '报表',
              'detail': '订单明细' + (startTime && endTime ? '-['+ ftStartTime + ' - ' + ftEndTime +']' : '') + '报表'
            }
            this.exportLoading = true
            url[this.staTKey](data).then((res) => {
              if (res.status === 200) {
                this.exportLoading = false
                exportExcel(title[this.staTKey], res.data)
              }
            })
          }
        })
      }
    },
    async getSiteData () {
      getAllSiteList((arr) => {
        this.searchSelectData.siteIds = arr
        this.fieldsVal.siteIds = this.user.siteIds ? (this.user.siteIds.indexOf(',') > -1 ? this.user.siteIds.split(',') : [this.user.siteIds]) : []
      })
    },
    async getCompanyData () {
      getAllCompanyList((arr) => {
        this.searchSelectData.companyId = arr
      })
      let res = await companyQueryGet({CompanyType: 1}) //获取运营商
      if(res.data.code === 0){
        let arr = []
        res.data.data.forEach((item)=>{
          arr.push({
            label: item.companyName,
            value: item.companyID.toString(),
            companyType: item.companyType
          })
        })
        this.searchSelectData.affiliatedCompanyId = arr
      }
    },
    searchChange (val) {
      this.searchParams = val
    },
    toTab (tab) {
      setTimeout(() => {
        getTableHeight()
      }, 200)
      this.staTKey = tab.key
      this.total = 0
      this.fieldsVal[this.staTKey].siteIds = this.user.siteIds ? (this.user.siteIds.indexOf(',') > -1 ? this.user.siteIds.split(',') : [this.user.siteIds]) : []
      this.params = {
        orderBy: 'orderEndTime',
        sort: 'descending',
        pageSize: 20,
        pageIndex: 1
      }
      this.list = []
      this.getData()
    },
    toDetail (row) {
      this.staTKey = 'detail'
      this.loading = true
      let startTime = dayjs(new Date(row.orderEndTime)).format('YYYY-MM-DD') + ' 00:00:00'
      let endTime = dayjs(new Date(row.orderEndTime)).format('YYYY-MM-DD') + ' 23:59:59'
      this.fieldsVal[this.staTKey].orderStartTime = startTime
      this.fieldsVal[this.staTKey].orderEndTime = endTime
      this.searchParams.orderStartTime = startTime
      this.searchParams.orderEndTime = endTime
      delete this.searchParams.yearMonth
      this.getDetailList()
    },
    pageChange (pageIndex) {
      this.params.pageIndex = pageIndex
      this.getData()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getData()
    },
  },
  watch: {
    searchParams: {
      handler (val) {
        if (Array.isArray(val.siteIds)) {
          if (val.siteIds.length) {
            val.siteIds = val.siteIds.join(',')
          } else {
            val.siteIds = this.user.siteIds
          }
        } else {
          if (!val.siteIds) {
            val.siteIds = this.user.siteIds
          }
        }
        if ([6].includes(this.user.userType)) {
          val.companyId = val.companyId || this.user.companyId
        }
        val.yearMonth && (val.yearMonth = dayjs(new Date(val.yearMonth)).format('YYYY-MM'))
        if (val.regionData && val.regionData.length) {
          val.siteProvinceId = val.regionData[0]
          val.regionData[1] && (val.siteCityId = val.regionData[1])
          val.regionData[2] && (val.siteAreaId = val.regionData[2])
        }
        delete val.regionData
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "statistics";
</style>
