<template>
  <div class="table-expand">
    <ul class="electric-list" v-for="(item, index) in list" :key="index">
      <li>{{index + 1}}</li>
      <li><label>电枪编号:</label><span>{{item.electricCode}}</span></li>
      <li><label>电枪状态:</label><span>{{getSelectLabel(electricStateOpt, item.ElectricState)}}</span></li>
      <li><label>电枪是否已经归位:</label><span>{{getSelectLabel(electricLinkStatusOpt, item.ElectricLinkStatus)}}</span></li>
      <li><label>是否插枪:</label><span>{{getSelectLabel(electricLinkStatusOpt, item.CarLinkStatus)}}</span></li>
      <li><a href="javascript:;" @click="toEleOpr('qr', item)" v-if="row.chargeBrand === 0" v-has-permi="['PRIV_CHARGE_SEND'  ]">下发二维码</a></li>
      <li><a href="javascript:;" style="margin-left:140px" v-has-permi="['PRIV_CHARGE_UPDATE']"><span @click="Update(item)">编辑</span></a></li>
    </ul>
  </div>
</template>

<script>
import { getEleByChargeId, getRealData, setQRCode } from '@/libs/api'
import { electricStateOpt, electricLinkStatusOpt } from '@/libs/dict'
import { getSelectLabel } from '@/libs/tools'

export default {
  name: "index",
  props: {
    row: Object
  },
  data () {
    return {
      electricStateOpt,
      electricLinkStatusOpt,
      eleTimer: null,
      list: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getList()
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.eleTimer) // 清除定时器
        this.eleTimer = null
      })
    })
  },
  methods: {
    getSelectLabel,
    async getList () {
      await getEleByChargeId({
        chargeId: this.row.chargeId
      }).then((res) => {
        if (res.data.code === 0) {
          this.list = res.data.data.ElectricManagement
          this.getEleRealData()
          this.eleTimer = setInterval(() => {
            this.getEleRealData()
          }, 10000)
        }
      })
    },
    getEleRealData (list) {
      list = list || this.list
      if (list.length) {
        list.forEach((item) => {
          let number = item.electricCode.slice(-1)
          getRealData({
            chargeNumber: this.row.chargeNumber,
            gunNumber: number
          }).then((res) => {
            if (res.data.code === 0) {
              let data = res.data.data
              Object.keys(data).forEach((key) => {
                this.$set(item, key, data[key])
              })
            }
          })
        })
      }

    },
    toEleOpr (type, item) {
      if (type === 'qr') { // 下发二维码
        this.$Modal.confirm({
          title: '下发二维码',
          content: '是否执行下发二维码命令?',
          onOk: async ()=> {
            await setQRCode({
              chargeNumber: this.row.chargeNumber,
              qrCodeUrl: 'https://www.mdxxny.cn/api/WxQRCode/GetCheckWxCallback/' + item.electricCode,
              gunNumber: Number(item.electricCode.split('-')[1])
            }).then((res) => {
              if (res.data.code === 0){
                this.$Message.success('操作成功')
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      }
    },
    Update (item){//修改电枪
      this.$emit('Update',item)
    }
  }
};
</script>

<style scoped lang="less">
.electric-list{
  display: flex;
  li{
    display: flex;
    margin-bottom: @base * 2;
    label, span{
      display: block;
    }
    label{
      width: 140px;
      text-align: right;
      padding-right: @base;
    }
    span{
      width: 150px;
    }
  }
}
</style>
