<template>
  <div class="fee-wrapper">
    <search-form :fields="searchFields" @on-search="toSearch" @on-reset="toReset" :okLoading="loading"></search-form>
    <div class="table-top">
      <Button  @click="toOpr('add')" v-has-permi="['PRIV_FEE_CREATE']"><Icon type="md-add"/> 新增</Button>
      <Button @click="toOpr('import')" v-has-permi="['PRIV_FEE_IMPORT']"><Icon type="ios-cloud-upload-outline" /> 导入</Button>
    </div>
    <div class="fee-content" :style="{height: tableHeight + 'px'}">
      <Table :columns="feeCols" :height="tableHeight" :data="list" ref="table" :loading="loading"></Table>
    </div>
    <Page :total="total" :current="params.pageNo" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
    <Modal :title="modalParams.title"
           v-model="showModal"
           width="900"
           class="modal-detail modal-form"
           class-name="vertical-center-modal charge-rate-modal"
           :mask-closable="false">
      <div slot="footer">
        <Button @click="modalCancel">取消</Button>
        <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :model="modalFormData" ref="modalForm" :label-width="120" :rules="modalRuleValid" v-if="showModal">
        <div class="modal-box">
          <div class="title">基本信息</div>
          <div class="form-content">
              <FormItem label="费率编号: " prop="rateCode" class="half">
                <Input type="number" v-model="modalFormData.rateCode" />
              </FormItem>
              <FormItem label="费率组名称: " prop="rateName" class="half">
                <Input v-model="modalFormData.rateName" />
              </FormItem>
              <FormItem label="费率类型" prop="rateType" class="half">
                <Select v-model="modalFormData.rateType">
                  <Option v-for="(opt, oInd) in rateTypeOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
                </Select>
              </FormItem>
              <FormItem label="是否有效: " prop="rateIsValid" class="half">
                <RadioGroup v-model="modalFormData.rateIsValid">
                  <Radio v-for="(item, index) in isValidOpt" :key="index" :label="item.value">{{item.label}}</Radio>
                </RadioGroup>
              </FormItem>
              <FormItem label="计损比例: " prop="lossCalculation" class="half">
                <Input v-model="modalFormData.lossCalculation" />
              </FormItem>
            <FormItem label="所属站点" prop="siteIds" class="long">
              <Select v-model="modalFormData.siteIds" multiple @on-change="siteChange" :label-in-value="true" :filterable="true">
                <Option v-for="(opt, oInd) in siteList" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
              <FormItem label="所属会员" prop="memberIds" class="long">
                <Select v-model="modalFormData.memberIds" multiple @on-change="memberChange" :label-in-value="true">
                  <Option v-for="(opt, oInd) in memberList" :key="oInd" :value="opt.value" :disabled="[1,2,6].includes(opt.value)">{{opt.label}}</Option>
                </Select>
              </FormItem>
              <FormItem label="备注: " prop="rateRemarke" class="long">
                <Input v-model="modalFormData.rateRemarke" type="textarea" />
              </FormItem>
          </div>
        </div>
        <div class="modal-box">
          <div class="title">价格设置
            <p class="hint" v-if="modalFormData.rateType === 0">温馨提示：价格时段设置48个时段，价格费用支持4组</p>
          </div>
          <div class="form-content">
            <p class="sub-title">价格类别</p>
            <div class="price-member-tab">
              <ul class="member-ul">
                <li v-for="(tab, tInd) in memberTab" :key="tInd" :class="{active: tInd === memberTabInd}" @click="toMemberTab(tab, tInd)">{{tab.label}}</li>
              </ul>
            </div>
            <template v-if="memberTab.length">
              <template v-for="(tab, tInd) in memberTab">
                <div v-show="tInd === memberTabInd" :key="tab.value + '' + tInd">
                  <div style="width: 100%;display: flex;flex-wrap: wrap;">
                    <template v-for="(cate) in priceCategory">
                      <FormItem :key="cate.type + '0'" label="类别名称:" :label-width="90">
                        <Input v-model="cate.name" disabled />
                      </FormItem>
                      <FormItem :key="cate.type + '1'"
                                label="价格:"
                                required
                                :prop="'category.' + tInd + '.MembershipRate.' + cate.key2 + 'ElectricityPrice'"
                                :rules="[{required: true, message: '请输入服务费'}, priceValid]"
                                :label-width="90">
                        <Input v-model="modalFormData.category[tInd].MembershipRate[cate.key2 + 'ElectricityPrice']" @on-change="priceChange(cate, tInd, cate.key2)">
                          <label slot="append">元/度</label>
                        </Input >
                      </FormItem>
                      <FormItem  :key="cate.type + '2'"
                                 required
                                 label="服务费:"
                                 :prop="'category.' + tInd + '.MembershipRate.' + cate.key2 + 'ServiceCharge'"
                                 :rules="[{required: true, message: '请输入服务费'}, priceValid]"
                                 :label-width="90">
                        <Input v-model="modalFormData.category[tInd].MembershipRate[cate.key2 + 'ServiceCharge']" @on-change="serviceChange(cate, tInd, cate.key2)">
                          <label slot="append">元/度</label>
                        </Input>
                      </FormItem>
                    </template>
                    <FormItem label="会员折扣:"
                              :prop="'category.' + tInd + '.MemberLevelDiscount'"
                              :rules="[{required: true, message: '请输入会员折扣'}]"
                              :label-width="90">
                      <InputNumber :step="0.01" v-model="modalFormData.category[tInd].MemberLevelDiscount">
                        <label slot="append">折</label>
                      </InputNumber >
                    </FormItem>
                  </div>
                </div>
              </template>
            </template>
            <p class="sub-title">价格设置</p>
            <template v-if="modalFormData.priceCurrent.length">
              <div v-for="(price, pInd) in modalFormData.priceCurrent" :key="pInd" class="form-item-box">
                <FormItem :prop="'priceCurrent.' + pInd + '.startTime'"
                          required
                          label=" "
                          class="four"
                          :rules="{required: true, message: '请选择开始时间'}"
                          :label-width="90">
                  <Select v-model="price.startTime"
                          @on-change="timeChange(pInd, 'startTime')"
                          placeholder="请选择开始时间">
                    <Option :value="time" v-for="(time, tInd) in timeOptions"
                            :key="tInd"
                            :disabled="getTimeSts(time, pInd, 'start')"
                    >{{time}}</Option>
                  </Select>
                </FormItem>
                <FormItem :prop="'priceCurrent.' + pInd + '.endTime'"
                          required
                          label=" "
                          class="four"
                          :rules="{required: true, message: '请选择结束时间'}"
                          :label-width="90">
                  <Select v-model="price.endTime"
                          @on-change="timeChange(pInd, 'endTime')"
                          placeholder="请选择结束时间">
                    <Option :value="time"
                            v-for="(time, tInd) in timeOptions"
                            :key="tInd"
                            :disabled="getTimeSts(time, pInd, 'end')">{{time}}</Option>
                  </Select>
                </FormItem>
                <FormItem :prop="'priceCurrent.' + pInd + '.priceType'"
                          class="half"
                          required
                          label=" "
                          :rules="{required: true, message: '请选择价格类别', trigger: 'change'}"
                          :label-width="90">
                  <Select v-model="price.priceType"
                          @on-open-change="priceTypeOpen"
                          placeholder="请选择价格类别">
                    <Option v-for="(type, tInd) in priceCategory" :key="tInd" :value="type.key">
                      {{type.name}}:电费【{{getDefaultNumber(type.key2 + 'ElectricityPrice')}}元】 服务费【{{getDefaultNumber(type.key2 + 'ServiceCharge')}}元】
                    </Option>
                  </Select>
                </FormItem>
                <Icon type="md-remove-circle" class="icon-del" v-if="pInd !== 0" @click="toDelTime(pInd)"/>
              </div>
            </template>
              <div class="form-item-box" v-if="showSurplus">
                <FormItem class="half" :label-width="90">
                  <Input placeholder="剩余时段价格同意选择" disabled />
                </FormItem>
                <FormItem prop="defPriceType"
                          class="half"
                          :label-width="90"
                          label=" "
                          required
                          :rules="{required: true, message: '请选择价格类别', trigger: 'change'}">
                  <Select v-model="modalFormData.defPriceType">
                    <Option v-for="(type, tInd) in priceCategory" :key="tInd" :value="type.key">
                      {{type.name}}:电费【{{getDefaultNumber(type.key2 + 'ElectricityPrice')}}元】 服务费【{{getDefaultNumber(type.key2 + 'ServiceCharge')}}元】
                    </Option>
                  </Select>
                </FormItem>
              </div>
              <div class="form-item-box" v-if="showSurplus">
                <FormItem :label-width="90">
                  <Icon type="md-add-circle" class="icon-add" @click="toAddTime"/> 添加时间区间
                </FormItem>
              </div>
          </div>
        </div>
      </Form>
    </Modal>
    <Modal v-model="showSendFee" title="提示" :closable="false">
      <div slot="footer">
        <Button @click="sendFeeCancel">取消</Button>
        <Button type="primary" @click="sendFeeOk" :disabled="feeLoading" :loading="feeLoading">确定</Button>
      </div>
      <p>操作成功，是否下发费率?</p>
    </Modal>
    <Modal v-model="showFeeImport"
           title="导入"
           :footer-hide="true"
           class="modal-detail modal-form"
           class-name="vertical-center-modal">
      <Form :label-width="120">
        <div class="form-content">
<!--          <FormItem label="下载模板" class="long">
            <a href="">费率模板.xlsx</a>
          </FormItem>-->
          <FormItem label="文件导入" class="long">
            <UploadNew name="excelFile" v-model="importForm.file" :cfg="{ type: 'file', action: '/api/ChargeRates/UploadExcel', accept: '.xls, .xlsx', maxSize: 20480, count: 1}"></UploadNew>
          </FormItem>
        </div>
      </Form>
    </Modal>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import UploadNew from '@/components/UploadNew'
import { mapState } from 'vuex'
import { isValidOpt, rateTypeOpt } from '@/libs/dict'
import dayjs from 'dayjs'
import { chargeRatesAdd, chargeRatesUpdate, chargeRatesQuery, chargeRatesUpdateState, updateRateIdBySiteIs, chargeRateImport } from '@/libs/api'
import { resolvePriceRange, getSelectLabel } from '@/libs/tools'
import { getAllMemberList, getAllSiteList } from '@/libs/requestCommon';
let digits = 5
let formData = {
  chargeId: '',
  chargeIpPort: '',
  chargeNumber: '',
  rateCode: '0000',
  rateName: '',
  rateType: 0,
  rateRemarke: '',
  frontEndRateDetails: '',
  frontEndPeriodRateNo: '',
  lossCalculation: '00',
  rateIsValid: 2,
  category: [],
  priceCurrent: [{
    startTime: '00:00',
    endTime: '24:00',
    priceType: '02'
  }],
  defPriceType: '00',
  numberOfPeriods: 0,
  memberIds: [],
  siteMembershipRateParmaters: [],
  siteIds: [],
  siteName: [],
  ordinaryRateDetails: '',
  goldRateDetails: ''
}
export default {
  name: "price-set",
  components: {
    SearchForm,
    UploadNew
  },
  data () {
    return {
      isValidOpt,
      rateTypeOpt,
      loading: false,
      searchFields: [
        {
          label: '费率编号',
          type: 'input',
          name: 'rateCode'
        },
        {
          label: '费率名称',
          type: 'input',
          name: 'rateName'
        },
        {
          label: '是否有效',
          type: 'select',
          name: 'rateIsValid',
          options: isValidOpt
        }
      ],
      searchParams: {},
      params: {
        pageSize: 10,
        pageNo: 1
      },
      total: 0,
      feeCols: [
        {
          key: 'index',
          title: '序号',
          align: 'center',
          width: 80,
          render: (h, params) => {
            let ind = params.index + 1 + (this.params.pageNo - 1) * this.params.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'rateCode',
          title: '费率组编号',
          align: 'center',
          width: 150
        },
        {
          key: 'siteName',
          title: '所属电站',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let str = params.row.siteName ? params.row.siteName : '--'
            return (<span>{str}</span>)
          }
        },
        {
          key: 'rateName',
          title: '费率组名称',
          width: 250
        },
        {
          key: 'rateIsValid',
          title: '是否有效',
          align: 'center',
          width: 100,
          render: (h, params) => {
            let val = {
              2: '是',
              1: '否'
            }
            return (
              <span class={(params.row.rateIsValid === 2 ? 'open' : 'close') + ' rate-state'}>{val[params.row.rateIsValid]}</span>
            )
          }
        },
        {
          key: 'rateType',
          title: '费率类型',
          width: 150,
          render: (h, params) => {
            let str = getSelectLabel(rateTypeOpt, params.row.rateType)
            return (<span>{str}费率</span>)
          }
        },
        {
          key: 'rateRemarke',
          title: '备注',
          align: 'center',
          minWidth: 200
        },
        {
          key: 'opr',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 240,
          render: (h, params) => {
            let obj = {
              1: ['启用', 'icon-qiyong', 'open'],
              2: ['停用', 'icon-tingyong_line', 'close']
            }
            return (
              <span class="table-opr-btn">
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'mod', params.row)} v-has-permi={['PRIV_FEE_UPDATE']}><i class="iconfont icon-bianji"></i> 编辑</a>
                <a href="javascript:;" class={obj[params.row.rateIsValid][2]} onClick={this.toOpr.bind(this, obj[params.row.rateIsValid][2], params.row)} v-has-permi={params.row.rateIsValid === 1 ? ['PRIV_FEE_OPEN'] : ['PRIV_FEE_CLOSE']}>
                  <i class={'iconfont ' + obj[params.row.rateIsValid][1]}></i> {obj[params.row.rateIsValid][0]}
                </a>
              </span>
            )
          }
        }
      ],
      list: [],
      oprType: 'add',
      showModal: false,
      modalParams: {
        title: '新增'
      },
      modalFormData: Object.assign({}, formData),
      modalRuleValid: {
        rateCode: [
          {required: true, message: '请输入费率编号'},
          { validator: (rule, value, callback) => {
             let len = value.length
              if (len > 16) {
                callback(new Error('编号最多不能超过16位'))
              } else {
                callback()
              }
           }, trigger: 'blur'}
        ],
        rateName: [{required: true, message: '请输入费率组名称'}],
        rateType: [{required: true, message: '请选择费率类型'}],
        lossCalculation: [{required: true, message: '请输入计损比例'}],
        memberIds: [{required: true, message: '请选择所属会员'}]
      },
      priceValid: { validator: (rule, value, callback) => {
        let reg = /^(([1-9]\d*)|0)(\.\d{1,5})?$/
        if (!reg.test(value)) {
          callback('请输入小数点最多5位的数字')
        } else {
          callback()
        }
        }, trigger: 'change'},
      priceCategory: [
        {
          name: '尖',
          type: 'high',
          key: '00',
          key2: 'Tip',
          price: null,
          service: null
        },
        {
          name: '峰',
          type: 'peak',
          key: '01',
          key2: 'Peak',
          price: null,
          service: null
        },
        {
          name: '平',
          type: 'flat',
          key: '02',
          key2: 'Average',
          price: null,
          service: null
        },
        {
          name: '谷',
          type: 'ebb',
          key: '03',
          key2: 'Valley',
          price: null,
          service: null
        }
      ],
      priceFields: {
        startTime: '',
        endTime: '',
        priceType: ''
      },
      timeOptions: [],
      showSurplus: false,
      modData: {},
      siteList: [],
      memberList: [],
      memberTab: [],
      memberTabInd: 0,
      btnLoading: false,
      showSendFee: false,
      feeLoading: false,
      sendRateId: '',
      showFeeImport: false,
      importForm: {
        file: ''
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      for (let i = 0; i <= 24; i++) {
        let it = i < 10 ? '0' + i : i
        if (i === 24) {
          this.timeOptions.push(it + ':00')
        } else {
          this.timeOptions.push(it + ':00', it + ':30')
        }
      }
      this.getList()
      this.getMemberList()
      this.getSiteList()
    })
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  methods: {
    async getList () {
      this.loading = true
      await chargeRatesQuery(Object.assign({}, this.searchParams, this.params)).then((res) => {
        if (res.data.code === 0) {
          this.loading = false
          this.list = res.data.data.ChargeRates.data
          this.total = res.data.data.ChargeRates.total
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    pageChange (pageNo) {
      this.params.pageNo = pageNo
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    toSearch (obj) {
      this.searchParams = obj
      this.params.pageNo = 1
      this.getList()
    },
    toReset (obj) {
      this.searchParams = obj
      this.getList()
    },
    refreshList () {
      this.params.pageNo = 1
      this.getList()
    },
    toOpr (type, row) {
      this.oprType = type
      if (row) {
        this.modData = row
      }
      if (['add', 'mod'].includes(type)) {
        this.modalParams.title = type === 'add' ? '新增' : '修改'
        this.showModal = true
      } else if (['open', 'close'].includes(type)) { //  启用/停用
        let value = row.rateIsValid
        let state = {
          1: [2, '启用'],
          2: [1, '停用']
        }
        this.$Modal.confirm({
          title: state[value][1],
          content: `是否${state[value][1]}该条费率组?`,
          onOk: async () => {
            await chargeRatesUpdateState({
              rateIsValid: state[value][0],
              rateId: row.rateId
            }).then((res) => {
              if (res.data.code === 0) {
                this.$Message.success('操作成功')
                this.refreshList()
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      } else if (type === 'import') { // 导入
        this.showFeeImport = true
      }
    },
    initCategoryForm () {
      this.modalFormData.category = []
      if (this.oprType === 'add') {
        let arr = []
        let arrIds = []
        let catePriceObj = {}
        this.priceCategory.forEach((cate) => {
          catePriceObj[cate.key2 + 'ElectricityPrice'] = ''
          catePriceObj[cate.key2 + 'ServiceCharge'] = ''
        })
        this.memberTab = []
        this.memberList.forEach((member) => {
           if ([6, 1, 2].includes(member.value)) { // 普通用户、普通会员、黄金会员
             arr.push({
               MembershipLevel: member.value, // 默认普通用户
               MemberLevelDiscount: 0,
               MembershipRate: catePriceObj
             })
             arrIds.push(member.value)
             this.memberTab.push(member)
             return false
           }
        })
        let timeRangeArr = []
        let rateNo = '0400000007030007001102001100120300120014020014002103002100230400230024' // 默认价格时间段
        for (let i  = 0; i < rateNo.length; i +=10) {
          let str = rateNo.slice(i, i + 10)
          let start = str.slice(2, 6)
          let end = str.slice(6, 10)
          let price = '0' + (str.slice(0, 2) - 0 - 1)
          timeRangeArr.push({
            startTime: start.slice(2, 4) + ':' + start.slice(0, 2),
            endTime: end.slice(2, 4) + ':' + end.slice(0, 2),
            priceType: price
          })
        }
        this.modalFormData.priceCurrent = timeRangeArr
        this.$set(this.modalFormData, 'category', arr)
        this.modalFormData.memberIds = arrIds
      } else if (this.oprType === 'mod') { // 修改赋值
        let row = this.modData
        Object.keys(this.modalFormData).forEach(key => {
          if (['siteIds'].includes(key)) {
            this.modalFormData[key] = row[key] ? row[key].split(',') : []
          } else {
            row[key] && (this.modalFormData[key] = row[key])
          }
        })
        if (!row.siteMembershipRate) { // 兼容以前费率格式
          let cateTempArr = row.frontEndRateDetails.split(',') // 价格类别
          let cateAllArr= []
          for (let i  = 0; i < cateTempArr.length; i += 2) {
            cateAllArr.push([
              ((cateTempArr[i] - 0)/ 100000).toFixed(digits),
              ((cateTempArr[i + 1] - 0) / 100000).toFixed(digits)
            ])
          }
          let arr = []
          let catePriceObj = {}
          this.priceCategory.forEach((cate, cInd) => {
            let cateItemVal = cateAllArr[cInd]
            catePriceObj[cate.key2 + 'ElectricityPrice'] = cateItemVal[0]
            catePriceObj[cate.key2 + 'ServiceCharge'] = cateItemVal[1]
          })
          arr.push({
            MembershipLevel: this.memberList[0].value, // 普通用户
            MemberLevelDiscount: 0,
            MembershipRate: catePriceObj
          })
          this.$set(this.modalFormData, 'category', arr)
          this.memberTab = [this.memberList[0]]
          this.modalFormData.memberIds = [this.memberList[0].value]
        } else {
          let arr = []
          let rateFeeArr = JSON.parse(row.siteMembershipRate)
          let tabList = []
          let memberIds = []
          rateFeeArr.forEach((fee) => {
           memberIds.push(fee.MembershipLevel)
            let catePriceObj = {}
            this.priceCategory.forEach((cate) => {
              let price = ((fee.MembershipRate[cate.key2 + 'ElectricityPrice'] - 0) / 100000).toFixed(digits)
              let service = ((fee.MembershipRate[cate.key2 + 'ServiceCharge'] - 0) / 100000).toFixed(digits)
              catePriceObj[cate.key2 + 'ElectricityPrice'] = price
              catePriceObj[cate.key2 + 'ServiceCharge'] = service
            })
            arr.push({
              MembershipLevel: fee.MembershipLevel,
              MemberLevelDiscount: (fee.MemberLevelDiscount / 100).toFixed(2) - 0,
              MembershipRate: catePriceObj
            })
          })
          this.memberList.forEach((tab) => {
            if (memberIds.indexOf(tab.value) > -1) {
              tabList.push(tab)
            }
          })
          this.$set(this.modalFormData, 'category', arr)
          this.memberTab = tabList
          this.modalFormData.memberIds = memberIds
        }
        let timeRangeArr = []
        if (row.rateType === 0 || row.rateType === 2) { // 云快充/万马基础
          let timeRangeTempArr = resolvePriceRange(row.frontEndPeriodRateNo)
          if (timeRangeTempArr.length) {
            timeRangeTempArr.forEach((item) => {
              let price = row.rateType === 0 ? item.value : '0' + (item.value - 0 - 1)
              timeRangeArr.push({
                startTime: this.timeOptions[item.start],
                endTime: this.timeOptions[item.end + 1],
                priceType: price
              })
            })
          }
        } else if (row.rateType === 1) { // 万马扩展
          let rateNo = row.frontEndPeriodRateNo
          for (let i  = 0; i < rateNo.length; i +=10) {
            let str = rateNo.slice(i, i + 10)
            let start = str.slice(2, 6)
            let end = str.slice(6, 10)
            let price = '0' + (str.slice(0, 2) - 0 - 1)
            timeRangeArr.push({
              startTime: start.slice(2, 4) + ':' + start.slice(0, 2),
              endTime: end.slice(2, 4) + ':' + end.slice(0, 2),
              priceType: price
            })
          }
        }
        this.modalFormData.priceCurrent = timeRangeArr
      }
    },
    priceChange (item, index, key) {
      this.$set(item, 'price', this.modalFormData.category[index].MembershipRate[key + 'ElectricityPrice'])
      this.$refs.modalForm.validateField('category.' + index + '.MembershipRate.' + key + 'ElectricityPrice')
    },
    serviceChange (item, index, key) {
      this.$set(item, 'service', this.modalFormData.category[index].MembershipRate[key + 'ServiceCharge'])
      this.$refs.modalForm.validateField('category.' + index + '.MembershipRate.' + key + 'ServiceCharge')
    },
    toAddTime () {
      this.modalFormData.priceCurrent.push(Object.assign({}, this.priceFields))
    },
    toDelTime (index) {
      this.modalFormData.priceCurrent.splice(index, 1)
    },
    getTimeSts (item, index, type) { // 价格设置中时间选项的是否可选
      let flag = false
      let currentForm = this.modalFormData.priceCurrent
      let nowDay = dayjs(new Date).format('YYYY-MM-DD')
      let currentTime = dayjs(nowDay + ' ' + item).unix()
      if (type === 'start') { // 开始时间
        let curEndTime = currentForm[index].endTime
        let currentEt = curEndTime ? dayjs(nowDay + ' ' + curEndTime).unix() : ''
        let prevEndTime = index > 0  ? currentForm[index - 1].endTime : ''
        let prevEt = prevEndTime ? dayjs(nowDay + ' ' + prevEndTime).unix() : ''
        if (prevEt && (currentTime < prevEt) || (currentEt && currentTime >= currentEt)) { // 小于上一个结束时间/大于等于当前结束时间
          flag = true
        }
      } else if (type === 'end') { // 结束时间
        let curStartTime = currentForm[index].startTime
        let currentSt = curStartTime ? dayjs(nowDay + ' ' + curStartTime).unix() : ''
        let nextStartTime = currentForm[index + 1] ? currentForm[index + 1].startTime : ''
        let nextSt = nextStartTime ? dayjs(nowDay + ' ' + nextStartTime).unix() : ''
        if (currentSt && currentTime <= currentSt || (nextSt && currentTime > nextSt)) { // 小于等于当前开始时间/大于下一个开始时间
          flag = true
        }
      }
      return flag
    },
    timeChange (index, name) {
      this.$refs.modalForm.validateField('priceCurrent.' + index + '.' + name)
      let len = this.getTimeLength()
      if (len >= 48) {
        this.modalFormData.priceCurrent[index + 1] && this.modalFormData.priceCurrent.splice(-1)
      }
    },
    getTimeLength (val) { // 获取时段内总长度
      val = val || this.modalFormData.priceCurrent
      let len = 0
      val.forEach((item) => {
        let nowDay = dayjs(new Date).format('YYYY-MM-DD')
        let time1 = item.startTime ? dayjs(nowDay + ' ' + item.startTime) : ''
        let time2 = item.endTime ? dayjs(nowDay + ' ' + item.endTime) : ''
        if (time1 && time2) {
          let diff = time2.diff(time1, 'minute')
          len += diff / 30
        }
      })
      return len
    },
    priceTypeOpen () {
      this.$refs.modalForm.validate((valid) => {
        if (valid) {
          console.log('')
        }
      })
    },
    modalCancel () {
      this.showModal = false
    },
    modalOk () {
      this.$refs.modalForm.validate(async (valid) => {
        if (valid) {
          let cateArr = []
          let cateRateOne = [] // 普通用户
          // let cateRateTwo = [] // 普通会员
          // let cateRateThree = [] // 黄金会员
          this.modalFormData.category.forEach((cate, index) => {
            let priceObj = {}
            Object.keys(cate.MembershipRate).forEach((key) => {
              priceObj[key] = (cate.MembershipRate[key] * 100000).toFixed(0)
            })
            if ([0, 1, 2].includes(index)) {
              let pArr = [
                priceObj.TipElectricityPrice,
                priceObj.TipServiceCharge,
                priceObj.PeakElectricityPrice,
                priceObj.PeakServiceCharge,
                priceObj.AverageElectricityPrice,
                priceObj.AverageServiceCharge,
                priceObj.ValleyElectricityPrice,
                priceObj.ValleyServiceCharge
              ]
              index === 0 && (cateRateOne = pArr) // 普通用户
              // index === 1 && (cateRateTwo = pArr) // 普通会员
              // index === 2 && (cateRateThree = pArr) // 黄金会员
            }
            cateArr.push({
              MembershipLevel: cate.MembershipLevel,
              MemberLevelDiscount: (cate.MemberLevelDiscount * 100).toFixed(0),
              MembershipRate: priceObj
            })
          })
          this.modalFormData.siteMembershipRateParmaters = cateArr
          this.modalFormData.frontEndRateDetails = cateRateOne.join(',')
          // this.modalFormData.ordinaryRateDetails = cateRateTwo.join(',')
          // this.modalFormData.goldRateDetails = cateRateThree.join(',')
          this.modalFormData.frontEndRateDetailsActivity = cateRateOne.join(',')
          if (this.modalFormData.rateType === 0 || this.modalFormData.rateType === 2) { // 云快充/万马基础
            let str = this.dealTimeDetails()
            this.modalFormData.frontEndPeriodRateNo = str.join('')
            this.modalFormData.numberOfPeriods = str.length
          } else if (this.modalFormData.rateType === 1) { // 万马扩展
            if (Number(this.modalFormData.rateCode) < 1) {
              this.$Message.error('费率编号不能全为0')
              return false
            }
            let str = this.dealWMTimeDetails()
            this.modalFormData.frontEndPeriodRateNo = str.join('')
            this.modalFormData.numberOfPeriods = str.length
          }
          if (!this.modalFormData.frontEndRateDetails || !this.modalFormData.frontEndPeriodRateNo) {
            this.$Message.error('请设置价格参数')
            return
          }
          let formData ={
            rateCode: this.modalFormData.rateCode,
            rateName: this.modalFormData.rateName,
            rateRemarke: this.modalFormData.rateRemarke,
            frontEndRateDetails: this.modalFormData.frontEndRateDetails,
            frontEndPeriodRateNo: this.modalFormData.frontEndPeriodRateNo,
            frontEndRateDetailsActivity: this.modalFormData.frontEndRateDetailsActivity,
            lossCalculation: this.modalFormData.lossCalculation,
            rateIsValid: this.modalFormData.rateIsValid,
            rateType: this.modalFormData.rateType,
            numberOfPeriods: this.modalFormData.numberOfPeriods,
            siteIds: this.modalFormData.siteIds.join(','),
            siteName: this.modalFormData.siteName.join(','),
            siteMembershipRateParmaters: this.modalFormData.siteMembershipRateParmaters,
          }
          let url = ''
          if (this.oprType === 'add') {
            url = chargeRatesAdd
          } else if (this.oprType === 'mod') {
            url = chargeRatesUpdate
            formData.rateId = this.modData.rateId
          }
          this.btnLoading = true
          await url(formData).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              if (this.modalFormData.siteIds.length) {
                this.sendRateId = res.data.data.rateId
                this.showSendFee = true
              } else {
                this.$Message.success('操作成功')
                this.refreshList()
                this.showModal = false
              }
            } else {
              this.$Message.error(res.data.message)
            }
          }).catch(() => {
            this.btnLoading = false
          })
        }
      })
    },
    sendFeeCancel () {
      this.refreshList()
      this.showModal = false
      this.showSendFee = false
    },
    sendFeeOk () {
      this.feeLoading = true
      updateRateIdBySiteIs({
        siteIds: this.modalFormData.siteIds.join(','),
        rateId: this.sendRateId
      }).then((res2) => {
        this.feeLoading = false
        if (res2.data.code === 0) {
          this.showSendFee = false
          let obj = res2.data.data
          let arr = []
          if (Object.keys(obj).length) {
            Object.keys(obj).forEach((key) => {
              if (!isNaN(key - 0)) {
                arr.push('电桩' + key + ':' + obj[key])
              }
            })
          }
          let str = ''
          if (arr.length) {
            arr.forEach((iMsg) => {
              let iArr = iMsg.split(':')
              let flag = iArr[1].slice(0, 1)
              str += `<p style="width: 100%;line-height:1.5;word-break: break-all">
                        ${iArr[0]}
                        <span style="color: ${flag === '1' ? 'green' : 'red'}">${iArr[1]}</span>
                        ${iArr[2]}
                    </p>`
            })
          }
          this.$Modal.info({
            title: '提示',
            closable: false,
            width: 600,
            content: `<div>${str}</div>`,
            onOk: () => {
              this.refreshList()
              this.showModal = false
            }
          })
        } else {
          this.$Message.error(res2.data.message)
        }
      }).catch(() => {
        this.feeLoading = false
      })
    },
    dealTimeDetails () { // 云快充处理时间段价格(48组)
      let arr = []
      let timeAllObj = []
      let timeSetObj = {}
      let nowDay = dayjs(new Date()).format('YYYY-MM-DD')
       this.modalFormData.priceCurrent.forEach((item) => {
         let time1 = item.startTime ? dayjs(nowDay + ' ' + item.startTime) : ''
         let time2 = item.endTime ? dayjs(nowDay + ' ' + item.endTime) : ''
         let diff = time2.diff(time1, 'minute')
         let len = diff / 30
         for (let i = 0; i < len; i++) {
           let sl = dayjs(new Date(time1)).add(30 * i, 'minute').format('HH:mm')
           let el = dayjs(new Date(time1)).add(30 * (i + 1), 'minute').format('HH:mm')
           if (el === '00:00') el = '24:00'
           let name = sl + ' - ' + el
           let price = this.modalFormData.rateType === 0 ? item.priceType : ('0' + (Number(item.priceType) + 1))
           timeSetObj[name] = price
         }
        })
        for (let i = 0; i < this.timeOptions.length - 1; i++) {
          let name = this.timeOptions[i] + ' - ' + this.timeOptions[i+1]
          let value = timeSetObj[name] ? timeSetObj[name] : this.modalFormData.defPriceType
          timeAllObj.push({
            name: name,
            value: value
          })
          arr.push(value)
        }
        // console.log(timeSetObj)
        // console.log(timeAllObj)
        // console.log(arr)
      return arr
    },
    dealWMTimeDetails () { // 万马处理时间段价格
      let arr = []
      this.modalFormData.priceCurrent.forEach((item) => {
        let price = '0' + (item.priceType - 0 + 1)
        let start = item.startTime.split(':').reverse().join('')
        let end = item.endTime.split(':').reverse().join('')
        let str = price + start + end
        arr.push(str)
      })
      return arr
    },
    async getSiteList () {
      getAllSiteList(arr => {
        this.siteList = arr
      })
    },
    async getMemberList () {
      getAllMemberList(arr => {
        this.memberList = arr
        // console.log(this.memberList)
      })
    },
    memberChange (val) {
      this.memberTab = []
      val.forEach((it) => {
        if (it.value === 1) {
          let temp = Object.assign({}, it)
          temp.label = '普通会员(快电推送)'
          this.memberTab.push(temp)
        } else {
          this.memberTab.push(it)
        }
      })
      let arr = []
      let formCateObj = []
      if (val.length) {
        val.forEach((item, index) => {
          arr.push(item.value)
          let itemCateObj = {}
          this.priceCategory.forEach((cate) => {
            let priceKey = cate.key2 + 'ElectricityPrice'
            let serviceKey  = cate.key2 + 'ServiceCharge'
            let price = (this.modalFormData.category[index] && this.modalFormData.category[index].MembershipRate[priceKey]) || null
            let service =  (this.modalFormData.category[index] && this.modalFormData.category[index].MembershipRate[serviceKey]) || null
            itemCateObj[priceKey] = price
            itemCateObj[serviceKey] = service
          })
          let defaultDiscount = 0
          if (item.value === 4) { // 钻石会员
            defaultDiscount = -0.02
          } else if (item.value === 3) { // 铂金会员
            defaultDiscount = 0.7
          }
          formCateObj.push({
            MembershipLevel: item.value,
            MembershipRate: itemCateObj,
            MemberLevelDiscount: this.modalFormData.category[index] ? this.modalFormData.category[index].MemberLevelDiscount : defaultDiscount
          })
        })
      }
      this.modalFormData.category = formCateObj
      this.modalFormData.memberIds = arr
    },
    toMemberTab (tab, index) {
      this.memberTabInd = index
      if (this.oprType === 'add' && index !== 0) {
        let obj = this.modalFormData.category[index].MembershipRate
        let flag = true
        Object.keys(obj).forEach((key) => {
          if (obj[key]) {
            flag = false
            return false
          }
        })
        if (flag) {
          this.modalFormData.category[index].MembershipRate = Object.assign({}, this.modalFormData.category[0].MembershipRate)
        }
      }
    },
    getDefaultNumber (key) {
      let number = this.modalFormData.category.length ? (this.modalFormData.category.length > 1 ? this.modalFormData.category[1].MembershipRate[key] : this.modalFormData.category[0].MembershipRate[key]): {}
      return number
    },
    siteChange (option) {
      let name = []
      if (option.length) {
        option.forEach((opt) => {
          name.push(opt.label)
        })
      }
      this.modalFormData.siteName = name
    }
  },
  watch: {
    showModal (val) {
      if (val) {
        this.initCategoryForm()
      } else { // 重置
        this.showSurplus = false
        this.modalFormData = Object.assign({}, formData)
        this.$refs.modalForm.resetFields()
        this.modData = {}
        this.memberTab = []
        this.memberTabInd = 0
        this.sendRateId = ''
      }
    },
    'modalFormData.priceCurrent': {
      handler (val) {
        let len = this.getTimeLength(val)
        this.showSurplus = len < 48
      },
      deep: true
    }
  }
};
</script>
<style lang="less">
.charge-rate-modal{
  .ivu-modal-body{
    height: 600px;
    overflow-y: auto;
  }
  .form-price.ivu-form{
    .ivu-form-item-label{
      padding-right: @base / 4;
    }
  }
}
</style>
<style scoped lang="less">
  @import "./state-set";
</style>
