<template>
  <div class="platform-wrapper">
    <search-form :fields="searchFields" :okLoading="loading" @on-search="toSearch" @on-reset="toReset" :selectData="searchSelectData"></search-form>
    <div class="table-top">
      <Button href="javascript:;" @click="toOpr('add')" v-has-permi="['PRIV_STATION_CREATE']"><Icon type="md-add"/> 新增</Button>
    </div>
    <div class="platform-list" ref="table" :style="{height: tableHeight + 'px'}">
      <template v-if="!loading">
        <template v-if="list.length">
          <div class="platform-item" v-for="(item, index) in list" :key="index">
            <div class="item-top">
              <p class="left">
                <label :title="item.siteName">{{item.siteName}}</label>
                <span class="status">{{getSelectLabel(runStatusOpt, item.siteState)}}</span>
                <span class="status">{{getSelectLabel(appShowOpt, item.appDisplay)}}</span>
                <span class="status">{{getSelectLabel(runAttrOpt, item.operationalAttributes)}}</span>
              </p>
              <p class="right">【可用电桩】
                <span>空闲: <label>{{item.freeLength ? item.freeLength : 0}}</label></span>
                <span>充电中: <label>{{item.chargeLength ? item.chargeLength : 0}}</label></span>
                <span>离线: <label>{{item.offlineLength ? item.offlineLength : 0}}</label></span>
                <span>故障: <label>{{item.troubleLength ? item.troubleLength : 0}}</label></span>
              </p>
            </div>
            <div class="platform-ul-box">
              <div class="platform-box-left">
                <div class="img-box">
                  <img :src="baseUrl + '/UploadImage/' + (item.siteImages.indexOf(',') > -1 ? item.siteImages.split(',')[0] : item.siteImages)">
                  <p class="code">ID: {{item.siteId}}</p>
                </div>
                <ul class="platform-ul">
                  <!--              <li><label>当前费率: </label><span class="blue">{{item.siteRate}}</span>元/度</li>-->
                  <li><label>电站地址: </label><span>{{item.siteAddress}}</span></li>
                  <li><label>运营商: </label><span>{{item.siteOperator}}</span></li>
                </ul>
              </div>
              <div class="list-btn-box">
                <a href="javascript:;"
                   v-for="(btn, bInd) in oprBtnArr"
                   :key="bInd"
                   @click="toOpr(btn.type, item)"
                   :class="btn.type === 'open' ? (item.siteState === 50 ? 'close' : 'open') : btn.type"
                   v-has-permi="btn.permission">
              <span v-if="btn.type === 'open'" v-has-permi="['PRIV_STATION_OPEN']">
                <i class="iconfont" :class="item.siteState === 50 ? 'icon-tingyong_line' : 'icon-qiyong'"></i>
                <span>{{item.siteState === 50 ? '停用' : '启用'}}</span>
              </span>
              <span v-else v-has-permi="['PRIV_STATION_CLOSE']">
                <i class="iconfont" :class="btn.icon"></i>
                <span>{{btn.name}}</span>
              </span>
                </a>
              </div>
            </div>
          </div>
        </template>
        <no-data v-else></no-data>
      </template>
      <loading :show="loading" :styl="{background: 'rgba(255, 255, 255, 0.3)'}"></loading>
    </div>
    <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
    <Modal v-model="showOprModal" :title="(oprType === 'add' ? '新增' : '编辑')"
           :fullscreen="true"
           width="800"
           :mask-closable="false"
           class-name="modal-detail modal-form">
      <div slot="footer">
        <Button @click="showOprModal=false">取消</Button>
        <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :model="modalFormData" ref="modalForm" :label-width="130" :rules="modalRuleValid" v-if="showOprModal">
        <div class="modal-box">
          <div class="title">电站信息</div>
          <div class="form-content">
            <FormItem label="电站名称" prop="siteName">
              <Input v-model="modalFormData.siteName" />
            </FormItem>
            <FormItem label="电站编号" prop="siteCode">
              <Input v-model="modalFormData.siteCode" />
            </FormItem>
            <FormItem label="是否对接监管平台" prop="isDockingSupervision">
              <RadioGroup v-model="modalFormData.isDockingSupervision">
                <Radio v-for="(opt, oInd) in isValidOpt" :key="oInd" :label="opt.value - 1">{{opt.label}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="归属运营商" prop="siteOperator">
              <Select v-model="modalFormData.siteOperator">
                <Option v-for="(opt, oInd) in searchSelectData.siteOperator" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="运营状态" prop="siteState">
              <Select v-model="modalFormData.siteState">
                <Option v-for="(opt, oInd) in runStatusOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="电站类型" prop="siteTypeStation">
              <Select v-model="modalFormData.siteTypeStation">
                <Option v-for="(opt, oInd) in siteTypeOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="建设场所" prop="constructionSite">
              <Select v-model="modalFormData.constructionSite">
                <Option v-for="(opt, oInd) in constructSiteOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="APP可见">
              <RadioGroup v-model="modalFormData.appDisplay">
                <Radio v-for="(opt, oInd) in appShowOpt" :key="oInd" :label="opt.value">{{opt.label}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="运营属性" prop="operationalAttributes">
              <Select v-model="modalFormData.operationalAttributes">
                <Option v-for="(opt, oInd) in runAttrOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="电站负责人" prop="siteController">
              <Input v-model="modalFormData.siteController" />
            </FormItem>
            <FormItem label="站点电话" prop="sitePhone">
              <Input v-model="modalFormData.sitePhone" />
            </FormItem>
            <FormItem label="站点创建时间" prop="siteCreateTime">
              <Input v-model="modalFormData.siteCreateTime" />
            </FormItem>
            <FormItem label="前置机IP" prop="siteIP">
              <Input v-model="modalFormData.siteIP" />
            </FormItem>
            <FormItem label="端口" prop="sitePort">
              <Input v-model="modalFormData.sitePort" />
            </FormItem>
            <FormItem label="站点管理桩数量" prop="siteChargingNumber">
              <Input v-model="modalFormData.siteChargingNumber" />
            </FormItem>
            <FormItem label="电站电流类型" prop="siteCurrentType">
              <Input v-model="modalFormData.siteCurrentType" />
            </FormItem>
            <FormItem label="供电类型" prop="supplyType">
              <Select v-model="modalFormData.supplyType">
                <Option v-for="(opt, oInd) in supplyTypeOpt" :key="oInd" :value="opt.value" :label="opt.label"></Option>
              </Select>
            </FormItem>
            <FormItem label="供电局用户编号" prop="residentNo">
              <Input v-model="modalFormData.residentNo" />
            </FormItem>
            <FormItem label="表号" prop="wattHourMeterNo">
              <Input v-model="modalFormData.wattHourMeterNo" />
            </FormItem>
            <FormItem label="充电站全省唯一备案号" prop="recordUniqueNo">
              <Input v-model="modalFormData.recordUniqueNo" />
            </FormItem>
            <FormItem label="投运时间" prop="operationDate">
              <DatePicker type="date" style="width: 148px" v-model="modalFormData.operationDate"></DatePicker>
            </FormItem>
            <FormItem label="电站地址" prop="siteAddress" class="six form-item-region">
              <Cascader :data="regionData" v-model="formRegionVal" @on-change="regionChange" :filterable="true"></Cascader>
              <Input v-model="modalFormData.siteAddress" :readonly="!modalFormData.siteAddress" @click.native="mapInputClick"/>
              <i class="iconfont icon-dingweimian" title="地图定位" @click="showMap"></i>
            </FormItem>
            <FormItem label="国家代码" prop="countryCode">
              <Input v-model="modalFormData.countryCode" />
            </FormItem>
            <FormItem label="区域代码" prop="areaCode">
              <Input v-model="modalFormData.areaCode" />
            </FormItem>
            <FormItem label="外电功率" prop="forwardPower">
              <Input v-model="modalFormData.forwardPower" />
            </FormItem>
            <FormItem label="车位口数量" prop="parkNums">
              <Input v-model="modalFormData.parkNums" />
            </FormItem>
            <FormItem label="电站最大SOC" prop="siteMaxSoc">
              <Input v-model="modalFormData.siteMaxSoc" type="number" :number="true"/>
              <span class="hint" style="font-size: 12px;color: #999;">最大充电SOC应设置不小于90%！</span>
            </FormItem>
          </div>
        </div>
        <div class="modal-box">
          <div class="title">电站配置</div>
          <div class="form-content">
            <FormItem label="所属费率" prop="siteRate" style="display: none;">
              <Select v-model="modalFormData.siteRate" :filterable="true" disabled>
                <Option v-for="(rate, rInd) in rateList" :key="rInd" :value="rate.rateId" :label="rate.rateName">
                  <div class="select-rate-box">
                    <p class="name">{{rate.rateName}}</p>
                    <p class="content">
                <span v-for="(price, pInd) in rate.frontEndRateDetails.split(',').length / 2" :key="pInd">
                  {{(getSelectLabel(rateCategoryOpt, '0' + pInd))}}:
                  价格 {{(rate.frontEndRateDetails.split(',')[pInd * 2] / 100000).toFixed(5)}}元;服务费 {{(rate.frontEndRateDetails.split(',')[pInd * 2 + 1] / 100000).toFixed(5)}}元
                </span>
                    </p>
                  </div>
                </Option>
              </Select>
            </FormItem>
            <FormItem label="站点收费类型">
              <Select v-model="modalFormData.isPrice">
                <Option v-for="(opt, oInd) in siteFeeTypOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="场站道闸" prop="siteIsRoadGate">
              <RadioGroup v-model="modalFormData.siteIsRoadGate">
                <Radio v-for="(opt, oInd) in radioCommonOpt" :key="oInd" :label="opt.value">{{opt.label}}道闸</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="是否对接道闸" prop="isDockingGate" v-if="modalFormData.siteIsRoadGate === 2" class="six">
              <RadioGroup v-model="modalFormData.isDockingGate">
                <Radio v-for="(opt, oInd) in lockingGateOpt" :key="oInd" :label="opt.value">{{opt.label}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="道闸品牌" prop="roadGateBrand">
              <Input v-model="modalFormData.roadGateBrand"/>
            </FormItem>
            <FormItem label="车位地锁" prop="siteIsGroundLock">
              <RadioGroup v-model="modalFormData.siteIsGroundLock">
                <Radio v-for="(opt, oInd) in radioCommonOpt" :key="oInd" :label="opt.value">{{opt.label}}地锁</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="特殊车位" prop="specialParking" class="half">
              <RadioGroup v-model="modalFormData.specialParking">
                <Radio v-for="(opt, oInd) in specialParkingOpt" :key="oInd" :label="opt.value">{{opt.label}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="平台服务电话" prop="serviceTel">
              <Input v-model="modalFormData.serviceTel" />
            </FormItem>
            <FormItem label="站点指引" prop="siteGuide">
              <Input v-model="modalFormData.siteGuide" />
            </FormItem>
            <FormItem label="可充电车型描述" prop="matchCars">
              <Input v-model="modalFormData.matchCars" />
            </FormItem>
            <FormItem label="车位楼层及数量信息" prop="parkInfo">
              <Input v-model="modalFormData.parkInfo" />
            </FormItem>
            <FormItem label="充电电费描述" prop="electricityFee">
              <Input v-model="modalFormData.electricityFee" />
            </FormItem>
            <FormItem label="充电服务费描述" prop="serviceFee">
              <Input v-model="modalFormData.serviceFee" />
            </FormItem>
            <FormItem label="支付方式" prop="payment">
              <Select v-model="modalFormData.payment">
                <Option v-for="(opt, oInd) in paymentOpt" :key="oInd" :value="opt.value" :label="opt.label"></Option>
              </Select>
            </FormItem>
            <FormItem label="是否支持预约" prop="supportOrder">
              <RadioGroup v-model="modalFormData.supportOrder">
                <Radio v-for="(opt, oInd) in isValidOpt" :key="oInd" :label="opt.value - 1">{{opt.label}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="充换电类型" prop="chargeReplaceType">
              <Select v-model="modalFormData.chargeReplaceType">
                <Option v-for="(opt, oInd) in chargeReplaceTypeOpt" :key="oInd" :value="opt.value" :label="opt.label"></Option>
              </Select>
            </FormItem>
            <FormItem label="设备运营商编号" prop="equipmentOwnerID">
              <Input v-model="modalFormData.equipmentOwnerID" />
            </FormItem>
            <FormItem label="⻋设备运营商全称" prop="equipmentOwnerName">
              <Input v-model="modalFormData.equipmentOwnerName" />
            </FormItem>
            <FormItem label="是否对接达州数字经济局" prop="isDaEconomicZone">
              <RadioGroup v-model="modalFormData.isDaEconomicZone">
                <Radio v-for="(opt, oInd) in isValidOpt" :key="oInd" :label="opt.value - 1">{{opt.label}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="是否对接快电" prop="isFastConnection" style="display: none;">
              <RadioGroup v-model="modalFormData.isFastConnection">
                <Radio v-for="(opt, oInd) in isValidOpt" :key="oInd" :label="opt.value - 1">{{opt.label}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="停车收费类型" prop="parkingChargeType">
              <Select v-model="modalFormData.parkingChargeType">
                <Option v-for="(opt, oInd) in parkingChargeTypeOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="停车说明" prop="parkingInstructions">
              <Input v-model="modalFormData.parkingInstructions" />
            </FormItem>
            <FormItem label="场地属性" prop="siteProperties">
              <Input v-model="modalFormData.siteProperties" />
            </FormItem>
            <FormItem label="站点标签" prop="siteLabel" class="six">
              <Select v-model="modalFormData.siteLabel" multiple>
                <Option v-for="(opt, oInd) in stateLabelOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="站点服务设施" prop="siteServiceFacilities" class="half">
              <Select v-model="modalFormData.siteServiceFacilities" multiple>
                <Option v-for="(opt, oInd) in stateLabelOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="周边基础设施" prop="surroundingInfrastructure" class="half">
              <Input v-model="modalFormData.surroundingInfrastructure" />
            </FormItem>
            <FormItem label="开放时间说明" prop="openingDescription" class="half">
              <Input v-model="modalFormData.openingDescription" />
            </FormItem>
            <FormItem label="滚动显示字段" prop="scrollField" class="half">
              <Input v-model="modalFormData.scrollField" />
            </FormItem>
            <FormItem label="电站图片" class="long" prop="siteImages">
              <UploadNew v-model="modalFormData.siteImages" name="file" v-if="showOprModal"></UploadNew>
            </FormItem>
            <FormItem label="所属资质" class="long" prop="siteQualification">
              <UploadNew v-model="modalFormData.siteQualification" name="file" v-if="showOprModal" :cfg="{count: 1}"></UploadNew>
            </FormItem>
            <FormItem label="备注说明" prop="siteRemarke" class="long">
              <Input type="textarea" v-model="modalFormData.siteRemarke"></Input>
            </FormItem>
          </div>
        </div>
      </Form>
    </Modal>
    <Modal title="下发" v-model="showSendModal" width="500" :mask-closable="false">
      <div slot="footer">
        <Button @click="showSendModal=false">取消</Button>
        <Button type="primary" @click="sendModalOk" :loading="sendLoading">确定</Button>
      </div>
      <Form inline :label-width="120" :model="sendParams" :rules="sendFormValid" ref="sendForm">
        <FormItem label="电站名称" style="width: 100%;">
          <span style="word-break: break-all;">{{modData.siteName}}</span>
        </FormItem>
        <FormItem label="所属费率" style="width: 100%;" prop="rateId">
          <Select v-model="sendParams.rateId">
            <Option v-for="(rate, rInd) in rateList" :key="rInd" :value="rate.rateId" :label="rate.rateName">
              <div class="select-rate-box">
                <p class="name">{{rate.rateName}}</p>
                <p class="content">
                <span v-for="(price, pInd) in rate.frontEndRateDetails.split(',').length / 2" :key="pInd">
                  {{(getSelectLabel(rateCategoryOpt, '0' + pInd))}}:
                  价格 {{(rate.frontEndRateDetails.split(',')[pInd * 2] / 100000).toFixed(4)}}元;服务费 {{(rate.frontEndRateDetails.split(',')[pInd * 2 + 1] / 100000).toFixed(4)}}元
                </span>
                </p>
              </div>
            </Option>
          </Select>
        </FormItem>
      </Form>
    </Modal>
    <choose-map v-model="showChooseMap" @on-ok="chooseMapOk" :center="chooseMapCenter" :inputVal="chooseMapVal"></choose-map>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import UploadNew from '@/components/UploadNew'
import ChooseMap from '@/components/ChooseMap'
import NoData from '@/components/NoData/'
import Loading from '@/components/Loading'
import { mapState } from 'vuex'
import { appShowOpt, runAttrOpt, runStatusOpt, stateLabelOpt, siteTypeOpt, constructSiteOpt, radioCommonOpt, specialParkingOpt, parkingChargeTypeOpt, isValidOpt, electricStateOpt, chartStateOpt, electricLinkStatusOpt, rateCategoryOpt, siteFeeTypOpt, supplyTypeOpt, paymentOpt, chargeReplaceTypeOpt, lockingGateOpt } from '@/libs/dict'
import { querySite,  siteAdd, siteUpdate, siteUpdateState, chargeRatesQuery, getElectricSearch, getRealData, updateEquipmentLngLatByCode, updateRateIdBySiteIs } from '@/libs/api'
import { getSelectLabel, getStorage } from '@/libs/tools'
import { baseUrl } from '@/libs/api.request'
import {getAllCompanyList} from "@/libs/requestCommon";
import axios from '@/libs/api.request';
import config from '@/config'
let formData = {
  siteCode: '',
  siteName: '',
  siteProvince: '',
  siteProvinceId: '',
  siteCity: '',
  siteCityId: '',
  siteArea: '',
  siteAreaId: '',
  siteAddress: '',
  stationLng: '',
  stationLat: '',
  siteCoordinate: '',
  siteController: '',
  sitePhone: '',
  siteCreateTime: '',
  siteIP: '',
  sitePort: '',
  siteChargingNumber: '',
  siteOperator: '',
  siteIsRoadGate: 1,
  isDockingGate: 0,
  roadGateBrand: '',
  siteState: '',
  siteProperties: '',
  siteTypeStation: '',
  constructionSite: '',
  siteIsGroundLock: 1,
  specialParking: 1,
  parkingChargeType: '',
  parkingInstructions: '',
  siteServiceFacilities: [],
  siteLabel: [],
  surroundingInfrastructure: '',
  openingDescription: '',
  siteImages: '',
  siteQualification: '',
  siteRemarke: '',
  appDisplay: 1,
  operationalAttributes: '',
  siteRate: '',
  isPrice: 0,
  isSupervise: 1,
  siteCurrentType: '',
  scrollField: '',
  residentNo: '',
  supplyType: 1,
  wattHourMeterNo: '',
  recordUniqueNo: '',
  operationDate: '',
  forwardPower: '',
  countryCode: '',
  areaCode: '',
  parkNums: 0,
  serviceTel: '',
  siteGuide: '',
  matchCars: '',
  parkInfo: '',
  electricityFee: '',
  serviceFee: '',
  payment: '',
  supportOrder: 0,
  chargeReplaceType: 1,
  isDockingSupervision: 0,
  equipmentOwnerID: '',
  equipmentOwnerName: '',
  isDaEconomicZone: 0,
  isFastConnection: 0,
  siteMaxSoc: 90
}
export default {
  name: "platform-state",
  components: {
    SearchForm,
    UploadNew,
    ChooseMap,
    NoData,
    Loading
  },
  data () {
    return {
      baseUrl,
      appShowOpt,
      runStatusOpt,
      runAttrOpt,
      siteTypeOpt,
      stateLabelOpt,
      constructSiteOpt,
      radioCommonOpt,
      specialParkingOpt,
      parkingChargeTypeOpt,
      isValidOpt,
      electricStateOpt,
      chartStateOpt,
      electricLinkStatusOpt,
      rateCategoryOpt,
      siteFeeTypOpt,
      supplyTypeOpt,
      paymentOpt,
      chargeReplaceTypeOpt,
      lockingGateOpt,
      loading: false,
      searchFields: [
        {
          label: '电站名称',
          name: 'siteName',
          type: 'input'
        },
        {
          label: 'APP显示',
          name: 'appDisplay',
          type: 'select',
          options: appShowOpt
        },
        {
          label: '运营属性',
          name: 'operationalAttributes',
          type: 'select',
          options: runAttrOpt
        },
        {
          label: '归属运营商',
          name: 'siteOperator',
          type: 'select'
        },
        {
          label: '电站地址',
          name: 'siteAddress',
          type: 'input'
        },
        {
          label: '电站标签',
          name: 'siteLabel',
          type: 'select',
          options: stateLabelOpt
        },
        {
          label: '运营状态',
          name: 'siteState',
          type: 'select',
          options: runStatusOpt
        }
      ],
      searchSelectData: {
        siteOperator: []
      },
      searchForm: {},
      oprBtnArr: [
        {
          name: '编辑',
          icon: 'icon-bianji',
          type: 'mod',
          permission: ['PRIV_STATION_UPDATE']
        },
        {
          name: '启用',
          icon: 'icon-qiyong',
          type: 'open',
          permission: ['PRIV_STATION_OPEN']
        },
        {
          name: '查看终端',
          icon: 'icon-chongdianzhuang1',
          type: 'view',
          permission: ['PRIV_STATION_VIEW']
        },
        // {
        //   name: '详情',
        //   icon: 'icon-chakanjiexi',
        //   type: 'detail',
        //   permission: ['PRIV_STATION_VIEW']
        // },
        {
          name: '下发费率',
          icon: 'icon-xiafapeizhi2',
          type: 'fee',
          permission: ['PRIV_STATION_SEND']
        }
      ],
      oprType: 'add',
      list: [],
      params: {
        pageSize: 20,
        pageIndex: 1
      },
      total: 0,
      showOprModal: false,
      modalFormData: Object.assign({}, formData),
      modalRuleValid: {
        siteName: [{required: true, message: '请输入电站名称!'}],
        siteOperator: [{required: true, message: '请输入归属运营商!'}],
        siteState: [{required: true, message: '请选择运营状态!'}],
        siteAddress: [{required: true, message: '请选择电站地址!'}],
        siteTypeStation: [{required: true, message: '请选择电站类型!'}],
        constructionSite: [{required: true, message: '请选择建设场所!'}],
        siteIsRoadGate: [{required: true, message: '请选择场站道闸!'}],
        siteIsGroundLock: [{required: true, message: '请选择车位地锁!'}],
        parkingChargeType: [{required: true, message: '请选择停车收费类型!'}],
        parkingInstructions: [
          {required: true, message: '请输入停车说明!'},
          {
            validator: (rule, value, callback) => {
              if (value.length > 100) {
                return callback(new Error('停车说明最大长度为50!'))
              } else {
                callback()
              }
            }
          }
        ],
        siteImages: [{required: true, message: '请选择电站图片!'}],
        operationalAttributes: [{required: true, message: '请选择运营属性!'}],
        siteRate: [{required: true, message: '请选择费率!'}],
        equipmentOwnerID: [{maxLength: 9, message: '编号最大长度为9!'}],
        siteMaxSoc: [
          {required: true, message: '请输入电站最大SOC!'},
          {
            validator: (rule, value, callback) => {
              let reg = /^[0-9]*[1-9][0-9]*$/
              if (!reg.test(value) || value <= 0 || value >  100) {
                return callback(new Error('请输入1~100之间的正整数!'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      formRegionVal: [],
      regionData: [],
      uploadImgCfg: {},
      modData: {},
      showChooseMap: false,
      chooseMapCenter: '',
      chooseMapVal: {},
      rateList: [],
      gunTimer: {},
      btnLoading: false,
      realTimeData: {},
      realTimeReqCount: 0,
      urls: getStorage('urls') ? JSON.parse(getStorage('urls')) : [],
      showSendModal: false,
      sendParams: {
        rateId: '' // 费率id
      },
      sendFormValid: {
        rateId: [{required: true, message: '请选择所属费率'}]
      },
      sendLoading: false,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.searchFields.forEach((item) => {
        let val = ''
        if (item.name === 'siteOperator') {
          val = 0
        }
        this.$set(this.searchForm, item.name, val)
      })
      this.getCompanyAll()
      this.getList()
      this.gunTimer = setInterval(() => {
        // this.getGunRealStatus()
        if (this.urls && this.urls.length) {
          this.realTimeData = {}
          this.realTimeReqCount = 0
          let noRepeatUrls = []
          this.urls.forEach((it) => {
            if (noRepeatUrls.indexOf(it.siteBaseUrl) === -1) {
              noRepeatUrls.push(it.siteBaseUrl)
            }
          })
          getRealData().then((res) => {
            if (res.data.code === 0) {
              let data = res.data.data.realTimeStatus
              this.realTimeData = data
              noRepeatUrls.forEach((url) => {
                this.realTimeReqCount ++
                this.getSubstationRealSts(url, noRepeatUrls)
              })
            }
          })
        } else {
          this.getGunRealStatus()
        }
      }, 15000)
    })
  },
  beforeDestroy () {
    this.clearGunTimer()
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  methods: {
    getSelectLabel,
    statusText (val) {
      let obj = {
        1: {
          text: '充电中',
          color: '#4d8cfd'
        }
      }
      return obj[val]
    },
    toSearch (obj) {
      this.searchForm = obj
      this.params.pageIndex = 1
      this.getList()
    },
    toReset (obj) {
      this.searchForm = obj
      this.getList()
    },
    async getList () {
      this.list = []
      this.loading = true
      let params = Object.assign({}, this.searchForm, this.params)
      this.clearGunTimer()
      await querySite(params).then((res) => {
        if (res.data.code === 0) {
          this.loading = false
          this.list = res.data.data.dataSource
          this.total = res.data.data.totalCount
          if (this.list.length) {
            /*this.getGunRealStatus((data) => {
              this.getGunList(data)
            })*/
            this.list.forEach((item) => {
              this.$set(item, 'freeLength', 0)
              this.$set(item, 'chargeLength', 0)
              this.$set(item, 'offlineLength', 0)
              this.$set(item, 'troubleLength', 0)
              this.$set(item, 'siteType', '01')
            })
            if (this.urls && this.urls.length) {
              this.realTimeData = {}
              this.realTimeReqCount = 0
              let noRepeatUrls = []
              this.urls.forEach((it) => {
                if (noRepeatUrls.indexOf(it.siteBaseUrl) === -1) {
                  noRepeatUrls.push(it.siteBaseUrl)
                }
              })
              getRealData().then((res) => {
                if (res.data.code === 0) {
                  let data = res.data.data.realTimeStatus
                  this.realTimeData = data
                  noRepeatUrls.forEach((url) => {
                    this.realTimeReqCount ++
                    this.getSubstationRealSts(url, noRepeatUrls)
                  })
                }
              })
            }
          } else {
            this.getGunRealStatus((data) => {
              this.getGunList(data)
            })
          }
        }
      })
    },
    getGunList (data) { // 获取站点下电枪数量
      this.list.forEach((item) => {
        if (!item.gunList || !item.gunList.length) {
          getElectricSearch({
            siteCode: item.siteCode,
            pageIndex: 1,
            pageSize: 10000
          }).then((res) => {
            if (res.data.code === 0) {
              let gunList = res.data.data.ElectricManagement.data
              let siteType = '01'
              if (gunList.length) {
                gunList.forEach(gun => {
                  if (gun.chargeType === '00') {
                    siteType = '00'
                    return false
                  }
                })
                item.siteType = siteType
                item.gunList = gunList
                this.dealGunStatus(data, gunList, item)
              }
            }
          })
        } else {
          this.dealGunStatus(data, item.gunList, item)
        }
      })
    },
    getGunRealStatus (callback) { // 获取电枪实时状态
      getRealData().then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data.realTimeStatus
          if (!callback) {
            this.dealGunStatus(data)
          } else {
            callback(data)
          }
        }
      })
    },
    getSubstationRealSts (url, urls) {
      axios.request({
        url: url + '/SocketGetChargingRealTimeStatus',
        method: 'get',
        timeout: 60000,
        noToast: true
      }).then((res) => {
        let data = res.data.data.realTimeStatus
        this.realTimeData = Object.assign({}, this.realTimeData, data)
        if (this.realTimeReqCount === urls.length) {
          this.getGunList(this.realTimeData)
        }
      })
    },
    dealGunStatus (data, gunArr, siteItem) {
      if (!gunArr) {
        if (this.list.length) {
          this.list.forEach((site) => {
            let gunList = site.gunList
            let len = this.dealGunStatusDetail(data, gunList)
            site.freeLength = len.freeLen
            site.chargeLen = len.chargeLen
            site.offlineLen = len.offlineLen
            site.troubleLen = len.troubleLen
          })
        }
      } else {
        let len = this.dealGunStatusDetail(data, gunArr)
        this.$set(siteItem, 'freeLength', len.freeLen)
        this.$set(siteItem, 'chargeLength', len.chargeLen)
        this.$set(siteItem, 'offlineLength', len.offlineLen)
        this.$set(siteItem, 'troubleLength', len.troubleLen)
      }
    },
    dealGunStatusDetail (data, gunList) {
      let freeLen = 0
      let chargeLen = 0
      let offlineLen = 0
      let troubleLen = 0
      if (gunList && gunList.length) {
        gunList.forEach((gun) => {
          let key = gun.electricCode.split('-').join('')
          let item = data[key]
          if (item) {
            let value = item.split(',')[0]
            let gunState = value.slice(0, 2) //电枪状态
            if (gunState === '02') { // 空闲
              freeLen ++
            }
            if (gunState === '03') { // 充电中
              chargeLen ++
            }
            if (gunState === '00') { // 离线
              offlineLen ++
            }
            if (gunState === '01') { // 故障
              troubleLen ++
            }
          }
        })
      }
      return {
        freeLen: freeLen,
        chargeLen: chargeLen,
        offlineLen: offlineLen,
        troubleLen: troubleLen
      }
    },
    clearGunTimer () {
      clearInterval(this.gunTimer)
      this.gunTimer = null
    },
    pageChange (page) {
      this.params.pageIndex = page
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    pageModalChange (page) {
      this.modalParams.pageIndex = page
    },
    pageSizeModalChange (pageSize) {
      this.modalParams.pageSize = pageSize
    },
    toOpr (type, row) {
      this.oprType = type
      if (['add', 'mod'].includes(type)) {
        this.showOprModal = true
        if (row) {
          this.modData = row
        }
      } else if (type === 'detail') {
        // 详情
      } else if (type === 'open') { // 启用/停用
        let value = row.siteState
        let state = {
          50: [5, '停用'],
          5: [50, '启用']
        }
        let titVal = state[value] ? state[value][1] : '启用'
        let stateVal = state[value] ? state[value][0] : 50
        this.$Modal.confirm({
          title: titVal,
          content: `是否${titVal}该电站?`,
          onOk: async () => {
            await siteUpdateState({
              siteState: stateVal,
              siteId: row.siteId
            }).then((res) => {
              if (res.data.code === 0) {
                this.$Message.success('操作成功')
                this.getList()
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      } else if (type === 'view') { // 查看终端
        this.$router.push({
          path: '/state-manage/terminal',
          query: {
            siteCode: row.siteCode
          }
        })
      } else if (type === 'fee') { // 下发费率
        this.modData = row
        this.showSendModal = true
        this.getRateList()
      }
    },
    mapInputClick () {
      if (!this.modalFormData.siteAddress) {
        this.showMap()
      }
    },
    showMap () {
      this.showChooseMap = true
    },
    chooseMapOk (data) {
      // console.log(data)
     if (data.address) {
       this.modalFormData.siteAddress = data.address
       this.modalFormData.stationLat = data.lat
       this.modalFormData.stationLng = data.lng
     }
    },
    resetModalForm () { // 重置新增表单
      this.$refs.modalForm.resetFields()
      this.modalFormData = Object.assign({}, formData)
      this.chooseMapCenter = ''
      this.chooseMapVal = {}
      this.formRegionVal = []
    },
    modalOk () {
      let ref =this.$refs.modalForm
      ref.validate(async (valid) => {
        if (valid) {
          let formData = this.oprType === 'mod' ? Object.assign({},this.modData, this.modalFormData): Object.assign({}, this.modalFormData)
          formData.siteServiceFacilities = formData.siteServiceFacilities ? formData.siteServiceFacilities.join(',') : ''
          formData.siteLabel = formData.siteLabel ? formData.siteLabel.join(',') : ''
          let urlObj = {
              add: siteAdd,
              mod: siteUpdate
          }
          this.btnLoading = true
          await urlObj[this.oprType](formData).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              /*if (this.oprType === 'mod') {
                if (this.modData.stationLat !== formData.stationLat || this.modData.stationLng !== formData.stationLng) {
                  updateEquipmentLngLatByCode({
                    equipmentLng: formData.stationLng,
                    equipmentLat: formData.stationLat,
                    siteCode: this.modData.siteCode
                  }).then((res2) => {
                    if (res2.data.code === 0) {
                      this.oprSuccess()
                    } else {
                      this.$Message.error(res2.data.message)
                    }
                  })
                }
              }*/
              this.oprSuccess()
            } else {
              this.$Message.error(res.data.message)
            }
          }).catch(() => {
            this.btnLoading = false
          })
        }
      })
    },
    regionChange (value, selectedData) { // 行政区域值变化
      let curVal = selectedData[selectedData.length - 1]
      this.chooseMapCenter = this.modalFormData.stationLng ? this.modalFormData.stationLat + ',' + this.modalFormData.stationLng : curVal.center.split(',')[1] + ',' + curVal.center.split(',')[0]
      this.modalFormData.siteProvince = selectedData[0].label
      this.modalFormData.siteProvinceId = selectedData[0].value
      this.modalFormData.siteCity = selectedData[1].label
      this.modalFormData.siteCityId = selectedData[1].value
      this.modalFormData.siteArea = selectedData[2] ? selectedData[2].label : ''
      this.modalFormData.siteAreaId = selectedData[2] ? selectedData[2].value : ''
    },
    oprSuccess () {
      this.$Message.success('操作成功')
      this.getList()
      this.showOprModal = false
    },
    initForm () {
      if (this.oprType === 'mod') {
        Object.keys(this.modalFormData).forEach((key) => {
          this.modalFormData[key] = this.modData[key]
          if (['siteServiceFacilities','siteLabel'].includes(key)) {
            this.modData[key] && (this.modalFormData[key] = this.modData[key].indexOf(',') > -1 ? this.modData[key].split(',') : [this.modData[key]])
          } else if (key === 'siteOperator') {
            this.modalFormData[key] = this.modData[key] + ''
          }
        })
        this.formRegionVal = [this.modData.siteProvinceId + '', this.modData.siteCityId + '', this.modData.siteAreaId + '']
      }
    },
    async getRateList () { // 获取费率组列表
      await chargeRatesQuery({
        rateIsValid: 2
      }).then((res) => {
        if (res.data.code === 0) {
          this.rateList = res.data.data
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    getCompanyAll () {
      getAllCompanyList((data) => {
        this.searchSelectData.siteOperator = []
        if (data.length) {
          data.forEach((item) => {
            this.searchSelectData.siteOperator.push({
              label: item.label,
              value: item.value.toString()
            })
          })
        }
      })
    },
    sendModalOk () { // 下发费率
      this.$refs.sendForm.validate((valid) => {
        if (valid) {
          this.sendLoading = true
          updateRateIdBySiteIs({
            siteIds: this.modData.siteId,
            rateId: this.sendParams.rateId
          }).then((res2) => {
            this.sendLoading = false
            if (res2.data.code === 0) {
              this.showSendModal = false
              let obj = res2.data.data
              let arr = []
              if (Object.keys(obj).length) {
                Object.keys(obj).forEach((key) => {
                  if (!isNaN(key - 0)) {
                    arr.push('电桩' + key + ':' + obj[key])
                  }
                })
              }
              let str = ''
              if (arr.length) {
                arr.forEach((iMsg) => {
                  let iArr = iMsg.split(':')
                  let flag = iArr[1].slice(0, 1)
                  str += `<p style="width: 100%;line-height:1.5;word-break: break-all">
                      ${iArr[0]}
                      <span style="color: ${flag === '1' ? 'green' : 'red'}">${iArr[1]}</span>
                      ${iArr[2] ? iArr[2] : ''}
                  </p>`
                })
              }
              this.$Modal.info({
                title: '提示',
                closable: false,
                width: 600,
                content: `<div>${str}</div>`,
                onOk: () => {
                  this.params.pageIndex = 1
                  this.getList()
                }
              })
            } else {
              this.$Message.error(res2.data.message)
            }
          }).catch(() => {
            this.sendLoading = false
          })
        }
      })
    }
  },
  watch: {
    showOprModal (val) {
      if (!val) {
        this.oprType = 'add'
        this.modData = {}
        this.resetModalForm()
      } else {
        this.initForm()
        this.getRateList()
        this.regionData = JSON.parse(getStorage('regionData'))
      }
    },
    showChooseMap (val) {
      if (val) {
        this.chooseMapVal = this.modalFormData
      }
    },
    showSendModal (val) {
      if (!val) {
        this.sendParams = {
          rateId: ''
        }
        this.$refs.sendForm.resetFields()
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "state-manage";
.select-rate-box{
  padding: @base;
  border-bottom: 1px solid #f1f1f1;
  align-items: center;
  .name{
    margin-bottom: @base / 2;
    font-weight: bold;
    white-space: pre-wrap;
  }
  .content{
    span{
      display: block;
      padding-bottom: @base / 2;
      font-size: 12px;
    }
  }
}
</style>
