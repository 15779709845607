<template>
    <div class="help-wrapper">
        <search-form :fields="searchFields" @on-search="toSearch" @on-reset="resSearch" :okLoading="loading" :selectData="searchSelectData" :fieldsVal="fieldsVal"></search-form>
        <div class="help-content" :style="{height: tableHeight + 'px'}">
            <Table stripe :columns="columns" :data="list" :loading="loading" ref="table" :height="tableHeight"></Table>
        </div>
        <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
        <Modal
            title="编辑"
            v-model="showModal"
            width="950"
            class-name="vertical-center-modal"
            :mask-closable="false">
            <div slot="footer">
                <Button @click="modalCancel">取消</Button>
                <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">保存</Button>
            </div>
            <Form :model="modalFormData" ref="modalForm" :label-width="140">
                <div class="form-content">
                    <FormItem label="用户手机: " prop="userId" class="three">
                        <Input v-model="userPhone" readonly/>
                        <Input v-model="modalFormData.userId"  style="display: none;"/>
                    </FormItem>
                    <FormItem label="开票金额: " prop="invoiceSum" class="three">
                        <Input v-model="modalFormData.invoiceSum" readonly/>
                    </FormItem>
                    <FormItem label="创建时间: " prop="createTime" class="three">
                        <Input v-model="modalFormData.createTime" readonly/>
                    </FormItem>
                    <FormItem label="更新时间: " prop="updateTime" class="three">
                        <Input v-model="modalFormData.updateTime" readonly/>
                    </FormItem>
                    <FormItem label="开票状态: " prop="state" class="three" required>
                        <Select v-model="modalFormData.state">
                        <Option v-for="(opt, oInd) in state" :key="oInd" :value="opt.value">{{opt.label}}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="抬头名称: " prop="invoiceTitle" class="three">
                        <Input v-model="invoiceTitleId" readonly/>
                        <Input v-model="modalFormData.invoiceTitle" style="display: none;"/>
                    </FormItem>
                    <FormItem label="接收邮箱: " prop="email" class="three">
                        <Input v-model="modalFormData.email" readonly/>
                    </FormItem>
                    <template v-if="titleType === 0">
                        <FormItem label="公司税号: " prop="companyTax" class="three">
                            <Input v-model="companyTax" readonly/>
                        </FormItem>
                        <FormItem label="注册地址: " prop="registerAddress" class="three">
                            <Input v-model="registerAddress" readonly/>
                        </FormItem>
                        <FormItem label="注册电话: " prop="registerPhone" class="three">
                            <Input v-model="registerPhone" readonly/>
                        </FormItem>
                        <FormItem label="开户银行: " prop="depositBank" class="three">
                            <Input v-model="depositBank" readonly/>
                        </FormItem>
                        <FormItem label="银行账户: " prop="bankAccount" class="three">
                            <Input v-model="bankAccount" readonly/>
                        </FormItem>
                    </template>
                    <FormItem label="开票备注: " prop="message" class="three" style="width: 99%;">
                        <Input type="textarea" v-model="modalFormData.message" readonly/>
                    </FormItem>
                    <FormItem label="关联订单: " prop="orderIds" style="width: 66%;">
                        <ul class="order-item">
                            <li class="order-list" v-for="(item,index) in orderList" :key="index">
                                <div class="order-content">
                                    <div class="site-name">站点：{{item.siteName}}</div>
                                    <div class="info">桩号：{{item.chargeName}}</div>
                                    <div class="info">
                                        <span class="ele-price">电费：{{item.electricityTotal/10000}}元</span>
                                        <span class="serve-price">服务费：{{item.serviceFeeDiscountPrice/10000}}元</span>
                                        <span class="total-price">合计{{item.mdxNetReceipts/100}}元</span>
                                        <span class="ele-num">电量{{item.totalElectricQuantity/10000}}度</span>
                                    </div>
                                    <div class="info">完成时间：{{item.orderEndTime}}</div>
                                </div>
                            </li>
                        </ul>
                        <Input v-model="modalFormData.orderIds" style="display: none;"/>
                        <div class="price-total">合计电费{{electricityTotal/10000}} 合计服务费{{serviceFeeDiscountPrice/10000}} 合计电量{{totalElectricQuantity/10000}}</div>
                    </FormItem>
                    <FormItem label="上传发票: " prop="invoiceImgPath" class="three upload" required>
                        <ul class="ivu-upload-list" v-show = "invoiceText" :style="{top:invoiceText?'-14px':0}">
                            <li class="ivu-upload-list-file ivu-upload-list-file-finish">
                                <span @click="uploadPreview('view')">
                                    <i class="ivu-icon ivu-icon-md-document"></i>
                                    {{modalFormData.invoiceImgPath.split('_')[modalFormData.invoiceImgPath.split('_').length-1]}}
                                </span>
                                <i class="ivu-icon ivu-icon-ios-close ivu-upload-list-remove" @click="uploadRemove()"></i>
                            </li>
                        </ul>
                        <Upload :action="baseUrl+uploadPdf"
                        :format="['pdf','zip','rar']"
                        :headers="{SYSTOKEN:token}"
                        name="files"
                        ref="upload"
                        :data="{wxid: modalFormData.userId}"
                        :before-upload="(file) => beforeUpload(file)"
                        :on-preview="(file)=> uploadPreview(file)"
                        :on-remove="()=> uploadRemove()"
                        :on-success="(response, file) => uploadSuccess(response, file)">
                            <Icon class="upload-add" type="md-add" v-show="addShow"/>
                        </Upload>
                        <div class="tips">多个发票请上传压缩包</div>
                    </FormItem>
                </div>
            </Form>
        </Modal>
    </div>
</template>

<script>
    import { baseUrl } from "@/libs/api.request"
    import { uploadPdf } from "@/libs/api"
    import SearchForm from '@/components/SearchForm'
    import { mapState } from 'vuex'
    import { GetInvoiceListsWeb, DeleteInvoiceInfo, UpdateInvoiceInfo, SendEmial } from '@/libs/api'
    export default {
        name: "company-list",
        components: {
            SearchForm
        },
        data () {
            return {
                baseUrl,
                uploadPdf,
                loading: false,
                total: 0,
                showModal: false,
                btnLoading: false,
                addShow: true,
                invoiceText: false,
                params: {
                    pageSize: 20,
                    pageIndex: 1
                },
                searchFields: [
                    {
                        label: '用户手机',
                        name: 'activityPhone',
                        type: 'input'
                    },
                    {
                        label: '开票状态',
                        name: 'State',
                        type: 'select' 
                    }
                ],
                searchSelectData: {
                    State: [
                        {value: -1, label: '全部'},
                        {value: 0, label: '未开票'},
                        {value: 1, label: '开票中'},
                        {value: 2, label: '开票完成'},
                        {value: 3, label: '开票失败'}
                    ]
                },
                fieldsVal: {
                    State: -1
                },
                list: [],
                state: [
                    {
                        label: '未开票',
                        value : 0
                    },
                    {
                        label: '开票中',
                        value : 1
                    },
                    {
                        label: '开票成功',
                        value : 2
                    },
                    {
                        label: '开票失败',
                        value : 3
                    }
                ],
                columns: [
                    {
                        key: 'index',
                        title: '序号',
                        width: 70,
                        align: 'center',
                        render: (h, params) => {
                            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
                            return (<span>{ind}</span>)
                        }
                    },
                    {
                        key: 'userPhone',
                        title: '用户手机',
                        width: 130,
                        align: 'center',
                        render: (h, params) => {
                            return (<span>{params.row.userPhone}</span>)
                        }
                    },
                    {
                        key: 'invoiceSum',
                        title: '开票金额',
                        width: 150,
                        align: 'center',
                        render: (h, params) => {
                            let num = params.row.invoiceSum / 100
                            return (<span>{num}</span>)
                        }
                    },
                    {
                        key: 'createTime',
                        title: '创建时间',
                        width: 200,
                        align: 'center'
                    },
                    {
                        key: 'state',
                        title: '开票状态',
                        width: 100,
                        align: 'center',
                        render: (h, params) => {
                            let state = params.row.state
                            if(state === 0){
                                return (<span>未开票</span>)
                            }else if(state === 1){
                                return (<span>开票中</span>)
                            }else if(state === 2){
                                return (<span>开票完成</span>)
                            }else if(state === 3){
                                return (<span>开票失败</span>)
                            }
                        }
                    },
                    {
                        key: 'message',
                        title: '备注说明',
                        width: 200,
                        align: 'center',
                        className: 'leave-out'
                    },
                    {
                        key: 'orderName',
                        title: '开票订单',
                        width: 300,
                        align: 'center',
                        className: 'leave-out'
                    },
                    {
                        key: 'invoiceTitle',
                        title: '抬头信息',
                        width: 150,
                        align: 'center',
                        render:(h,params)=>{
                            let type = params.row.titleType
                            if(type === 0){
                                return (<span>{params.row.companyName}</span>)
                            }else if(type === 1){
                                return (<span>{params.row.titlehead}</span>)
                            }
                        }
                    },
                    {
                        key: 'titleType',
                        title: '抬头类型',
                        width: 100,
                        align: 'center',
                        render:(h,params)=>{
                            let type = params.row.titleType
                            if(type === 0){
                                return (<span>公司</span>)
                            }else if(type === 1){
                                return (<span>个人</span>)
                            }
                        }
                    },
                    {
                        key: 'email',
                        title: '邮箱',
                        width: 200,
                        align: 'center'
                    },
                    {
                        key: 'extendedField1',
                        title: '邮件状态',
                        width: 200,
                        align: 'center',
                        render:(h,params)=>{
                            let state = params.row.extendedField1
                            if(state === '0'){
                                return (<span>发送成功</span>)
                            }else if(state === '1'){
                                return (<span>发送失败</span>)
                            }else{
                                return (<span>未发送</span>)
                            }
                        }
                    },
                    {
                        key: 'opr',
                        title: '操作',
                        align: 'center',
                        fixed: 'right',
                        minWidth: 250,
                        render: (h, params) => {
                            return (
                                <span class="table-opr-btn">
                                    <a href="javascript:;" class="mod" onClick={this.update.bind(this, params.row)} v-has-permi={['PRIV_INVOICE_UPDATE']}><i class="iconfont icon-bianji"></i>编辑</a>
                                    <a href="javascript:;" class="mod" onClick={this.sendEmial.bind(this,params.row)} v-has-permi={['PRIV_INVOICE_SEND']}><i class="iconfont icon-bianji"></i>重发邮件</a>
                                    <a href="javascript:;" class="close" onClick={this.delete.bind(this, params.row)} v-has-permi={['PRIV_INVOICE_DELETE']}><i class="iconfont icon-tingyong_line"></i>删除</a>
                                </span>
                            )
                        }
                    }
                ],
                modalFormData: {
                    userId: '',
                    invoiceSum: 0,
                    createTime: '',
                    updateTime: '',
                    state: 0,
                    message: '',
                    invoiceImgPath: '',
                    orderIds: '',
                    invoiceTitle: '',
                    email: ''
                },
                orderId: 0,
                userPhone: '',//用户手机
                orderName: '',//绑定站点
                orderList: [],//站点列表
                invoiceTitleId: '',//抬头名称
                titleType: 0,//抬头类型
                companyTax: '',
                registerAddress:'',
                registerPhone: '',
                depositBank:'',
                bankAccount: '',

                updateEmail: null,//邮件状态

                electricityTotal: 0,//电费
                serviceFeeDiscountPrice: 0, //服务费
                totalElectricQuantity: 0,//电量,
                token: localStorage.getItem('token')
            }
        },
        computed: {
            ...mapState(['tableHeight'])
        },
        watch: {
            showModal (val) {
                if(!val){
                   this.electricityTotal = 0 
                   this.serviceFeeDiscountPrice = 0
                   this.totalElectricQuantity = 0
                }
            }
        },
        mounted () {
            this.$nextTick(() => {
                this.loading = true
                this.GetInvoiceList()
            })
        },
        methods: {
            GetInvoiceList(KeyWork,State){//获取列表
                GetInvoiceListsWeb(Object.assign({},this.params,{KeyWork,State})).then((res)=>{
                    if(res.data.code ===0 ){
                        let data = res.data.data
                        this.list = data.dataSource
                        this.total = data.totalCount
                        this.loading = false
                    }
                })
            },
            toSearch(info){//搜索
                this.params.pageIndex = 1
                this.loading = true
                this.GetInvoiceList(info.activityPhone,info.State)
            },
            resSearch(){//重置
                this.GetInvoiceList()
            },
            pageChange (pageNum) {
                this.params.pageIndex = pageNum
                this.GetInvoiceList()
            },
            pageSizeChange (pageSize) {
                this.params.pageSize = pageSize
                this.GetInvoiceList()
            },
            update(row){//编辑
                this.showModal = true
                this.addShow = true
                this.orderId = row.id
                this.modalFormData.userId = row.userId
                this.modalFormData.invoiceSum = row.invoiceSum / 100
                this.modalFormData.createTime = row.createTime
                this.modalFormData.updateTime = row.updateTime
                this.modalFormData.state = row.state
                this.modalFormData.message = row.message
                this.modalFormData.email = row.email
                if(row.invoiceImgPath){ //只取一个
                    this.modalFormData.invoiceImgPath = row.invoiceImgPath
                }
                this.modalFormData.orderIds = row.orderIds
                this.orderName = row.orderName
                //详细费用
                this.orderList = row.orderInforDetailsCharges
                this.orderList.forEach((item)=>{
                    this.electricityTotal = this.electricityTotal + item.electricityTotal
                    this.serviceFeeDiscountPrice =  this.serviceFeeDiscountPrice + item.serviceFeeDiscountPrice
                    this.totalElectricQuantity = this.totalElectricQuantity + Number(item.totalElectricQuantity)
                })
                //
                this.userPhone = row.userPhone
                this.modalFormData.invoiceTitle = row.invoiceTitle
                this.titleType = row.titleType
                if(row.titleType === 0){
                    this.invoiceTitleId = row.companyName
                }else if(row.titleType === 1){
                    this.invoiceTitleId = row.titlehead
                }
                if(row.invoiceImgPath){ // 如果已上传
                    this.addShow = false
                    this.invoiceText = true
                }else{
                    this.addShow = true
                    this.invoiceText = false
                }
                this.updateEmail = row.extendedField1 //邮件状态
                //公司信息
                this.companyTax = row.companyTax
                this.registerAddress = row.registerAddress
                this.registerPhone = row.registerPhone
                this.depositBank = row.depositBank
                this.bankAccount = row.bankAccount
            },
            modalCancel () {//取消
                this.showModal = false
                this.btnLoading = false
                this.$refs.upload.clearFiles();
            },
            modalOk(){//确定
                this.btnLoading = true
                this.$refs.modalForm.validate(async (valid) => {
                    if (valid) {
                        let obj = {
                            id: this.orderId,
                            extendedField1: this.updateEmail,
                            UserId: this.modalFormData.userId,
                            InvoiceSum: parseInt(this.modalFormData.invoiceSum * 100),
                            Message: this.modalFormData.message,
                            State: this.modalFormData.state,
                            InvoiceImgPath: this.modalFormData.invoiceImgPath,
                            OrderIds: this.modalFormData.orderIds,
                            InvoiceTitle: this.modalFormData.invoiceTitle,
                            Email: this.modalFormData.email
                        }
                        if(!this.updateEmail){ //未发送
                            UpdateInvoiceInfo(obj).then((res)=>{
                                if (res.data.code === 0) {
                                    this.modalCancel()
                                    this.GetInvoiceList()
                                    this.sendEmial(obj) //发送邮件
                                } else {
                                    this.$Message.error(res.data.message)
                                }
                            })
                        }else if(this.updateEmail === '1'){//失败状态不发送
                            UpdateInvoiceInfo(obj).then((res)=>{
                                if (res.data.code === 0) {
                                    this.modalCancel()
                                    this.GetInvoiceList()
                                    this.$Message.success('保存成功！')
                                } else {
                                    this.$Message.error(res.data.message)
                                }
                            })
                        }
                        else{//发送成功不发送
                            UpdateInvoiceInfo(obj).then((res)=>{
                                if (res.data.code === 0) {
                                    this.modalCancel()
                                    this.GetInvoiceList()
                                    this.$Message.success('保存成功！')
                                } else {
                                    this.$Message.error(res.data.message)
                                }
                            })
                        }
                    }else{
                        this.btnLoading = false
                    }
                })
            },
            sendEmial(row){
                if(!row.extendedField1){ //未发送
                    this.loading = true
                    SendEmial({
                        id: row.id
                    }).then((res)=>{
                        if(res.data.code === 0){
                            this.GetInvoiceList()
                            this.loading = false
                            this.$Message.success('邮件发送成功！')
                        }else{
                            this.loading = false
                            this.$Message.error(res.data.message)
                        }
                    })
                }else if(row.extendedField1 === '1'){//发送失败
                    this.loading = true
                    SendEmial({
                        id: row.id
                    }).then((res)=>{
                        if(res.data.code === 0){
                            this.GetInvoiceList()
                            this.loading = false
                            this.$Message.success('邮件发送成功！')
                        }else{
                            this.loading = false
                            this.$Message.error(res.data.message)
                        }
                    })
                }else{ //已成功发送
                    this.$Message.error("已发送成功，请勿再次发送！")
                }

            },
            beforeUpload(file){//上传前拦截
                let name = file.name.split('.')[file.name.split('.').length - 1].toLowerCase()
                if (name !== 'pdf' && name !== 'zip' && name !== 'rar') {
                    this.$Message.error('请上传pdf，zip，rar文件！')
                    return false
                }
            },
            uploadSuccess(response, file){//上传成功回调
                if(file.percentage === 100){
                    this.addShow = false
                }
                if(file.response.code === 0){
                    let data = file.response.data
                    this.modalFormData.invoiceImgPath = '/'+ data[0].url
                }
            },
            uploadPreview(file){ //预览文件
                if(file == 'view'){
                    window.open(baseUrl + this.modalFormData.invoiceImgPath, '_blank')
                }else{
                    if(file.response.code === 0){
                        let data = file.response.data
                        window.open(baseUrl +'/'+ data[0].url, '_blank')
                    }
                }
            },
            uploadRemove(){//移除列表
                this.addShow = true
                this.invoiceText = false
                this.modalFormData.invoiceImgPath = ""
            },
            delete(row){//删除
                this.$Modal.confirm({
                    title: '警告',
                    content: '是否删除该开票',
                    onOk:() => {
                        DeleteInvoiceInfo({
                            id: row.id
                        }).then((res) => {
                            if (res.data.code === 0) {
                                this.$Message.success('操作成功')
                                this.GetInvoiceList()
                            } else {
                                this.$Message.error(res.data.message)
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .help-wrapper{
        .help-content{
            box-sizing: border-box;
            overflow-y: auto;
        }
    }
    .form-content{
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 16px 16px 0 0;
        .three{
            width: 33%;
            margin-bottom: 20px;
            .upload-add{
                font-size: 30px;
                padding: 30px;
                border: 1px solid #ddd;
            }
        }
        .order-item{
            height: 180px;
            line-height: 20px;
            overflow-y: auto;
            .order-list{
                margin-bottom: 10px;
                .order-content{
                    padding: 10px 20px 10px;
                    .site-name{
                        font-size: 14px;
                    }
                    .info{
                        font-size: 13px;
                        .serve-price{
                            margin-left: 10px;
                        }
                        .total-price{
                            margin-left: 10px;
                        }
                        .ele-num{
                            margin-left: 10px;
                            color: #ff0000; 
                        }
                    }
                }
                .order-content:hover{
                    background-color: #f8f8f8;
                }
            }
        }
        .price-total{
            color: #ff0000;
            padding-left: 20px;
        }
        .upload{
            position: relative;
            .tips{
                font-size: 10px;
                color: #ff0000;
            }
        }
        /deep/.ivu-upload-list{
            position: relative;
            top: -46px;
        }
    }
    /deep/.leave-out>.ivu-table-cell>span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
