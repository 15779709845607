<template>
    <div class="help-wrapper" :style="{height: tableHeight + 'px'}">
        <search-form :fields="searchFields" :okLoading="loading" @on-search="toSearch" @on-reset="toReset" :selectData="searchSelectData"></search-form>
        <div class="help-content">
            <div class="table-top">
                <Button @click="toOpr('add')" v-has-permi="['PRIV_FEE_CREATE']"><Icon type="md-add"/> 新增</Button>
                <Button @click="toOpr('batchDistribute')" v-has-permi="['PRIV_FEE_BATCHDELIVERY']"><Icon type="md-add"/> 批量下发</Button>
            </div>
            <Table stripe :columns="columns" :data="list" :loading="loading" ref="table" :height="tableHeight - 62" @on-selection-change="selection"></Table>
        </div>
        <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
        <Modal :title="modalType === 'add' ? '新增' : '修改'"
           v-model="showModal"
           width="800"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           :mask-closable="false">
            <div slot="footer">
                <Button @click="modalCancel">取消</Button>
                <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
            </div>
            <Form :model="modalFormData" ref="modalForm" :label-width="120" :rules="modalRuleValid" v-if="showModal">
                <div class="form-content group">
                    <FormItem label="站点选择: " prop="name" class="half">
                        <Select v-model="modalFormData.name" label-in-value transfer @on-change="feeChecked" :disabled="isDetail">
                            <Option v-for="(item,index) in costNameList" :value="item.value" :tag="item.id" :key="index">{{ item.label }}</Option>
                        </Select>
                    </FormItem>
                </div>
                <div class="form-content group">
                    <FormItem label="电费策略名称: " prop="chargingFee.name" class="half">
                        <Select v-model="modalFormData.chargingFee.name" label-in-value transfer @on-change="chargingFeeChecked">
                            <Option v-for="(item,index) in eleNameList" :value="item.value" :tag="item.id" :key="index">{{ item.label }}</Option>
                        </Select>
                    </FormItem>
                    <div class="fee-content">
                        <div class="fee-title">
                            <span>尖</span>
                            <span>峰</span>
                            <span>平</span>
                            <span>谷</span>
                        </div>
                        <div class="fee-data">
                            <FormItem prop="chargingFee.fee1" class="half-ele">
                                <Input v-model="modalFormData.chargingFee.fee1" disabled><span slot="append">元/度</span></Input>
                            </FormItem>
                            <FormItem prop="chargingFee.fee2" class="half-ele">
                                <Input v-model="modalFormData.chargingFee.fee2" disabled><span slot="append">元/度</span></Input>
                            </FormItem>
                            <FormItem prop="chargingFee.fee3" class="half-ele">
                                <Input v-model="modalFormData.chargingFee.fee3" disabled><span slot="append">元/度</span></Input>
                            </FormItem>
                            <FormItem prop="chargingFee.fee4" class="half-ele">
                                <Input v-model="modalFormData.chargingFee.fee4" disabled><span slot="append">元/度</span></Input>
                            </FormItem>
                        </div>
                    </div>
                </div>
                <div class="form-content group">
                    <div class="time-interval">
                        <FormItem label="服务费策略名称: " prop="serviceFeePlan.name" class="half">
                            <Select v-model="modalFormData.serviceFeePlan.name" label-in-value transfer @on-change="serviceFeeChecked">
                                <Option v-for="(item,index) in serveNameList" :value="item.value" :tag="item.id" :key="index">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <!-- <FormItem :label-width="90">
                            <Icon type="md-add-circle" class="icon-add" @click="toAddPrice"/> 添加价格类别
                        </FormItem> -->
                    </div>
                    <div class="fee-content service">
                        <div class="serve-title" v-if="modalFormData.serviceFeePlan.serviceFees.length">
                            <span>尖</span>
                            <span>峰</span>
                            <span>平</span>
                            <span>谷</span>
                            <span class="discount">会员折扣</span>
                        </div>
                        <div class="fee-data" v-for="(item,index) in modalFormData.serviceFeePlan.serviceFees" :key="index">
                            <div class="fee-left">
                                <Icon type="md-remove-circle" class="icon-price" @click="toDelPrice(index)"/>
                                <FormItem :prop="'serviceFeePlan.serviceFees.' + index +'.memberLevelId'" :rules="{required: true,message: '请选择会员级别'}" class="halfs">
                                    <Select v-model="modalFormData.serviceFeePlan.serviceFees[index].memberLevelId" class="fee-user" placeholder="会员级别" disabled>
                                        <Option v-for="item in serveList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                                    </Select>
                                </FormItem>
                            </div>
                            <div class="fee-list">
                                <FormItem :prop="'serviceFeePlan.serviceFees.' + index +'.fee1'" :rules="{required: true,message: '请输入尖时服务费'}" class="halfs">
                                    <Input v-model="modalFormData.serviceFeePlan.serviceFees[index].fee1" disabled><span slot="append">元/度</span></Input>
                                </FormItem>
                                <FormItem :prop="'serviceFeePlan.serviceFees.' + index +'.fee2'" :rules="{required: true,message: '请输入峰时服务费'}" class="halfs">
                                    <Input v-model="modalFormData.serviceFeePlan.serviceFees[index].fee2" disabled><span slot="append">元/度</span></Input>
                                </FormItem>
                                <FormItem :prop="'serviceFeePlan.serviceFees.' + index +'.fee3'" :rules="{required: true,message: '请输入平时服务费'}" class="halfs">
                                    <Input v-model="modalFormData.serviceFeePlan.serviceFees[index].fee3" disabled><span slot="append">元/度</span></Input>
                                </FormItem>
                                <FormItem :prop="'serviceFeePlan.serviceFees.' + index +'.fee4'" :rules="{required: true,message: '请输入谷时服务费'}" class="halfs">
                                    <Input v-model="modalFormData.serviceFeePlan.serviceFees[index].fee4" disabled><span slot="append">元/度</span></Input>
                                </FormItem>
                            </div>
                            <FormItem :prop="'serviceFeePlan.serviceFees.' + index +'.discountRate'" :rules="{required: true,message: '请输入会员折扣'}" class="halfs">
                                <Input v-model="modalFormData.serviceFeePlan.serviceFees[index].discountRate" disabled><span slot="append">%</span></Input>
                            </FormItem>
                        </div>
                    </div>
                </div>
                <div class="form-content group time-price">
                    <div class="time-interval">
                        <FormItem label="时段策略名称: " prop="timeSlicePlan.name" class="half">
                            <Select v-model="modalFormData.timeSlicePlan.name" label-in-value transfer @on-change="timeSliceChecked">
                                <Option v-for="(item,index) in timeNameList" :value="item.value" :tag="item.id" :key="index">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <!-- <FormItem :label-width="90">
                            <Icon type="md-add-circle" class="icon-add" @click="toAddTime"/> 添加时间区间
                        </FormItem> -->
                    </div>
                    <div class="fee-time" v-for="(price, pInd) in modalFormData.timeSlicePlan.timeSlices" :key="pInd">
                        <Icon type="md-remove-circle" class="icon-time" @click="toDelTime(pInd)"/>
                        <FormItem :prop="'timeSlicePlan.timeSlices.' + pInd +'.startTime'" :rules="{required: true,message: '请选择开始时间'}">
                            <Select v-model="modalFormData.timeSlicePlan.timeSlices[pInd].startTime" placeholder="请选择开始时间" disabled>
                                <Option v-for="item in timeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem :prop="'timeSlicePlan.timeSlices.' + pInd +'.endTime'" :rules="{required: true,message: '请选择结束时间'}">
                            <Select v-model="modalFormData.timeSlicePlan.timeSlices[pInd].endTime" placeholder="请选择结束时间" disabled>
                                <Option v-for="item in timeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem :prop="'timeSlicePlan.timeSlices.' + pInd +'.type'" :rules="{required: true,message: '请选择时段类别'}">
                            <Select v-model="modalFormData.timeSlicePlan.timeSlices[pInd].type" placeholder="请选择时段类别" disabled>
                                <Option v-for="item in timeInterval" :value="item.value" :key="item.value">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                    </div>
                </div>
            </Form>
        </Modal>
        <Modal :title="siteTitle"
           v-model="siteModal"
           width="800"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           :mask-closable="false">
            <div slot="footer">
                <Button @click="siteModal = false">取消</Button>
                <Button type="primary" @click="siteOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
            </div>
            <Transfer
                :data="siteList"
                :target-keys="selectList"
                :list-style="listStyle"
                :titles="['未选择站点','已选择站点']"
                :operations="['取消','绑定']"
                filter-placeholder="请输入搜索站点"
                filterable
                @on-change="handleChange">
            </Transfer>
        </Modal> 
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import SearchForm from '@/components/SearchForm'
    import {getMemberList,feePlanGetPage,feePlanCreate,FeePlanGetDetail,feePlanEdit,feePlanDel,SetSiteFeePlan,FeePlanPushById,ChargingFeeGetSelect,ServiceFeeGetSelect,TimeSliceGetSelect,ChargingFeeGetDetail,ServiceFeeGetDetail,TimeSliceGetDetail,GetSiteNotFeePlan} from '@/libs/api'
    import { mapState } from 'vuex'
    export default {
        name: 'cost',
        components: {
            SearchForm
        },
        data(){
            return {
                loading: false,
                searchForm: {},
                total: 0,
                params: {
                    pageIndex: 1,
                    pageSize: 20
                },
                list: [],
                showModal: false,
                siteModal: false,
                btnLoading: false,
                modalType: '',
                isDetail: false,
                modalFormData: {
                    name: "",
                    chargingFee: {
                        name: "",
                        fee1: null,
                        fee2: null,
                        fee3: null,
                        fee4: null
                    },
                    serviceFeePlan: {
                        name: "",
                        serviceFees: [
                            {
                                fee1: null,
                                fee2: null,
                                fee3: null,
                                fee4: null,
                                memberLevelId: null,
                                discountRate: null,
                            }
                        ]
                    },
                    timeSlicePlan: {
                        name: "",
                        timeSlices: [
                            {
                                startTime: null,
                                endTime: null,
                                type: null
                            }
                        ]
                    }
                },
                modalRuleValid: {
                    name: [{required: true, message: '请选择费率组名称'}],
                    'chargingFee.name': [{required: true, message: '请选择电费策略名称'}],
                    'chargingFee.fee1': [{required: true, message: '请输入尖时电价'}],
                    'chargingFee.fee2': [{required: true, message: '请输入峰时电价'}],
                    'chargingFee.fee3': [{required: true, message: '请输入平时电价'}],
                    'chargingFee.fee4': [{required: true, message: '请输入谷时电价'}],
                    'serviceFeePlan.name': [{required: true, message: '请选择服务费策略名称'}],
                    'timeSlicePlan.name': [{required: true, message: '请选择时段策略名称'}]
                },
                searchFields: [
                    {
                        label: '费率名称',
                        name: 'name',
                        type: 'input'
                    },
                    {
                        label: '下发状态',
                        name: 'isPush',
                        type: 'select'
                    }
                ],
                searchSelectData: {
                    isPush: [
                        {value: 1, label: '已下发'},
                        {value: 0, label: '未下发'}
                    ]
                },
                costNameList: [],
                eleNameList: [],
                serveNameList: [],
                timeNameList: [],
                columns: [
                    {
                        type: 'selection',
                        width: 60,
                        align: 'center'
                    },
                    {
                        key: 'index',
                        title: '序号',
                        width: 70,
                        align: 'center',
                        render: (h, params) => {
                            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
                            return (<span>{ind}</span>)
                        }
                    },
                    {
                        key: 'name',
                        title: '费率名称',
                        align: 'center',
                    },
                    {
                        key: 'sites',
                        title: '所属站点',
                        align: 'center',
                        render: (h, params) => {
                            let res = params.row.sites ? params.row.sites.join(',') : ''
                            return (<Tooltip max-width="660" transfer content={res}><div class='site-title'>{res}</div></Tooltip>)
                        }
                    },
                    {
                        key: 'isPush',
                        title: '下发状态',
                        align: 'center',
                        render: (h, params) => {
                            let res = params.row.isPush
                            return (<span>{ res ? '已下发' : '未下发'}</span>)
                        }
                    },
                    {
                        title: '操作',
                        align: 'center',
                        fixed: 'right',
                        width: 260,
                        render: (h, params) => {
                            return (
                                <span class="table-opr-btn">
                                    <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'edit', params.row)} v-has-permi={['PRIV_FEE_UPDATE']}>
                                        <i class="iconfont icon-bianji"></i> 编辑
                                    </a>
                                    <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'distribute', params.row)} v-has-permi={['PRIV_FEE_ISSUE']}>
                                        <i class="iconfont icon-xiafapeizhi2"></i> 下发
                                    </a>
                                    <a href="javascript:;" class="del" onClick={this.delRate.bind(this, params.row)} v-has-permi={['PRIV_FEE_DELETE']}>
                                        <i class="iconfont icon-shanchu"></i> 删除
                                    </a>
                                </span>
                            )
                        }
                    }
                ],
                serveList: [],
                timeList: [],
                timeInterval: [
                    {
                        value: 1,
                        label: '尖'
                    },
                    {
                        value: 2,
                        label: '峰'
                    },
                    {
                        value: 3,
                        label: '平'
                    },
                    {
                        value: 4,
                        label: '谷'
                    }
                ],
                // 穿梭框
                siteList: [], //未绑定站点
                selectList: [], //已绑定站点
                listStyle: { //自定义样式
                    width: '320px',
                    height: '570px'
                },
                planId: null, //获取的上级ID,
                siteTitle: '', //站点名称
                pushList: [] //下发列表
            }
        },
        computed: {
            ...mapState(['tableHeight'])
        },
        mounted () {
            this.$nextTick(() => {
                this.feePlanGetPage()
                getMemberList().then((res)=>{
                    if(res.data.code === 0){
                        res.data.data.forEach(item => {
                            this.serveList.push({
                                label: item.memberLevelName,
                                value: item.memberLevelId
                            })
                        })
                    }
                })
                for (let i = 0; i <= 24; i++) {
                    let it = i < 10 ? '0' + i : i
                    if (i === 24) {
                        this.timeList.push({
                            label: it + ':00',
                            value: it + ':00'
                        })
                    } else {
                        this.timeList.push({
                            label: it + ':00',
                            value: it + ':00'
                        },
                        {
                            label: it + ':30',
                            value: it + ':30'
                        })
                    }
                }
            })
        },
        methods: {
            toSearch (obj) { //搜索
                this.searchForm = obj
                this.params.pageIndex = 1
                this.feePlanGetPage()
            },
            toReset (obj) { //重置
                this.searchForm = obj
                this.feePlanGetPage()
            },
            toOpr (type, row) { //操作
                this.modalType = type
                switch (type){
                    case 'add':
                        this.showModal = true
                        this.isDetail = false
                        this.getSelect()
                        break
                    case 'edit':
                        this.showModal = true
                        this.isDetail = true
                        this.detailRate(row)
                        this.getSelect()
                        break
                    case 'distribute':
                        this.distribute(row)
                        break
                    case 'batchDistribute':
                        this.batchDistribute()
                        break
                }
            },
            pageChange (pageNum) {
                this.params.pageIndex = pageNum
                this.feePlanGetPage()
            },
            pageSizeChange (pageSize) {
                this.params.pageSize = pageSize
                this.feePlanGetPage()
            },
            modalOk () { //确定
                this.$refs.modalForm.validate(async (valid) => {
                    if (valid) {
                        if(this.modalFormData.timeSlicePlan.timeSlices[this.modalFormData.timeSlicePlan.timeSlices.length-1].endTime !== '24:00'){
                            this.$Message.error('时段不足24小时')
                        }else{
                            if(this.modalType === 'add'){ //新增
                                this.btnLoading = true
                                let res = await feePlanCreate(this.modalFormData)
                                if(res.data.code === 0){
                                    this.showModal = false
                                    this.btnLoading = false
                                    this.$Message.success('新增成功')
                                    this.resetFields()
                                    this.feePlanGetPage()
                                }else{
                                    this.btnLoading = false
                                    this.$Message.error(res.data.message)
                                }
                            }else{ //修改
                                this.btnLoading = true
                                let res = await feePlanEdit(this.modalFormData)
                                if(res.data.code === 0){
                                    this.showModal = false
                                    this.btnLoading = false
                                    this.$Message.success('修改成功')
                                    this.resetFields()
                                    this.feePlanGetPage()
                                }else{
                                    this.btnLoading = false
                                    this.$Message.error(res.data.message)
                                }
                            }
                        }
                    }
                })
            },
            modalCancel(){ //取消
                this.showModal = false
                this.resetFields()
            },
            toAddPrice(){ //添加服务费
                this.modalFormData.serviceFeePlan.serviceFees.push({ 
                    fee1: null,
                    fee2: null,
                    fee3: null,
                    fee4: null,
                    memberLevelId: null
                })
            },
            toDelPrice(index){
                if(this.modalFormData.serviceFeePlan.serviceFees.length > 1){
                    this.modalFormData.serviceFeePlan.serviceFees.splice(index, 1)
                }
            },
            toAddTime () { //添加时间段
                this.modalFormData.timeSlicePlan.timeSlices.push({
                    startTime: null,
                    endTime: null,
                    type: null
                })
            },
            toDelTime (index) {
                if(this.modalFormData.timeSlicePlan.timeSlices.length > 1){
                    this.modalFormData.timeSlicePlan.timeSlices.splice(index, 1)
                }
            },
            resetFields(){ //重置
                this.$refs.modalForm.resetFields()
            },
            async feePlanGetPage(){ //获取列表
                let params = Object.assign({}, this.params, this.searchForm)
                let res = await feePlanGetPage(params)
                if(res.data.code === 0){
                    res.data.data.dataSource.map(item=>{
                        item._disabled = item.isPush
                        return item
                    })
                    this.total = res.data.data.totalCount
                    this.list = res.data.data.dataSource
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            feeChecked(val){ //未下发的站点选中项
                this.modalFormData.name = val.value
            },
            async chargingFeeChecked(val){ //电费选中项
                let res = await ChargingFeeGetDetail({id:val.tag})
                if(res.data.code === 0){
                    this.modalFormData.chargingFee = res.data.data
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            async serviceFeeChecked(val){ //服务费选中项
                let res = await ServiceFeeGetDetail({id:val.tag})
                if(res.data.code === 0){
                    this.modalFormData.serviceFeePlan = res.data.data
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            async timeSliceChecked(val){ //时段选中项
                let res = await TimeSliceGetDetail({id:val.tag})
                if(res.data.code === 0){
                    this.modalFormData.timeSlicePlan = res.data.data
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            async getSelect(){ //查询列表
                this.eleNameList = []
                this.serveNameList = []
                this.timeNameList = []
                if(this.modalType == 'add'){
                    let NotFeePlan = await GetSiteNotFeePlan()
                    if(NotFeePlan.data.code === 0){
                        NotFeePlan.data.data.forEach(item => {
                            this.costNameList.push({label:item.siteName,value:item.siteName,id:item.siteId})
                        })
                    }else{
                        this.$Message.error(NotFeePlan.data.message)
                    }
                }
                
                let ChargingFee = await ChargingFeeGetSelect()
                let ServiceFee = await ServiceFeeGetSelect()
                let TimeSlice = await TimeSliceGetSelect()
                
                if(ChargingFee.data.code === 0){
                    ChargingFee.data.data.forEach(item => {
                        this.eleNameList.push({label:item.name,value:item.name,id:item.id})
                    })
                }else{
                    this.$Message.error(ChargingFee.data.message)
                }
                if(ServiceFee.data.code === 0){
                    ServiceFee.data.data.forEach(item => {
                        this.serveNameList.push({label:item.name,value:item.name,id:item.id})
                    })
                }else{
                    this.$Message.error(ServiceFee.data.message)
                }
                if(TimeSlice.data.code === 0){
                    TimeSlice.data.data.forEach(item => {
                        this.timeNameList.push({label:item.name,value:item.name,id:item.id})
                    })
                }else{
                    this.$Message.error(TimeSlice.data.message)
                }
            },
            async detailRate(row){ //获取详情
                let res = await FeePlanGetDetail({id:row.id})
                if(res.data.code === 0){
                    this.modalFormData = res.data.data
                    this.costNameList.push({label:res.data.data.name,value:res.data.data.name})
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            async siteOk(){ //绑定站点
                this.btnLoading = true
                let res = await SetSiteFeePlan({
                    feePlanId: this.planId,
                    siteIds: this.selectList
                })
                if(res.data.code === 0){
                    this.siteModal = false
                    this.btnLoading = false
                    this.$Message.success('操作成功')
                    this.feePlanGetPage()
                }else{
                    this.siteModal = false
                    this.btnLoading = false
                    this.$Message.error(res.data.message)
                }
            },
            delRate(row){ //删除费率
                this.$Modal.confirm({
                    title: '警告',
                    width: 300,
                    content: '确认删除该费率吗!',
                    loading: true,
                    onOk: async () => {
                        let res = await feePlanDel({id: row.id})
                        if(res.data.code === 0){
                            this.$Modal.remove()
                            this.$Message.success('操作成功')
                            this.feePlanGetPage()
                        }else{
                            this.$Modal.remove()
                            this.$Message.error(res.data.message)
                        }
                    }
                })
            },
            handleChange (newTargetKeys) { //设置站点
                this.selectList = [...new Set(newTargetKeys)]
            },
            selection(row){ //批量选择
                this.pushList = row.map(item => item.id)
            },
            distribute(row){ //下发
                if(row.isPush){
                    this.$Message.info('该费率已下发!')
                }else{
                    let pushId = [row.id]
                    this.$Modal.confirm({
                        title: '提示',
                        width: 300,
                        content: '确认下发该费率吗!',
                        loading: true,
                        onOk: async () => {
                            let res = await FeePlanPushById(pushId)
                            if(res.data.code === 0){
                                this.$Modal.remove()
                                this.$Message.success('操作成功')
                                this.feePlanGetPage()
                            }else{
                                this.$Message.error(res.data.message)
                            }
                        }
                    })
                }
            },
            batchDistribute(){ //批量下发
                if(this.pushList.length){
                    this.$Modal.confirm({
                        title: '提示',
                        width: 300,
                        content: '确认下发选中费率吗!',
                        loading: true,
                        onOk: async () => {
                            let res = await FeePlanPushById(this.pushList)
                            if(res.data.code === 0){
                                this.$Modal.remove()
                                this.$Message.success('操作成功')
                                this.feePlanGetPage()
                            }else{
                                this.$Message.error(res.data.message)
                            }
                        }
                    })
                }else{
                    this.$Message.warning('请选择批量下发的站点')
                }
            }
        },
        watch: {
            showModal(){
                this.modalFormData = {
                    name: "",
                    chargingFee: {
                        name: "",
                        fee1: null,
                        fee2: null,
                        fee3: null,
                        fee4: null
                    },
                    serviceFeePlan: {
                        name: "",
                        serviceFees: [
                            {
                                fee1: null,
                                fee2: null,
                                fee3: null,
                                fee4: null,
                                memberLevelId: null,
                                discountRate: null,
                            }
                        ]
                    },
                    timeSlicePlan: {
                        name: "",
                        timeSlices: [
                            {
                                startTime: null,
                                endTime: null,
                                type: null
                            }
                        ]
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import 'rate-manage';
    /deep/.ivu-modal-body{
        height: 608px;
        overflow: auto;
    }
</style>