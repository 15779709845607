<template>
  <div class="upload-content-wrapper" :class="'form-' + config.type + '-upload'">
    <ul class="upload-list" v-if="uploadList.length" :style="config.type === 'file' ? 'width: 100%;' : ''">
      <li v-for="(file, fInd) in uploadList" :key="fInd" :class="config.type === 'file' ? 'upload-long' : ''">
        <template v-if="file.status === 'finished'">
          <img :src="file.url" v-if="config.type === 'image'">
          <span v-else :title="file.name">{{file.name}}</span>
          <div class="upload-opr">
            <Icon class="upload-icon" type="ios-eye" @click="toImgView(uploadList, fInd)" v-if="config.type === 'image'"/>
            <Icon class="upload-icon icon-del" type="ios-trash" @click="toUploadDel(file, fInd)"/>
          </div>
        </template>
        <template v-else>
          <Progress v-if="file.showProgress" :percent="file.percentage" hide-info></Progress>
        </template>
      </li>
    </ul>
    <Upload :action="baseUrl + config.action"
      :headers="headers"
      :name="name"
      :show-upload-list="false"
      :accept="config.accept || 'images/*'"
      :format="config.format ? config.format : []"
      :max-size="config.maxSize || 10240"
      :default-file-list="defaultFileList"
      :on-success="(response, file, fileList) => uploadSuccess(response, file, fileList)"
      v-show="!uploadList || uploadList.length < config.count"
      :before-upload="(file) => beforeUpload(file)"
      :ref="'upload' + name"
      v-model="uploadIds"
      :multiple="config.count > 1">
      <Button icon="ios-cloud-upload-outline" v-if="config.type === 'file'">上传文件</Button>
      <Button v-else><Icon type="ios-add"/></Button>
      <Input v-model="uploadIds" style="display: none;"></Input>
    </Upload>
    <p class="upload-hint">
      <template v-if="config.hint">{{config.hint}}</template>
      <template v-else>上传格式:{{config.accept ? config.accept : 'images/*'}}; 最大上传个数: {{config.count}}; 大小限制:{{ computedImageSize(config.maxSize) }}以内</template>
    </p>
  </div>
</template>

<script>
import { baseUrl } from "@/libs/api.request"
import { uploadImage } from "@/libs/api"
import { getStorage } from "@/libs/tools";

export default {
  name: "UploadNew",
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    cfg: {
      type: Object
    },
    headers: {
      type: Object,
      default () {
        return {
          Authorization: 'Bearer ' + getStorage('token') ? getStorage('token') : '',
          SYSTOKEN: getStorage('token') ? getStorage('token') : ''
        }
      }
    }
  },
  data () {
    return {
      defaultCfg: {
        type: 'image',
        count: 5,
        action: uploadImage,
        accept: '.jpg,.jpeg,.png,.tiff,.raw,.bmp,.jfif,.svg',
        maxSize: 10240
      },
      config: {},
      baseUrl,
      uploadIds: '',
      defaultFileList: [], // 默认已上传列表
      uploadList: [], // 已上传列表
      imgViewList: [], // 图片预览数组
      imgViewInd: 0,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.config = Object.assign({}, this.defaultCfg, this.cfg)
      if (this.value) {
        let arr = this.value.indexOf(',') ? this.value.split(',') : [this.value]
        this.uploadIds = this.value
        arr.forEach((item) => {
          this.defaultFileList.push({
            percentage: 100,
            name: item.split('.')[0],
            url: this.baseUrl + '/UploadImage/' + item
          })
        })
      }
    })
  },
  methods: {
    uploadSuccess (response, file, fileList) { // 上传组件上传成功后的回调
      if (response.code === 0) {
        this.uploadList = fileList
        if (response.data.FileName) {
          file.url = this.baseUrl + '/UploadImage/' + response.data.FileName
          if (this.config.count === 1) {
            this.uploadIds = response.data.FileName
          } else {
            this.uploadIds = this.uploadIds ? this.uploadIds + ',' + response.data.FileName : response.data.FileName
          }
        } else {
          this.$Message.success('上传成功!')
        }
      } else {
        fileList.forEach((item, index) => {
          if (item.uid === file.uid) {
            fileList.splice(index, 1)
          }
        })
        this.$Message.error({
          content: response.message,
          duration: 2
        })
      }
    },
    beforeUpload (file) { // 上传组件上传前的回调
      let format = this.config.accept.toLowerCase()
      let name = file.name.split('.')[file.name.split('.').length - 1].toLowerCase()
      if (format.indexOf(name) === -1) {
        this.$Message.error('上传类型不符！')
        return false
      }
      if (file.size / 1024 > this.config.maxSize) {
        this.$Message.error('文件大小超出限制！')
        return false
      }
      let names = []
      if (this.uploadList.length) {
        if (this.uploadList.length >= this.config.count) {
          this.$Message.error('文件个数超出限制！')
          return false
        }
        this.uploadList.forEach((item) => {
          names.push(item.name)
        })
      }
      let nameStr = file.name.split('.')[0]
      if (names.length && names.indexOf(nameStr) > -1) {
        this.$Message.error('文件不能重复!')
        return false
      }
    },
    toUploadDel (data, fileInd) { // 删除上传成功的文件
      this.uploadList.splice(fileInd, 1)
      let fileId = this.uploadIds.toString()
      let fileIdArr = fileId.indexOf(',') > -1 ? fileId.split(',') : [fileId]
      fileIdArr.splice(fileInd, 1)
      this.uploadIds = fileIdArr.join(',')
    },
    toImgView (list, ind) { // 图片预览
      list.forEach((img, iInd) => {
        this.imgViewList[iInd] = img.url
      })
      this.imgViewInd = ind
      this.$viewerApi({
        images: this.imgViewList,
        options: {
          initialViewIndex: this.imgViewInd
        }
      })
    },
    computedImageSize (val) {
      val = val || 10240
      let num = ''
      let unit = 'M'
      if (val / 1024 > 1024) {
        unit = 'G'
        num = val / 1024 / 1024
      } else {
        num = val / 1024
      }
      return String(num).indexOf('.') > -1 ? num.toFixed(2) : num + unit
    }
  },
  watch: {
    uploadIds (val) {
      this.$emit('input', val)
    },
    defaultFileList () {
      this.$nextTick(() => {
        this.$refs['upload' + this.name] && (this.uploadList = this.$refs['upload' + this.name].fileList)
      })
    }
  }
};
</script>

<style scoped lang="less">
.upload-content-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.ivu-upload{
  .icon-upload{
    font-size: 40px;
    color: #42A9F3;
    cursor: pointer;
  }
}
.form-image-upload{
  .upload-content-wrapper{
    display: flex;
  }
  .ivu-upload{
    .ivu-btn{
      width: 80px;
      height: 80px;
      font-size: 56px;
      padding: 0;
      line-height: 1;
    }
  }
}
.upload-list{
  display: flex;
  flex-wrap: wrap;
  li{
    width: 80px;
    height: 80px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-radius: @radius;
    margin-right: @base;
    margin-bottom: @base;
    img{
      width: 100%;
      height: 100%;
      border-radius: @radius;
    }
    .upload-opr{
      display: none;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 6;
      position: absolute;
      left: 0;
      right: 0;
      background: rgba(0,0,0,0.5);
      border-radius: @radius;
      .upload-icon{
        margin-right: @base / 2;
        font-size: 22px;
        cursor: pointer;
        color: rgba(255,255,255,.8);
      }
    }
    &.upload-long{
      width: 100%;
      height: 50px;
      span{
        width: 90%;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 12px;
        line-height: 1.2;
      }
      .upload-opr{
        text-align: right;
        line-height: 4;
      }
    }
    &:hover{
      background: #f1f1f1;
      .upload-opr{
        display: block;
      }
    }
  }
}
.upload-hint{
  width: 100%;
  padding-top: @base;
  color: #42A9F3;
  font-size: 12px;
}
</style>
