<template>
    <div class="help-wrapper" :style="{height: tableHeight + 'px'}">
        <search-form :fields="searchFields" :okLoading="loading" @on-search="toSearch" @on-reset="toReset" :selectData="searchSelectData"></search-form>
        <div class="help-content">
            <div class="table-top">
                <Button @click="toOpr('add')" v-has-permi="['PRIV_CHARGEFEE_CREATE']"><Icon type="md-add"/> 新增</Button>
            </div>
            <Table stripe :columns="columns" :data="list" :loading="loading" ref="table" :height="tableHeight - 62"></Table>
        </div>
        <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
        <Modal :title="modalType === 'add' ? '新增' : '修改'"
           v-model="showModal"
           width="700"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           :mask-closable="false">
            <div slot="footer">
                <Button @click="modalCancel">取消</Button>
                <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
            </div>
            <Form :model="modalFormData" ref="modalForm" :label-width="120" :rules="modalRuleValid" v-if="showModal">
                <div class="form-content group">
                    <FormItem label="充电费策略名称: " prop="name" class="half">
                        <Input v-model="modalFormData.name"/>
                    </FormItem>
                    <div class="fee-content">
                        <div class="fee-title">
                            <span>尖</span>
                            <span>峰</span>
                            <span>平</span>
                            <span>谷</span>
                        </div>
                        <div class="fee-data">
                            <FormItem prop="fee1" class="half-ele">
                                <Input v-model="modalFormData.fee1" number><span slot="append">元/度</span></Input>
                            </FormItem>
                            <FormItem prop="fee2" class="half-ele">
                                <Input v-model="modalFormData.fee2" number><span slot="append">元/度</span></Input>
                            </FormItem>
                            <FormItem prop="fee3" class="half-ele">
                                <Input v-model="modalFormData.fee3" number><span slot="append">元/度</span></Input>
                            </FormItem>
                            <FormItem prop="fee4" class="half-ele">
                                <Input v-model="modalFormData.fee4" number><span slot="append">元/度</span></Input>
                            </FormItem>
                        </div>
                    </div>
                </div>
                <div>
                    <Transfer
                        :data="siteList"
                        :target-keys="selectList"
                        :list-style="listStyle"
                        :titles="['未选择站点','已选择站点']"
                        :operations="['取消','修改']"
                        filter-placeholder="请输入搜索站点"
                        filterable
                        @on-change="handleChange">
                    </Transfer>
                </div>
            </Form>
        </Modal>
    </div>
</template>

<script>
    import SearchForm from '@/components/SearchForm'
    import {ChargingFeeGetPage,ChargingFeeCreate,ChargingFeeGetDetail,ChargingFeeSiteInPlan,ChargingFeeEdit,ChargingFeeDelete,querySiteAll,ChargingFeePushById} from '@/libs/api'
    import { mapState } from 'vuex'
    export default {
        name: 'charg-fee',
        components: {
            SearchForm
        },
        data(){
            return {
                loading: false,
                searchForm: {},
                total: 0,
                params: {
                    pageIndex: 1,
                    pageSize: 20
                },
                list: [],
                showModal: false,
                btnLoading: false,
                searchFields: [
                    {
                        label: '充电费名称',
                        name: 'name',
                        type: 'input'
                    },
                    {
                        label: '下发状态',
                        name: 'isPush',
                        type: 'select'
                    }
                ],
                searchSelectData: {
                    isPush: [
                        {value: 1, label: '已下发'},
                        {value: 0, label: '未下发'}
                    ]
                },
                modalFormData: {
                    name: "",
                    fee1: null,
                    fee2: null,
                    fee3: null,
                    fee4: null
                },
                modalRuleValid: {
                    name: [{required: true, message: '请输入电费策略名称'}],
                    fee1: [{required: true, message: '请输入尖时电价'}],
                    fee2: [{required: true, message: '请输入峰时电价'}],
                    fee3: [{required: true, message: '请输入平时电价'}],
                    fee4: [{required: true, message: '请输入谷时电价'}],
                },
                columns: [
                    {
                        key: 'index',
                        title: '序号',
                        width: 70,
                        align: 'center',
                        render: (h, params) => {
                            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
                            return (<span>{ind}</span>)
                        }
                    },
                    {
                        key: 'name',
                        title: '充电费名称',
                        align: 'center'
                    },
                    {
                        title: '所属站点',
                        align: 'center',
                        render: (h, params) => {
                            let res = params.row.sites.join(',')
                            return (<Tooltip max-width="210" transfer content={res}><div class='site-title'>{res}</div></Tooltip>)
                        }
                    },
                    {
                        key: 'fee1',
                        title: '尖时电价',
                        align: 'center'
                    },
                    {
                        key: 'fee2',
                        title: '峰时电价',
                        align: 'center'
                    },
                    {
                        key: 'fee3',
                        title: '平时电价',
                        align: 'center'
                    },
                    {
                        key: 'fee4',
                        title: '谷时电价',
                        align: 'center'
                    },
                    {
                        key: 'isPush',
                        title: '下发状态',
                        align: 'center',
                        render: (h, params) => {
                            let res = params.row.isPush
                            return (<span>{ res ? '已下发' : '未下发'}</span>)
                        }
                    },
                    {
                        title: '操作',
                        align: 'center',
                        fixed: 'right',
                        width: 260,
                        render: (h, params) => {
                            return (
                                <span class="table-opr-btn">
                                    <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'edit', params.row)} v-has-permi={['PRIV_CHARGEFEE_UPDATE']}>
                                        <i class="iconfont icon-bianji"></i> 编辑
                                    </a>
                                    <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'distribute', params.row)} v-has-permi={['PRIV_CHARGEFEE_ISSUE']}>
                                        <i class="iconfont icon-xiafapeizhi2"></i> 下发
                                    </a>
                                    <a href="javascript:;" class="del" onClick={this.delRate.bind(this, params.row)} v-has-permi={['PRIV_CHARGEFEE_DELETE']}>
                                        <i class="iconfont icon-shanchu"></i> 删除
                                    </a>
                                </span>
                            )
                        }
                    }
                ],
                modalType: '',
                // 穿梭框
                siteList: [], //未绑定站点
                selectList: [], //已绑定站点
                listStyle: { //自定义样式
                    width: '287px',
                    height: '400px'
                }
            }
        },
        computed: {
            ...mapState(['tableHeight'])
        },
        mounted () {
            this.$nextTick(() => {
                this.ChargingFeeGetPage()
            })
        },
        methods: {
            toSearch (obj) { //搜索
                this.searchForm = obj
                this.params.pageIndex = 1
                this.ChargingFeeGetPage()
            },
            toReset (obj) { //重置
                this.searchForm = obj
                this.ChargingFeeGetPage()
            },
            toOpr (type, row) { //操作
                this.modalType = type
                switch (type){
                    case 'add':
                        this.getSite()
                        this.showModal = true
                        break
                    case 'edit':
                        this.detailRate(row)
                        this.showModal = true
                        break
                    case 'distribute':
                        this.distribute(row)
                        break
                }
            },
            pageChange (pageNum) {
                this.params.pageIndex = pageNum
                this.ChargingFeeGetPage()
            },
            pageSizeChange (pageSize) {
                this.params.pageSize = pageSize
                this.ChargingFeeGetPage()
            },
            modalOk () {
                this.$refs.modalForm.validate(async (valid) => {
                    if (valid) {
                        if(this.modalType === 'add'){ //新增
                            let res = await ChargingFeeCreate(Object.assign({},this.modalFormData,{sites: this.selectList}))
                            if(res.data.code === 0){
                                this.showModal = false
                                this.$Message.success('新增成功')
                                this.resetFields()
                                this.ChargingFeeGetPage()
                            }else{
                                this.$Message.error(res.data.message)
                            }
                        }else{ //修改
                            let res = await ChargingFeeEdit(Object.assign({},this.modalFormData,{sites: this.selectList}))
                            if(res.data.code === 0){
                                this.showModal = false
                                this.$Message.success('修改成功')
                                this.resetFields()
                                this.ChargingFeeGetPage()
                            }else{
                                this.$Message.error(res.data.message)
                            }
                        }
                    }
                })
            },
            modalCancel(){ //取消
                this.showModal = false
                this.resetFields()
            },
            resetFields(){ //重置
                this.$refs.modalForm.resetFields()
            },
            async getSite(){ //获取所有站点
                let res = await querySiteAll()
                if(res.data.code === 0){
                    res.data.data.forEach((item)=>{
                        this.siteList.push({
                            key: item.siteId,
                            label: item.siteName
                        })
                    })
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            async ChargingFeeGetPage(){ //获取列表
                let params = Object.assign({}, this.params, this.searchForm)
                let res = await ChargingFeeGetPage(params)
                if(res.data.code === 0){
                    this.total = res.data.data.totalCount
                    this.list = res.data.data.dataSource
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            async GetSiteInPlan(id){ //获取绑定站点
                let res = await ChargingFeeSiteInPlan({id})
                if(res.data.code === 0){
                    res.data.data.forEach((item)=>{
                        this.siteList.push({
                            key: item.siteId,
                            label: item.siteName
                        })
                        if(item.inPlan){
                            this.selectList.push(item.siteId)
                        }
                    })
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            async detailRate(row){ //详情
                this.GetSiteInPlan(row.id) //获取绑定站点
                let res = await ChargingFeeGetDetail({id:row.id})
                if(res.data.code === 0){
                    this.modalFormData = res.data.data
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            delRate(row){ //删除电价
                this.$Modal.confirm({
                    title: '警告',
                    width: 300,
                    content: '确认删除该电价吗!',
                    loading: true,
                    onOk: async () => {
                        let res = await ChargingFeeDelete({id: row.id})
                        if(res.data.code === 0){
                            this.$Modal.remove()
                            this.$Message.success('操作成功!')
                            this.ChargingFeeGetPage()
                        }else{
                            this.$Modal.remove()
                            this.$Message.error(res.data.message)
                        }
                    }
                })
            },
            handleChange (newTargetKeys) { //设置站点
                this.selectList = [...new Set(newTargetKeys)]
            },
            distribute(row){ //下发
                if(row.isPush){
                    this.$Message.info('该费率已下发!')
                }else{
                    this.$Modal.confirm({
                        title: '提示',
                        width: 300,
                        content: '确认下发该费率吗!',
                        loading: true,
                        onOk: async () => {
                            let res = await ChargingFeePushById({id:row.id})
                            if(res.data.code === 0){
                                this.$Modal.remove()
                                this.$Message.success('操作成功')
                                this.ChargingFeeGetPage()
                            }else{
                                this.$Message.error(res.data.message)
                            }
                        }
                    })
                }
            }
        },
        watch: {
            showModal(){
                this.modalFormData = { //清空表单
                    name: "",
                    fee1: null,
                    fee2: null,
                    fee3: null,
                    fee4: null
                }
                this.siteList = [] //清空获取站点
                this.selectList = [] //清空绑定站点
            }
        }
    }
</script>

<style lang="less" scoped>
@import 'rate-manage';
</style>