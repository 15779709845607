<template>
  <div class="help-wrapper">
    <search-form :fields="searchFields" @on-search="toSearch" @on-reset="toReset" :okLoading="loading" :selectData="searchSelectData" :fieldsVal="fieldsVal"></search-form>
    <div class="help-content" :style="{height: tableHeight + 'px'}">
      <div class="table-top">
        <Button @click="toOpr('add')" v-has-permi="['PRIV_COMPANY_CREATE']"><Icon type="md-add"/> 新增</Button>
      </div>
      <Table stripe :columns="columns" :data="list" :loading="loading" ref="table" :height="tableHeight - 62"></Table>
    </div>
    <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
    <Modal :title="oprType === 'add' ? '新增' : '编辑'"
      v-model="showModal"
      width="980"
      class="modal-detail modal-form"
      class-name="vertical-center-modal"
      :mask-closable="false">
      <div slot="footer">
        <Button @click="modalCancel">取消</Button>
        <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :model="modalFormData" ref="modalForm" :label-width="120" :rules="modalRuleValid">
        <div class="form-content">
          <FormItem label="运营商名称: " prop="companyName" class="three">
            <Input v-model="modalFormData.companyName"/>
          </FormItem>
          <FormItem label="运营商缩写: " prop="companyAbbreviation" class="three">
            <Input v-model="modalFormData.companyAbbreviation"/>
          </FormItem>
          <FormItem label="运营商负责人: " prop="companyPerson" class="three">
            <Input v-model="modalFormData.companyPerson"/>
          </FormItem>
          <FormItem label="运营商类型: " prop="companyType" class="three">
            <Select v-model="modalFormData.companyType">
              <Option v-for="(opt, oInd) in companyTypeOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
            </Select>
          </FormItem>
          <FormItem label="运营商创建时间: " prop="companyTime" class="three">
            <DatePicker type="date" v-model="modalFormData.companyTime"></DatePicker>
          </FormItem>
          <FormItem label="运营商电话: " prop="companyPhone" class="three">
            <Input v-model="modalFormData.companyPhone"/>
          </FormItem>
          <FormItem label="运营商地址: " prop="companyAdress" class="long">
            <Input v-model="modalFormData.companyAdress"/>
          </FormItem>
          <template v-if="modalFormData.companyType === 1">
            <FormItem label="运营商税号: " prop="companyDuty" class="three">
              <Input v-model="modalFormData.companyDuty"/>
            </FormItem>
            <FormItem label="开户银行: " prop="openingBank" class="three">
              <Input v-model="modalFormData.openingBank"/>
            </FormItem>
            <FormItem label="银行账户: " prop="bankAccount" class="three">
              <Input v-model="modalFormData.bankAccount"/>
            </FormItem>
            <FormItem label="运营商编号: " prop="operatorID" class="three">
              <Input v-model="modalFormData.operatorID" />
            </FormItem>
            <FormItem label="运营商状态: " prop="companyState" class="three">
              <Select v-model="modalFormData.companyState">
                <Option v-for="(opt, oInd) in companyStsOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="微信小程序费率: " prop="wechatRate" class="three">
              <Input v-model="modalFormData.wechatRate" type="number">
                <span slot="append">%</span>
              </Input>
            </FormItem>
            <FormItem label="支付宝小程序费率: " prop="zfbRate" class="three">
              <Input v-model="modalFormData.zfbRate" type="number">
                <span slot="append">%</span>
              </Input>
            </FormItem>
            <FormItem label="APP微信费率: " prop="wechatAppRate" class="three">
              <Input v-model="modalFormData.wechatAppRate" type="number">
                <span slot="append">%</span>
              </Input>
            </FormItem>
            <FormItem label="APP支付宝费率: " prop="zfbAppRate" class="three">
              <Input v-model="modalFormData.zfbAppRate" type="number">
                <span slot="append">%</span>
              </Input>
            </FormItem>
            <!-- <FormItem label="满电行费率: " prop="mdxRate" class="three">
              <Input  v-model="modalFormData.mdxRate" type="number">
                <span slot="append">%</span>
              </Input>
            </FormItem> -->
            <FormItem label="满电行服务费率" prop="mdxServerRateId" class="six">
              <Select v-model="modalFormData.mdxServerRateId">
                <Option v-for="(opt, oInd) in mdxServiceRateList"
                  :key="oInd"
                  :value="opt.mdxServerId"
                  :label="getSelectLabel(mdxServerTypeOpt, opt.mdxServerType)">
                  <span>
                    {{ getSelectLabel(mdxServerTypeOpt, opt.mdxServerType) }} -
                    <template v-if="opt.mdxServerType === 1">
                    {{opt.mdxServerElectricity}}度以内按照{{opt.mdxServerInitialRate}}元/每度电,超过按照{{opt.mdxServerNewRate}}元/每度电
                    </template>
                    <template v-if="opt.mdxServerType === 2">
                    {{ opt.mdxServerRegular }}元/每年/每杆枪
                    </template>
                    <template v-if="opt.mdxServerType === 3">
                    {{ opt.mdxServerInitialRate }}元/每度电
                    </template>
                  </span>
                </Option>
              </Select>
            </FormItem>
          </template>
          <FormItem label="运营商备注: " prop="companyRemark" class="long">
            <Input type="textarea" v-model="modalFormData.companyRemark" />
          </FormItem>
        </div>
      </Form>
    </Modal>
    <Modal :fullscreen="true" title="提现" :footer-hide="true" v-model="showSettleModal" class="settle-account-modal">
      <div class="account-total">
        <p class="settle-title">钱包汇总</p>
        <ul class="settle-total-count">
          <li class="bank-info">
            <p v-for="(item, index) in companyViewArr" :key="index">
              <label>{{ item.label }}: </label>
              <span>{{modData[item.key]}}</span>
            </p>
          </li>
          <li>
            <label>已提现(元)</label>
            <span>{{ withdrawedAmount }}</span>
            <span class="btn-box"><Button type="primary" @click="showWithdraw = true" v-if="modData.companyType !== 2">提现记录</Button></span>
            <span class="hint">已成功提现到绑定银行卡的金额汇总</span>
          </li>
          <li>
            <label>可提现(元)</label>
            <span>{{ withdrawalTotalAmount }}</span>
            <span class="btn-box"><Button type="primary" @click="toModalOpr('all')" v-if="modData.companyType !== 2">一键提现申请</Button></span>
          </li>
        </ul>
      </div>
      <div class="account-settle-content">
        <div class="settle-tab-box">
          <ul class="settle-tab">
            <li v-for="(sTab, sInd) in settleTab" :key="sInd" :class="{current: settleUlInd === sInd}">{{sTab.label}}</li>
          </ul>
          <div class="settle-tab-btn">
            <!--            <Button v-if="settleKey === 'withdraw'" @click="toBatchWithdraw">批量提现</Button>-->
          </div>
        </div>
        <search-form :fields="settleSearchFields" @on-search="toSettleSearch" @on-reset="toSettleReset" :okLoading="settleLoading" :selectData="settleSelectData" :fieldsVal="settleFieldsVal"></search-form>
        <Table stripe
          :columns="settleCol"
          :data="settleList"
          :loading="settleLoading"
          :height="tableHeight - 180"
          @on-selection-change="settleTableSelectChange"></Table>
      </div>
    </Modal>
    <Modal title="提现" v-model="settleApplyShow" width="750" class-name="vertical-center-modal modal-form">
      <div slot="footer">
        <Button @click="settleApplyCancel">取消</Button>
        <Button type="primary" @click="settleApplyOk" :loading="isWithdrawApply" :disabled="isWithdrawApply">确定</Button>
      </div>
      <Form :label-width="100">
        <div class="form-content">
          <template v-if="withdrawType === 'all'">
            <FormItem label="结束时间: " class="half">
              <DatePicker type="date" v-model="settleTime.endTime" @on-change="withdrawChange('end')"></DatePicker>
              <span class="withdraw-hint" v-if="withdrawBeforeEndTime">只能提现到{{withdrawBeforeEndTime}}</span>
            </FormItem>
            <template v-if="Object.keys(withdrawWaitingAllData).length">
              <div class="withdraw">
                <FormItem label="电量: ">
                  <span>{{(withdrawWaitingAllData.totalElectricQuantity / 10000).toFixed(2)}}度</span>
                </FormItem>
                <FormItem label="电费金额: ">
                  <span>{{(withdrawWaitingAllData.electricityDiscountTotal / 10000).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="服务费金额: ">
                  <span>{{(withdrawWaitingAllData.serviceFeeDiscountPrice / 10000).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="订单总金额: ">
                  <span>{{(withdrawWaitingAllData.paymentAmount / 10000).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="活动抵扣金额: ">
                  <span>{{(withdrawWaitingAllData.giftBalanceDeduction / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="预充值余额: ">
                  <span>{{(withdrawWaitingAllData.useDirectBlance / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="其他账户: ">
                  <span>{{(withdrawWaitingAllData.ykcDeduction / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="快电支付">
                  <span>{{(withdrawWaitingAllData.kuaiDianPay / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="满电行账户: " style="width: 100%;">
                  <span>
                    {{(withdrawWaitingAllData.withdrawalTotalAmount / 100).toFixed(2)}}元(微信账户{{(withdrawWaitingAllData.wxAccount / 100).toFixed(2)}}元 + 支付宝账户{{(withdrawWaitingAllData.zfbAccount / 100).toFixed(2)}}元)
                  </span>
                </FormItem>
              </div>
              <div class="withdraw">
                <FormItem label="微信手续费: ">
                  <span>{{(withdrawWaitingAllData.wxServiceCharge / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="支付宝手续费: ">
                  <span>{{(withdrawWaitingAllData.zfbServiceCharge / 100).toFixed(2)}}元</span>
                </FormItem>
  <!--              <FormItem label="APP微信手续费: ">
                  <span>{{(withdrawWaitingAllData.wxServiceCharge / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="APP支付宝手续费: ">
                  <span>{{(withdrawWaitingAllData.zfbServiceCharge / 100).toFixed(2)}}元</span>
                </FormItem>-->
                <FormItem label="满电行手续费: ">
                  <span>{{(withdrawWaitingAllData.mdxServiceCharge / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="实际到账金额: ">
                  <span>{{(withdrawWaitingAllData.amountReceived / 100).toFixed(2)}}元</span>
                </FormItem>
              </div>
              <div class="withdraw">
                <FormItem label="微信App支付金额: ">
                  <span>{{(withdrawWaitingAllData.wxAppPayMoney / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="微信App手续费: ">
                  <span>{{(withdrawWaitingAllData.wxAppServiceCharge / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="微信小程序支付金额: ">
                  <span>{{(withdrawWaitingAllData.wxxcxPayMoney / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="微信小程序手续费: ">
                  <span>{{(withdrawWaitingAllData.weChatServiceCharge / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="支付宝App支付金额: ">
                  <span>{{(withdrawWaitingAllData.zfbAppPayMoney / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="支付宝APP手续费: ">
                  <span>{{(withdrawWaitingAllData.zfbAppServiceCharge / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="支付宝小程序支付金额: ">
                  <span>{{(withdrawWaitingAllData.alipayPayMoney / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="支付宝小程序手续费: ">
                  <span>{{(withdrawWaitingAllData.alipayServiceCharge / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="满电行手续费: ">
                  <span>{{(withdrawWaitingAllData.mdxOrderServiceCharge / 100).toFixed(2)}}元</span>
                </FormItem>
                <FormItem label="运营商实收: ">
                  <span>{{(withdrawWaitingAllData.operatorReceived / 100).toFixed(2)}}元</span>
                </FormItem>
              </div>
            </template>
            <template v-if="!Object.keys(withdrawWaitingAllData).length">
              <FormItem class="long">
                <span>暂无提现清单!</span>
              </FormItem>
            </template>
          </template>
          <FormItem label="备注:" class="long">
            <Input type="textarea" v-model="settleApplyRemark" />
          </FormItem>
        </div>
      </Form>
    </Modal>
    <Modal title="提现记录" v-model="showWithdraw" width="1100" class-name="vertical-center-modal" :footer-hide="true">
      <search-form :fields="withdrawFields" :selectData="withdrawSelectData" @on-search="toWithdrawSearch" @on-reset="toWithdrawReset" :okLoading="loading"></search-form>
      <Table stripe
        :columns="withdrawCol"
        :data="withdrawList"
        :loading="withdrawLoading"
        :height="tableHeight - 150"></Table>
      <Page
        :total="withdrawTotal"
        :current="withdrawPageParams.pageIndex"
        :page-size="withdrawPageParams.pageSize"
        show-elevator
        show-sizer
        show-total
        @on-change="withdrawPageChange"
        @on-page-size-change="withdrawPageSizeChange"
        class="table-page1"></Page>
    </Modal>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import { mapState } from 'vuex'
import { companyAdd, companyQuery, companyMod, withdrawWaitingQuery, withdrawWaitingQuerySummary, withdrawAdd, withdrawQuery, withdrawApplyOk, getSumOperatorWithdrawal, insertRecharge, getMdxServiceRateQuery } from '@/libs/api'
import { companyStsOpt, companyTypeOpt, mdxServerTypeOpt } from '@/libs/dict'
import dayjs from 'dayjs'
import { getStorage, getSelectLabel } from '@/libs/tools'
import { getAllSiteList } from '@/libs/requestCommon'
let formData = {
  companyName: "",
  companyAbbreviation: "",
  companyPerson: "",
  companyTime: "",
  companyPhone: "",
  companyAdress: "",
  companyDuty: "",
  openingBank: "",
  bankAccount: "",
  companyState: 1,
  companyRemark: "",
  wechatRate: null,
  mdxRate: null,
  zfbRate: null,
  wechatAppRate: null,
  zfbAppRate: null,
  mdxServerRateId: null,
  companyType: 1,
  operatorID: ''
}
export default {
  name: "company-list",
  components: {
    SearchForm
  },
  data () {
    return {
      companyStsOpt,
      companyTypeOpt,
      mdxServerTypeOpt,
      user: getStorage('user') ? JSON.parse(getStorage('user')) : {},
      searchFields: [
        {
          label: '运营商名称',
          name: 'companyName',
          type: 'input'
        },
        {
          label: '运营商类型',
          name: 'companyType',
          type: 'select',
          useValue: 'value'
        }
      ],
      fieldsVal: {
        companyType: 1
      },
      searchParams: {
        companyType: 1
      },
      searchSelectData: {
        companyType: [{value: -1, label: '全部'}].concat(companyTypeOpt)
      },
      params: {
        pageIndex: 1,
        pageSize: 10,
        orderBy: 'companyTime',
        sort: 'descending'
      },
      loading: false,
      columns: [
        {
          type: 'index',
          title: '序号',
          width: 70,
          align: 'center',
          render: (h, params) => {
            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'companyName',
          title: '运营商名称',
          align: 'center',
          width: 150
        },
        {
          key: 'companyPerson',
          title: '运营商负责人',
          align: 'center',
          width: 150
        },
        {
          key: 'companyPhone',
          title: '运营商电话',
          align: 'center',
          width: 150
        },
        {
          key: 'companyAdress',
          title: '运营商地址',
          align: 'center',
          width: 180
        },
        {
          key: 'companyDuty',
          title: '运营商税号',
          align: 'center',
          width: 180
        },
        {
          key: 'openingBank',
          title: '开户银行',
          align: 'center',
          width: 150
        },
        {
          key: 'bankAccount',
          title: '银行账号',
          align: 'center',
          width: 180
        },
        {
          key: 'wechatRate',
          title: '微信费率(%)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = params.row.wechatRate / 100
            return (<span>{num}</span>)
          }
        },
        {
          key: 'zfbRate',
          title: '支付宝费率(%)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = params.row.zfbRate / 100
            return (<span>{num}</span>)
          }
        },
        // {
        //   key: 'mdxRate',
        //   title: '满电行费率(%)',
        //   align: 'center',
        //   width: 150,
        //   render: (h, params) => {
        //     let num = params.row.mdxRate / 100
        //     return (<span>{num}</span>)
        //   }
        // },
        {
          key: 'companyRemark',
          title: '运营商备注',
          width: 500
        },
        {
          key: 'opr',
          title: '操作',
          align: 'center',
          fixed: 'right',
          minWidth: 220,
          render: (h, params) => {
            let type = params.row.companyType
            return (
                <span class="table-opr-btn">
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'mod', params.row)} v-has-permi={['PRIV_COMPANY_UPDATE']}><i
                    class="iconfont icon-bianji"></i> 编辑</a>
                  { type === 1 ?
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'settle', params.row)} v-has-permi={['PRIV_COMPANY_WITHDRAW']}><i
                    class="iconfont icon-bianji"></i> 提现</a> : ''}
              </span>
            )
          }
        }
      ],
      list: [],
      total: 0,
      oprType: '',
      modData: {},
      showModal: false,
      modalFormData: Object.assign({}, formData),
      modalRuleValid: {
        companyName: [
          { required: true, message: '请输入运营商名称!' },
        ],
        companyPerson: [
          { required: true, message: '请输入运营商负责人!' },
        ],
        companyAdress: [
          { required: true, message: '请输入运营商地址!' },
        ],
        companyPhone: [
          { required: true, message: '请输入运营商电话!' },
        ],
        companyType: [
          { required: true, message: '请选择运营商类型!'},
        ],
        operatorID: [
          {maxLength: 9, message: '编号最大长度为9！'}
        ],
        companyDuty: [
          { required: true, message: '请输入运营商税号!' },
        ],
        openingBank: [
          { required: true, message: '请输入开户银行!' },
        ],
        bankAccount: [
          { required: true, message: '请输入银行账户!' },
        ],
        wechatRate: [
          { required: true, message: '请输入微信费率!' },
        ],
        zfbRate: [
          { required: true, message: '请输入支付宝费率!' },
        ],
        // mdxRate: [
        //   { required: true, message: '请输入满电行费率!' },
        // ],
        wechatAppRate: [
          { required: true, message: '请输入APP微信费率!' },
        ],
        zfbAppRate: [
          { required: true, message: '请输入APP支付宝费率!' },
        ],
        mdxServerRateId: [
          {required: true, message: '请选择满电行服务费率!'}
        ]
      },
      showSettleModal: false,
      companyViewArr: [
        {
          label: '运营商',
          key: 'companyName'
        },
        {
          label: '开户行名称',
          key: 'openingBank'
        },
        {
          label: '收款卡号',
          key: 'bankAccount'
        },
        {
          label: '运营商电话',
          key: 'companyPhone'
        }
      ],
      settleTab: [
        {
          label: '提现清单',
          key: 'withdraw'
        }
      ],
      settleUlInd: 0,
      settleKey: 'withdraw',
      settleLoading: false,
      settleSearchFields: [
        {
          label: '结束时间',
          name: 'endTime',
          type: 'date'
        },
        {
          label: '所属站点',
          name: 'siteIds',
          type: 'select'
        }
      ],
      settleSearchForm: {},
      settleFieldsVal: {
        siteIds: ''
      },
      settlePageParams: {
        companyId: ''
      },
      settleTime: {
        start: '',
        end: '',
        startTime: '',
        endTime: ''
      },
      settleSelectData: {
        siteIds: []
      },
      withdrawWaitingAllData: {},
      withdrawalTotalAmount: '', // 可提现金额
      withdrawedAmount: '', // 已提现金额
      settleCol: [
        {
          type: 'index',
          title: '序号',
          width: 80,
          align: 'center'
        },
        {
          key: 'siteName',
          title: '所属站点',
          align: 'center',
          width: 300
        },
        {
          key: 'totalElectricQuantity',
          title: '电量(度)',
          width: 150,
          align: 'center',
          render: (h, params) => {
            let str = (params.row.totalElectricQuantity / 10000).toFixed(2)
            return (<span>{str}</span>)
          }
        },
        {
          key: 'mdxNetReceipts',
          title: '满电行账户(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.mdxNetReceipts / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'giftBalanceDeduction',
          title: '活动抵扣金额(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.giftBalanceDeduction / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'ykcDeduction',
          title: '其他账户',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.ykcDeduction / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'serviceFeeDiscountPrice',
          title: '服务费折扣后价格(元)',
          align: 'center',
          minWidth: 150,
          render: (h, params) => {
            let num = (params.row.serviceFeeDiscountPrice / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        }
      ],
      settleList: [],
      settleApplyShow: false,
      settleApplyRemark: '',
      modalModData: {},
      settleSelectedList: [],
      showWithdraw: false,
      withdrawCol: [
        {
          key: 'index',
          title: '序号',
          align: 'center',
          width: 80,
          render: (h, params) => {
            let ind = params.index + 1 + (this.withdrawPageParams.pageIndex - 1) * this.withdrawPageParams.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'endTime',
          title: '订单结束时间',
          align: 'center',
          width: 180
        },
        {
          key: 'withdrawalNumber',
          title: '流水号',
          align: 'center',
          width: 200
        },
        {
          key: 'userName',
          title: '操作人',
          align: 'center',
          width: 120
        },
        {
          key: 'totalElectricQuantity',
          title: '电量(度)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.totalElectricQuantity / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'electricityDiscountTotal',
          title: '电费金额(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.electricityDiscountTotal / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'serviceFeeDiscountPrice',
          title: '服务费金额(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.serviceFeeDiscountPrice / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'paymentAmount',
          title: '订单总金额(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.paymentAmount / 10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'giftBalanceDeduction',
          title: '活动抵扣金额(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.giftBalanceDeduction / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'ykcDeduction',
          title: '其他账户(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.ykcDeduction / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'kuaiDianPay',
          title: '快电支付(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.kuaiDianPay / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'withdrawalTotalAmount',
          title: '提现金额(元)',
          align: 'center',
          width: 180,
          render: (h, params) => {
            let num = (params.row.withdrawalTotalAmount / 100).toFixed(2)
            let wxAccount = (params.row.wxAccount / 100).toFixed(2)
            let zfbAccount = (params.row.zfbAccount / 100).toFixed(2)
            return (<div class="withdraw-account-list">
              <p>微信: { wxAccount}元</p>
              <p>支付宝: {zfbAccount}元</p>
              <p>共: {num}元</p>
            </div>)
          }
        },
        {
          key: 'wxServiceCharge',
          title: '微信手续费(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.wxServiceCharge / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'zfbServiceCharge',
          title: '支付宝手续费(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.zfbServiceCharge / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'mdxServiceCharge',
          title: '满电行手续费(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.mdxServiceCharge / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'amountReceived',
          title: '到账金额(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let num = (params.row.amountReceived / 100).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: '',
          title: '提现状态',
          align: 'center',
          width: 150,
          render: (h, params) => {
            if (params.row.withdrawalStatus === 2) {// 已提现
              return (<span style="color: green;">已提现</span>)
            } else {
              return (<span style="color: red;">待执行</span>)
            }
          }
        },
        {
          key: 'withdrawalName',
          title: '提现运营商',
          align: 'center',
          width: 150
        },
        {
          key: 'withdrawalBank',
          title: '提现银行',
          align: 'center',
          width: 180,
        },
        {
          key: 'withdrawalAccount',
          title: '提现账户',
          align: 'center',
          width: 180
        },
        {
          key: 'withdrawalTime',
          title: '操作时间',
          align: 'center',
          width: 180
        },
        {
          key: 'withdrawalRemarks',
          title: '提现备注',
          minWidth: 200,
          align: 'center'
        },
        {
          key: 'opr',
          title: '操作',
          width: 200,
          fixed: 'right',
          render: (h, params) => {
            if (params.row.withdrawalStatus !== 2) {
              return (
                <span class="table-opr-btn">
                  <a href="javascript:;" class="mod" onClick={this.toModalOpr.bind(this, 'withdrawOk', params.row)}>
                    <i class="iconfont icon-bianji"></i> 执行</a>
                  <a href="javascript:;" class="detail" onClick={this.toModalOpr.bind(this, 'detail', params.row)}>
                    <i class="iconfont icon-chakanjiexi"></i> 订单明细</a>
                </span>
              )
            } else { // 已提现
              return (
                <span class="table-opr-btn">
                  <a href="javascript:;" class="detail" onClick={this.toModalOpr.bind(this, 'detail', params.row)}>
                  <i class="iconfont icon-chakanjiexi"></i> 订单明细</a>
                </span>
              )
            }
          }
        }
      ],
      withdrawList: [],
      withdrawLoading: false,
      withdrawTotal: 0,
      withdrawPageParams: {
        pageSize: 10,
        pageIndex: 1,
        withdrawalStatus: 0,
        companyId: ''
      },
      isWithdrawApply: false,
      withdrawType: '',
      withdrawFields: [
        {
          label: '提现状态',
          name: 'withdrawalStatus',
          type: 'select'
        }
      ],
      withdrawSelectData: {
        withdrawalStatus: [
          {
            label: '待执行',
            value: 1
          },
          {
            label: '已提现',
            value: 2
          }
        ]
      },
      withdrawBeforeEndTime: '',
      btnLoading: false,
      showRecharge: false,
      rechargeForm: {
        money: '',
        password: '',
        rechargeType: 1,
        rechargeRemarke: ''
      },
      rechargeRule: {
        money: [{required: true, message: '请输入金额!'}],
        password: [{required: true, message: '请输入密码!'}],
        rechargeRemarke: [{required: true, message: '请输入备注!'}]
      },
      mdxServiceRateList: []
    }
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  mounted () {
    this.$nextTick(() => {
      this.getList()
      this.getMdxServiceRateData()
    })
  },
  methods: {
    getSelectLabel,
    pageChange (pageNum) {
      this.params.pageIndex = pageNum
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    toSearch (obj) {
      this.searchParams = obj
      this.params.pageIndex = 1
      this.getList()
    },
    toReset (obj) {
      this.searchParams = obj
      this.getList()
    },
    async getList () {
      this.loading = true
      let sParams = {}
      Object.keys(this.searchParams).forEach((key) => {
        this.searchParams[key] && (sParams[key] = this.searchParams[key])
      })
      await companyQuery(Object.assign({}, sParams, this.params)).then((res) => {
        if (res.data.code === 0) {
          this.loading = false
          this.list = res.data.data.dataSource
          this.total = res.data.data.totalCount
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    toOpr (type, row) {
      this.oprType = type
      if (row) {
        this.modData = row
      }
      if (['add', 'mod'].includes(type)) {
        this.showModal = true
      } else if (type === 'settle') {
        this.showSettleModal = true
      } else if (type === 'recharge') {
        this.showRecharge = true
      }
    },
    toModalOpr (type, row) {
      this.modalModData = row
      if (['all'].includes(type)) { // 提现申请-分站点/一键提现 // settleApply
        this.settleApplyShow = true
        this.withdrawType = type
      } else if (type === 'settleApply') {
        this.$Message.info('正在开发中...')
      } else if (type === 'withdrawOk') { // 提现执行
        this.$Modal.confirm({
          title: '提示',
          content: '确认执行此条提现申请?',
          onOk: async () => {
            await withdrawApplyOk({
              withdrawalStatus: 2,
              withdrawalId: row.withdrawalId
            }).then((res) => {
              if (res.data.code === 0) {
                this.$Message.success('操作成功')
                this.getWithdrawList()
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      } else if (type === 'detail') { // 订单明细
        this.$router.push({
          path: '/statistics/order-statistics',
          query: {
            withdrawalNumber: row.withdrawalNumber,
            staTKey: 'detail'
          }
        })
      }
    },
    modalCancel () {
      this.showModal = false
    },
    modalOk () {
      this.$refs.modalForm.validate(async (valid) => {
        if (valid) {
          let url = {
            add: companyAdd,
            mod: companyMod
          }
          let formData = Object.assign({}, this.modalFormData)
          formData.companyTime = dayjs(new Date(this.modalFormData.companyTime)).format('YYYY-MM-DD')
          formData.mdxRate = (formData.mdxRate - 0) * 100
          formData.wechatRate = (formData.wechatRate - 0) * 100
          formData.zfbRate = (formData.zfbRate - 0) * 100
          formData.wechatAppRate = (formData.wechatAppRate - 0) * 100
          formData.zfbAppRate = (formData.zfbAppRate - 0) * 100
          if (this.oprType === 'mod') formData.companyID = this.modData.companyID
          this.btnLoading = true
          await url[this.oprType](formData).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.$Message.success('操作成功')
              this.showModal = false
              this.getList()
            } else {
              this.$Message.error(res.data.message)
            }
          })
        }
      })
    },
    initForm () {
      if (this.oprType === 'mod') {
        Object.keys(this.modalFormData).forEach(key => {
          if (['wechatRate', 'mdxRate', 'zfbRate', 'zfbAppRate', 'wechatAppRate'].includes(key)) {
            this.modalFormData[key] = (this.modData[key] / 100).toFixed(2)
          } else {
            this.modalFormData[key] = this.modData[key]
          }
        })
      } else {
        this.modalFormData = Object.assign({}, formData)
      }
    },
    toSettleSearch (obj) {
      this.settleSearchForm = obj
      this.getSettleModalList()
    },
    toSettleReset (obj) {
      this.settleSearchForm = obj
      this.getSettleModalList()
    },
    getSettleModalList () {
      this.settleLoading = true
      this.getWithdrawWaitingList()
    },
    async getWithdrawWaitingList () {
      let params = Object.assign({}, this.settleSearchForm, {
        companyId: this.modData.companyID
      })
      await withdrawWaitingQuery(params).then((res) => {
        if (res.data.code === 0) {
          this.settleLoading = false
          this.settleList = res.data.data
          // this.settleTime.start = res.data.data.OrderStartTime
          this.settleTime.end = dayjs(new Date()).subtract(1, 'day').format('YYYY-MM-DD')
        } else {
          this.settleLoading = false
          this.$Message.error(res.data.message)
        }
      })
    },
    async getWithdrawWaitingAll (callback) { // 待提现-所有
      let params = Object.assign({}, this.settlePageParams, {
        companyId: this.modData.companyID,
        // startTime: this.settleTime.startTime,
      })
      if (callback) {
        params.endTime = this.settleTime.endTime
      }
      await withdrawWaitingQuerySummary(params).then((res) => {
        if (res.data.code === 0) {
          if (callback) {
            callback(res)
          } else {
            if (res.data.data) {
              this.withdrawWaitingAllData = res.data.data
              this.withdrawalTotalAmount = (this.withdrawWaitingAllData.withdrawalTotalAmount / 100).toFixed(2)
            } else {
              this.withdrawWaitingAllData = {}
            }
            // this.settleTime.start = res.data.data.OrderStartTime
            // this.settleTime.end = res.data.data.OrderEndTime
          }
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    async getWithdrawAll () { // 已提现总金额
      await getSumOperatorWithdrawal({
        companyId: this.modData.companyID
      }).then((res) => {
        if (res.data.code === 0) {
          this.withdrawedAmount = (res.data.data.withdrawalTotalAmount / 100).toFixed(2)
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    settleApplyCancel (){
      this.settleApplyShow = false
    },
    async settleApplyOk () {
      if (this.withdrawWaitingAllData.withdrawalTotalAmount <= 0) {
        return this.$Message.error('提现金额必须大于0!')
      }
      this.isWithdrawApply = true
      let data = {
        operator: this.user.userWxOpenId,
        withdrawalRemarks: this.settleApplyRemark,
        endTime: dayjs(new Date(this.settleTime.endTime)).format('YYYY-MM-DD'),
        companyId: this.modData.companyID,
        siteOperator: this.modData.companyID
      }
      /*if (this.settleTime.startTime) {
        data.startTime =  dayjs(new Date(this.settleTime.startTime)).format('YYYY-MM-DD')
      }*/
      if (this.withdrawType === 'all') {
        // data.siteIds = this.withdrawWaitingAllData.siteIds
      } else {
        let row = this.modalModData
        let siteIds = []
        if (this.settleSelectedList.length) {
          this.settleSelectedList.forEach((item) => {
            siteIds.push(item.siteId)
          })
        } else {
          siteIds = [row.siteId]
        }
        // data.siteIds =  siteIds.join(',')
      }
      await withdrawAdd(data).then((res) => { // 提现申请
        if (res.data.code === 0) {
          this.$Message.success('操作成功')
          this.getSettleModalList()
          this.getWithdrawWaitingAll()
          this.settleApplyShow = false
          this.isWithdrawApply = false
        } else {
          this.isWithdrawApply = false
          this.$Message.error(res.data.message)
        }
      })
    },
    async getSiteData () {
      getAllSiteList((arr) => {
        this.settleSelectData.siteIds = arr
        this.settleFieldsVal.siteIds = this.user.siteIds ? (this.user.siteIds.indexOf(',') > -1 ? this.user.siteIds.split(',') : [this.user.siteIds]) : []
      })
    },
    settleTableSelectChange (selection) {
      this.settleSelectedList = selection
    },
    toBatchWithdraw () {
      /*if (!this.settleSelectedList.length) {
        return this.$Message.error('请至少选择一条数据!')
      }
      this.settleApplyShow = true
      this.withdrawType = 'settleApply'*/
      this.$Message.info('正在开发中...')
    },
    async getWithdrawList () { // 待提现列表-分站点
      await withdrawQuery(this.withdrawPageParams).then((res) => {
        if (res.data.code === 0) {
          this.withdrawList = res.data.data.dataSource
          this.withdrawTotal = res.data.data.totalCount
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    withdrawPageChange (pageNum) {
      this.withdrawPageParams.pageIndex = pageNum
      this.getWithdrawList()
    },
    withdrawPageSizeChange (pageSize) {
      this.withdrawPageSizeChange.pageSize = pageSize
      this.getWithdrawList()
    },
    withdrawChange (type) {
      if (type === 'start') {
        this.settleTime.startTime = dayjs(new Date(this.settleTime.startTime)).format('YYYY-MM-DD')
      }
      if (type === 'end') {
        if (this.settleTime.endTime) {
          this.settleTime.endTime = dayjs(new Date(this.settleTime.endTime)).format('YYYY-MM-DD')
        }
      }
      this.getWithdrawWaitingAll((res) => {
        this.withdrawWaitingAllData = res.data.data ? res.data.data : {}
      })
    },
    toWithdrawSearch (obj) {
      this.withdrawPageParams.withdrawalStatus = obj.withdrawalStatus ? obj.withdrawalStatus : 0
      this.getWithdrawList()
    },
    toWithdrawReset () {
      this.withdrawPageParams.withdrawalStatus = 0
      this.getWithdrawList()
    },
    rechargeCancel () {
      this.showRecharge = false
      this.btnLoading = false
    },
    rechargeOk () {
      this.$refs.rechargeFormRef.validate(async (valid) => {
        if (valid) {
          this.btnLoading = true
          await insertRecharge({
            userOpenId: this.modData.operatorID,
            money: Number((this.rechargeForm.money * 100).toFixed(2)),
            operator: this.user.userWxOpenId,
            password: this.rechargeForm.password,
            rechargeRemarke: this.rechargeForm.rechargeRemarke,
            rechargeType: this.rechargeForm.rechargeType
          }).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.$Message.success('操作成功')
              this.getList()
              this.showRecharge = false
            } else {
              this.$Message.error(res.data.message)
            }
          }).catch(() => {
            this.btnLoading = false
          })
        }
      })
    },
    async getMdxServiceRateData () {
      await getMdxServiceRateQuery().then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data
          let tempArr = []
          if (data.length) {
            data.forEach((item) => {
              let tempItem = {}
              Object.keys(item).forEach((key) => {
                if (['mdxServerInitialRate', 'mdxServerNewRate', 'mdxServerRegular'].includes(key)) {
                  tempItem[key] = (item[key] / 100).toFixed(2)
                } else {
                  tempItem[key] = item[key]
                }
              })
              tempArr.push(tempItem)
            })
          }
          this.mdxServiceRateList = tempArr
        } else {
          this.$Message.error(res.data.message)
        }
      })
    }
  },
  watch: {
    showModal (val) {
      if (!val) {
        this.modData = {}
        this.oprType = ''
        this.$refs.modalForm.resetFields()
      } else {
        this.initForm()
      }
    },
    showSettleModal (val) {
      if (!val) {
        this.modalModData = {}
        this.settleTime = {
          start: '',
          end: '',
          startTime: '',
          endTime: ''
        }
        this.settleSelectedList = []
        this.withdrawWaitingAllData = {}
        this.withdrawType = ''
        this.withdrawalTotalAmount = ''
        this.withdrawedAmount = ''
      } else {
        this.getSettleModalList()
        this.getWithdrawWaitingAll()
        this.getWithdrawAll()
        this.getSiteData()
      }
    },
    settleApplyShow (val) {
      if (!val) {
        this.settleApplyRemark = ''
        this.isWithdrawApply = false
        this.settleTime.startTime = ''
        this.settleTime.endTime = ''
        this.getWithdrawWaitingAll()
      } else {
        this.settleTime.startTime = this.settleTime.start
        this.settleTime.endTime = dayjs(new Date(this.settleTime.end)).format('YYYY-MM-DD')
        this.withdrawBeforeEndTime = dayjs(new Date()).subtract(1, 'day').format('YYYY-MM-DD') + ' 23:59:59'
      }
    },
    showWithdraw (val) {
      if (!val) {
        this.withdrawList = []
        this.withdrawPageParams = {
          pageSize: 10,
          pageIndex: 1,
          companyId: this.modData.companyID
        }
        this.withdrawTotal = 0
        this.getWithdrawAll()
      } else {
        this.withdrawPageParams.companyId = this.modData.companyID
        this.getWithdrawList()
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "company-manage";
.help-wrapper{
  .title{
    padding: @base;
    font-size: 16px;
    background: #f1f1f1;
  }
  .help-content{
    margin-top: @base;
    box-sizing: border-box;
    overflow-y: auto;
  }
}
/deep/.withdraw-account-list{
  padding: @base 0;
  p{
    line-height: 1.6;
  }
}
.withdraw{
  width: 100%;
  margin-top: 10px;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ddd;
}
.long{
  margin-top: 10px;
}
.form-content{
  padding: 0;
}
// /deep/.ivu-form-item-label{
//   width: auto !important;
// }
</style>
