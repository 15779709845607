import { querySiteAll, getMemberList, companyQueryGet} from './api'
import { getStorage } from './tools'
import { getEncryptDecrypt, getSign } from '@/libs/apiSecond'
import dayjs from 'dayjs'
export function getAllSiteList (callback) {
  let user = getStorage('user') ? JSON.parse(getStorage('user')) : {}
  let params = {}
  if (user.siteIds) {
    params.siteIds = user.siteIds
  }
  querySiteAll(params).then((res) => {
    if (res.data.code === 0) {
      let data = res.data.data
      let arr = []
      if (data.length) {
        data.forEach((item) => {
          if (item.siteState !== 1) {
            arr.push({
              label: item.siteName,
              value: item.siteId.toString()
            })
          }
        })
      }
      callback && callback(arr)
    }
  })
}
export function getAllCompanyList (callback) {
  let user = getStorage('user') ? JSON.parse(getStorage('user')) : {}
  let params = {}
  // if ([6].includes(user.userType) && user.companyId) {
  //   params.companyId = user.companyId
  // }
  companyQueryGet(params).then((res) => {
    if (res.data.code === 0) {
      let data = res.data.data
      let arr = []
      if (data.length) {
        data.forEach((item) => {
          arr.push({
            label: item.companyName,
            value: item.companyID.toString(),
            companyType: item.companyType
          })
        })
      }
      callback && callback(arr)
    }
  })
}
export function getAllMemberList (callback) {
  getMemberList({}).then((res) => {
    if (res.data.code === 0) {
      let arr = []
      let data = res.data.data.MemberLevelManagement
      if (data.length) {
        data.forEach((item) => {
          if (item.memberLevelId === 6) {
            arr.unshift({
              label: item.memberLevelName,
              value: item.memberLevelId
            })
          } else {
            arr.push({
              label: item.memberLevelName,
              value: item.memberLevelId
            })
          }

        })
      }
      callback && callback(arr)
    }
  })
}

// 加密接口
export function getEncryptRequest ({data, OperatorID, success, error}) {
  if (!data || !OperatorID) {
    return this.$Message.error('缺少必传参!')
  }
  getEncryptDecrypt({
    Str: JSON.stringify(data), // 加密数据
    AesType: 1 // 1加密 2解密
  }).then((res) => {
    if (res.status === 200) {
      let Data = res.data
      let TimeStamp = dayjs(new Date()).format('YYYYMMDDHHmmss')
      getSign({ // 获取签名
        OperatorID: OperatorID,
        Data,
        Seq: '0001',
        TimeStamp: TimeStamp
      }).then((res2) => {
        if (res2.status === 200) {
          let Sig = res2.data
          success && success({
            OperatorID: OperatorID,
            Data,
            Seq: '0001',
            TimeStamp: TimeStamp,
            Sig
          })
        } else {
          if (error) {
            error(res2.data)
          } else {
            this.$Message.error(res2.data.Msg)
          }
        }
      })
    } else {
      if (error) {
        error(res.data)
      } else {
        this.$Message.error(res.data.Msg)
      }
    }
  })
}
// 解密接口数据
export function getDecryptRequest ({str, success, error}) {
  if (!str) {
    return this.$Message.error('缺少必传参!')
  }
  getEncryptDecrypt({
    Str: str,
    AesType: 2 // 1加密 2解密
  }).then((res) => {
    if (res.status === 200) {
      success && success(res.data)
    } else {
      if (error) {
        error(res.data)
      } else {
        this.$Message.error(res.data.Msg)
      }
    }
  })
}

