<template>
    <div class="elecontent">
        <search-form :fields="searchFields[staTInd].concat(searchCommonFields)" :okLoading="loading" :fieldsVal="fieldsVal[staTInd]" :selectData="searchSelectData" @on-search="toSearch" @on-reset="toReset" @on-change="searchChange" :minSize="3"></search-form>
        <div>
            <Table stripe :columns="columns" :data="list" :loading="loading" :height="tableHeight - 70" @on-sort-change="sortChange" @on-selection-change="tableSelectChange"></Table>
            <Page
                :total="total"
                :current="params.pageNo"
                :page-size="params.pageSize"
                show-elevator
                show-sizer
                show-total
                @on-change="pageChange"
                @on-page-size-change="pageSizeChange"
            class="table-page1"></Page>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import { mapState } from 'vuex'
    import { orderStateOpt, orderStartModeOpt } from '@/libs/dict'
    import { getAllSiteList, getAllCompanyList } from '@/libs/requestCommon'
    import SearchForm from '@/components/SearchForm'
    export default {
        name: 'ele-statistics',
        components: {
            SearchForm
        },
        mounted(){
            this.$nextTick(() => {
                this.getSiteData()
                this.getCompanyData()
            })
        },
        computed: {
            ...mapState(['tableHeight'])
        },
        data(){
            return{
                searchFields: {
                    0: [
                    {
                        type: 'month',
                        label: '时间',
                        name: 'yearMonth',
                        clearable: false,
                        editable: false
                    }
                    ],
                    1: [ // 汇总
                    {
                        type: 'month',
                        label: '时间',
                        name: 'yearMonth',
                        clearable: false,
                        editable: false
                    }
                    ],
                    2: [ // 明细
                    {
                        type: 'datetime',
                        label: '开始时间',
                        name: 'orderStartTime',
                        editable: false
                    },
                    {
                        type: 'datetime',
                        label: '结束时间',
                        name: 'orderEndTime',
                        editable: false
                    },
                    {
                        type: 'cascader',
                        label: '区域选择',
                        name: 'regionData',
                        multiple: true,
                        cls: 'half',
                        filterable: true
                    },
                    {
                        type: 'input',
                        label: '手机号',
                        name: 'userPhone'
                    },
                    {
                        type: 'input',
                        label: '流水号',
                        name: 'withdrawalNumber'
                    },
                    {
                        type: 'select',
                        label: '所属公司',
                        name: 'companyId',
                        useValue: 'value',
                        filterable: true
                    },
                    {
                        type: 'select',
                        label: '所属运营商',
                        name: 'affiliatedCompanyId',
                        useValue: 'value',
                        filterable: true
                    },
                    {
                        type: 'select',
                        label: '订单状态',
                        name: 'orderState',
                        useValue: 'value',
                        clearable: true
                    },
                    {
                        type: 'select',
                        label: '启动方式',
                        name: 'startupMode',
                        useValue: 'value',
                        clearable: true
                    }
                    ]
                },
                staTInd: this.$route.query.staTInd ? Number(this.$route.query.staTInd) : 0,
                searchCommonFields: [
                    {
                        type: 'select',
                        label: '站点名称',
                        name: 'siteIds',
                        useValue: 'value',
                        cls: 'half',
                        filterable: true,
                        multiple: true
                    }
                ],
                loading: false,
                fieldsVal: {
                    0: {
                        yearMonth: dayjs(new Date()).format('YYYY-MM'),
                        siteIds: []
                    },
                    1: {
                        yearMonth: dayjs(new Date()).format('YYYY-MM'),
                        siteIds: []
                    },
                    2: {
                        userPhone: this.$route.query.userPhone ? this.$route.query.userPhone : '',
                        withdrawalNumber: this.$route.query.withdrawalNumber ? this.$route.query.withdrawalNumber : '',
                        siteIds: []
                    }
                },
                searchSelectData: {
                    siteIds: [],
                    companyId: [],
                    affiliatedCompanyId: [],
                    orderState: orderStateOpt,
                    startupMode: orderStartModeOpt,
                    regionData: []
                },
                columns: [
                    {
                        key: 'index',
                        title: '序号',
                        width: 70,
                        align: 'center',
                        render: (h, params) => {
                            let ind = params.index + 1 + (this.params.pageNo - 1) * this.params.pageSize
                            return (<span>{ind}</span>)
                        }
                    },
                    {
                        key: 'siteName',
                        title: '所属站点',
                        align: 'center',
                        width: 200
                    },
                    {
                        key: 'totalElectricQuantity',
                        title: '总电量(度)',
                        align: 'center',
                        width: 120,
                        sortable: 'custom',
                        render: (h, params) => {
                            let num = (params.row.totalElectricQuantity / 10000).toFixed(2)
                            return (<span>{num}</span>)
                        }
                    },
                    {
                        key: 'tipCharge',
                        title: '尖电量(度)',
                        align: 'center',
                        minWidth: 120,
                        render: (h, params) => {
                            let num = (params.row.tipCharge / 10000).toFixed(2)
                            return (<span>{num}</span>)
                        }
                    },
                    {
                        key: 'peakCharge',
                        title: '峰电量(度)',
                        align: 'center',
                        minWidth: 120,
                        render: (h, params) => {
                            let num = (params.row.peakCharge / 10000).toFixed(2)
                            return (<span>{num}</span>)
                        }
                    },
                    {
                        key: 'averageCharge',
                        title: '平电量(度)',
                        align: 'center',
                        minWidth: 120,
                        render: (h, params) => {
                            let num = (params.row.averageCharge / 10000).toFixed(2)
                            return (<span>{num}</span>)
                        }
                    },
                    {
                        key: 'valleyCharge',
                        title: '谷电量(度)',
                        align: 'center',
                        minWidth: 120,
                        render: (h, params) => {
                            let num = (params.row.valleyCharge / 10000).toFixed(2)
                            return (<span>{num}</span>)
                        }
                    }
                ],
                list: [
                    {
                        siteName: '东二环核工业充电站',
                        totalElectricQuantity: '9923452',
                        tipCharge: '109384',
                        peakCharge: '3746548',
                        averageCharge: '234234',
                        valleyCharge: '121335'
                    },
                    {
                        siteName: '东二环核工业充电站',
                        totalElectricQuantity: '9923452',
                        tipCharge: '109384',
                        peakCharge: '3746548',
                        averageCharge: '234234',
                        valleyCharge: '121335'
                    },
                    {
                        siteName: '东二环核工业充电站',
                        totalElectricQuantity: '9923452',
                        tipCharge: '109384',
                        peakCharge: '3746548',
                        averageCharge: '234234',
                        valleyCharge: '121335'
                    },
                    {
                        siteName: '东二环核工业充电站',
                        totalElectricQuantity: '9923452',
                        tipCharge: '109384',
                        peakCharge: '3746548',
                        averageCharge: '234234',
                        valleyCharge: '121335'
                    },
                    {
                        siteName: '东二环核工业充电站',
                        totalElectricQuantity: '9923452',
                        tipCharge: '109384',
                        peakCharge: '3746548',
                        averageCharge: '234234',
                        valleyCharge: '121335'
                    }
                ],
                total: 0,
                params: {
                    pageSize: 20,
                    pageNo: 1,
                    sortColumn: 'totalElectricQuantity',
                    sortOrder: 0
                },
            }
        },
        methods: {
            toSearch (obj) {
                console.log(obj);
            },
            toReset (obj) {
                console.log(obj);
            },
            searchChange (val) {
                console.log(val);
            },
            async getSiteData () {
                getAllSiteList((arr) => {
                    this.searchSelectData.siteIds = arr
                })
            },
            async getCompanyData () {
                getAllCompanyList((arr) => {
                    this.searchSelectData.companyId = arr
                    let affiliatedArr = []
                    if (arr.length) {
                    arr.forEach((item) => {
                        if (item.companyType === 1) {
                            affiliatedArr.push(item)
                        }
                    })
                    }
                    this.searchSelectData.affiliatedCompanyId = affiliatedArr
                })
            },
            sortChange (params) {
                console.log(params);
            },
            tableSelectChange (selection) {
                console.log(selection);
            },
            pageChange (pageNo) {
                console.log(pageNo);
            },
            pageSizeChange (pageSize) {
                console.log(pageSize);
            }
        }
    }
</script>

<style lang="less" scoped>

</style>