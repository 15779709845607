<template>
  <Modal title="选择地址" v-model="show" width="700" class="map-picker-modal">
    <div slot="footer">
      <Button @click="show=false">取消</Button>
      <Button type="primary" @click="modalOk">确定</Button>
    </div>
    <div class="map-picker-content">
      <Input placeholder="请点击地图选择" element-id="search" v-model="data.address" @input="getSuggestions"/>
      <ul class="suggest-list">
        <li v-for="(sug, sInd) in suggestList" :key="sInd" @click="toAddress(sug)">
          <p class="title">{{sug.name}}</p>
          <p class="info">{{sug.address.length ? sug.address : ''}}</p>
        </li>
      </ul>
      <div class="map-picker" id="map-picker"></div>
    </div>
  </Modal>
</template>

<script>
import { center, mapKey ,SafeJsCode} from "@/libs/api"
import AMapLoader from '@amap/amap-jsapi-loader'
window._AMapSecurityConfig = {
  securityJsCode: SafeJsCode, //安全密钥
}
export default {
  name: "ChooseMap",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    center: {
      type: String,
      default: ''
    },
    inputVal: {
      type: Object,
      default: ()=>{}
    }
  },
  data () {
    return {
      show: false,
      data: {
        address: '',
        lng: '',
        lat: ''
      },
      AMap: null,
      marker: null,
      suggestList: [],
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.show = this.value
      this.initValue()
    })
  },
  methods: {
    initValue () {
      if (this.inputVal) this.data.address = this.inputVal.siteAddress
      if (this.center) {
        this.data.lat = this.center.split(',')[0]
        this.data.lng = this.center.split(',')[1]
      }else{
        this.data.lat = this.inputVal.stationLat
        this.data.lng = this.inputVal.stationLng
      }
    },
    async initMap () {
      //如果存在地图则销毁地图
      if(this.map){
        this.map.destroy()
      }
      let latLng = this.data.lng&&this.data.lat ? {lng:this.data.lng,lat:this.data.lat} : {lng:center.lng,lat:center.lat}
      //初始化高德地图
      let AMap = this.AMap = await AMapLoader.load({
        key: mapKey,  //设置您的key
        version: "2.0",
        plugins: ['AMap.Geocoder', 'AMap.AutoComplete'],
      })
      //初始化地图DOM
      this.map = new AMap.Map("map-picker", {
        viewMode: "2D",
        zoom:  (center.lng === latLng.lng && center.lat == latLng.lat) ? center.zoom : 12,
        center: [latLng.lng,latLng.lat],
        resizeEnable: true,
      })
      //添加默认标记点
      this.markerLayer(AMap,latLng.lng,latLng.lat)
      //获取城市
      let siteCity = this.inputVal.siteCity ? this.inputVal.siteCity : '达州'
      //标记点击事件
      this.map.on('click', (e)=> {
        //逆地理编码
        this.geocoder(AMap,e,siteCity)
        //如果有之前的点标记则删除
        if (this.marker) {
          this.marker.setMap(null)
          this.marker = null
        }
        //添加点标记
        this.markerLayer(AMap,e.lnglat.lng,e.lnglat.lat)
        this.data.lng = (e.lnglat.lng).toFixed(6)
        this.data.lat = (e.lnglat.lat).toFixed(6)
      })
      //搜索位置
      this.search(AMap,siteCity)
    },
    // 添加点击标记点事件
    markerLayer(AMap,lng,lat){
      let position = new AMap.LngLat(lng,lat)
      this.marker = new AMap.Marker({
        position: position,
      })
      this.map.setCenter(position)
      this.marker.setMap(this.map)
    },
     //逆地理编码
    geocoder(AMap,e,siteCity){
      let that = this
      let geocoder = new AMap.Geocoder({
        city: siteCity
      })
      let lnglat = [e.lnglat.lng,e.lnglat.lat]
      geocoder.getAddress(lnglat, (status, result)=> {
        if (status === 'complete' && result.info === 'OK') {
          that.data.address = result.regeocode.formattedAddress
        }
      })
    },
    //搜索位置
    search(AMap,siteCity){
      let that = this
      let autoOptions = {
        city: siteCity,
        input: 'search'
      }
      let autocomplete = new AMap.AutoComplete(autoOptions)
      autocomplete.on('complete',(e)=>{
        if(e.info === 'OK'&&e.type==='complete'){
          that.suggestList = e.tips
        }
      })
    },
    modalOk () {
      this.show = false
      this.$emit('on-ok', this.data)
    },
    getSuggestions () {
      this.suggestList = []
    },
    toAddress (item) {
      let AMap = this.AMap
      if (this.marker) {//删除点标记
        this.marker.setMap(null);
        this.marker = null;
      }
      if(item.location){
        this.data.lng = (item.location.lng).toFixed(6)
        this.data.lat = (item.location.lat).toFixed(6)
      }else{
        this.$Message.info("请选择其他位置")
        return false
      }
      this.data.address = item.district+item.address
      this.markerLayer(AMap,item.location.lng,item.location.lat)
      this.suggestList = []
    },
  },
  watch: {
    value (val) {
      this.show = val
    },
    show (val) {
      if (!val) {
        this.data = {
          address: '',
          lng: '',
          lat: ''
        }
        this.suggestList = []
      } else {
        this.initValue()
        setTimeout(() => {
          this.initMap()
        }, 200)
      }
      this.$emit('input', val)
    }
  }
};
</script>

<style scoped lang="less">
.map-picker-modal{
  /deep/.ivu-modal-body{
    padding: 0;
  }
}
.map-picker-content{
  position: relative;
  .ivu-input-wrapper{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1001;
    padding: @base * 2;
    width: 60%;
  }
  .suggest-list{
    position: absolute;
    left: @base;
    top:48px;
    z-index: 1001;
    padding: 0 @base;
    width: 57.5%;
    box-sizing: border-box;
    max-height: 350px;
    overflow-y: auto;
    li{
      padding: @base;
      background: #fff;
      border-bottom: 1px solid #dfdfdf;
      cursor: pointer;
      &:last-child{
        border-bottom: 0;
      }
      .title{
        color: #333;
        margin-bottom: @base / 2;
      }
      .info{
        color: #999;
        word-break: break-all;
        font-size: 12px;
      }
    }
  }
}
.map-picker{
  width: 100%;
  height: 400px;
}
</style>
