<template>
  <div class="help-wrapper">
    <search-form :fields="searchFields" :okLoading="loading"></search-form>
    <div class="help-content" :style="{height: tableHeight + 'px'}">
      <div class="title">文档列表</div>
      <Table stripe :columns="columns" :data="list" :loading="loading" ref="table"></Table>
    </div>
    <Page :total="total" :current="params.pageNo" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import { mapState } from 'vuex'
export default {
  name: "help-list",
  components: {
    SearchForm
  },
  data () {
    return {
      searchFields: [
        {
          label: '文档标题',
          name: 'rechargeNo',
          type: 'input'
        },
        {
          label: '文档分类',
          name: 'rechargeType',
          type: 'select'
        }
      ],
      loading: false,
      total: 0,
      params: {
        pageSize: 10,
        pageNo: 1
      },
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          key: 'index',
          title: '序号',
          width: 70,
          align: 'center'
        },
        {
          key: 'title',
          title: '文档标题',
          align: 'center',
          width: 200
        },
        {
          key: 'type',
          title: '文档分类',
          align: 'center',
          width: 120
        },
        {
          key: 'time',
          title: '编辑时间',
          align: 'center',
          width: 180
        },
        {
          key: 'person',
          title: '编辑人',
          align: 'center',
          width: 150
        },
        {
          key: 'opr',
          title: '操作',
          align: 'center',
          minWidth: 120
        }
      ],
      list: []
    }
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  methods: {
    pageChange (pageNum) {
      this.params.pageNum = pageNum
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
    }
  }
};
</script>

<style scoped lang="less">
.help-wrapper{
  .title{
    padding: @base;
    font-size: 16px;
    background: #f1f1f1;
  }
  .help-content{
    margin-top: @base;
    box-sizing: border-box;
    overflow-y: auto;
  }
}
</style>
