<template>
  <div class="help-wrapper">
    <div class="help-content-box">
      <div class="content-left">
        <p class="title">角色名称</p>
        <ul class="data-list role-list" :style="{height: tableHeight + 55 + 'px'}">
          <li v-for="(role, rInd) in roleList" :key="rInd" :class="{current: rInd === roleInd}" @click="roleChange(role, rInd)">
            {{role.name}}
          </li>
        </ul>
      </div>
      <div class="content-right">
        <search-form :fields="searchFields" @on-search="toSearch" @on-reset="toReset" :okLoading="loading"></search-form>
        <div class="help-content" :style="{height: tableHeight + 'px'}">
          <div class="table-top">
            <Button @click="toOpr('add')"><Icon type="md-add"/> 添加用户</Button>
            <Button @click="toOpr('delBatch')" type="error"><i class="iconfont icon-shanchu"></i> 删除用户</Button>
          </div>
          <Table stripe :columns="indexCol.concat(columns, oprCol)" :data="userList" :loading="loading" ref="table" :height="tableHeight - 12" @on-selection-change="roleUserSelectChange"></Table>
        </div>
      </div>
    </div>
    <Modal title="添加用户"
           v-model="showModal"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           :mask-closable="false"
           width="800">
      <div slot="footer">
        <Button @click="excludeModalCancel">取消</Button>
        <Button type="primary" @click="excludeModalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <search-form :fields="modalSearchFields" @on-search="toModalSearch" @on-reset="toModalReset" :okLoading="modalLoading" v-if="showModal"></search-form>
      <div style="height: 400px; overflow-y: auto;">
        <Table stripe
               :columns="excludeIndCol.concat(columns)"
               :data="excludeList"
               :loading="modalLoading"
               ref="modalTable"
               @on-selection-change="excludeSelectChange"></Table>
      </div>
      <Page :total="excludeTotal"
            :current="excludeParams.pageIndex"
            :page-size="excludeParams.pageSize"
            show-elevator show-sizer show-total
            class="table-page"
            @on-change="excludePageChange"
            @on-page-size-change="excludePageSizeChange"></Page>
    </Modal>
  </div>
</template>

<script>
import { getRolesQueryNoPage, getRoleUsersQuery, roleUsersAdd, roleUsersDel, getExcludeUsers } from '@/libs/api'
import { mapState } from 'vuex'
import SearchForm from '@/components/SearchForm'
export default {
  name: "role-users",
  components: {
    SearchForm
  },
  data () {
    return {
      roleList: [],
      userList: [],
      noRoleUserList: [],
      searchFields: [
        {
          label: '用户名称',
          name: 'userName',
          type: 'input',
          cls: 'three'
        },
        {
          label: '手机号码',
          name: 'phone',
          type: 'input',
          cls: 'three'
        }
      ],
      searchParams: {},
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
          fixed: 'left'
        },
        {
          key: 'userId',
          title: '用户Id',
          width: 100,
          align: 'center'
        },
        {
          key: 'userName',
          title: '用户姓名',
          width: 100,
          align: 'center'
        },
        {
          key: 'userPhone',
          title: '手机号码',
          width: 150,
          align: 'center'
        },
        {
          key: 'reviewComments',
          title: '备注',
          minWidth: 200,
          align: 'center'
        }
      ],
      indexCol: [
        {
          type: 'index',
          width: 80,
          align: 'center',
          title: '序号'
        },
      ],
      oprCol: [
        {
          key: 'opr',
          title: '操作',
          minWidth: 100,
          fixed: 'right',
          render: (h,params) => {
            return (
                <span class="table-opr-btn">
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'del', params.row)}>
                  <i class="iconfont icon-shanchu"></i> 删除</a>
              </span>
            )
          }
        }
      ],
      loading: false,
      roleUserSelectArr: [],
      oprType: '',
      roleInd: 0,
      showModal: false,
      modalLoading: false,
      modalSearchFields: [
        {
          label: '用户名称',
          name: 'userName',
          type: 'input',
          cls: 'three'
        },
        {
          label: '手机号码',
          name: 'phone',
          type: 'input',
          cls: 'three'
        }
      ],
      modalSearchParams: {},
      excludeParams: {
        pageSize: 20,
        pageIndex: 1
      },
      excludeTotal: 0,
      excludeIndCol: [
        {
          key: 'index',
          title: '序号',
          align: 'center',
          width: 80,
          render: (h, params) => {
            let ind = params.index + 1 + (this.excludeParams.pageSize * (this.excludeParams.pageIndex - 1))
            return (<span>{ind}</span>)
          }
        }
      ],
      excludeList: [],
      excludeSelectArr: [],
      btnLoading: false
    }
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  mounted () {
    this.$nextTick(() => {
      this.getRoleList()
    })
  },
  methods: {
    toSearch (obj) {
      this.searchParams = obj
      this.getRoleUserList()
    },
    toReset (obj) {
      this.searchParams = obj
      this.getRoleUserList()
    },
    toOpr (type, row) {
      this.oprType = type
      if (type === 'add') {
        this.showModal = true
      } else if (['del', 'delBatch'].includes(type)) {
        if (type === 'delBatch' && !this.roleUserSelectArr.length) {
          return this.$Message.error('请至少选择一条数据')
        }
        let ids = []
        let selectTemp = type === 'del' ? [row] : this.roleUserSelectArr
        selectTemp.forEach((item) => {
          ids.push(item.userWxOpenId)
        })
        this.$Modal.confirm({
          title: '删除',
          content: `确定删除${type === 'del' ? '该' : '选中'}用户?`,
          loading: true,
          onOk: async () => {
            await roleUsersDel({
              roleId: this.roleList[this.roleInd].id,
              userIds: ids
            }).then((res) => {
              if (res.data.code === 0) {
                this.$Message.success('操作成功!')
                this.getRoleUserList()
                this.$Modal.remove()
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      }
    },
    async getRoleList () {
      await getRolesQueryNoPage({}).then((res) => {
        if (res.data.code === 0) {
          this.roleList = res.data.data
          this.getRoleUserList()
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    roleChange (role, index) {
      this.roleInd = index
      this.getRoleUserList(role)
    },
    async getRoleUserList (role) {
      let formData = Object.assign({}, this.searchParams, {
        roleId: role ? role.id : this.roleList[this.roleInd].id
      })
      await getRoleUsersQuery(formData).then((res) => {
        if (res.data.code === 0) {
          this.userList = res.data.data
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    roleUserSelectChange (selection) {
      this.roleUserSelectArr = selection
    },
    toModalSearch (obj) {
      this.modalSearchParams = obj
      this.getExcludeUserList()
    },
    toModalReset (obj) {
      this.modalSearchParams = obj
      this.getExcludeUserList()
    },
    excludePageChange (pageIndex) {
      this.excludeParams.pageIndex = pageIndex
      this.getExcludeUserList()
    },
    excludePageSizeChange (pageSize) {
      this.excludeParams.pageSize = pageSize
      this.getExcludeUserList()
    },
    async getExcludeUserList () {
      let formData = Object.assign({}, this.modalSearchParams, this.excludeParams,{
        roleId: this.roleList[this.roleInd].id
      })
      this.modalLoading = true
      await getExcludeUsers(formData).then((res) => {
        this.modalLoading = false
        if (res.data.code === 0) {
          this.excludeList = res.data.data.dataSource
          this.excludeTotal = res.data.data.totalCount
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    excludeSelectChange (selection) {
      this.excludeSelectArr = selection
    },
    excludeModalCancel () {
      this.showModal = false
    },
    excludeModalOk () {
      if (!this.excludeSelectArr.length) {
        return this.$Message.error('请至少选择一条数据!')
      }
      let idArr = []
      this.excludeSelectArr.forEach((item) => {
        idArr.push(item.userWxOpenId)
      })
      this.btnLoading = true
      roleUsersAdd({
        roleId: this.roleList[this.roleInd].id,
        userIds: idArr
      }).then((res) => {
        this.btnLoading = false
        if (res.data.code === 0) {
          this.$Message.success('操作成功!')
          this.showModal = false
          this.getRoleUserList()
        } else {
          this.$Message.error(res.data.message)
        }
      })
    }
  },
  watch: {
    showModal (val) {
      if (val) {
        this.getExcludeUserList()
      } else {
        this.modalSearchParams = {}
        this.excludeParams = {
          pageSize: 20,
          pageIndex: 1
        }
        this.btnLoading = false
        this.modalLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
