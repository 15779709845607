<template>
    <div>
        <div ref="siterank" style="width:100%;height:240px;"></div>
        <Edialog :show="show" :info="info" @closeModal="closeModal"/>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import { ElectricQuantity} from '@/libs/apiBIgScreen'
    import Edialog from '../../modal'
    export default {
        components: {
            Edialog
        },
        name: 'index',
        mounted(){
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.init()
                },500)
            })
            this.time = setInterval(()=>{
                this.init()
                clearInterval(this.tiemSite)
                this.tiemSite = null
            },30000)
        },
        beforeDestroy(){
            clearInterval(this.time)
            clearInterval(this.tiemSite) //清除电站循环
            this.time = null
            this.tiemSite = null
        },
        data(){
            return {
                time: null,
                tiemSite: null,
                show: false,
                info: {}
            }
        },
        methods: {
            closeModal(){
               this.show = false 
            },
            init(){
                ElectricQuantity({ //充电站电量排行
                    topNum: 10,
                    OrderBy: 'TotalElectricQuantity',
                    Sort: 'descending'
                }).then((res)=>{
                    let data = res.data
                    if(data.code === 0){
                        let valueList = []
                        let nameList = []
                        data.data.forEach((item)=>{
                            valueList.push({value:parseInt(item.totalElectricQuantity/10000),siteCode: item.siteCode})
                            nameList.push(item.siteName.length > 10 ? item.siteName.substr(0,10) + '...' : item.siteName)
                        })
                        if (this.$refs.siterank == null) {
                            return
                        }
                        let myChart = echarts.getInstanceByDom(this.$refs.siterank);
                        if (myChart == null) { // 如果不存在，就进行初始化。
                            myChart = echarts.init(this.$refs.siterank)
                        }
                        window.addEventListener("resize", () => {
                            myChart.resize()
                        })
                        myChart.off("click")
                        myChart.on('click', (params) => {
                            this.show = true
                            this.info = Object.assign({},{showType:'expand'},params)
                        })
                        // 需要展示几条
                        const showNum = 5;

                        /* 函数 */
                        // 获取当前数据
                        let currentIndex = showNum; // 当前添加数据下标
                        let currentValueList = valueList.slice(0, showNum); // 当前数值列表
                        let currentNameList = nameList.slice(0, showNum); // 当前类型列表
                        // 改变当前数据
                        function getCurrentData(){
                            // 添加数据
                            currentValueList.push(valueList[currentIndex]);
                            currentNameList.push(nameList[currentIndex]);
                            // 重新切割
                            currentValueList = currentValueList.slice(1, showNum + 1);
                            currentNameList = currentNameList.slice(1, showNum + 1);
                            // 判断当前下标是否超过最大值
                            if (currentIndex < nameList.length - 1) {
                                currentIndex++;
                            } else {
                                currentIndex = 0;
                            }
                        }

                        let option = {
                            animationDuration: 0,
                            animationDurationUpdate: 1000,
                            animationEasing: 'linear',
                            animationEasingUpdate: 'linear',
                            isHorizon: true,
                            legend: {
                                show: false,
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item',
                                position: 'right',
                                formatter:(value)=>{
                                    return `
                                    ${value.name}  <br/>
                                    ${value.marker}
                                    ${value.seriesName} :
                                    ${value.value.toLocaleString()}度`
                                }
                            },
                            grid: {
                                left: "-150",
                                right: "50",
                                bottom: "0",
                                top: "30",
                                containLabel: true,
                            },
                            xAxis: [
                                {
                                    splitLine: {
                                        show: false,
                                    },
                                    type: "value",
                                    show: false,
                                }
                            ],
                            yAxis: [
                                {
                                    splitLine: {
                                        show: false,
                                    },
                                    axisLine: {//y轴
                                        show: false,
                                    },
                                    type: "category",
                                    id: "yAxis",
                                    axisTick: {
                                        show: false,
                                    },
                                    inverse: true, // 倒序
                                    boundaryGap: false, // 留白政策
                                    data: currentNameList,
                                    axisLabel: {
                                        color: "rgba(0, 0, 0, 0.65)",
                                        align: 'left',
                                        padding: [-30, 0, 0, 0],
                                        formatter: (value) => {
                                        let i = nameList.indexOf(value);
                                        if (i == 0) {
                                            return [`{lg1|${i + 1}} ` + "{title|" + value + "} "].join("\n");
                                        } else if (i == 1) {
                                            return [`{lg2|${i + 1}} ` + "{title|" + value + "} "].join("\n");
                                        } else if (i == 2) {
                                            return [`{lg3|${i + 1}} ` + "{title|" + value + "} "].join("\n");
                                        } else {
                                            return [`{lg|${i + 1}} ` + "{title|" + value + "} "].join("\n");
                                        }
                                        },
                                        rich: {
                                        lg1: {
                                            color: 'rgba(240, 106, 57, 1)',
                                            padding: 5,
                                            fontSize: 12,
                                            fontFamily: 'Source Han Sans CN-Regular',
                                        },
                                        lg2: {
                                            color: 'rgba(255, 176, 38, 1)',
                                            padding: 5,
                                            fontSize: 12,
                                            fontFamily: 'Source Han Sans CN-Regular',
                                        },
                                        lg3: {
                                            color: 'rgba(51, 207, 201, 1)',
                                            padding: 5,
                                            fontSize: 12,
                                            fontFamily: 'Source Han Sans CN-Regular',
                                        },
                                        lg: {
                                            color: 'rgba(51, 207, 255, 1)',
                                            padding: 5,
                                            fontSize: 12,
                                            fontFamily: 'Source Han Sans CN-Regular',
                                        },
                                        title: {
                                            color: '#fff',
                                            fontSize: 12,
                                            fontFamily: 'Source Han Sans CN-Regular',
                                        }
                                        }
                                    }
                                }
                            ],
                            series: [
                                {
                                    name: "电量",
                                    sitecode: '',
                                    type: "bar",
                                    id: "bar",
                                    barWidth: 10, // 柱子宽度
                                    label: {
                                        show: true,
                                        formatter: (value) => {
                                            return value.data.value.toLocaleString() + '度'
                                        },
                                        color: "#fff",
                                        fontFamily: "HarmonyOS Sans-Medium",
                                        fontSize: 10,
                                        position: 'right'
                                    },
                                    itemStyle: {
                                        borderRadius: [2, 3, 3, 2], // 圆角（左上、右上、右下、左下）
                                        color: {
                                        x: 0,
                                        y: 1,
                                        x2: 1,
                                        y2: 0,
                                        colorStops: [{
                                            offset: 0,
                                            color: 'rgba(57, 126, 240, 1)' // 0% 处的颜色
                                        }, {
                                            offset: 1,
                                            color: 'rgba(51, 207, 201, 1)' // 100% 处的颜色
                                        }]
                                        }
                                    },
                                    data: currentValueList
                                }
                            ]
                        };
                        // 循环更新数据
                        this.tiemSite = setInterval(() => {
                            getCurrentData();
                            myChart.setOption({
                                yAxis: [{
                                    id: "yAxis",
                                    data: currentNameList,
                                }],
                                series: [{
                                    id: 'bar',
                                    data: currentValueList,
                                }],
                            });
                        }, 5000)
                        myChart.setOption(option)
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>

</style>