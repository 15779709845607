<template>
  <div class="breadcrumb-new">
    <Breadcrumb>
      <label class="bread-head"><i class="iconfont icon-ico_site icon-address"></i> 您所在位置: </label>
      <template v-for="(item, index) in $route.matched">
        <BreadcrumbItem :key="index">
          {{item.meta.text}}
        </BreadcrumbItem>
      </template>
    </Breadcrumb>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped lang="less">
  @import "index";
</style>
