<template>
  <div class="test">
    <Form :model="form" :label-width="100">
      <FormItem label="桩号">
        <Input v-model="form.chargeNumber" />
      </FormItem>
      <FormItem style="width: 300px;">
        <Button @click="toStart">开始充电</Button>
        <Button @click="toStop">停止充电</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
/* eslint-disable */
import { /*testApi,*/ startCharge, stopCharge, querySite, querySiteStas} from '@/libs/apiSecond'
import { getStorage } from '@/libs/tools'
import { getEncryptRequest, getDecryptRequest } from '@/libs/requestCommon'

export default {
  name: "test.vue",
  data () {
    return {
      user: getStorage('user') ? JSON.parse(getStorage('user')) : null,
      form: {
        chargeNumber: ''
      },
      StartChargeSeq: 'MA005DBW1123456789123456729',
      ConnectorID: '10216'
    }
  },
  methods: {
    toStart () {
      getEncryptRequest({
        data: {
          // "EquipBizSeq":"MA005DBW1202212221814176381",
          // "ConnectorID":"10181",
          "StartChargeSeq": this.StartChargeSeq,
          "ConnectorID": this.ConnectorID,
          "QRCode":"czb",
          "PlateNum":"浙ADK3187"
        },
        OperatorID: this.user.OperatorID,
        success: (data) => {
          /*testApi(data).then(res => {
            if (res.data.Ret === 0) {
            } else {
              this.$Message.error(res.data.Msg)
            }
          })*/
          startCharge(data).then(res => {
            if (res.data.Ret === 0) {
              getDecryptRequest({
                str: res.data.Data,
                success: (dData) => {
                  console.log(dData)
                }
              })
            } else {
              this.$Message.error(res.data.Msg)
            }
          })
        }
      })
    },
    async toStop () {
      getEncryptRequest({
        data: {
          // StartChargeSeq: '24354254344232',
          // ConnectorID: '02'
          // "StartChargeSeq": this.StartChargeSeq,
          // "ConnectorID": this.ConnectorID
          StationID: '9AF87F8A7D38489BB0BE96463148C21C',
          StartTime: "2023-01-03",
          EndTime: "2023-01-03"
        },
        OperatorID: this.user.OperatorID,
        success: (data) => {
          /*querySite(data).then((res) => {
            if (res.data.Ret === 0) {
              getDecryptRequest({
                str: res.data.Data,
                success: (dData) => {
                  console.log(dData)
                }
              })
            } else {
              this.$Message.error(res.data.Msg)
            }
          })*/
          /*stopCharge(data).then((res) => {
            if (res.data.Ret === 0) {
              getDecryptRequest({
                str: res.data.Data,
                success: (dData) => {
                  console.log(dData)
                }
              })
            } else {
              this.$Message.error(res.data.Msg)
            }
          })*/
          querySiteStas(data).then((res) => {
            if (res.data.Ret === 0) {
              getDecryptRequest({
                str: res.data.Data,
                success: (dData) => {
                  console.log(dData)
                }
              })
            } else {
              this.$Message.error(res.data.Msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
