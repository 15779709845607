<template>
  <div class="help-wrapper">
    <search-form :fields="searchFields" @on-search="toSearch" @on-reset="toReset" :okLoading="loading" :selectData="searchSelectData" :fieldsVal="fieldsVal"></search-form>
    <div class="help-content" :style="{height: tableHeight + 'px'}">
      <div class="table-top">
        <Button @click="toOpr('add')" v-has-permi="['PRIV_USERS_CREATE']"><Icon type="md-add"/> 新增</Button>
      </div>
      <Table stripe :columns="columns" :data="list" :loading="loading" ref="table" :height="tableHeight - 62"></Table>
    </div>
    <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
    <Modal title="编辑"
           v-model="showModal"
           width="700"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           :mask-closable="false">
      <div slot="footer">
        <Button @click="modalCancel">取消</Button>
        <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :model="modalFormData" ref="modalForm" :label-width="120" :rules="modalRuleValid" v-if="showModal">
          <div class="form-content">
            <FormItem label="手机号: " prop="userPhone" class="half">
              <Input v-model="modalFormData.userPhone" disabled/>
            </FormItem>
            <FormItem label="姓名: " prop="userName" class="half">
              <Input v-model="modalFormData.userName" />
            </FormItem>
            <FormItem label="昵称: " prop="rateName" class="half">
              <Input v-model="modalFormData.userNickName" />
            </FormItem>
            <FormItem label="会员级别: " prop="membershipLevel" class="half">
              <Select v-model="modalFormData.membershipLevel">
                  <Option v-for="(opt, oInd) in memberList" :key="oInd" :value="opt.memberLevelId">{{opt.memberLevelName}}</Option>
              </Select>
            </FormItem>
            <FormItem label="所属运营商: " prop="companyId" class="half">
              <Select v-model="modalFormData.companyId">
                <Option v-for="(opt, oInd) in companyList" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="积分: " prop="userIntegral" class="half">
              <Input v-model="modalFormData.userIntegral" disabled/>
            </FormItem>
            <FormItem label="来源: " prop="userSource" class="half">
              <Input v-model="modalFormData.userSource" />
            </FormItem>
            <FormItem label="性别: " prop="userSex" class="half">
              <RadioGroup v-model="modalFormData.userSex">
                <Radio v-for="(opt, oInd) in userSexOpt" :key="oInd" :label="opt.value">{{opt.label}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="用户余额: " prop="userBlance" class="half">
              <Input v-model="modalFormData.userBlance" disabled>
                <label slot="append">元</label>
              </Input>
            </FormItem>
            <FormItem label="用户类型" prop="userType" class="half">
              <Select v-model="modalFormData.userType">
                <Option v-for="(opt, oInd) in userTypeOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="车牌号" prop="userCarNumber" class="long">
              <Input v-model="modalFormData.userCarNumber" />
            </FormItem>
            <FormItem label="是否允许多充" prop="isAllowOvercharge" class="half">
              <RadioGroup v-model="modalFormData.isAllowOvercharge">
                <Radio v-for="(opt, oInd) in isAllowOverchargeOpt" :key="oInd" :label="opt.value">{{opt.label}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="是否显示手机" prop="enabled" class="half">
              <RadioGroup v-model="modalFormData.enabled">
                <Radio v-for="(opt, oInd) in enabledOpt" :key="oInd" :label="opt.value">{{opt.label}}</Radio>
              </RadioGroup>
            </FormItem>
          </div>
      </Form>
    </Modal>
    <Modal title="车辆信息审核"
           v-model="showCheckModal"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           width="700"
           :mask-closable="false">
      <div slot="footer">
        <Button @click="checkModalCancel" :disabled="btnLoading" :loading="btnLoading">拒绝</Button>
        <Button type="primary" @click="checkModalOk" :disabled="btnLoading" :loading="btnLoading">同意</Button>
      </div>
      <Form :model="checkForm" :label-width="120" v-if="showCheckModal">
        <div class="form-content">
          <FormItem label="车辆图片: " class="long">
            <img v-if="modData.licenseUrls.length" :src="modData.licenseUrls[0]"
              class="check-car-pic"
              @click="toPicView(modData.licenseUrls)">
            <span v-if="modData.licenseUrls.length" class="check-img-len">{{modData.licenseUrls.length}}</span>
            <span v-else class="no-img-len">暂无图片</span>
          </FormItem>
          <FormItem label="备注: " prop="reviewComments" class="long">
            <Input type="textarea" placeholder="拒绝原因" v-model="checkForm.reviewComments"/>
          </FormItem>
        </div>
      </Form>
    </Modal>
    <Modal :title="oprType === 'add' ? '新增对账用户' : '删除用户'"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           v-model="showReconcileModal">
      <div slot="footer">
        <Button @click="reconcileModalCancel">取消</Button>
        <Button type="primary" @click="reconcileModalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :label-width="120" :model="reconcileForm" :rules="reconcileRule" ref="reconcileFormRef" v-if="showReconcileModal">
        <div class="form-content">
          <template v-if="oprType === 'add'">
            <FormItem label="所属站点: " class="long" prop="siteIds">
              <Select v-model="reconcileForm.siteIds" multiple>
                <Option v-for="(opt, oInd) in siteList" :value="opt.value" :key="oInd">{{opt.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="昵称: " class="long" prop="userNickName">
              <Input v-model="reconcileForm.userNickName"/>
            </FormItem>
          </template>
          <template v-if="oprType === 'del'">
           <FormItem class="long">
             确定要删除手机号为{{modData.userPhone}}的用户?
           </FormItem>
            <FormItem label="密码: " class="long" prop="pwd">
              <Input type="password" v-model="reconcileForm.pwd" />
            </FormItem>
          </template>
        </div>
      </Form>
    </Modal>
    <Modal title="手写余额"
        class="modal-detail modal-form"
        class-name="vertical-center-modal"
        v-model="showRecharge"
        :mask-closable="false">
      <div slot="footer">
        <Button @click="rechargeCancel">取消</Button>
        <Button type="primary" @click="rechargeOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :label-width="120" :model="rechargeForm" :rules="rechargeRule" ref="rechargeFormRef" v-if="showRecharge">
        <FormItem class="long">
          确定要为手机号为{{modData.userPhone}}的用户手写余额?
        </FormItem>
        <FormItem label="金额: " class="long" prop="money">
          <Input type="number" v-model="rechargeForm.money" >
            <span slot="append">元</span>
          </Input>
        </FormItem>
        <FormItem label="密码: " class="long" prop="password">
          <Input type="password" v-model="rechargeForm.password" />
        </FormItem>
        <FormItem label="充值余额类型"  class="long" prop="virtualAmount">
          <Select v-model="rechargeForm.virtualAmount">
            <Option v-for="(opt, oInd) in rechargeAmountTypeOpt" :key="oInd" :label="opt.label" :value="opt.value"></Option>
          </Select>
        </FormItem>
        <FormItem label="备注: " class="long" prop="rechargeRemarke">
          <Input type="textarea" v-model="rechargeForm.rechargeRemarke" />
        </FormItem>
      </Form>
    </Modal>
    <Modal title="流水明细"
      class="modal-detail modal-form"
      class-name="vertical-center-modal"
      v-model="definiteModal"
      :mask-closable="false"
      width="1220">
      <div slot="footer"></div>
      <div class="definiteModel">
        查询类型：
        <Select v-model="definiteModel" @on-change="definiteType" style="width:200px">
          <Option v-for="item in definiteItem" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
        付款方式：
        <Select v-model="definitePayTypeModel" @on-change="definitePayType" style="width:200px" :disabled="definiteShow">
          <Option v-for="item in definitePayTypeItem" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
        <Button :loading="exportLoading" :disabled="exportLoading" @click="exportDetail" class="export-button" v-has-permi="['PRIV_RECHARGE_EXPORT']"><Icon type="md-download"/>导出全部</Button>
      </div>
      <Table stripe :columns="definiteColumns" :data="definiteList" :loading="loading" :height="tableHeight - 62"></Table>
      <Page :total="definiteTotal" :current="definiteParams.pageIndex" :page-size="definiteParams.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="definitePageChange" @on-page-size-change="definitePageSizeChange"></Page>
    </Modal>
    <Modal
      title="提示"
      v-model="RefundModal"
      loading
      @on-ok="confirm"
      class-name="vertical-center-modal">
      <p style="text-align:center">请确认是否退款</p>
    </Modal>
    <Modal title="组织权限配置"
      class="modal-detail modal-form"
      class-name="vertical-center-modal permission"
      v-model="permissionModal"
      :mask-closable="false"
      width="400">
      <div slot="footer">
        <Button @click="permissionModal=false">取消</Button>
        <Button type="primary" @click="permissionConfirm" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Tree :data="cooperOperator" show-checkbox @on-check-change="checkCooperOperator"></Tree>
      <Tree :data="cooperationCompany" show-checkbox @on-check-change="checkCooperationCompany"></Tree>
      <Tree :data="operator" show-checkbox @on-check-change="checkOperator"></Tree>
    </Modal>
    <Loading :show="exportLoading" :styl="{background: 'rgba(255,255,255,.65)'}" text="导出中，请等待..."></Loading>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import { mapState } from 'vuex'
import { getUserList, getMemberList, userListUpdate, checkCarInfo, userReconcileAdd, userDel, insertRecharge, TradeRefund, getSendSms,definiteList,GetRelationTree,Authorize,flowDetails } from '@/libs/api'
import { userSexOpt, userTypeOpt, isAllowOverchargeOpt, rechargeAmountTypeOpt,enabledOpt } from '@/libs/dict'
import { getStorage } from '@/libs/tools'
import { baseUrl } from '@/libs/api.request'
import { getAllSiteList, getAllCompanyList } from '@/libs/requestCommon'
import { exportExcel } from '@/libs/tools'
import Loading from '@/components/Loading'
let formData = {
  consumptionOrder: '',
  membershipLevel: null,
  userAdminPwd: '',
  userBlance: null,
  userCarNumber: null,
  userFrozenBalance: null,
  userHeadPortrait: '',
  userId: null,
  userIntegral: null,
  userLastChargingNum: null,
  userLastLogin: '',
  userLastUseTime: null,
  userLogicalCard: '',
  userName: '',
  userNickName: '',
  userPhone: '',
  userPhysicalCard:'',
  userReasonFreezing: null,
  userSex: null,
  userShutdownPassword: null,
  userSource: null,
  userState: null,
  userType: 1,
  userWxOpenId: '',
  companyId: null,
  isAllowOvercharge: 0,
  enabled: null
}
export default {
  name: "user-list",
  components: {
    SearchForm,
    Loading
  },
  data () {
    return {
      baseUrl,
      userSexOpt,
      userTypeOpt,
      isAllowOverchargeOpt,
      enabledOpt,
      rechargeAmountTypeOpt,
      user: getStorage('user') ? JSON.parse(getStorage('user')) : {},
      searchFields: [
        {
          label: '手机号',
          name: 'userPhone',
          type: 'input'
        },
        {
          label: 'openId',
          name: 'userWxOpenId',
          type: 'input'
        },
        {
          label: '所属运营商',
          name: 'companyId',
          type: 'select',
          cls: 'half'
        },
        {
          label: '用户名称',
          name: 'userName',
          type: 'input'
        },
        {
          label: '车牌号',
          name: 'userCarNumber',
          type: 'input'
        },
        {
          label: '用户类型',
          name: 'userType',
          type: 'select'
        },
        {
          label: '会员级别',
          name: 'membershipLevel',
          type: 'select'
        }
      ],
      fieldsVal: {
        companyId: ''
      },
      searchParams: {},
      searchSelectData: {
        companyId: [],
        userType: userTypeOpt,
        membershipLevel: []
      },
      loading: false,
      total: 0,
      params: {
        pageSize: 10,
        pageIndex: 1
      },
      columns: [
        /*{
          type: 'selection',
          width: 60,
          align: 'center'
        },*/
        {
          key: 'index',
          title: '序号',
          width: 70,
          align: 'center',
          render: (h, params) => {
            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'userPhone',
          title: '手机号',
          align: 'center',
          width: 150
        },
        {
          key: 'userName',
          title: '姓名',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let str = params.row.userName || '--'
            return (<span>{str}</span>)
          }
        },
        {
          key: 'userNickName',
          title: '昵称',
          align: 'center',
          width: 150
        },
        {
          key: 'userWxOpenId',
          title: 'openId',
          align: 'center',
          minWidth: 300
        },
        {
          key: 'userBlance',
          title: '余额(元)',
          align: 'center',
          width:150,
          render: (h, params) => {
            let count = (params.row.userBlance / 100).toFixed(2)
            return (<span>{count}</span>)
          }
        },
        {
          key: 'giftBalance',
          title: '赠送余额(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let count = (params.row.giftBalance / 100).toFixed(2)
            return (<span>{count}</span>)
          }
        },
        {
          key: 'userMdxBlance',
          title: '满电行账户(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let count = (params.row.userMdxBlance / 100).toFixed(2)
            return (<span>{count}</span>)
          }
        },
        {
          key: 'ykcAccount',
          title: '其他账户(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let count = (params.row.ykcAccount / 100).toFixed(2)
            return (<span>{count}</span>)
          }
        },
        {
          key: 'virtualAccount',
          title: '虚拟账户(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let count = '--'
            if (params.row.userType === 3) { // 管理员
             count = (params.row.virtualAccount / 100).toFixed(2)
            }
            return (<span>{count}</span>)
          }
        },
        {
          key: 'userDirectBlance',
          title: '预充值账户(元)',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let count = (params.row.userDirectBlance / 100).toFixed(2)
            return (<span>{count}</span>)
          }
        },
        {
          key: 'memberLevelName',
          title: '会员等级',
          align: 'center',
          width: 100,
          render: (h, params) => {
            let str = params.row.memberLevelName ? params.row.memberLevelName : '--'
            return (<span>{str}</span>)
          }
        },
        {
          key: 'userLastLogin',
          title: '最后一次登录时间',
          align: 'center',
          width: 250
        },
        {
          key: 'userRemarke',
          title: '备注',
          align: 'center',
          width: 150
        },
        {
          key: 'opr',
          title: '操作',
          align: 'center',
          fixed: 'right',
          minWidth: 260,
          render: (h, params) => {
            /*<a href="javascript:;" className="mod" onClick={this.toOpr.bind(this, 'consumer', params.row)}>
              <i className="iconfont icon-chakanjiexi"></i> 消费记录</a>*/
            return (
              <span class="table-opr-btn">
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'mod', params.row)} v-has-permi={['PRIV_USERS_UPDATE']}>
                  <i class="iconfont icon-bianji"></i> 编辑</a>
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'view', params.row)} v-has-permi={['PRIV_USERS_ORDER']}>
                  <i class="iconfont icon-chakanjiexi"></i> 订单明细</a>
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'definite', params.row)} v-has-permi={['PRIV_USERS_DETAIL']}>
                  <i class="iconfont icon-chakanjiexi"></i> 流水明细</a>
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'check', params.row)} v-has-permi={['PRIV_USERS_CHECK']}>
                  <i class="iconfont icon-kaihuhedui"></i> 审核</a>
                 <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'del', params.row)} v-has-permi={['PRIV_USERS_DELETE']}>
                  <i class="iconfont icon-kaihuhedui"></i> 删除</a>
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'recharge', params.row)} v-has-permi={['PRIV_USERS_RECHARGE']}>
                  <i class="iconfont icon-bianji"></i> 手写余额</a>
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'refund', params.row)} v-has-permi={['PRIV_USERS_REFUND']}>
                  <i class="iconfont icon-bianji"></i> 退款</a>
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'permission', params.row)}>
                  <i class="iconfont icon-bianji"></i> 权限分配</a>
              </span>
            )
          }
        }
      ],
      list: [],
      oprType: '',
      modData: {},
      showModal: false,
      modalFormData: Object.assign({}, formData),
      modalRuleValid: {},
      showMemberOpt: false,
      memberList: [],
      companyList: [],
      showCheckModal: false,
      checkForm: {
        userWxOpenId: '',
        reviewComments: '',
        auditStatus: null
      },
      showReconcileModal: false,
      reconcileSiteIds: '',
      siteList: [],
      reconcileForm: {
        siteIds: [],
        userNickName: '',
        pwd: ''
      },
      reconcileRule: {
        siteIds: [{required: true, message: '请选择所属站点!'}],
        userNickName: [{required: true, message: '请输入昵称!'}],
        pwd: [{required: true, message: '请输入密码!'}]
      },
      showRecharge: false,
      rechargeForm: {
        money: '',
        password: '',
        virtualAmount: 0,
        rechargeRemarke: ''
      },
      rechargeRule: {
        money: [{required: true, message: '请输入金额!'}],
        password: [{required: true, message: '请输入密码!'}],
        rechargeRemarke: [{required: true, message: '请输入备注!'}]
      },
      btnLoading: false,

      userMdxBlance: 0,//账户余额
      userWxOpenId: '',//退款账号
      RefundModal: false, //退款

      //流水明细
      definiteModal: false,
      definiteShow: true,
      definiteTotal: 0,
      definiteParams: {
        pageSize: 20,
        pageIndex: 1
      },
      definiteColumns: [
        {
          key: 'index',
          title: '序号',
          width: 70,
          align: 'center',
          render: (h, params) => {
            let ind = params.index + 1 + (this.definiteParams.pageIndex - 1) * this.definiteParams.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'rechargeTime',
          title: '时间',
          align: 'center',
          width: 200
        },
        {
          key: 'userName',
          title: '用户',
          align: 'center',
          width: 150
        },
        {
          key: 'userPhone',
          title: '手机号',
          align: 'center',
          width: 150
        },
        {
          key: 'rechargeMoney',
          title: '金额',
          align: 'center',
          width: 100,
          render: (h, params) => {
            let num = (params.row.rechargeMoney /10000).toFixed(2)
            return (<span>{num}</span>)
          }
        },
        {
          key: 'rechargeState',
          title: '类型',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let payment = {
              'SUCCESS': '支付成功',
              'REFUND': '转入退款',
              'NOTPAY': '未支付',
              'CLOSED': '已关闭',
              'REVOKED': '已撤销',
              'USERPAYING': '支付中',
              'PAYERROR': '支付失败',
              'APPLYREFUND': '退款中',
              'SUCCESSREFUND': '退款完成',
              'REFUNDCLOSED': '退款关闭',
              'REFUNDABNORMAL': '退款异常',
              'Consumption': '消费成功',
            }
            return (<span>{payment[params.row.rechargeState] ? payment[params.row.rechargeState] : '--'}</span>)
          }
        },
        {
          key: 'paymentMethod',
          title: '支付方式',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let payment = {
              'wxpay': '微信',
              'zfbpay': '支付宝',
              'apppay': '微信APP',
              'zfbapppay': '支付宝APP',
              'SDCZ': '手动充值',
              'XTZS': '系统赠送',
              'YCZ': '预充值'
            }
            return (<span>{payment[params.row.paymentMethod] ? payment[params.row.paymentMethod] : '--'}</span>)
          }
        },
        {
          
          key: 'rechargeRemarke',
          title: '备注',
          align: 'center',
          width: 200
        }
      ],
      definiteList: [],
      definiteItem: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: 'SUCCESS',
          label: '支付成功'
        },
        {
          value: 'SUCCESSREFUND',
          label: '退款完成'
        },
        {
          value: 'Consumption',
          label: '消费成功'
        }
      ],
      definiteModel: 'all',
      definitePayTypeItem: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: 'wxpay',
          label: '微信'
        },
        {
          value: 'zfbpay',
          label: '支付宝'
        },
        {
          value: 'SDCZ',
          label: '手动充值'
        },
        {
          value: 'apppay',
          label: '微信APP'
        },
        {
          value: 'zfbapppay',
          label: '支付宝APP'
        },
        {
          value: 'XTZS',
          label: '系统赠送'
        },
        {
          value: 'YCZ ',
          label: '预充值'
        }
      ],
      definitePayTypeModel: 'all',
      // 导出明细
      exportLoading: false,
      //用户权限配置
      prLoading: false,
      permissionModal: false,
      cooperOperator: [], //合作运营公司数据
      cooperationCompany: [], //合作公司数据
      operator: [], //运营商数据
      cooperOperatorList: [], //合作运营公司列表
      cooperationCompanyList: [],//合作公司
      operatorList: [], //运营商
      relation: [] //要保存的数据
    }
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  mounted () {
    this.$nextTick(() => {
      this.user.userType !== 3 && (this.fieldsVal.companyId = this.user.companyId)
      this.searchParams = Object.assign({}, this.fieldsVal)
      this.getMemberList()
      this.getCompanyList()
      this.getSiteList ()
      setTimeout(() => {
        this.getList()
      }, 200)
    })
  },
  methods: {
    pageChange (pageNum) {
      this.params.pageIndex = pageNum
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    toSearch (obj) {
      this.searchParams = obj
      this.params.pageIndex = 1
      setTimeout(() => {
        this.getList()
      }, 10)
    },
    toReset (obj) {
      this.searchParams = obj
      setTimeout(() => {
        this.getList()
      }, 10)
    },
    async getList () {
      this.loading = true
      await getUserList(Object.assign(this.searchParams, this.params)).then((res) => {
        if (res.data.code === 0) {
          this.loading = false
          res.data.data.dataSource.map((item)=>{
            item.enabled = item.enabled ? 1 : 0
          })
          this.list = res.data.data.dataSource
          this.total =  res.data.data.totalCount
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    toOpr (type, row) {
      this.oprType = type
      if (row) {
        this.modData = row
        let licenseUrls =[]
        if (this.modData.drivingLlicenseImage) {
          let imgArr = this.modData.drivingLlicenseImage.indexOf(',') > -1 ? this.modData.drivingLlicenseImage.split(',') : [this.modData.drivingLlicenseImage]
         imgArr.forEach((item) => {
           licenseUrls.push(this.baseUrl + '/UploadImage/' + item)
         })

        }
        this.modData.licenseUrls = licenseUrls
      }
      if (['mod'].includes(type)) {
        this.showModal = true
      } else if (type === 'view') { // 查看订单明细
        this.$router.push({
          path: '/statistics/order-statistics',
          query: {
            staTKey: 'detail',
            userPhone: row.userPhone
          }
        })
      } else if(type === 'definite'){
        this.definiteModal = true
        this.userWxOpenId = row.userWxOpenId
        this.getDefiniteList(row.userWxOpenId,'all','all')
      }else if (type === 'check') { // 审核车辆信息
        this.showCheckModal = true
      } else if (['add', 'del'].includes(type)) { // 新增对账用户
        this.showReconcileModal = true
      } else if (type === 'recharge') { // 手写余额
        this.showRecharge = true
      }else if(type === 'refund'){ //退款
        this.userWxOpenId = row.userWxOpenId
        this.userMdxBlance = row.userMdxBlance
        this.RefundModal = true
      }else if(type === 'permission'){//用户权限分配
        this.permissionModal = true
        this.prLoading = true
        this.cooperOperator = []
        this.cooperationCompany = []
        this.operator = []
        this.cooperOperatorList = []
        this.cooperationCompanyList = []
        this.operatorList = []
        this.userWxOpenId = row.userWxOpenId
        GetRelationTree({
          userId: row.userWxOpenId
        }).then((res)=>{
          if(res.data.code === 0){
            this.prLoading = false
            // 合作运营公司
            res.data.data.cooperOperator.map((item) => {
              item.checked = item.hasRelation
              if(item.hasRelation){
                this.cooperOperatorList.push({objectID: item.id,objectType: item.relationType})
              }
              if(item.children.length){
                item.children.map((items)=>{
                  item.expand = items.hasRelation
                  items.checked = items.hasRelation
                  if(items.hasRelation){
                    this.cooperOperatorList.push({objectID: items.id,objectType: items.relationType})
                  }
                })
              }
            })
            res.data.data.cooperOperator.map((item)=>{ //获名
              item.title = item.relationName
              if(item.children){
                item.children.map((items)=>{
                  items.title = items.relationName
                })
              }
            })
            const cooperOperator = []
            cooperOperator.push({
              title: '合作运营公司',
              expand: true,
              checked: false,
              children: res.data.data.cooperOperator
            })
            this.cooperOperator = cooperOperator
            // 合作公司
            res.data.data.cooperationCompany.map((item) => {
              item.checked = item.hasRelation
              if(item.hasRelation){
                this.cooperationCompanyList.push({objectID: item.id,objectType: item.relationType})
              }
              if(item.children.length){
                item.children.map((items)=>{
                  item.expand = items.hasRelation
                  items.checked = items.hasRelation
                  if(items.hasRelation){
                    this.cooperationCompanyList.push({objectID: items.id,objectType: items.relationType})
                  }
                })
              }
            })
            res.data.data.cooperationCompany.map((item)=>{ //获名
              item.title = item.relationName
              if(item.children){
                item.children.map((items)=>{
                  items.title = items.relationName
                })
              }
            })
            const cooperationCompany = []
            cooperationCompany.push({
              title: '合作公司',
              expand: true,
              checked: false,
              children: res.data.data.cooperationCompany
            })
            this.cooperationCompany = cooperationCompany
            // 运营商
            res.data.data.operator.map((item) => {
              item.checked = item.hasRelation
              if(item.hasRelation){
                this.operatorList.push({objectID: item.id,objectType: item.relationType})
              }
              if(item.children.length){
                item.children.map((items)=>{
                  item.expand = items.hasRelation
                  items.checked = items.hasRelation
                  if(items.hasRelation){
                    this.operatorList.push({objectID: items.id,objectType: items.relationType})
                  }
                })
              }
            })
            res.data.data.operator.map((item)=>{ //获名
              item.title = item.relationName
              if(item.children){
                item.children.map((items)=>{
                  items.title = items.relationName
                })
              }
            })
            const operator = []
            operator.push({
              title: '运营商',
              expand: true,
              checked: false,
              children: res.data.data.operator
            })
            this.operator = operator
          }
        })
      }
    },
    confirm(){
      if(this.userMdxBlance > 0){
        TradeRefund({
          wxOpenId: this.userWxOpenId,
          userOldOpenId: this.userWxOpenId
        }).then((res)=>{
          if(res.data.code === 0){
            this.RefundModal = false
            this.$Message.success(res.data.message)
          }else{
            this.RefundModal = false
            this.$Message.error(res.data.message)
          }
        })
      }else{
        this.RefundModal = false
        this.$Message.error("该账户可退余额为0")
      }
    },
    modalCancel () {
      this.showModal = false
    },
    modalOk () {
      this.$refs.modalForm.validate(async (valid) => {
        if (valid) {
          let formData = Object.assign({}, this.modalFormData)
          formData.userBlance = this.modalFormData.userBlance * 100
          this.btnLoading = true
          await userListUpdate(formData).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.$Message.success('操作成功')
              this.showModal = false
              this.getList()
            } else {
              this.$Message.error(res.data.message)
            }
          }).catch(() => {
            this.btnLoading = false
          })
        }
      })
    },
    initForm () {
      if (this.oprType === 'mod') {
        Object.keys(this.modalFormData).forEach(key => {
          if (key === 'userBlance') {
            this.modalFormData.userBlance = (this.modData.userBlance / 100).toFixed(2)
          } else  if (key === 'companyId'){
            this.modalFormData[key] = this.modData[key] + ''
          } else {
            this.modalFormData[key] = this.modData[key]
          }
        })
      } else {
        this.modalFormData = Object.assign({}, formData)
      }
    },
    async getMemberList () {
      await getMemberList({}).then((res) => {
        if (res.data.code === 0) {
          this.memberList = res.data.data
          let memberArr = []
          if (this.memberList.length) {
            this.memberList.forEach((member) => {
              memberArr.push({
                label: member.memberLevelName,
                value: member.memberLevelId
              })
            })
          }
          this.searchSelectData.membershipLevel = memberArr
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    async getCompanyList () {
      getAllCompanyList((arr) => {
        this.companyList = arr
        this.searchSelectData.companyId = arr
      })
    },
    checkModalCancel () {
      if (!this.checkForm.reviewComments) {
        return this.$Message.error('请输入审核备注!')
      }
      this.checkForm.auditStatus = 3
      this.checkRequest()
    },
    checkModalOk () {
      this.checkForm.auditStatus = 2
      this.checkRequest()
    },
    checkRequest () {
      this.checkForm.userWxOpenId = this.modData.userWxOpenId
      this.btnLoading = true
      checkCarInfo(this.checkForm).then((res) => {
        this.btnLoading = false
        if (res.data.code === 0) {
          this.$Message.success('操作成功')
          this.resetCheckForm()
        }
      }).catch(() => {
        this.btnLoading = false
      })
    },
    resetCheckForm () {
      this.showCheckModal = false
      this.oprType = ''
      this.modData = {}
      this.checkForm = {
        userWxOpenId: '',
        reviewComments: '',
        auditStatus: null
      }
      this.btnLoading = false
      this.getList()
    },
    toPicView (urls) {
      this.$viewerApi({
        images: urls,
        options: {
          initialViewIndex: 0
        }
      })
    },
    getSiteList () {
      getAllSiteList((arr) => {
        this.siteList = arr
      })
    },
    resetReconcileForm () {
      this.reconcileForm = {
        siteIds: [],
        pwd: '',
        userNickName: ''
      }
      this.btnLoading = false
      this.showReconcileModal = false
    },
    reconcileModalCancel () {
      this.resetReconcileForm()
    },
    reconcileModalOk () {
      this.$refs.reconcileFormRef.validate(async (valid) => {
        if (valid) {
          let obj = {
            add: {
              url: userReconcileAdd,
              data: {
                siteIds: this.reconcileForm.siteIds.join(','),
                userName: this.user.userName,
                userNickName: this.reconcileForm.userNickName
              }
            },
            del: {
              url: userDel,
              data: {
                userId: this.modData.userId,
                pwd: this.reconcileForm.pwd
              }
            }
          }
          this.btnLoading = true
          await obj[this.oprType].url(obj[this.oprType].data).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.$Message.success('操作成功！')
              this.resetReconcileForm()
              this.getList()
            } else {
              this.$Message.error(res.data.message)
            }
          }).catch(() => {
            this.btnLoading = false
          })
        }
      })
    },
    rechargeCancel () {
      this.showRecharge = false
      this.btnLoading = false
    },
    rechargeOk () {
      this.$refs.rechargeFormRef.validate(async (valid) => {
       if (valid) {
         this.btnLoading = true
         await insertRecharge({
           userOpenId: this.modData.userWxOpenId,
           money: Number((this.rechargeForm.money * 100).toFixed(2)),
           operator: this.user.userWxOpenId || this.user.userID,
           password: this.rechargeForm.password,
           virtualAmount: this.rechargeForm.virtualAmount,
           rechargeRemarke: this.rechargeForm.rechargeRemarke
         }).then((res) => {
           if (res.data.code === 0) {
             getSendSms({ // 1 登录 2 登录+有效时间 3 修改密码 4 手动充值
               sendType: 4,
               phoneNumberSet: [this.modData.userPhone],
               TemplateParamSet: [this.rechargeForm.money]
             }).then((res2) => {
               this.btnLoading = false
               if (res2.data.code === 0) {
                 this.$Message.success('操作成功')
                 this.getList()
                 this.showRecharge = false
             } else {
                 this.btnLoading = false
                 this.$Message.error(res2.data.message)
               }
             })
           } else {
             this.btnLoading = false
             this.$Message.error(res.data.message)
           }
         }).catch(() => {
           this.btnLoading = false
         })
       }
     })
    },
    async getDefiniteList(userWxOpenId,rechargeState,paymentMethod){ //获取用户流水明细
      this.definiteList = []
        this.definiteTotal = 0
      let data = Object.assign({},this.definiteParams,{
        userWxOpenId,
        rechargeState,
        paymentMethod,
        orderBy: 'rechargeTime',
        sort: 'descending'
      })
      let res = await definiteList(data)
      if(res.data.code === 0){
        this.definiteList = res.data.data.dataSource
        this.definiteTotal = res.data.data.totalCount
      }else{
        this.$Message.error(res.data.message)
      }
    },
    definitePageChange (pageNum) {
      this.definiteParams.pageIndex = pageNum
      this.getDefiniteList(this.userWxOpenId,this.definiteModel,this.definitePayTypeModel)
    },
    definitePageSizeChange (pageSize) {
      this.definiteParams.pageSize = pageSize
      this.getDefiniteList(this.userWxOpenId,this.definiteModel,this.definitePayTypeModel)
    },
    definiteType(val){ //流水明细查询类型
      this.definiteParams.pageIndex = 1
      if(this.definiteModel == 'SUCCESS'){
        this.definiteShow = false
      }else{
        this.definiteShow = true
        this.definitePayTypeModel = 'all'
      }
      this.getDefiniteList(this.userWxOpenId,val,this.definitePayTypeModel)
    },
    definitePayType(val){ //流水明细查询支付方式
      this.definiteParams.pageIndex = 1
      this.getDefiniteList(this.userWxOpenId,this.definiteModel,val)
    },
    exportDetail(){ //用户流水明细导出
      this.$Modal.confirm({
        title: '导出',
        content: '确认导出?',
        onOk: async () => {
          this.exportLoading = true
          let data = Object.assign({},this.searchParams,this.params,{
            orderBy: 'startTime',
            sort: 'descending'
          })
          let res = await flowDetails(data)
          if (res.status === 200) {
            this.exportLoading = false
            exportExcel('用户流水明细', res.data)
          }else{
            this.$Message.error(res.data)
          }
        }
      })
    },
    async permissionConfirm(){ //退款
      this.btnLoading = true
      let relation = this.relation.concat(this.cooperOperatorList,this.cooperationCompanyList,this.operatorList)
      let res = await Authorize({userID: this.userWxOpenId,relation})
      if(res.data.code === 0){
        this.$Message.success('操作成功')
        this.btnLoading = false
        this.permissionModal = false
      }else{
        this.btnLoading = false
        this.$Message.error(res.data.message)
      }
    },
    checkCooperOperator(row){//权限分配选择组
      this.cooperOperatorList = []
      row.forEach((item)=>{
        if(item.id && item.relationType){
          this.cooperOperatorList.push({objectID: item.id,objectType: item.relationType})
        }
      })
    },
    checkCooperationCompany(row){
      this.cooperationCompanyList = []
      row.forEach((item)=>{
        if(item.id && item.relationType){
          this.cooperationCompanyList.push({objectID: item.id,objectType: item.relationType})
        }
      })
    },
    checkOperator(row){
      this.operatorList = []
      row.forEach((item)=>{
        if(item.id && item.relationType){
          this.operatorList.push({objectID: item.id,objectType: item.relationType})
        }
      })
    }
  },
  watch: {
    showModal (val) {
      if (!val) {
        this.modData = {}
        this.oprType = ''
        this.$refs.modalForm.resetFields()
        this.btnLoading = false
      } else {
        this.initForm()
      }
    },
    searchParams: {
      handler (val) {
        if (!val.companyId) {
          this.user.userType !== 3 && (val.companyId = this.user.companyId)
        }
      }
    },
    showRecharge (val) {
      if (!val) {
        this.rechargeForm = {
          money: null,
          password: '',
          virtualAmount: 0,
          rechargeRemarke: ''
        }
      }
    }
  }
};
</script>

<style scoped lang="less">
.help-wrapper{
  .title{
    padding: @base;
    font-size: 16px;
    background: #f1f1f1;
  }
  .help-content{
    margin-top: @base;
    box-sizing: border-box;
    overflow-y: auto;
  }
}
.check-car-pic{
  width: 20%;
  max-height: 100px;
}
.check-img-len{
  line-height: 10px;
  position: absolute;
  left: 0;
  top:0;
  background: #000;
  color: #fff;
  padding: @base;
}
.no-img-len{
  line-height: 14px;
  position: absolute;
  left: 0;
  top:0;
  padding: @base;
}
.definiteModel{
  padding: 10px 0;
}
/deep/.permission{
  .ivu-modal{
    .ivu-modal-content{
      .ivu-modal-body{
        overflow: auto;
        height: 710px
      }
    }
  }
}
.export-button{
  margin-left: 20px;
}
</style>
