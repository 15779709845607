<template>
    <div class="help-wrapper" :style="{height: tableHeight + 'px'}">
        <search-form :fields="searchFields" :okLoading="loading" @on-search="toSearch" @on-reset="toReset" :selectData="searchSelectData"></search-form>
        <div class="help-content">
            <div class="table-top">
                <Button @click="toOpr('add')" v-has-permi="['PRIV_SERVERFEE_CREATE']"><Icon type="md-add"/> 新增</Button>
            </div>
            <Table stripe :columns="columns" :data="list" :loading="loading" ref="table" :height="tableHeight - 62"></Table>
        </div>
        <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
        <Modal :title="modalType === 'add' ? '新增' : '修改'"
           v-model="showModal"
           width="1465"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           :mask-closable="false">
            <div slot="footer">
                <Button @click="modalCancel">取消</Button>
                <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
            </div>
            <Form :model="modalFormData" ref="modalForm" :label-width="120" :rules="modalRuleValid" v-if="showModal">
                <div class="form-content group" ref="serveRef">
                    <div class="time-interval">
                        <FormItem label="服务费策略名称: " prop="name" class="half">
                            <Input v-model="modalFormData.name"/>
                        </FormItem>
                        <FormItem :label-width="90">
                            <Icon type="md-add-circle" class="icon-add" @click="toAddPrice"/> 添加价格类别
                        </FormItem>
                    </div>
                    <div class="fee-content service">
                        <div class="serve-title" v-if="modalFormData.serviceFees.length">
                            <span>尖</span>
                            <span>峰</span>
                            <span>平</span>
                            <span>谷</span>
                            <span class="discount">会员折扣</span>
                        </div>
                        <div class="fee-data" v-for="(item,index) in modalFormData.serviceFees" :key="index">
                            <div class="fee-left">
                                <Icon type="md-remove-circle" class="icon-price" @click="toDelPrice(index)"/>
                                <FormItem :prop="'serviceFees.' + index +'.memberLevelId'" :rules="{required: true,message: '请选择会员级别'}" class="halfs">
                                    <Select v-model="modalFormData.serviceFees[index].memberLevelId" class="fee-user" placeholder="会员级别">
                                        <Option v-for="item in serveList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                                    </Select>
                                </FormItem>
                            </div>
                            <div class="fee-list">
                                <FormItem :prop="'serviceFees.' + index +'.fee1'" :rules="{required: true,message: '请输入尖时服务费'}" class="halfs">
                                    <Input v-model="modalFormData.serviceFees[index].fee1" number><span slot="append">元/度</span></Input>
                                </FormItem>
                                <FormItem :prop="'serviceFees.' + index +'.fee2'" :rules="{required: true,message: '请输入峰时服务费'}" class="halfs">
                                    <Input v-model="modalFormData.serviceFees[index].fee2" number><span slot="append">元/度</span></Input>
                                </FormItem>
                                <FormItem :prop="'serviceFees.' + index +'.fee3'" :rules="{required: true,message: '请输入平时服务费'}" class="halfs">
                                    <Input v-model="modalFormData.serviceFees[index].fee3" number><span slot="append">元/度</span></Input>
                                </FormItem>
                                <FormItem :prop="'serviceFees.' + index +'.fee4'" :rules="{required: true,message: '请输入谷时服务费'}" class="halfs">
                                    <Input v-model="modalFormData.serviceFees[index].fee4" number><span slot="append">元/度</span></Input>
                                </FormItem>
                            </div>
                            <FormItem :prop="'serviceFees.' + index +'.discountRate'" :rules="{required: true,message: '请输入会员折扣'}" class="halfs">
                                <Input v-model="modalFormData.serviceFees[index].discountRate" number><span slot="append">%</span></Input>
                            </FormItem>
                        </div>
                    </div>
                </div>
                <div class="set-site">
                    <Transfer
                        :data="siteList"
                        :target-keys="selectList"
                        :list-style="listStyle"
                        :titles="['未选择站点','已选择站点']"
                        :operations="['取消','修改']"
                        filter-placeholder="请输入搜索站点"
                        filterable
                        @on-change="handleChange">
                    </Transfer>
                </div>
            </Form>
        </Modal>
    </div>
</template>

<script>
    import SearchForm from '@/components/SearchForm'
    import {getMemberList,ServiceFeeGetPage,ServiceFeeCreate,ServiceFeeGetDetail,ServiceFeeSiteInPlan,ServiceFeeEdit,ServiceFeeDelete,querySiteAll,ServiceFeePushById} from '@/libs/api'
    import { mapState } from 'vuex'
    export default {
        name: 'serve-fee',
        components: {
            SearchForm
        },
        data(){
            return {
                loading: false,
                searchForm: {},
                total: 0,
                params: {
                    pageIndex: 1,
                    pageSize: 20
                },
                list: [],
                showModal: false,
                btnLoading: false,
                searchFields: [
                    {
                        label: '服务费名称',
                        name: 'name',
                        type: 'input'
                    },
                    {
                        label: '下发状态',
                        name: 'isPush',
                        type: 'select'
                    }
                ],
                searchSelectData: {
                    isPush: [
                        {value: 1, label: '已下发'},
                        {value: 0, label: '未下发'}
                    ]
                },
                modalFormData: {
                    name: "",
                    serviceFees: [
                        {
                            fee1: null,
                            fee2: null,
                            fee3: null,
                            fee4: null,
                            memberLevelId: null,
                            discountRate: null,
                        }
                    ]
                },
                modalRuleValid: {
                    name: [{required: true, message: '请输入服务费策略名称'}]
                },
                columns: [
                    {
                        key: 'index',
                        title: '序号',
                        width: 70,
                        align: 'center',
                        render: (h, params) => {
                            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
                            return (<span>{ind}</span>)
                        }
                    },
                    {
                        key: 'name',
                        title: '服务费名称',
                        align: 'center'
                    },
                    {
                        key: 'sites',
                        title: '所属站点',
                        align: 'center',
                        render: (h, params) => {
                            let res = params.row.sites.join(',')
                            return (<Tooltip max-width="210" transfer content={res}><div class='site-title'>{res}</div></Tooltip>)
                        }
                    },
                    {
                        key: 'fee1',
                        title: '尖时服务费',
                        align: 'center'
                    },
                    {
                        key: 'fee2',
                        title: '峰时服务费',
                        align: 'center'
                    },
                    {
                        key: 'fee3',
                        title: '平时服务费',
                        align: 'center'
                    },
                    {
                        key: 'fee4',
                        title: '谷时服务费',
                        align: 'center'
                    },
                    {
                        key: 'isPush',
                        title: '下发状态',
                        align: 'center',
                        render: (h, params) => {
                            let res = params.row.isPush
                            return (<span>{ res ? '已下发' : '未下发'}</span>)
                        }
                    },
                    {
                        title: '操作',
                        align: 'center',
                        fixed: 'right',
                        width: 260,
                        render: (h, params) => {
                            return (
                                <span class="table-opr-btn">
                                    <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'edit', params.row)} v-has-permi={['PRIV_SERVERFEE_UPDATE']}>
                                        <i class="iconfont icon-bianji"></i> 编辑
                                    </a>
                                    <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'distribute', params.row)} v-has-permi={['PRIV_SERVERFEE_ISSUE']}>
                                        <i class="iconfont icon-xiafapeizhi2"></i> 下发
                                    </a>
                                    <a href="javascript:;" class="del" onClick={this.delRate.bind(this, params.row)} v-has-permi={['PRIV_SERVERFEE_DELETE']}>
                                        <i class="iconfont icon-shanchu"></i> 删除
                                    </a>
                                </span>
                            )
                        }
                    }
                ],
                modalType: '',
                serveList: [],
                // 穿梭框
                siteList: [], //未绑定站点
                selectList: [], //已绑定站点
                listStyle: { //自定义样式
                    width: '287px',
                    height: '152px'
                }
            }
        },
        computed: {
            ...mapState(['tableHeight'])
        },
        mounted () {
            this.$nextTick(() => {
                this.ServiceFeeGetPage()
                getMemberList().then((res)=>{
                    if(res.data.code === 0){
                        res.data.data.forEach(item => {
                            this.serveList.push({
                                label: item.memberLevelName,
                                value: item.memberLevelId
                            })
                        })
                    }
                })
            })
        },
        methods: {
            toSearch (obj) {
                this.searchForm = obj
                this.params.pageIndex = 1
                this.ServiceFeeGetPage()
            },
            toReset (obj) {
                this.searchForm = obj
                this.ServiceFeeGetPage()
            },
            toOpr (type, row) {
                this.modalType = type
                switch (type){
                    case 'add':
                        this.getSite()
                        this.showModal = true
                        break
                    case 'edit':
                        this.detailRate(row)
                        this.showModal = true
                        break
                    case 'distribute':
                        this.distribute(row)
                        break
                }
            },
            pageChange (pageNum) {
                this.params.pageIndex = pageNum
                this.ServiceFeeGetPage()
            },
            pageSizeChange (pageSize) {
                this.params.pageSize = pageSize
                this.ServiceFeeGetPage()
            },
            modalOk () {
                this.$refs.modalForm.validate(async (valid) => {
                    if (valid) {
                        if(this.modalType === 'add'){ //新增
                            let res = await ServiceFeeCreate(Object.assign({},this.modalFormData,{sites: this.selectList}))
                            if(res.data.code === 0){
                                this.showModal = false
                                this.$Message.success('新增成功')
                                this.resetFields()
                                this.ServiceFeeGetPage()
                            }else{
                                this.$Message.error(res.data.message)
                            }
                        }else{ //修改
                            let res = await ServiceFeeEdit(Object.assign({},this.modalFormData,{sites: this.selectList}))
                            if(res.data.code === 0){
                                this.showModal = false
                                this.$Message.success('修改成功')
                                this.resetFields()
                                this.ServiceFeeGetPage()
                            }else{
                                this.$Message.error(res.data.message)
                            }
                        }
                    }
                })
            },
            modalCancel(){ //取消
                this.showModal = false
                this.resetFields()
            },
            resetFields(){ //重置
                this.$refs.modalForm.resetFields()
            },
            async getSite(){ //获取所有站点
                let res = await querySiteAll()
                if(res.data.code === 0){
                    res.data.data.forEach((item)=>{
                        this.siteList.push({
                            key: item.siteId,
                            label: item.siteName
                        })
                    })
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            toAddPrice(){ //添加服务费
                this.modalFormData.serviceFees.push({
                    fee1: null,
                    fee2: null,
                    fee3: null,
                    fee4: null,
                    memberLevelId: null,
                })
                setTimeout(()=>{
                    this.listStyle.height = this.$refs.serveRef.offsetHeight + 'px'
                },100)
            },
            toDelPrice(index){
                if(this.modalFormData.serviceFees.length > 1){
                    this.modalFormData.serviceFees.splice(index, 1)
                    this.listStyle.height = this.$refs.serveRef.offsetHeight - 53 + 'px'
                }
            },
            async ServiceFeeGetPage(){ //获取列表
                let params = Object.assign({}, this.params, this.searchForm)
                let res = await ServiceFeeGetPage(params)
                if(res.data.code === 0){
                    this.total = res.data.data.totalCount
                    this.list = res.data.data.dataSource
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            async GetSiteInPlan(id){ //获取绑定站点
                let res = await ServiceFeeSiteInPlan({id})
                if(res.data.code === 0){
                    res.data.data.forEach((item)=>{
                        this.siteList.push({
                            key: item.siteId,
                            label: item.siteName
                        })
                        if(item.inPlan){
                            this.selectList.push(item.siteId)
                        }
                    })
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            async detailRate(row){ //详情
                this.GetSiteInPlan(row.id) //获取绑定站点
                let res = await ServiceFeeGetDetail({id:row.id})
                if(res.data.code === 0){
                    this.modalFormData = res.data.data
                }else{
                    this.$Message.error(res.data.message)
                }
            },
            delRate(row){ //删除服务费
                this.$Modal.confirm({
                    title: '警告',
                    width: 300,
                    content: '确认删除该服务费吗!',
                    loading: true,
                    onOk: async () => {
                        let res = await ServiceFeeDelete({id: row.id})
                        if(res.data.code === 0){
                            this.$Modal.remove()
                            this.$Message.success('操作成功!')
                            this.ServiceFeeGetPage()
                        }else{
                            this.$Modal.remove()
                            this.$Message.error(res.data.message)
                        }
                    }
                })
            },
            handleChange (newTargetKeys) { //设置站点
                this.selectList = [...new Set(newTargetKeys)]
            },
            distribute(row){ //下发
                if(row.isPush){
                    this.$Message.info('该费率已下发!')
                }else{
                    this.$Modal.confirm({
                        title: '提示',
                        width: 300,
                        content: '确认下发该费率吗!',
                        loading: true,
                        onOk: async () => {
                            let res = await ServiceFeePushById({id:row.id})
                            if(res.data.code === 0){
                                this.$Modal.remove()
                                this.$Message.success('操作成功')
                                this.ServiceFeeGetPage()
                            }else{
                                this.$Message.error(res.data.message)
                            }
                        }
                    })
                }
            }
        },
        watch: {
            showModal(val){
                if(val){
                    this.listStyle.height = '258px'
                    setTimeout(()=>{
                        this.listStyle.height = this.$refs.serveRef.offsetHeight + 'px'
                    },150)
                }
                this.modalFormData = { //清空表单
                    name: "",
                    serviceFees: [
                        {
                            fee1: null,
                            fee2: null,
                            fee3: null,
                            fee4: null,
                            memberLevelId: null,
                            discountRate: null,
                        }
                    ]
                }
                this.siteList = [] //清空获取站点
                this.selectList = [] //清空绑定站点
            }
        }
    }
</script>

<style lang="less" scoped>
    @import 'rate-manage';
    .set-site{
        padding-left: 10px;
        margin: 10px 0;
    }
    .group{
        width: 755px;
    }
</style>