<template>
  <div class="help-wrapper">
    <div class="help-content">
      <div class="table-top">
        <Button href="javascript:;" @click="toOpr('add')"><Icon type="md-add"/>新增</Button>
        <Button href="javascript:;" @click="toOpr('mdd')"><Icon type="md-create"/>修改</Button>
      </div>
      <div class="version">
        <div class="version-data">
          <span>版本名称</span>
          <span class="vdata">{{VFormData.versionName}}</span>
        </div>
        <div class="version-data">
          <span>版本号</span>
          <span class="vdata">{{VFormData.versionNumber}}</span>
        </div>
        <div class="version-data">
          <span>版本内容</span>
          <span class="vdata">{{VFormData.versionContent}}</span>
        </div>
        <div class="version-data">
          <span>版本URL</span>
          <span class="vdata">{{VFormData.versionUrl}}</span>
        </div>
        <div class="version-data">
          <span>版本上传时间</span>
          <span class="vdata">{{VFormData.versionUploadTime}}</span>
        </div>
        <div class="version-data">
          <span>版本状态</span>
          <span class="vdata">{{verSionState(VFormData.verSionState)}}</span>
        </div>
        <div class="version-data">
          <span>备注(维护通知)</span>
          <span class="vdata">{{VFormData.versionRemarke ? VFormData.versionRemarke : '未使用'}}</span>
        </div>
      </div>
    </div>
    <Modal :title="oprType === 'add' ? '新增' : '编辑'"
        v-model="showModal"
        width="700"
        class="modal-detail modal-form"
        class-name="vertical-center-modal"
        :mask-closable="false">
      <div slot="footer">
        <Button  @click="modalCancel">取消</Button>
        <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :model="modalFormData" ref="modalForm" :label-width="120" :rules="modalRuleValid">
        <div class="form-content">
          <FormItem label="版本名称: " prop="versionName" class="half">
            <Input v-model="modalFormData.versionName"/>
          </FormItem>
          <FormItem label="版本号: " prop="versionNumber" class="half">
            <Input v-model="modalFormData.versionNumber"/>
          </FormItem>
          <FormItem label="版本内容: " prop="versionContent" class="half">
            <Input v-model="modalFormData.versionContent"/>
          </FormItem>
          <FormItem label="版本状态: " prop="verSionState" class="half">
            <Select v-model="modalFormData.verSionState">
              <Option v-for="(opt, oInd) in discountTypeOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
            </Select>
          </FormItem>
          <FormItem label="版本URL: " prop="versionUrl" class="long">
            <Input v-model="modalFormData.versionUrl"/>
          </FormItem>
          <FormItem label="备注: " prop="versionRemarke" class="long">
            <Input type="textarea" v-model="modalFormData.versionRemarke"/>
          </FormItem>
        </div>
      </Form>
    </Modal>
  </div>
</template>

<script>
import { GetByVersionNumber,InsertVersion,UpdateVersion } from '@/libs/api'
export default {
  name: "app-manage",
  data () {
    return {
      modData: {},
      showModal: false,
      btnLoading: false,
      oprType: '',
      discountTypeOpt: [ // 版本状态
        {
          value: 1,
          label: '正常更新(可取消)'
        },
        {
          value: 2,
          label: '强制更新(取消关闭)'
        },
        {
          value: 3,
          label: '更新维护'
        },
        {
          value: 4,
          label: '关闭更新'
        }
      ],
      VFormData:{},
      modalFormData: {},
      modalRuleValid: {
        versionName: [
          { required: true, message: '请输入版本名称!'}
        ],
        versionNumber: [
          { required: true, message: '请输入版本号!'}
        ],
        versionContent:[
          { required: true, message: '请选择版本内容!'}
        ],
        versionUrl: [
          { required: true, message: '请输入版本URL!'}
        ],
        verSionState: [
          { required: true, message: '请输入版本状态!'}
        ],
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getVersion()
    })
  },
  methods: {
    getVersion(){//获取版本号
      GetByVersionNumber().then((res)=>{
        if(res.data.code === 0){
          this.VFormData = res.data.data.VersionManagement
        }
      })
    },
    toOpr (type) { //新增或修改
      this.oprType = type
      this.showModal = true
      if( type === 'mdd' ){
        this.modalFormData = Object.assign({},this.VFormData)
      }else{
        this.modalFormData = {}
      }
    },
    modalCancel () { //取消
      this.showModal = false
      this.$refs.modalForm.resetFields()
    },
    modalOk(){ //确认
      this.$refs.modalForm.validate(async (valid) => {
        if(valid){
          this.btnLoading = true
          let data = this.modalFormData
          if(this.oprType === 'add'){
            InsertVersion(data).then((res) => {//新增
              if(res.data.code === 0){
                this.showModal = false
                this.btnLoading = false
                this.$Message.success(res.data.message)
                this.getVersion()
              }else{
                this.btnLoading = false
                this.$Message.error(res.data.message)
              }
            })
          }else{
            UpdateVersion(data).then((res)=>{//修改
              if(res.data.code === 0){
                this.showModal = false
                this.btnLoading = false
                this.$Message.success(res.data.message)
                this.getVersion()
              }else{
                this.btnLoading = false
                this.$Message.error(res.data.message)
              }
            })
          }
        }
      })
    },
    verSionState(state){
      if(state===1){
        return '正常更新(可取消)'
      }else if(state===2){
        return '强制更新(取消关闭)'
      }else if(state===3){
        return '更新维护'
      }else if(state===4){
        return '关闭更新'
      }
    }
  }
}
</script>

<style scoped lang="less">
.version{
  margin-top: 20px;
}
.version{
  display: flex;
  justify-content: space-around;
  align-items: center;
  .version-data{
    display: flex;
    flex-direction: column;
    .vdata{
      margin-top: 20px;
    }
  }
}

.half{
  width: 45% !important;
  margin-left: 20px;
}
  .form-content{
    padding: 0;
  }
  /deep/ .ivu-form-item-content{
    display: flex;
  }

</style>
