<template>
  <div class="statistics-wrapper">
    <search-form :fields="searchFields" :okLoading="loading" :fieldsVal="fieldsVal" :selectData="searchSelectData" @on-search="toSearch" @on-reset="toReset" :minSize="2"></search-form>
    <div class="statistics-content-wrapper">
      <div class="statistics-compare">
        <ul class="statistics-compare-ul">
          <li>
            <label>总电量(度)</label>
            <countTo :startVal="countParams.startVal" :endVal="statisticsTotal.nowInfo" :duration="countParams.duration" :separator="countParams.separator" :decimals="countParams.decimals"></countTo>
          </li>
          <li>
            <label>较上周</label>
            <span :class="statisticsTotal.totalRate >= 0 ? 'up' : 'down'">
            <i class="iconfont" :class="statisticsTotal.totalRate >= 0 ? 'icon-rise' : 'icon-xiajiang'"></i> {{statisticsTotal.totalRate}}%
            </span>
          </li>
        </ul>
      </div>
      <div class="statistics-tab table-top">
        <div class="tab-left">
          <ul>
            <li v-for="(tab, tInd) in tabList" :key="tInd" :class="{current: tab.key === tabKey}" @click="toTab(tab)">{{tab.label}}</li>
          </ul>
        </div>
      </div>
      <div class="statistics-content-box">
        <div class="charts-item" id="order-charts" v-show="tabKey === 0"></div>
        <div class="charts-item" id="time-order-charts1" v-show="tabKey === 1"></div>
      </div>
      <div class="statistics-tab table-top">
        <div class="tab-left">
          <ul>
            <li class="current">列表</li>
          </ul>
        </div>
      </div>
      <Table stripe
             :columns="column"
             :data="list"
             :loading="loading"
             :height="350"
             @on-sort-change="sortChange"></Table>
      <Page
          :total="total"
          :current="params.pageIndex"
          :page-size="params.pageSize"
          show-elevator
          show-sizer
          show-total
          @on-change="pageChange"
          @on-page-size-change="pageSizeChange"
          class="table-page1"></Page>
    </div>
    <Loading :show="loading" :styl="{background: 'rgba(255,255,255,.65)'}"></Loading>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import SearchForm from '@/components/SearchForm'
import { mapState } from 'vuex'
import { orderStateOpt, orderStartModeOpt } from '@/libs/dict'
import { electricStatisticsBySite, electricStatisticsByTime, electricStatisticsList} from '@/libs/api'
import { getStorage } from '@/libs/tools'
import { getAllSiteList } from '@/libs/requestCommon'
import Loading from '@/components/Loading'

let dayDefaultVal = dayjs(new Date()).format('YYYY-MM-DD')
import * as echarts from 'echarts'
import { deepCopy } from '@/libs/tools'
import countTo from 'vue-count-to'

export default {
  name: "electric-day-statistics",
  components: {
    SearchForm,
    Loading,
    countTo
  },
  data () {
    return {
      orderStateOpt,
      orderStartModeOpt,
      loading: false,
      exportLoading: false,
      user: getStorage('user') ? JSON.parse(getStorage('user')) : {},
      fieldsVal: {
        time: dayDefaultVal,
        siteIds: []
      },
      searchFields: [
        {
          type: 'date',
          label: '时间',
          name: 'time',
          clearable: false,
          editable: false,
          options: {
            disabledDate: (date) => {
              let flag = dayjs(new Date(dayDefaultVal)).isBefore(date)
              return flag
            }
          }
        },
        {
          type: 'select',
          label: '站点名称',
          name: 'siteIds',
          useValue: 'value',
          cls: 'three',
          filterable: true,
          multiple: true
        },
        {
          type: 'cascader',
          label: '区域选择',
          name: 'regionData',
          multiple: true,
          cls: 'three',
          filterable: true
        }
      ],
      searchParams: {
        queryType: 0
      },
      searchSelectData: {
        siteIds: [],
        regionData: []
      },
      params: {
        pageSize: 20,
        pageIndex: 1
      },
      total: 0,
      chartsData: [],
      chartsOpt: {
        color: ['#fac858', '#f60', '#6fd2ef', '#ea7ccc'],
        dataZoom: [
          {
            type: 'slider',
            backgroundColor: 'none',
            start: 0,
            height: 20
          }
        ],
        grid: {
          left: '3%',
          right: '3%',
          bottom: '10px',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params) => {
            let ele = ''
            params.forEach((item) => {
              ele += `<p style="line-height: 1.7;">
                        ${item.seriesName} :
                        <span style="color: ${item.color}">${item.data}</span>
                        <span style="display: inline-block; padding-left: 12px;">较上周</span>
                        <span style="color: green;"><i class="iconfont icon-rise"></i> ${(Math.random()* 10).toFixed(2)}%</span>
                      </p>`
            })
            return `<div>
                     <p>${params[0].axisValueLabel}</p>
                     ${ele}
                   </div>`
          }
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: []
        },
        yAxis: [
          {
            type: 'value'
          }
        ],
        legend: {
          top: '20px',
          data: ['电量(度)']
        },
        series: [
          {
            data: [],
            name: '电量(度)',
            type: 'bar',
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      },
      orderChartsObj: null,
      timeOrderCharts1: null,
      timeOrderCharts2: null,
      siteData: [],
      column: [ // 明细
        {
          key: 'index',
          title: '序号',
          width: 70,
          align: 'center',
          render: (h, params) => {
            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key:'siteName',
          title: '所属站点',
          align: 'center',
          minWidth: 300
        },
        {
          key: 'dealLimit',
          title: '电量(度)',
          align: 'center',
          width: 250,
          render: (h, params) => {
            let num = (params.row.dealLimit / 10000).toFixed(2)
            return (
                <p>{num}</p>
            )
          }
        },
        {
          key: 'turnoverLastRise',
          title: '电量环比(较上周)',
          align: 'center',
          width: 180,
          render: (h, params) => {
            let val = params.row.turnoverLastRise
            return (
                <p style={{color: val >= 0 ? 'green' : 'red'}}>
                  <i class={{'iconfont': true, 'icon-rise': val >= 0, 'icon-xiajiang': val < 0}}></i>
                  <span> {val}%</span>
                </p>
            )
          }
        }
      ],
      list: [],
      tabKey: 0,
      tabList: [
        {
          label: '按电站统计',
          key: 0
        },
        {
          label: '按时间段统计',
          key: 1
        }
      ],
      countParams: {
        startVal: 0,
        duration: 3000,
        separator: ',',
        decimals: 2
      },
      statisticsTotal: {}
    }
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  mounted () {
    this.$nextTick(() => {
      let regionData = getStorage('regionData') ? JSON.parse(getStorage('regionData')) : []
      regionData.forEach((province) => {
        if (province.children.length) {
          province.children.unshift({
            value: '',
            label: '全部',
            children: []
          })
          province.children.forEach((city) => {
            if (city.children.length) {
              city.children.unshift({
                value: '',
                label: '全部',
                children: []
              })
            }
          })
        }
      })
      this.searchSelectData.regionData = regionData
      this.getSiteData()
      this.searchParams = Object.assign({}, this.fieldsVal)
    })
  },
  methods: {
    getData () {
      setTimeout(() => {
        switch (this.tabKey) {
          case 0: this.getElectricCharts(); break
          case 1: this.getTimeElectricCharts1(); break
          default:
        }
        this.getList()
      }, 0)
    },
    toSearch (obj) {
      this.params.pageIndex = 1
      this.searchParams = obj
      this.getData()
    },
    toReset (obj) {
      this.searchParams = obj
      this.getData()
    },
    getElectricCharts () { // 以电站统计交易额
      this.loading = true
      this.orderChartsObj = echarts.init(document.getElementById('order-charts'))
      this.setChartsData()
    },
    async setChartsData () {
      let xData = []
      let yData1 = []
      let params = deepCopy(this.chartsOpt)
      electricStatisticsBySite(this.searchParams).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          let data = res.data.data.list
          this.statisticsTotal = {...res.data.data.totale}
          this.statisticsTotal.nowInfo = Number((res.data.data.totale.nowInfo / 10000).toFixed(2))
          if (data.length) {
            data.forEach((item) => {
              xData.push(item.siteName)
              yData1.push((item.turnoverTotal / 10000).toFixed(2))
            })
          }
          params.grid.bottom = '60px'
          params.xAxis.data = xData
          params.series[0].data = yData1
          params.tooltip.formatter = (paramsTooltip) => {
            let ele = ''
            let objKey = {
              0: 'turnoverLastRise',
              1: 'tradesLastRise'
            }
            paramsTooltip.forEach((item, index) => {
              let riseObj = data[item.dataIndex]
              let riseVal = riseObj[objKey[index]]
              ele += `<p style="line-height: 1.7;">
                        ${item.seriesName} :
                        <span style="color: ${item.color}">${item.data}</span>
                        <span style="display: inline-block; padding-left: 12px;">较上周</span>
                        <span style="color: ${riseVal >= 0 ? 'green' : 'red'};"><i class="iconfont ${riseVal >= 0 ? 'icon-rise' : 'icon-xiajiang'}"></i> ${riseVal}%</span>
                      </p>`
            })
            return `<div>
                     <p>${paramsTooltip[0].axisValueLabel}</p>
                     ${ele}
                   </div>`
          }
          this.orderChartsObj.setOption(params)
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    getTimeElectricCharts1 () { // 以时间统计维度
      this.timeOrderCharts1 = echarts.init(document.getElementById('time-order-charts1'))
      this.setTimeChartsData1()
    },
    setTimeChartsData1 () {
      let xData = []
      let yData1 = []
      let params = deepCopy(this.chartsOpt)
      electricStatisticsByTime(this.searchParams).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data
          if (data.length) {
            data.forEach((item) => {
              xData.push(item.timeForm + ':00')
              yData1.push((item.turnoverTotal / 10000).toFixed(2))
            })
          }
          params.legend.data = ['电量(度)']
          params.series[0].type = 'line'
          params.series[0].label.show = false
          params.color = ['#6fd2ef']
          params.dataZoom = []
          params.xAxis.data = xData
          params.series[0].data = yData1
          params.tooltip.formatter = (paramsTooltip) => {
            let ele = ''
            paramsTooltip.forEach((item, index) => {
              let riseVal = data[item.dataIndex].turnoverLastRise
              ele += `<p style="line-height: 1.7;">
                        ${item.seriesName} :
                        <span style="color: ${item.color}">${item.data}</span>
                        <span style="display: inline-block; padding-left: 12px;">较上周</span>
                        <span style="color: ${riseVal >= 0 ? 'green' : 'red'};"><i class="iconfont ${riseVal >= 0 ? 'icon-rise' : 'icon-xiajiang'}"></i> ${riseVal}%</span>
                      </p>`
            })
            return `<div>
                     <p>${paramsTooltip[0].axisValueLabel}</p>
                     ${ele}
                   </div>`
          }
          this.timeOrderCharts1.setOption(params)
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    sortChange (params) { // 排序
      let sortInd = {
        asc: 'asc', // 升序
        desc: 'descending' // 降序
      }
      this.params.orderBy = params.order !== 'normal' ? params.key : 'orderEndTime'
      this.params.sort = params.order !== 'normal' ? sortInd[params.order] : 'descending'
      this.getList()
    },
    getList () {
      electricStatisticsList(Object.assign(this.searchParams, this.params)).then((res) => {
        if (res.data.code === 0) {
          this.list = res.data.data.dataSource
          this.total = res.data.data.totalCount
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    pageChange (pageIndex) {
      this.params.pageIndex = pageIndex
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    async getSiteData () {
      getAllSiteList((arr) => {
        this.siteData = arr
        this.searchSelectData.siteIds = arr
        this.fieldsVal.siteIds = this.user.siteIds ? (this.user.siteIds.indexOf(',') > -1 ? this.user.siteIds.split(',') : [this.user.siteIds]) : []
        this.getData()
      })
    },
    toTab (tab) {
      this.tabKey = tab.key
      setTimeout(() => {
        switch (this.tabKey) {
          case 0:
            this.getElectricCharts();
            break
          case 1:
            this.getTimeElectricCharts1();
            break
          default:
        }
      }, 0)
    }
  },
  watch: {
    searchParams: {
      handler (val) {
        val.queryType = 0
        if (Array.isArray(val.siteIds)) {
          if (val.siteIds.length) {
            val.siteIds = val.siteIds.join(',')
          } else {
            val.siteIds = this.user.siteIds
          }
        } else {
          if (!val.siteIds) {
            val.siteIds = this.user.siteIds
          }
        }
        if (val.regionData && val.regionData.length) {
          val.province = val.regionData[0]
          val.regionData[1] && (val.city = val.regionData[1])
          val.regionData[2] && (val.ccuntry = val.regionData[2])
        }
        delete val.regionData
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "statistics";
.statistics-content-wrapper{
  padding-bottom: @base * 2;
  .statistics-compare{
    display: flex;
    justify-content: space-between;
    padding-top: @base * 3;
  }
  .statistics-compare-ul{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: @base * 3;
    li{
      flex: 1;
      position: relative;
      &:last-child{
        &:before{
          content: '';
          width: 1px;
          height: 80%;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background: #dfdfdf;
        }
      }
    }
    label, span{
      display: block;
      text-align: center;
      color: #333;
    }
    span{
      color: @baseColor;
      font-size: 20px;
      font-weight: bold;
      padding-top: @base;
      &.down{
        color: deeppink;
      }
    }
  }
}
.statistics-content-box{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  .charts-item{
    width: 100%;
    height: 350px;
    margin-bottom: @base;
  }
}
</style>
