<template>
  <div class="help-wrapper">
    <div class="help-content-box">
      <div class="content-left">
        <p class="title">角色名称</p>
        <ul class="data-list role-list">
          <li v-for="(role, rInd) in roleList" :key="rInd" :class="{current: rInd === roleInd}" @click="roleChange(role, rInd)">
            {{role.name}}
          </li>
        </ul>
      </div>
      <div class="content-right">
        <search-form :fields="searchFields" @on-search="toSearch" @on-reset="toReset" :okLoading="loading"></search-form>
        <div class="help-content" :style="{height: tableHeight + 'px'}">
          <div class="table-top">
            <Button @click="toOpr('selectAll')"><Icon type="md-checkbox"/> 全部选择</Button>
            <Button @click="toOpr('cancelAll')" type="error"><Icon type="ios-square" /> 全部取消</Button>
            <Button @click="toOpr('save')" type="primary"><i class="iconfont icon-bianji"></i> 保存设置</Button>
          </div>
          <Table stripe :columns="columns" :data="groupList" :loading="loading" ref="table" :height="tableHeight - 32"></Table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRolesQueryNoPage, getPowersGroupQuery, getRolePowersQuery, setRolePowersMod } from '@/libs/api'
import { mapState } from 'vuex'
import SearchForm from '@/components/SearchForm'
import store from "../../store";
export default {
  name: "role-powers",
  components: {
    SearchForm
  },
  data () {
    return {
      roleList: [],
      groupList: [],
      noRoleUserList: [],
      searchFields: [
        {
          label: '分组名称',
          name: 'userName',
          type: 'input',
          cls: 'three'
        }
      ],
      searchParams: {},
      columns: [
        {
          type: 'index',
          width: 80,
          align: 'center',
          title: '序号'
        },
        {
          key: 'page',
          title: '分组名称',
          width: 120,
          align: 'center'
        },
        {
          key: 'userName',
          title: '权限列表',
          minWidth: 400,
          render: (h,params) => {
            let renderArr = []
            let powers = params.row.powers
            if (powers.length) {
              powers.forEach((item, index) => {
                renderArr.push(
                    h('Checkbox',{
                      attrs: {
                        value: item.checked
                      },
                      on: {
                        'on-change': (val) => {
                          let groupInd = params.index
                          this.groupList[groupInd].powers[index].checked = val
                        }
                      }
                    }, item.description)
                )
              })
            }
            return h('p', renderArr)
          }
        },
      ],
      loading: false,
      roleInd: 0,
      rolePowerObj: {}
    }
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  mounted () {
    this.$nextTick(() => {
      this.getPowerGroupList()
    })
  },
  methods: {
    toSearch (obj) {
      this.searchParams = obj
      this.getRolePowerList()
    },
    toReset (obj) {
      this.searchParams = obj
      this.getRolePowerList()
    },
    async getRoleList () {
      await getRolesQueryNoPage({}).then((res) => {
        if (res.data.code === 0) {
          this.roleList = res.data.data
          this.getRolePowerList()
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    roleChange (role, index) {
      this.roleInd = index
      this.getRolePowerList(role)
    },
    async getPowerGroupList () {
      await getPowersGroupQuery().then((res) => {
        if (res.data.code === 0) {
         this.groupList = res.data.data
          this.getRoleList()
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    async getRolePowerList (role) {
      this.loading = true
      let formData = Object.assign({}, this.searchParams, {
        roleId: role ? role.id : this.roleList[this.roleInd].id
      })
      await getRolePowersQuery(formData).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          this.rolePowerObj = res.data.data
          if (this.groupList.length) {
            this.groupList.forEach((item) => {
              let power = item.powers
              if (power.length) {
                power.forEach((p) => {
                 p.checked = this.rolePowerObj.indexOf(p.id) > -1
                })
              }
            })
          }
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    toOpr (type) {
      if (this.groupList.length) {
        let powerIds = []
        this.groupList.forEach((item) => {
          let powers = item.powers
          if (powers.length) {
            powers.forEach((p) => {
              if (['selectAll', 'cancelAll'].includes(type)) {
                p.checked = type === 'selectAll'
              } else if (type === 'save') { // 保存设置
                if (p.checked) {
                  powerIds.push(p.id)
                }
              }
            })
          }
        })
        if (type === 'save') {
          this.$Modal.confirm({
            title: '提示',
            content: '确定保存设置?',
            loading: true,
            onOk: async () => {
              setRolePowersMod({
                roleId: this.roleList[this.roleInd].id,
                powerIds: powerIds
              }).then((res) => {
                if (res.data.code === 0) {
                  this.$Message.success('操作成功')
                  this.$store.dispatch('GenerateRoutes').then(() => {
                    this.$store.dispatch('setRoles', [])
                    this.$Modal.remove()
                  })
                } else {
                  this.$Message.error(res.data.message)
                }
              })
            }
          })

        }
      } else {
        this.$Message.error('暂无数据!')
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped>

</style>
