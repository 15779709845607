<template>
  <div class="help-wrapper">
    <search-form :fields="searchFields" @on-search="toSearch" @on-reset="toReset" :okLoading="loading"></search-form>
    <div class="help-content" :style="{height: tableHeight + 'px'}">
      <div class="table-top">
        <Button @click="toOpr('add')"><Icon type="md-add"/> 新增</Button>
      </div>
      <Table stripe :columns="columns" :data="list" :loading="loading" ref="table" :height="tableHeight - 22"></Table>
      <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
    </div>
    <Modal :title="oprType === 'add' ? '新增' : '编辑'"
           v-model="showModal"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           :mask-closable="false">
      <div slot="footer">
        <Button @click="modalCancel">取消</Button>
        <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :model="modalFormData" ref="modalForm" :label-width="120" :rules="modalRuleValid" v-if="showModal">
        <FormItem label="角色名称: " class="long" prop="name">
          <Input v-model="modalFormData.name" >
          </Input>
        </FormItem>
        <FormItem label="角色备注" class="long" prop="remark">
          <Input type="textarea"  v-model="modalFormData.remark"/>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import { getRolesQuery, rolesAdd, rolesMod, rolesDel } from '@/libs/api'
import { mapState } from 'vuex'
export default {
  name: "role-manage",
  components: {
    SearchForm
  },
  data () {
    return {
      searchFields: [
        {
          label: '角色名称',
          name: 'name',
          type: 'input'
        }
      ],
      searchParams: {},
      loading: false,
      columns: [
        {
          key: 'index',
          title: '序号',
          width: 80,
          align: 'center',
          render: (h, params) => {
            let ind = params.index  + 1 + this.params.pageSize * (this.params.pageIndex - 1)
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'name',
          title: '角色名称',
          align: 'center'
        },
        {
          key: 'remark',
          title: '角色备注',
          align: 'center'
        },
        {
          key: 'createTime',
          title: '创建时间',
          align: 'center'
        },
        {
          key: 'opr',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 200,
          render: (h, params) => {
            return (
                <span class="table-opr-btn">
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'mod', params.row)}>
                  <i class="iconfont icon-bianji"></i> 编辑</a>
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'del', params.row)}>
                  <i class="iconfont icon-shanchu"></i> 删除</a>
              </span>
            )
          }
        }
      ],
      list: [],
      total: 0,
      params: {
        pageIndex: 1,
        pageSize: 20
      },
      oprType: 'add',
      btnLoading: false,
      showModal: false,
      modalFormData: {
        name: '',
        remark: ''
      },
      modalRuleValid: {
        name: [{required: true, message: '请输入角色名称!'}]
      },
      modData: {},
      showDelModal: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getList()
    })
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  methods: {
    toSearch (obj) {
      this.params.pageIndex = 1
      this.searchParams = obj
      this.getList()
    },
    toReset (obj) {
      this.searchParams = obj
      this.getList()
    },
    pageChange (pageIndex) {
      this.params.pageIndex = pageIndex
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    async getList () {
      this.loading = true
      await getRolesQuery(Object.assign({}, this.searchParams, this.params)).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          this.total = res.data.data.totalCount
          this.list = res.data.data.dataSource
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    toOpr (type, row) {
      this.oprType = type
      if (row) {
        this.modData = row
      }
      if (['add', 'mod'].includes(type)) {
        this.showModal = true
        if (type === 'mod') {
          this.initForm()
        }
      } else if (type === 'del') {
        this.$Modal.confirm({
          title: '删除',
          content: '是否要删除该角色?',
          loading: true,
          onOk: async () => {
            await rolesDel({
              id: row.id
            }).then((res) => {
              if (res.data.code === 0) {
                this.$Message.success('操作成功')
                this.getList()
                this.$Modal.remove()
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      }
    },
    modalOk () {
      this.$refs.modalForm.validate(async (valid) => {
        if (valid) {
          let url = {
            add: rolesAdd,
            mod: rolesMod
          }
          let formData = Object.assign({}, this.modalFormData)
          if (this.oprType === 'mod') {
            formData.id = this.modData.id
          }
          this.btnLoading = true
          url[this.oprType](formData).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.$Message.success('操作成功')
              this.getList()
              this.showModal = false
            } else {
              this.$Message.error(res.data.message)
            }
          })
        }
      })
    },
    modalCancel () {
      this.showModal = false
    },
    initForm () {
      Object.keys(this.modalFormData).forEach((key) => {
        this.modalFormData[key] = this.modData[key]
      })
    }
  },
  watch: {
    showModal (val) {
      if (!val) {
        this.$refs.modalForm.resetFields()
        this.btnLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
