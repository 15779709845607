<template>
  <div class="help-wrapper">
    <search-form :fields="searchFields" @on-search="toSearch" @on-reset="toReset" :okLoading="loading" :selectData="searchSelectData"></search-form>
    <div class="table-top">
      <Button href="javascript:;" @click="toOpr('add')" v-has-permi="['PRIV_DISCOUNT_CREATE']"><Icon type="md-add"/> 新增</Button>
    </div>
    <div class="help-content" :style="{height: tableHeight + 'px'}">
      <Table stripe :columns="columns" :data="list" :loading="loading" ref="table" :height="tableHeight"></Table>
    </div>
    <Page :total="total" :current="params.pageIndex" :page-size="params.pageSize" show-elevator show-sizer show-total class="table-page" @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
    <Modal title="新增"
           v-model="showModal"
           width="950"
           class="modal-detail modal-form"
           class-name="vertical-center-modal"
           :mask-closable="false">
      <div slot="footer">
        <Button @click="modalCancel">取消</Button>
        <Button type="primary" @click="modalOk" :disabled="btnLoading" :loading="btnLoading">确定</Button>
      </div>
      <Form :model="modalFormData" ref="modalForm" :label-width="140" :rules="modalRuleValid">
        <div class="form-content">
          <FormItem label="活动类型: " prop="activityType" class="three">
            <Select v-model="modalFormData.activityType">
              <Option v-for="(opt, oInd) in activityTypeOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
            </Select>
          </FormItem>
          <FormItem label="活动名称: " prop="activityName" class="three">
            <Input v-model="modalFormData.activityName" />
          </FormItem>
          <template v-if="[1, 3].includes(modalFormData.activityType)">
            <FormItem label="活动金额: " prop="activityMoney" class="three">
              <Input type="number" v-model="modalFormData.activityMoney">
                <span slot="append">元</span>
              </Input>
            </FormItem>
            <FormItem label="活动使用门槛: " prop="activityUsageMoney" class="three">
              <Input type="number" v-model="modalFormData.activityUsageMoney">
                <span slot="append">元</span>
              </Input>
            </FormItem>
          </template>
          <template v-if="modalFormData.activityType === 1">
            <FormItem label="活动开始日期: " prop="activityStartDate" class="three">
              <DatePicker type="datetime" style="width: 180px" v-model="modalFormData.activityStartDate"></DatePicker>
            </FormItem>
            <FormItem label="活动结束日期: " prop="activityEndDate" class="three">
              <DatePicker type="datetime" style="width: 180px" v-model="modalFormData.activityEndDate"></DatePicker>
            </FormItem>
            <!-- <FormItem label="活动发放数量: " prop="activityNumber" class="three">
              <Input v-model="modalFormData.activityNumber" type="number">
                <span slot="append">张</span>
              </Input>
            </FormItem>
            <FormItem label="活动有效天数: " prop="activityEffectiveDays" class="three">
              <Input v-model="modalFormData.activityEffectiveDays" type="number">
                <span slot="append">天</span>
              </Input>
            </FormItem> -->
            <FormItem label="活动有效期: " prop="lastValidTimeCoupon" class="three">
              <DatePicker type="datetime" style="width: 180px" v-model="modalFormData.lastValidTimeCoupon"></DatePicker>
            </FormItem>
            <FormItem label="可用电站: " prop="siteIds" class="long">
              <Select v-model="modalFormData.siteIds" multiple filterable>
                <Option v-for="(opt, oInd) in siteList" :key="oInd" :value="opt.siteId">{{opt.siteName}}</Option>
              </Select>
            </FormItem>
            <FormItem label="活动使用条件: " prop="activityUsageConditions" class="three">
              <Select v-model="modalFormData.activityUsageConditions">
                <Option v-for="(opt, oInd) in activityConditionOpt" :key="oInd" :value="opt.value">{{opt.label}}</Option>
              </Select>
            </FormItem>
          </template>
          <FormItem label="活动是否有效: " prop="activityIsValid" class="three">
            <RadioGroup v-model="modalFormData.activityIsValid">
              <Radio v-for="(opt, oInd) in activityIsValidOpt" :key="oInd" :label="opt.value">{{opt.label}}</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="是否首页加载: " prop="isHomeLoading" class="three">
            <RadioGroup v-model="modalFormData.isHomeLoading">
              <Radio v-for="(opt, oInd) in isValidOpt" :key="oInd" :label="opt.value - 1">{{opt.label}}</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="活动内容: " prop="activityContont" class="half">
            <Input type="textarea" v-model="modalFormData.activityContont" />
          </FormItem>
          <FormItem label="活动备注: " prop="activityRemarke" class="half">
            <Input type="textarea" v-model="modalFormData.activityRemarke" />
          </FormItem>
          <FormItem label="活动展示到应用" class="half" prop="showApps">
            <Select v-model="modalFormData.showApps" multiple>
              <Option v-for="(opt, oInd) in showAppOpt" :key="oInd" :value="opt.value" :label="opt.label"></Option>
            </Select>
          </FormItem>
          <FormItem label="活动应用到" class="half" prop="useApps">
            <Select v-model="modalFormData.useApps" multiple>
              <Option v-for="(opt, oInd) in showAppOpt" :key="oInd" :value="opt.value" :label="opt.label"></Option>
            </Select>
          </FormItem>
          <FormItem label="活动图片: " class="long" prop="activityImage">
            <UploadNew v-model="modalFormData.activityImage" name="file" :cfg="{count: 1}" v-if="showModal"></UploadNew>
          </FormItem>
        </div>
      </Form>
    </Modal>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import UploadNew from '@/components/UploadNew'
import { mapState } from 'vuex'
import { getActivityQuery, setActivityAdd, setActivityUpdate, setActivityState, querySiteAll } from '@/libs/api'
import { isValidOpt, activityConditionOpt, activityTypeOpt, activityIsValidOpt, showAppOpt } from '@/libs/dict'
import { getStorage, getSelectLabel } from '@/libs/tools'
import dayjs from 'dayjs'
let formData = {
  activityName: '',
  activityMoney: null,
  activityContont: '',
  activityStartDate: '',
  activityEndDate: '',//活动有效期
  activityNumber: 1,
  // activityEffectiveDays: null,
  activityIsValid: 1,
  activityImage: '',
  isHomeLoading: 1,
  siteIds: [],
  activityRemarke: '',
  lastValidTimeCoupon: '',//领取后剩余使用时间
  activityUsageConditions: 1,
  activityUsageMoney: null,
  activityType: 1,
  showApps: [],
  useApps: []
}
export default {
  name: "discount-manage",
  components: {
    SearchForm,
    UploadNew
  },
  data () {
    return {
      isValidOpt,
      activityConditionOpt,
      activityTypeOpt,
      activityIsValidOpt,
      showAppOpt,
      user: getStorage('user') ? JSON.parse(getStorage('user')) : {},
      searchFields: [
        {
          label: '活动名称',
          name: 'activityName',
          type: 'input'
        },
        {
          label: '活动是否有效',
          name: 'activityIsValid',
          type: 'select'
        }
      ],
      searchParams: {},
      searchSelectData: {
        activityIsValid: activityIsValidOpt
      },
      loading: false,
      total: 0,
      params: {
        pageSize: 10,
        pageIndex: 1
      },
      columns: [
        /*{
          type: 'selection',
          width: 60,
          align: 'center'
        },*/
        {
          key: 'index',
          title: '序号',
          width: 70,
          align: 'center',
          render: (h, params) => {
            let ind = params.index + 1 + (this.params.pageIndex - 1) * this.params.pageSize
            return (<span>{ind}</span>)
          }
        },
        {
          key: 'activityType',
          title: '活动类型',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let str = getSelectLabel(activityTypeOpt, params.row.activityType)
            return (<span>{str}</span>)
          }
        },
        {
          key: 'activityName',
          title: '活动名称',
          align: 'center',
          width: 150
        },
        {
          key: 'activityMoney',
          title: '活动金额',
          align: 'center',
          width: 150,
          render: (h, params) => {
            let str = `满￥${(params.row.activityUsageMoney / 100).toFixed(2)}减￥${(params.row.activityMoney / 100).toFixed(0)}`
            return (<span>{params.row.activityMoney ? str : '--'}</span>)
          }
        },
        {
          key: 'activityContont',
          title: '活动内容',
          align: 'center',
          width: 200
        },
        {
          key: 'activityStartDate',
          title: '活动日期',
          align: 'center',
          minWidth: 180
        },
        {
          key: 'activityEndDate',
          title: '活动结束日期',
          align: 'center',
          width: 180
        },
        // {
        //   key: 'activityEffectiveDays',
        //   title: '活动有效天数(天)',
        //   align: 'center',
        //   width: 150
        // },
        {
          key: 'activityIsValid',
          title: '活动是否有效',
          align: 'center',
          width: 120,
          render: (h, params) => {
            let str = ''
            activityIsValidOpt.forEach((item) => {
              if (item.value ===  params.row.activityIsValid) {
                str = item.label
                return false
              }
            })
            return (<span class={(params.row.activityIsValid === 1 ? 'open' : 'close') + ' rate-state'}>{str}</span>)
          }
        },
        {
          key: 'isHomeLoading',
          title: '是否首页加载',
          align: 'center',
          width: 120,
          render: (h, params) => {
            let str = ''
            isValidOpt.forEach((item) => {
              if (item.value - 1 ===  params.row.isHomeLoading) {
                str = item.label
                return false
              }
            })
            return (<span class={(params.row.isHomeLoading === 1 ? 'open' : 'close') + ' rate-state'}>{str}</span>)
          }
        },
        {
          key: 'siteNames',
          title: '可用站点',
          align: 'center',
          width: 150
        },
        {
          key: 'lastValidTimeCoupon',
          title: '活动有效期',
          align: 'center',
          width: 180
        },
        {
          key: 'activityUsageConditions',
          title: '活动使用条件',
          align: 'center',
          width: 120,
          render: (h, params) => {
            return (<span>抵扣服务费</span>)
          }
        },
        {
          key: 'opr',
          title: '操作',
          align: 'center',
          fixed: 'right',
          minWidth: 200,
          render: (h, params) => {
            let obj = {
              2: ['启用', 'icon-qiyong', 'open'],
              1: ['停用', 'icon-tingyong_line', 'close']
            }
            return (
                <span class="table-opr-btn">
                <a href="javascript:;" class="mod" onClick={this.toOpr.bind(this, 'mod', params.row)} v-has-permi={['PRIV_DISCOUNT_UPDATE']}>
                  <i class="iconfont icon-bianji"></i> 编辑</a>
               <a href="javascript:;" class={obj[params.row.activityIsValid][2]}
                  onClick={this.toOpr.bind(this, obj[params.row.activityIsValid][2], params.row)} v-has-permi={params.row.activityIsValid === 2 ? ['PRIV_DISCOUNT_OPEN'] : ['PRIV_DISCOUNT_CLOSE']}>
                  <i class={'iconfont ' + obj[params.row.activityIsValid][1]}></i> {obj[params.row.activityIsValid][0]}
                </a>
              </span>
            )
          }
        }
      ],
      list: [],
      oprType: '',
      modData: {},
      showModal: false,
      modalFormData: Object.assign({}, formData),
      modalRuleValid: {
        activityName: [{required: true, message: '请输入活动名称!'}],
        activityMoney: [{required: true, message: '请输入活动金额!'}],
        activityStartDate: [{required: true, message: '请选择开始日期!'}],
        activityEndDate: [{required: true, message: '请选择结束日期!'}],
        // activityNumber: [{required: true, message: '请输入活动发放数量!'}],
        // activityEffectiveDays: [{required: true, message: '请输入活动有效天数!'}],
        siteIds: [{required: true, message: '请选择可用站点!'}],
        activityUsageMoney: [
          {required: true, message: '请输入活动使用门槛!'},
          { validator: (rule, value, callback) => {
            if (Number(value) > Number(this.modalFormData.activityMoney)) {
              callback()
            } else {
              callback(new Error('活动使用门槛必须大于活动金额!'))
            }
          }}
        ],
        lastValidTimeCoupon: [{required: true, message: '请选择活动最后有效期!'}],
        activityUsageConditions: [{required: true, message: '请选择活动使用条件!'}],
        activityType: [{required: true, message: '请选择活动类型!'}],
        activityImage: [{required: true, message: '请选择活动图片!'}],
        activityContont: [{required: true, message: '请输入活动内容!'}],
        showApps: [{required: true, type: 'array', min: 1, message: '请选择活动展示到应用!'}],
        useApps: [{required: true, type: 'array', min: 1, message: '请选择活动应用!'}]
      },
      siteList: [],
      btnLoading: false
    }
  },
  computed: {
    ...mapState(['tableHeight'])
  },
  mounted () {
    this.$nextTick(() => {
      this.getSiteList()
      setTimeout(() => {
        this.getList()
      }, 200)
    })
  },
  methods: {
    pageChange (pageNum) {
      this.params.pageIndex = pageNum
      this.getList()
    },
    pageSizeChange (pageSize) {
      this.params.pageSize = pageSize
      this.getList()
    },
    toSearch (obj) {
      this.searchParams = obj
      this.params.pageIndex = 1
      setTimeout(() => {
        this.getList()
      }, 10)
    },
    toReset (obj) {
      this.searchParams = obj
      setTimeout(() => {
        this.getList()
      }, 10)
    },
    async getList () {
      this.loading = true
      await getActivityQuery(Object.assign(this.searchParams, this.params)).then((res) => {
        if (res.data.code === 0) {
          this.loading = false
          this.list = res.data.data.dataSource
          this.total =  res.data.data.totalCount
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    toOpr (type, row) {
      this.oprType = type
      if (row) {
        this.modData = row
      }
      if (['add', 'mod'].includes(type)) {
        this.showModal = true
      } else if (['open', 'close'].includes(type)) { // 启用/禁用
        let obj = {
          2: {
            name: '启用',
            value: 1
          },
          1: {
            name: '停用',
            value: 2
          }
        }
        let status = row.activityIsValid
        let name = obj[status].name
        let value = obj[status].value
        this.$Modal.confirm({
          title: name,
          content: `是否${name}该活动?`,
          onOk: async () => {
            await setActivityState({
              activityId: row.activityId,
              activityIsValid: value
            }).then((res) => {
              if (res.data.code === 0) {
                this.$Message.success('操作成功')
                this.getList()
              } else {
                this.$Message.error(res.data.message)
              }
            })
          }
        })
      }
    },
    modalCancel () {
      this.showModal = false
    },
    modalOk () {
      this.$refs.modalForm.validate(async (valid) => {
        if (valid) {
          let formData = Object.assign({}, this.modalFormData)
          let keys = ['activityStartDate', 'activityEndDate', 'lastValidTimeCoupon']
          keys.forEach((item) => {
            formData[item] = dayjs(new Date(this.modalFormData[item])).format('YYYY-MM-DD HH:mm:ss')
          })
          formData.siteIds = this.modalFormData.siteIds.join(',')
          formData.showApps = this.modalFormData.showApps.join(',')
          formData.useApps = this.modalFormData.useApps.join(',')
          if (this.oprType === 'mod') {
            formData.activityId = this.modData.activityId
          }
          formData.activityMoney = this.modalFormData.activityMoney * 100
          formData.activityUsageMoney = this.modalFormData.activityUsageMoney * 100
          let url = {
            add: setActivityAdd,
            mod: setActivityUpdate
          }
         this.btnLoading = true
          await url[this.oprType](formData).then((res) => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.$Message.success('操作成功')
              this.showModal = false
              this.getList()
            } else {
              this.$Message.error(res.data.message)
            }
          })
        }
      })
    },
    initForm () {
      if (this.oprType === 'mod') {
        Object.keys(this.modalFormData).forEach(key => {
          if (['siteIds'].includes(key)) {
            let arr = []
            this.modData[key].split(',').forEach((item) => {
              arr.push(Number(item))
            })
            this.modalFormData[key] = arr
          }  else if ( ['activityMoney', 'activityUsageMoney'].includes(key)) {
            this.modalFormData[key] =  this.modData[key] / 100
          } else if (['useApps', 'showApps'].includes(key)) {
            this.modalFormData[key] = this.modData[key] ? (this.modData[key].indexOf(',') > -1 ? this.modData[key].split(',') : this.modData[key]) : []
          } else {
            this.modalFormData[key] = this.modData[key]
          }
        })
      } else {
        this.modalFormData = Object.assign({}, formData)
      }
    },
    async getSiteList () {
      await querySiteAll().then((res) => {
        if (res.data.code === 0) {
          this.siteList = res.data.data
        }
      })
    }
  },
  watch: {
    showModal (val) {
      if (!val) {
        this.modData = {}
        this.oprType = ''
        this.$refs.modalForm.resetFields()
      } else {
        this.initForm()
      }
    }
  }
};
</script>

<style scoped lang="less">
.help-wrapper{
  .help-content{
    box-sizing: border-box;
    overflow-y: auto;
  }
}
</style>
